import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Routes } from 'app/Routes';
import { RestrictedRoute } from 'components/RestrictedRoute';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';
import { Header } from 'features/header';
import { selectIsAccountLoading, selectIsUserAuthorized } from 'auth/selectors';
import { NotFoundErrorPage } from 'features/errorHandling';

import { DoiCreate } from './DoiCreate';
import { DoiRead } from './DoiRead';
import { DoiList } from './DoiList';
import { DoiSave } from './DoiSave';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		minHeight: `calc(100vh - ${theme.spacing(HEADER_HEIGHT_SPACING)}px)`
	},
	loaderContainer: {
		position: 'absolute',
		top: theme.spacing(HEADER_HEIGHT_SPACING),
		width: '100%',
		zIndex: theme.zIndex.appBar
	}
}));

export const DoiLayout: FunctionComponent = () => {
	const classes = useStyles();

	const isAccountLoading = useSelector(selectIsAccountLoading);
	const isUserAuthorized = useSelector(selectIsUserAuthorized);

	return (
		<>
			<Header />
			<Box visibility={isAccountLoading ? 'visible' : 'hidden'} className={classes.loaderContainer}>
				<LinearProgress />
			</Box>
			<Box className={classes.container}>
				{!isAccountLoading &&
					<Switch>
						<RestrictedRoute exact path={`${Routes.Doi}/create`} component={DoiCreate} isForbidden={!isUserAuthorized} />
						<RestrictedRoute exact path={`${Routes.Doi}/list`} component={DoiList} isForbidden={!isUserAuthorized} />
						<RestrictedRoute exact path={`${Routes.Doi}/save/:doiId`} component={DoiSave} isForbidden={!isUserAuthorized} />
						<Route exact path={`${Routes.Doi}/:doiId`} component={DoiRead} />
						<Route component={NotFoundErrorPage} />
					</Switch>
				}
			</Box>
		</>
	);
};
