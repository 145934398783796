import {
	Box,
	Button,
	Divider,
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@material-ui/core';
import {
	FieldArray,
	FormikProps,
	getIn
} from 'formik';
import React, { FunctionComponent } from 'react';
import { MarkupFormFields, UiFormFields } from '../types';
import { ButtonDelete } from 'components';

export interface CollaboratorsFieldsProps {
	markupConfiguration: MarkupFormFields
	form: FormikProps<UiFormFields>
}

export const CollaboratorsFields: FunctionComponent<CollaboratorsFieldsProps> = ({
	markupConfiguration,
	form
}) => {
	const MAX_COLLABORATORS = 20;

	return <>
		<Typography component="h3" variant="subtitle1" style={{ fontSize: '1.5rem' }}>
			{markupConfiguration.collaborators.label}
		</Typography>
		<Box pb={2} />
		<Typography component="p" variant="body1">
			{markupConfiguration.collaborators.helperText}
		</Typography>
		<Box pb={2} />
		<FieldArray name='collaborators'>
			{({ push, remove }) =>
				<Box>
					{
						form.values.collaborators.length < MAX_COLLABORATORS &&
						<Box>
							<Button
								type="button"
								variant='outlined'
								color='primary'
								// eslint-disable-next-line react/jsx-no-bind
								onClick={() => push({ name: '', title: '', email: '', roleOnProject: '' })}>
								{'Add more'}
							</Button>
							<Box pb={2} />
						</Box>
					}
					{
						form.values.collaborators.map((value, index: number) => {
							const collabName = `collaborators[${index}].name`;
							const touchedCollabName: boolean = getIn(form.touched, collabName);
							const errorCollabName: string = getIn(form.errors, collabName);

							const collabRoleOnProject = `collaborators[${index}].roleOnProject`;
							const touchedCollabRoleOnProject: boolean = getIn(form.touched, collabRoleOnProject);
							const errorCollabRoleOnProject: string = getIn(form.errors, collabRoleOnProject);

							const collabTitle = `collaborators[${index}].title`;
							const touchedCollabTitle: boolean = getIn(form.touched, collabTitle);
							const errorCollabTitle: string = getIn(form.errors, collabTitle);

							const collabEmail = `collaborators[${index}].email`;
							const touchedCollabEmail: boolean = getIn(form.touched, collabEmail);
							const errorCollabEmail: string = getIn(form.errors, collabEmail);

							return <Box key={index}>
								<Box display="flex">
									<TextField
										label={'Name'}
										variant="outlined"
										fullWidth
										name={collabName}
										onChange={form.handleChange}
										value={value.name}
										helperText={touchedCollabName && errorCollabName}
										error={touchedCollabName && Boolean(errorCollabName)}
									/>
									<Box pr={2} />
									<TextField
										label={'Email'}
										variant="outlined"
										fullWidth
										name={collabEmail}
										onChange={form.handleChange}
										value={value.email}
										helperText={touchedCollabEmail && errorCollabEmail}
										error={touchedCollabEmail && Boolean(errorCollabEmail)}
									/>
								</Box>
								<Box pb={2} />
								<Box display="flex">
									<TextField
										label={'Title'}
										variant="outlined"
										fullWidth
										name={collabTitle}
										onChange={form.handleChange}
										value={value.title}
										helperText={touchedCollabTitle && errorCollabTitle}
										error={touchedCollabTitle && Boolean(errorCollabTitle)}
									/>
									<Box pr={2} />
									<FormControl
										variant="outlined"
										fullWidth
										error={touchedCollabRoleOnProject && Boolean(errorCollabRoleOnProject)}
									>
										<InputLabel id="role-on-project-input-label">{'Role On Project'}</InputLabel>
										<Select labelId="role-on-project-label"
											name={collabRoleOnProject}
											id="role-on-project-select"
											value={value.roleOnProject}
											onChange={form.handleChange}
											label="Role On Project"
										>
											<MenuItem value={'Principal Investigator'}>{'Principal Investigator'}</MenuItem>
											<MenuItem value={'Data User'}>{'Data User'}</MenuItem>
										</Select>
										<FormHelperText>
											{touchedCollabRoleOnProject && errorCollabRoleOnProject}
										</FormHelperText>
									</FormControl>
								</Box>
								<Box pb={2} />
								<Box display="flex" alignItems="flex-end">
									<ButtonDelete
										type='button'
										variant='outlined'
										// eslint-disable-next-line react/jsx-no-bind
										onClick={() => remove(index)}>
										{'remove'}
									</ButtonDelete>
								</Box>
								<Box pb={2} />
								<Divider />
								<Box pb={2} />
							</Box>;
						})
					}
				</Box>
			}
		</FieldArray>
	</>;
};
