import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { CreateUserDoiRequest, CreateUserDoiResponse } from '../types';

export function* createUserDoi(params: CreateUserDoiRequest): SagaIterator<AxiosResponse<CreateUserDoiResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params
	};

	return (yield call(networkManager.fetch, Urls.CreateUserDoi, requestOptions)) as AxiosResponse<CreateUserDoiResponse>;
}
