export function base64UrlEncode(str: string) {
	return btoa(str)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
}

export function base64UrlDecode(str: string) {
	return atob(str
		.replace(/-/g, '+')
		.replace(/_/g, '/'));
}
