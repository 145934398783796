import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, ButtonBaseActions, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { TooltipWrapper } from '../../../components';
import { DRAWER_VALUE_WIDTH } from 'styles/constants.ui';

import { FacetsBaseProps, FacetValue, SelectedFacetValue } from '../types';
import { generateFacetPanelEntityId, compareFacetValues } from '../utils';
import { selectFacetsPanelTargetSearchId } from '../selectors';
import { facetsPanelSlice } from '../slices';

export interface FacetValueItemProps extends FacetsBaseProps {
	facetValue: FacetValue
	facetName: string
	facetValueLabel: string
	selectedFacetValues: SelectedFacetValue[]
	ariaLabel?: string
	panelPath: string[]
}

const MIN_LETTERS_FOR_FACET_VALUE_TOOLTIP = 15;

const useStyles = makeStyles(() => ({
	label: {
		color: grey['800'],
		'&:hover': {
			color: grey['900']
		}
	},
	labelRoot: {
		marginRight: 0
	}
}));

export const FacetValueItem: FunctionComponent<FacetValueItemProps> = ({
	facetName,
	facetValue,
	facetValueLabel,
	selectFacetValue,
	unselectFacetValue,
	selectedFacetValues,
	ariaLabel,
	panelPath
}: FacetValueItemProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const checkboxRef = useRef<HTMLInputElement>(null);
	const actions = useRef<ButtonBaseActions>(null);

	const targetSearchId = useSelector(selectFacetsPanelTargetSearchId);

	const { value, count, from, to } = facetValue;
	const [isSelected, setIsSelected] = useState(false);

	useEffect(() => {
		let isValueSelected = false;
		if (selectedFacetValues && selectedFacetValues.length) {
			isValueSelected = selectedFacetValues.some(item => compareFacetValues(item, facetValue));
		}
		setIsSelected(isValueSelected);
	}, [selectedFacetValues, facetName, facetValue]);

	const handleChange = () => {
		if (!isSelected) {
			selectFacetValue(facetName, { value, from, to });
		} else {
			unselectFacetValue(facetName, { value, from, to });
		}
		setIsSelected(!isSelected);
	};

	useEffect(() => {
		const currentEntityId = generateFacetPanelEntityId(panelPath);
		if (currentEntityId === targetSearchId && checkboxRef && actions) {
			dispatch(facetsPanelSlice.actions.setTargetSearchId(''));
			if (checkboxRef.current && actions.current) {
				checkboxRef.current.focus();
				actions.current.focusVisible();
			}
		}
	}, [dispatch, targetSearchId, panelPath, checkboxRef, actions]);

	return (
		<Box pl={4} pr={0} display="flex" justifyContent="space-between">
			<Box>
				<FormControlLabel
					classes={{ label: classes.label, root: classes.labelRoot }}
					control={
						<Checkbox
							action={actions}
							inputRef={checkboxRef}
							data-testid="facet-value-item-checkbox"
							id={generateFacetPanelEntityId(panelPath)}
							color="primary"
							checked={isSelected}
							onChange={handleChange}
						/>
					}
					label={
						<Box width={DRAWER_VALUE_WIDTH} color={ grey['800'] }>
							<TooltipWrapper title={ facetValueLabel } disabled={ facetValueLabel.length < MIN_LETTERS_FOR_FACET_VALUE_TOOLTIP }>
								<Typography
									aria-label={ ariaLabel || facetValueLabel }
									display="block"
									component="span"
									variant="body1"
									noWrap={true}>
									{ facetValueLabel }
								</Typography>
							</TooltipWrapper>
						</Box>
					}
				/>
			</Box>
			<Box display="flex" alignItems="center" color={grey['700']}>
				<Typography component="span" variant="body2">{ count }</Typography>
			</Box>
		</Box>
	);
};
