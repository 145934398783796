import { takeEvery, StrictEffect } from 'redux-saga/effects';

import { requestAccessSlice } from '../slices';

import { getPageConfigurationSaga } from './getPageConfigurationSaga';
import { sendAccessRequestSaga } from './sendAccessRequestSaga';

export function* rootRequestAccessSaga(): Generator<StrictEffect, void> {
	yield takeEvery(requestAccessSlice.actions.pageConfigurationRequest.type, getPageConfigurationSaga);
	yield takeEvery(requestAccessSlice.actions.sendFormDataRequest.type, sendAccessRequestSaga);
}
