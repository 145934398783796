import React, { FunctionComponent, ReactElement } from 'react';

import { MarkupFormField, OmicsConfiguration, DownloadOmicLevels } from '../types';

interface FormOmicCard {
	name: string
	levels: (MarkupFormField & {
		formFieldName: keyof DownloadOmicLevels
	})[]
}
interface ChildFunction {
	formOmicCards: FormOmicCard[]
}
interface DuaFieldsProps {
	omicsConfiguration: OmicsConfiguration
	children: (params: ChildFunction) => ReactElement | ReactElement[] | null
}

export const OmicCardsInfo: FunctionComponent<DuaFieldsProps> = ({
	omicsConfiguration,
	children
}: DuaFieldsProps) => {
	const formOmicCards: FormOmicCard[] = [
		{
			name: omicsConfiguration.genomics.name,
			levels: [
				{ formFieldName: 'accessToGenomicsLevel1', ...omicsConfiguration.genomics.fields.level1 },
				{ formFieldName: 'accessToGenomicsLevel2', ...omicsConfiguration.genomics.fields.level2 },
				{ formFieldName: 'accessToGenomicsLevel3', ...omicsConfiguration.genomics.fields.level3 },
				{ formFieldName: 'accessToGenomicsLevel4', ...omicsConfiguration.genomics.fields.level4 },
			]
		},
		{
			name: omicsConfiguration.epigenomics.name,
			levels: [
				{ formFieldName: 'accessToEpigenomicsLevel1', ...omicsConfiguration.epigenomics.fields.level1 },
				{ formFieldName: 'accessToEpigenomicsLevel2', ...omicsConfiguration.epigenomics.fields.level2 },
				{ formFieldName: 'accessToEpigenomicsLevel3', ...omicsConfiguration.epigenomics.fields.level3 },
				{ formFieldName: 'accessToEpigenomicsLevel4', ...omicsConfiguration.epigenomics.fields.level4 },
			]
		},
		{
			name: omicsConfiguration.transcriptomics.name,
			levels: [
				{ formFieldName: 'accessToTranscriptomicsLevel1', ...omicsConfiguration.transcriptomics.fields.level1 },
				{ formFieldName: 'accessToTranscriptomicsLevel2', ...omicsConfiguration.transcriptomics.fields.level2 },
				{ formFieldName: 'accessToTranscriptomicsLevel3', ...omicsConfiguration.transcriptomics.fields.level3 },
				{ formFieldName: 'accessToTranscriptomicsLevel4', ...omicsConfiguration.transcriptomics.fields.level4 },
			]
		},
		{
			name: omicsConfiguration.proteomics.name,
			levels: [
				{ formFieldName: 'accessToProteomicsLevel1', ...omicsConfiguration.proteomics.fields.level1 },
				{ formFieldName: 'accessToProteomicsLevel2', ...omicsConfiguration.proteomics.fields.level2 },
				{ formFieldName: 'accessToProteomicsLevel4', ...omicsConfiguration.proteomics.fields.level4 },
			]
		},
	];

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{ children({ formOmicCards }) }
		</>
	);
};
