import React, { FunctionComponent, SyntheticEvent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { makeStyles } from '@material-ui/core/styles';

import { Routes } from 'app/Routes';
import { selectIsUserHasAccessToAdminPortal, selectUserInfo } from 'auth/selectors';
import { portalAuthManager } from 'auth/portalAuthManager';

interface MenuItemConfig {
	target: string
	handler: (event: SyntheticEvent) => void
}
interface MenuItemsConfigOverride {
	[index: string]: Partial<MenuItemConfig>
}

const useStyles = makeStyles(() => ({
	userButton: {
		textTransform: 'none'
	},
	avatar: {
		backgroundColor: 'transparent'
	}
}));

export const UserMenu: FunctionComponent = () => {
	const classes = useStyles();

	const { userName, userMenuItems } = useSelector(selectUserInfo);
	const isUserHasAccessToAdminPortal = useSelector(selectIsUserHasAccessToAdminPortal);

	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'profile-menu',
	});

	const signOutHandler = useCallback((e: SyntheticEvent) => {
		e.preventDefault();
		portalAuthManager.signOut();
	}, []);

	const defaultMenuItemConfig: MenuItemConfig = {
		target: '_self',
		handler: popupState.close
	};
	const menuItemsConfigOverride: MenuItemsConfigOverride = {
		logout: {
			handler: signOutHandler
		},
		getDownloadTool: {
			target: '_blank'
		}
	};
	function getMenuItemConfig(menuItemName: string): MenuItemConfig {
		const overrideConfig = menuItemsConfigOverride[menuItemName];
		return {
			...defaultMenuItemConfig,
			...overrideConfig
		};
	}

	return (
		<>
			<Button
				{...bindTrigger(popupState)}
				classes={{ root: classes.userButton }}
				color="inherit"
				aria-label={ 'Profile' }
				aria-haspopup="true"
				aria-expanded={popupState.isOpen}
				data-testid="profile-menu-button"
			>
				<Avatar alt={'Avatar'} classes={{ colorDefault: classes.avatar }}>
					<AccountCircle fontSize="large"/>
				</Avatar>
				<Box mx={0.5}>
					<Typography component="span" variant="body2">{ userName }</Typography>
				</Box>
				<ArrowDropDown />
			</Button>
			<Menu
				keepMounted
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				getContentAnchorEl={null}
				{...bindMenu(popupState)}
			>
				{userMenuItems.map((itemMenu, index) => {
					const itemConfig = getMenuItemConfig(itemMenu.name);
					return (
						<Link
							key={index}
							to={itemMenu.url}
							component={RouterLink}
							target={itemConfig.target}
							color="inherit"
							underline="none"
						>
							<MenuItem
								key={index}
								onClick={itemConfig.handler}
								data-testid={ `${itemMenu.name}-li` }
							>
								{ itemMenu.displayName }
							</MenuItem>
						</Link>
					);
				})}
				{
					isUserHasAccessToAdminPortal && <Link key={'admin-portal-link'} to={Routes.UsersManagement} component={RouterLink} color="inherit" underline="none">
						<MenuItem key={'admin-portal-menu-item'}>
							{ 'Admin Portal' }
						</MenuItem>
					</Link>
				}
			</Menu>
		</>
	);
};
