import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementUserSlice } from '../slices';
import { rejectUserDua } from '../api';
import { selectUser } from '../selectors';

import { getUserSaga } from './getUserSaga';

export function* rejectUserDuaSaga({ payload }: PayloadAction<{ rejectionReason: string }>): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.rejectUserDuaStart());

			yield call(rejectUserDua, { id: user.id, rejectionReason: payload.rejectionReason });
			yield call(getUserSaga, user.id);

			yield put(usersManagementUserSlice.actions.rejectUserDuaEnd());
		} catch (e) {
			yield put(usersManagementUserSlice.actions.rejectUserDuaEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
