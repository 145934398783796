import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';

interface WPFrameProps {
	link: string
}

enum PostMessageEvents {
	contentLoaded = 'contentLoaded'
}
interface PostMessage {
	eventName: PostMessageEvents
	data?: Record<string, unknown>
}

export const WPFrame: FunctionComponent<WPFrameProps> = ({ link }) => {
	const iFrameRef = useRef<HTMLIFrameElement>(null);

	const onPostMessage = useCallback((e: MessageEvent) => {
		const message = e.data as PostMessage;

		if (message.eventName === PostMessageEvents.contentLoaded) {
			// eslint-disable-next-line no-unused-expressions
			iFrameRef.current?.contentWindow?.postMessage({
				eventName: 'init',
				data: { origin: window.location.origin }
			}, '*');
		}
	}, [iFrameRef]);

	useEffect(() => {
		window.addEventListener('message', onPostMessage);
		return () => window.removeEventListener('message', onPostMessage);
	}, [onPostMessage]);

	return (
		// eslint-disable-next-line jsx-a11y/iframe-has-title
		<iframe
			ref={iFrameRef}
			id="static-page-frame"
			src={link}
			width="100%"
			style={{ height: 'calc(100% - 85px)', border: 'none' }}
			scrolling="auto"
		>
			{ 'Your browser does not support iframe' }
		</iframe>
	);
};
