import { fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { rootSearchSaga } from 'features/search';
import { rootCaseSetSaga } from 'features/search/features/caseSet';
import { rootAuthSaga } from 'auth/sagas';
import { rootProceedSearchResultSaga } from 'features/proceedSearchResult';
import { rootTotpAuthenticationSaga } from 'features/totpAuthentication';
import { rootPageSaga } from 'features/wpFrame';
import { rootRequestAccessSaga } from 'features/requestAccess';
import { rootSubmitDataSaga } from 'features/submitData';
import { omicL4VisualizationRootSaga } from 'features/visualization/features/omicL4';
import { rootUsersManagementSaga } from 'features/administration/features/usersManagement';
import { rootDoiSaga } from 'features/doi';

import { initAppSaga } from './sagas/initAppSaga';
import { rootIndexManagementSaga } from 'features/administration/features/indexManagement/sagas';
import { rootStatisticsSaga } from 'features/administration/features/statistics/sagas';
import { rootReleaseDataManagementSaga } from 'features/administration/features/releaseData/sagas';
import { rootAddiDataRequestsSaga } from 'features/administration/features/addiDataRequests/sagas';

export function* rootSaga(): SagaIterator {
	yield fork(initAppSaga);
	yield fork(rootSearchSaga);
	yield fork(rootCaseSetSaga);
	yield fork(rootAuthSaga);
	yield fork(rootProceedSearchResultSaga);
	yield fork(rootTotpAuthenticationSaga);
	yield fork(rootPageSaga);
	yield fork(rootRequestAccessSaga);
	yield fork(rootSubmitDataSaga);
	yield fork(omicL4VisualizationRootSaga);
	yield fork(rootUsersManagementSaga);
	yield fork(rootDoiSaga);
	yield fork(rootIndexManagementSaga);
	yield fork(rootStatisticsSaga);
	yield fork(rootReleaseDataManagementSaga);
	yield fork(rootAddiDataRequestsSaga);
}
