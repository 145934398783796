import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from './constants';
import { UserInfo } from './types';

interface AccountState {
	isUserAuthorized: boolean
	userInfo: UserInfo
	isInitLoading: boolean
	isAccountDetailsLoading: boolean
}

const initialState: AccountState = {
	isUserAuthorized: false,
	userInfo: {
		userName: '',
		userEmails: [],
		userRoles: [],
		userMenuItems: [],
		dataRequestStatus: null
	},
	isInitLoading: true,
	isAccountDetailsLoading: false
};

export const accountSlice = createSlice({
	name: `${ACTIONS_PREFIX}.info`,
	initialState,
	reducers: {
		initStart: (state: AccountState) => {
			state.isInitLoading = true;
		},
		initComplete: (state: AccountState) => {
			state.isInitLoading = false;
		},

		getUserDetails: (state: AccountState) => {
			state.isAccountDetailsLoading = true;
		},
		getUserDetailsSuccess: (state: AccountState) => {
			state.isAccountDetailsLoading = false;
		},
		getUserDetailsFail: (state: AccountState) => {
			state.isAccountDetailsLoading = false;
		},

		setIsAuthorized: (state: AccountState, { payload }: PayloadAction<boolean>) => {
			state.isUserAuthorized = payload;
		},

		setUserInfo: (state: AccountState, { payload }: PayloadAction<Partial<UserInfo>>) => {
			state.userInfo = {
				...state.userInfo,
				...payload
			};
		}
	}
});
