import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { statisticsSlice } from '../slices';
import { selectTotalAmountOfDownloadedData } from '../selectors';
import { getTotalAmountOfDownloadedData } from '../api';
import { GetTotalAmountOfDownloadedDataResponse } from '../types';

export function* getTotalAmountOfDownloadedDataSaga(): SagaIterator {
	try {
		yield put(statisticsSlice.actions.getTotalAmountOfDownloadedDataStart());

		const totalAmountOfDownloadedData = (yield select(selectTotalAmountOfDownloadedData)) as ReturnType<typeof selectTotalAmountOfDownloadedData>;

		if (totalAmountOfDownloadedData === null) {
			const response = (yield call(getTotalAmountOfDownloadedData)) as AxiosResponse<GetTotalAmountOfDownloadedDataResponse>;
			yield put(statisticsSlice.actions.getTotalAmountOfDownloadedDataEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(statisticsSlice.actions.getTotalAmountOfDownloadedDataEnd({ endState: SagaEndState.Success, data: totalAmountOfDownloadedData }));
		}
	} catch (e) {
		yield put(statisticsSlice.actions.getTotalAmountOfDownloadedDataEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
