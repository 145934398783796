import { takeEvery, StrictEffect } from 'redux-saga/effects';

import { totpAuthenticationCodeSlice } from '../slices';

import { invokeCodeUpdaterSaga } from './invokeCodeUpdaterSaga';
import { generateCodeSaga } from './generateCodeSaga';

export function* rootTotpAuthenticationSaga(): Generator<StrictEffect, void> {
	yield takeEvery(totpAuthenticationCodeSlice.actions.startCodeUpdater.type, invokeCodeUpdaterSaga);
	yield takeEvery(totpAuthenticationCodeSlice.actions.generateCodeRequest.type, generateCodeSaga);
}
