import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { indexManagementSlice } from '../slices';
import { selectCreateManagementConfig } from '../selectors';
import { getCreateManagementConfig } from '../api/getCreateManagementConfig';
import { CreateManagementConfigResponse } from '../types';
import { handleErrorSaga } from 'features/errorHandling/sagas';


export function* getCreateManagementConfigSaga(): SagaIterator {
	try {
		yield put(indexManagementSlice.actions.getCreateManagementConfigStart());

		const createManagementConfig = (yield select(selectCreateManagementConfig)) as ReturnType<typeof selectCreateManagementConfig>;

		if (createManagementConfig === null) {
			const response = (yield call(getCreateManagementConfig)) as AxiosResponse<CreateManagementConfigResponse>;
			yield put(indexManagementSlice.actions.getCreateManagementConfigEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(indexManagementSlice.actions.getCreateManagementConfigEnd({ endState: SagaEndState.Success, data: createManagementConfig }));
		}
	} catch (e) {
		yield put(indexManagementSlice.actions.getCreateManagementConfigEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
