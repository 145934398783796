import { SagaIterator } from 'redux-saga';

import { addiDataRequestDetailsSlice, addiDataRequestsListSlice } from '../slices';
import { takeEvery } from '@redux-saga/core/effects';
import { getAddiDataRequestsListSaga } from './getAddiDataRequestsListSaga';
import { getAddiDataRequestDetailsFlowSaga } from './getAddiDataRequestDetailsFlowSaga';
import { fork } from 'redux-saga/effects';
import { approveAddiDataRequestSaga } from './approveAddiDataRequestSaga';
import { rejectAddiDataRequestSaga } from './rejectAddiDataRequestSaga';
import { sendAddiSignDataRequestSaga } from './sendAddiSignDataRequestSaga';
import { updateAddiDataRequestSaga } from './updateAddiDataRequestSaga';
import { getAddiDocuSignSaga } from './getAddiDocuSignSaga';
import { takeLatestWithDelay } from 'utils';
import { MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS } from 'features/administration/constants';
import { exportAddiDataRequestsSaga } from './exportAddiDataRequestsSaga';

export function* rootAddiDataRequestsSaga(): SagaIterator {
	yield fork(getAddiDataRequestDetailsFlowSaga);
	yield takeEvery(addiDataRequestsListSlice.actions.getAddiDataRequestsList.type, getAddiDataRequestsListSaga);
	yield takeEvery(addiDataRequestsListSlice.actions.exportAddiDataRequests.type, exportAddiDataRequestsSaga);
	yield takeLatestWithDelay(
		MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS,
		[
			addiDataRequestsListSlice.actions.setRowsPerPage.type,
			addiDataRequestsListSlice.actions.setCurrentPage.type,
			addiDataRequestsListSlice.actions.setFilterValue.type,
			addiDataRequestsListSlice.actions.setSorting.type,
			addiDataRequestsListSlice.actions.setSearchQuery.type
		],
		getAddiDataRequestsListSaga);

	yield takeEvery(addiDataRequestDetailsSlice.actions.approveAddiDataRequest.type, approveAddiDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.rejectAddiDataRequest.type, rejectAddiDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequest.type, sendAddiSignDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.updateAddiDataRequest.type, updateAddiDataRequestSaga);
	yield takeEvery(addiDataRequestDetailsSlice.actions.downloadAddiDocuSign.type, getAddiDocuSignSaga);
}
