import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Button,
	SnackbarContent,
	Snackbar
} from '@material-ui/core';

import { selectCaseSetErrorId } from '../selectors';
import { caseSetSlice } from '../slices';
import { useTranslation } from 'react-i18next';

export const CaseSetSnackbar: FunctionComponent = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const caseSetErrorId = useSelector(selectCaseSetErrorId);

	const closeSnackbar = useCallback(() => {
		dispatch(caseSetSlice.actions.resetValidationError());
	}, [dispatch]);

	const seeDetails = useCallback(() => {
		if (caseSetErrorId !== null) {
			dispatch(caseSetSlice.actions.startModalOpen({ editCaseSetId: caseSetErrorId, showNotFounded: true }));
		}
		closeSnackbar();
	}, [dispatch, closeSnackbar, caseSetErrorId]);

	return (
		<Snackbar open={caseSetErrorId !== null} autoHideDuration={null} onClose={closeSnackbar}>
			<SnackbarContent
				message={ t('caseSet.submit.notFoundAlert') }
				action={
					<Button color="inherit" size="large" onClick={seeDetails}>
						{ t('caseSet.submit.notFoundAlertButton') }
					</Button>
				}
			/>
		</Snackbar>
	);
};
