import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { selectIsUserAuthorized, selectUserDataAccessStatus } from 'auth/selectors';
import { UserDataAccessStatuses } from 'auth/types';
import { PendingApproval } from 'features/publicLayout/components/PendingApproval';
import { RequestDataAccess } from './../../publicLayout/components/RequestDataAccess';
import { RequestDataRejected } from './../../publicLayout/components/RequestDataRejected';
import { Search } from './Search';
import { Footer } from 'features/footer';
import data from './../../publicLayout/data/text.json';

export const SearchLayout: FunctionComponent = () => {

	const isUserAuthorized = useSelector(selectIsUserAuthorized);
	const isUserRequestedAccessToData = useSelector(selectUserDataAccessStatus) === UserDataAccessStatuses.Requested;
	const isUserHasAccessToData = useSelector(selectUserDataAccessStatus) === UserDataAccessStatuses.HasAccess;
	const isUserHasNoAccessToData = useSelector(selectUserDataAccessStatus) === UserDataAccessStatuses.NoAccess;
	const isUserRejectedAccessToData = useSelector(selectUserDataAccessStatus) === UserDataAccessStatuses.Rejected;

	return (
		<>
			{/* Anonymous - user is not logged in */}
			{!isUserAuthorized &&
				<RequestDataAccess showRequestDataButton={false}/>
			}
			{/* Has not yet Requested */}
			{isUserAuthorized && isUserHasNoAccessToData &&
				<RequestDataAccess showRequestDataButton={true} />
			}
			{/* Researcher - Approved */}
			{isUserAuthorized && isUserHasAccessToData &&
				<Search />
			}
			{/* Requested access to data */}
			{isUserAuthorized && isUserRequestedAccessToData &&
				<PendingApproval />
			}
			{/* Rejected access to data */}
			{isUserRejectedAccessToData &&
				<RequestDataRejected />
			}
			<Footer links={data.footer.links} />
		</>
	);
};
