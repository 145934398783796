import React, { FunctionComponent } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { DataOperation } from '../types/ReleaseOperations';

interface OperationsTableProps {
	releaseOperations: DataOperation[] | undefined
}

export const OperationsTable: FunctionComponent<OperationsTableProps> = ({ releaseOperations }: OperationsTableProps) => {

	const tableData: DataOperation[] = releaseOperations ? releaseOperations : [];

	const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour12: true
	};

	return (
		<Box>
			<Typography component="h6" variant="h6">
				{'Release Operations'}
			</Typography>
			<Box pb={2}></Box>
			<Paper>
				<Table size="small">
					<TableHead>
						<TableCell>
							{'Id'}
						</TableCell>
						<TableCell>
							{'Status'}
						</TableCell>
						<TableCell>
							{'Total'}
						</TableCell>
						<TableCell>
							{'Errors'}
						</TableCell>
						<TableCell>
							{'Started By'}
						</TableCell>
						<TableCell>
							{'Started'}
						</TableCell>
						<TableCell>
							{'Completed'}
						</TableCell>

					</TableHead>
					<TableBody>
						{tableData.map(row =>
							<TableRow key={row.releaseId}>
								<TableCell component="th">
									{row.releaseId}
								</TableCell>
								<TableCell>
									{row.status}
								</TableCell>
								<TableCell>
									{row.countCompleted + row.countError + row.countInProgress}
								</TableCell>
								<TableCell>
									{row.countError}
								</TableCell>
								<TableCell>
									{row.startedByName}
								</TableCell>
								<TableCell>
									{new Intl.DateTimeFormat('en-US', dateTimeFormatOptions).format(new Date(row.startedDateTime))}
								</TableCell>
								<TableCell>
									{row.completedDateTime ? new Intl.DateTimeFormat('en-US', dateTimeFormatOptions).format(new Date(row.completedDateTime)) : ''}
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</Paper>
		</Box>
	);
};
