import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from '../../../network';

export const getDownloadMetadataLink = async (filter: string): Promise<AxiosResponse> => {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
		data: { filter },
	};

	return networkManager.fetch(Urls.GetDownloadMetadataLink, requestOptions);
};
