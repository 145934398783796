import { SagaIterator } from 'redux-saga';
import { put, select } from 'redux-saga/effects';

import { selectDefaultFacetsCategory } from '../../../selectors';
import { facetsPanelSlice } from '../../../slices';

import { caseSetSlice } from '../slices';

export function* showCaseSetsPanelSaga(): SagaIterator {
	yield put(caseSetSlice.actions.expandCaseSetGroup());

	const defaultCategory = (yield select(selectDefaultFacetsCategory)) as ReturnType<typeof selectDefaultFacetsCategory>;
	if (defaultCategory !== null) {
		yield put(facetsPanelSlice.actions.setActiveTab({ tabName: defaultCategory.name }));
	}
}
