import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { AppBar, Box, Theme, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { TooltipWrapper } from '../../../components';
import { FOOTER_HEIGHT_SPACING } from 'styles/constants.ui';

import { selectEntitiesCount } from '../selectors';

import { ProceedButtonWithHover } from './ProceedButtonWithHover';

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		top: 'auto',
		bottom: 0,
		backgroundColor: '#ffffff'
	},
	footerToolBar: {
		height: theme.spacing(FOOTER_HEIGHT_SPACING),
		minHeight: theme.spacing(FOOTER_HEIGHT_SPACING),
	},
	divider: {
		opacity: 0.5
	}
}));

export const SearchFooterUpdated: FunctionComponent = () => {
	const classes = useStyles();

	const entitiesCount = useSelector(selectEntitiesCount);

	return (
		<AppBar component="div" position="fixed" color="default" className={classes.appBar}>
			<Toolbar className={ classes.footerToolBar }>
				<Box width='100%' display='flex' flexDirection='flex-row' justifyContent='space-between' alignItems='center'>
					<Box
						component="h2"
						m={0}
						display="flex"
						flexDirection="flex-row"
						alignItems="center"
						color={grey['900']}
						flexWrap="wrap"
					>
						{entitiesCount.map(({ name: entityName, description: entityDescription, count: entityCount }) =>
							<Box key={entityName} mr={2} display="flex">
								<TooltipWrapper title={entityDescription || entityName}>
									<div>
										<Typography component='span' variant='h5' style={{ paddingRight: '5px' }}>{ entityCount }</Typography>
										<Typography component='span' variant='subtitle1'>{ entityName }</Typography>
									</div>
								</TooltipWrapper>
							</Box>
						)}
					</Box>
					<ProceedButtonWithHover />
				</Box>
			</Toolbar>
		</AppBar>
	);
};
