import { call, put, StrictEffect, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from '../../errorHandling/sagas';
import { alertSlice } from '../../alert/slices';

import { Configuration, UserDuaInformation } from '../types';
import { requestAccessSlice } from '../slices';
import { sendAccessRequest } from '../api';
import { selectConfiguration } from '../selectors';
import { getUserDetailsSaga } from 'auth/sagas/getUserDetailsSaga';

export function* sendAccessRequestSaga({ payload: formData }: PayloadAction<UserDuaInformation>): Generator<StrictEffect, void> {
	try {
		yield call(sendAccessRequest, formData);

		yield put(requestAccessSlice.actions.sendFormDataSuccess());

		yield call(getUserDetailsSaga);

		const pageConfiguration = (yield select(selectConfiguration)) as Configuration;

		if (pageConfiguration.successSubmit) {
			window.scrollTo(0, 0);
			yield put(push('/search'));
			yield put(alertSlice.actions.showAlert({ message: pageConfiguration.successSubmit }));
		}
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(requestAccessSlice.actions.sendFormDataFailure());
	}
}
