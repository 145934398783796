/* es-lint-disable @typescript-eslint/no-unsafe-argument */
import { FormikConfig, FormikValues } from 'formik';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

const DEFAULT_DEBOUNCE_TIME = 200;

export function useDebouncedValidate<T extends FormikValues>({
	values,
	validate,
	debounceTime = DEFAULT_DEBOUNCE_TIME,
}: {
	values: T
	validate: FormikConfig<T>['validate']
	debounceTime?: number
}) {
	const debouncedFunction = useRef(
		debounce((validateFunc: FormikConfig<T>['validate'], data: T) => {
			if (validateFunc) {
				return validateFunc(data);
			}
		}, debounceTime),
	);

	const debounceValidate = useCallback((data: T) => {
		return debouncedFunction.current(validate, data);
	}, [validate]);

	useEffect(() => {
		debounceValidate(values);
	}, [values]);

	useEffect(() => {
		return () => {
			debouncedFunction.current.cancel();
		};
	}, []);
}
