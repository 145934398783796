export const getBlobText = async (blobInstance: Blob): Promise<string> => {
	if (typeof blobInstance.text !== 'function') {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.onload = () => resolve(reader.result as string);
			reader.readAsText(blobInstance);
		});
	}
	return blobInstance.text();
};
