import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { ExportUsersRequest, ExportUsersResponse } from '../types';

export function* exportUsers(params: ExportUsersRequest): SagaIterator<AxiosResponse<ExportUsersResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
		data: params
	};

	return (yield call(networkManager.fetch, Urls.ExportUsers, requestOptions)) as AxiosResponse<ExportUsersResponse>;
}

