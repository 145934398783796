import React, { FunctionComponent, useEffect, useState } from 'react';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

interface SnackbarWrapperProps {
	open: boolean
	message: string
	onClose: () => void
}

const MIN_AUTO_HIDE_DURATION = 4000;
const HIDE_DURATION_PER_SYMBOL = 150;

export const SnackbarWrapper: FunctionComponent<SnackbarWrapperProps> = ({
	open: isOpen,
	message,
	onClose
}: SnackbarWrapperProps) => {
	const [autoHideDuration, setAutoHideDuration] = useState<number | null>(null);

	useEffect(() => {
		const hideTime = Math.max(MIN_AUTO_HIDE_DURATION, message.length * HIDE_DURATION_PER_SYMBOL);
		setAutoHideDuration(hideTime);
	}, [message]);

	return (
		<Snackbar
			open={ isOpen }
			autoHideDuration={ autoHideDuration }
			onClose={ onClose }
		>
			<SnackbarContent
				message={ message }
				action={
					<IconButton
						aria-label="close"
						color="inherit"
						onClick={ onClose }
						data-testid="close-button"
					>
						<Close/>
					</IconButton>
				}
			/>
		</Snackbar>
	);
};
