export enum ApiApplicationType {
	Search,
	ProceedSearch,
	Billing,
	UsersManagement,
	Wordpress,
	ExtInt,
	Doi,
	IndexManagement,
	Statistics
}
