import React, { FunctionComponent } from 'react';

import Box from '@material-ui/core/Box';

import {
	DefaultFacetsConfig,
	DetailsFacetsConfig,
	Facets,
	FacetsBaseProps,
	SelectedFacets,
	DisplayedFacetsInCardView
} from '../types';

import { FacetsGrid } from './FacetsGrid';

interface SearchCardViewProps extends FacetsBaseProps {
	facets: Facets
	defaultFacetsConfig: DefaultFacetsConfig
	detailsFacetsConfig: DetailsFacetsConfig
	displayedFacetsInCardView: DisplayedFacetsInCardView
	selectedFacets: SelectedFacets
	hideFacetInCardView: (facetName: string) => void
}

export const SearchCardView: FunctionComponent<SearchCardViewProps> = ({
	facets,
	defaultFacetsConfig,
	detailsFacetsConfig,
	selectedFacets,
	displayedFacetsInCardView,
	hideFacetInCardView,
	selectFacetValue,
	unselectFacetValue
}: SearchCardViewProps) => <Box py={1.5}>
	<FacetsGrid
		facets={ facets }
		defaultFacetsConfig={ defaultFacetsConfig }
		selectedFacets={ selectedFacets }
		detailsFacetsConfig={ detailsFacetsConfig }
		displayedFacetsInCardView={ displayedFacetsInCardView }
		hideFacetInCardView={ hideFacetInCardView }
		selectFacetValue={ selectFacetValue }
		unselectFacetValue={ unselectFacetValue }
	/>
</Box>;
