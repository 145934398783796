import { SagaIterator } from 'redux-saga';
import { getOmicsReleaseReadySaga } from './getOmicsReleaseReadySaga';

import { releaseDataManagementSlice } from '../slices';
import { takeEvery } from '@redux-saga/core/effects';
import { startReleaseProcessSaga } from './startReleaseProcessSaga';
import { getReleaseOperationsSaga } from './getReleaseOperationsSaga';
import { exportOmicsSaga } from './exportOmicsSaga';

export function* rootReleaseDataManagementSaga(): SagaIterator {
	yield takeEvery(releaseDataManagementSlice.actions.getOmicsReleaseReady.type, getOmicsReleaseReadySaga);
	yield takeEvery(releaseDataManagementSlice.actions.getReleaseOperations.type, getReleaseOperationsSaga);
	yield takeEvery(releaseDataManagementSlice.actions.startReleaseProcess.type, startReleaseProcessSaga);
	yield takeEvery(releaseDataManagementSlice.actions.exportOmics.type, exportOmicsSaga);
}
