import { BrowserCacheItem } from '../BrowserCache';

export interface ClientSettings {
	tenant: string;
	clientId: string;
	loginRegisteredRedirectUri: string;
	codeScopes: string[];
	tokenScopes: string[];
	defaultPolicy: string;
	name?: string;
}

export interface LoginSettings {
	policy?: string;
	callbackUri?: string;
}

export interface LogoutSettings {
	policy?: string;
	callbackUri?: string;
}

export enum CacheKeys {
	REDIRECT_INFO = 'REDIRECT_INFO',
	TOKEN_INFO = 'TOKEN_INFO',
	ACCOUNT_INFO = 'ACCOUNT_INFO'
}

export enum RedirectState {
	GET_AUTHORIZATION_CODE_ATTEMPT = 'GET_AUTHORIZATION_CODE_ATTEMPT',
	REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR = 'REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR',
	REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_AUTHORIZATION_CODE = 'REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_AUTHORIZATION_CODE',
	LOGOUT_ATTEMPT = 'LOGOUT_ATTEMPT'
}

export enum RedirectPromiseStates {
	PENDING,
	IN_PROGRESS,
	COMPLETED
}

export interface ErrorResponse {
	error: string;
	error_description: string;
}

export interface TokenResponse {
	token_type: string;
	access_token: string;
	expires_on: number;
	expires_in: number;
	refresh_token: string;
	refresh_token_expires_in: number;
	id_token: string;
	id_token_expires_in: number;
	not_before: number;
	resource: string;
	profile_info: string;
	scope: string;
}

export interface IdTokenInfo {
	name: string;
	emails: string[];
	tfp: string;
}

export interface LoginRedirectState {
	salt: string;
}

export interface TokenInfo extends BrowserCacheItem {
	accessToken: string;
	expiresOn: number;
	refreshToken: string;
	policy: string;
}

export interface AccountInfo extends BrowserCacheItem {
	name: string;
	emails: string[];
}

export interface AcquireTokenOptions {
	forceRefresh?: boolean;
	refreshThresholdBeforeExpirationInSec?: number;
}

export interface ParsedTokenResponse {
	accountInfo: AccountInfo;
	tokenInfo: TokenInfo;
}

export interface RedirectCache extends BrowserCacheItem {
	step: RedirectState;
}

export interface GetAuthorizationCodeAttemptCache extends RedirectCache {
	codeVerifier: string;
	state: string;
	policy: string;
	callbackUri: string;
}

export interface RedirectToLoginCallbackUriWithErrorCache extends RedirectCache {
	errorCode: string;
	errorMessage: string;
}

export interface RedirectToLoginCallbackUriWithAuthorizationCodeCache extends RedirectCache {
	code: string;
	codeVerifier: string;
	policy: string;
}

export interface LogoutAttemptCache extends RedirectCache {
	callbackUri: string;
}
