/**
 * Calls a `worker` on each action dispatched to the Store that matches
 * `pattern`. And automatically cancels any previous `worker` started
 * previously if it's still running and `ms` time is up. `ms` is a min
 * delay between calling `worker` tasks.
 * It is a non-blocking helper.
 */

import { ActionPattern } from '@redux-saga/types';
import { actionChannel, all, call, delay, fork, ForkEffect, race, take } from 'redux-saga/effects';
import { buffers, Channel, SagaIterator } from 'redux-saga';
import { Action, PayloadAction } from '@reduxjs/toolkit';

type Worker =
	(() => SagaIterator) |
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	((action: PayloadAction<any>) => SagaIterator)
;

export const takeLatestWithDelay = (
	ms: number,
	pattern: ActionPattern,
	worker: Worker
): ForkEffect => fork(function* (): SagaIterator {
	const actionsBuffer = buffers.sliding<Action>(1);
	const channel = (yield actionChannel(pattern, actionsBuffer)) as Channel<PayloadAction>;
	let action: PayloadAction | undefined;

	while (true) {
		action = (yield take(channel)) as PayloadAction;

		while (true) {
			[[action]] = (yield all([
				race([
					take(channel),
					call(worker, action)
				]),
				delay(ms)
			])) as [[PayloadAction | undefined]];

			if (action) {
				if (!actionsBuffer.isEmpty()) {
					action = (yield take(channel)) as PayloadAction;
				}
			} else {
				break;
			}
		}
	}
});
