import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

import { LoaderButton } from 'components';
import { Footer } from 'features/footer';

import { DoiCreateFormFields, DownloadCreateFileListRequest } from '../types';
import { doiSlice } from '../slices';
import {
	selectDoiConfig,
	selectIsCreateUserDoiLoading,
	selectIsSaveUserDoiLoading,
	selectIsDoiConfigLoading,
	selectIsDownloadCreateFileListLoading,
	selectIsOmicsInitialized,
	selectIsOmicsLoading,
	selectUserOmics
} from '../selectors';

import { DoiOmicLevels } from './DoiOmicLevels';

const FIELD_ERROR_TEXT = 'Please fill the data';
const TEXTAREA_Y_PADDING = 2.3125;
const TEXTAREA_X_PADDING = 1.75;

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		height: '100%',
		minHeight: 'inherit',
		backgroundColor: theme.palette.common.white,
		position: 'relative'
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		padding: theme.spacing(3)
	},
	textareaRoot: {
		'& > div': {
			backgroundColor: 'transparent',
			zIndex: 1,
			padding: theme.spacing(TEXTAREA_Y_PADDING, 0, 0, 0),
			'& > textarea': {
				padding: theme.spacing(0, TEXTAREA_X_PADDING, TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
				resize: 'vertical',
				minHeight: '100px',
				'&::-webkit-scrollbar': {
					width: '10px'
				}
			}
		}
	},
}));

const validationSchema = yup.object().shape({
	title: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT),
	principalInvestigator: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT),
	description: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT)
});

// eslint-disable-next-line sonarjs/cognitive-complexity
export const DoiCreate: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();

	const isOmicsLoading = useSelector(selectIsOmicsLoading);
	const isOmicsInitialized = useSelector(selectIsOmicsInitialized);
	const userOmics = useSelector(selectUserOmics);
	const isCreateUserDoiLoading = useSelector(selectIsCreateUserDoiLoading);
	const isSaveUserDoiLoading = useSelector(selectIsSaveUserDoiLoading);
	const isDownloadCreateFileListLoading = useSelector(selectIsDownloadCreateFileListLoading);
	const isDoiConfigLoading = useSelector(selectIsDoiConfigLoading);
	const doiConfig = useSelector(selectDoiConfig);

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const form = useFormik<DoiCreateFormFields>({
		initialValues: {
			id: null,
			title: '',
			principalInvestigator: '',
			description: '',
			published: false,
			omics: {}
		},
		validationSchema,
		onSubmit: values => {
			if (form.values.published) {
				dispatch(doiSlice.actions.createUserDoi({
					id: null,
					title: values.title.trim(),
					principalInvestigator: values.principalInvestigator,
					description: values.description,
					published: true,
					omics: userOmics.map(omic => ({
						...omic,
						levels: omic.levels.map((omicLevel, index) => ({
							...omicLevel,
							selected: values.omics[omic.type][index]
						}))
					}))
				}));
			} else {
				dispatch(doiSlice.actions.saveUserDoi({
					id: null,
					title: form.values.title.trim(),
					principalInvestigator: form.values.principalInvestigator,
					description: form.values.description,
					published: false,
					omics: userOmics.map(omic => ({
						...omic,
						levels: omic.levels.map((omicLevel, index) => ({
							...omicLevel,
							selected: form.values.omics[omic.type][index]
						}))
					}))
				}));
			}
		}
	});

	useEffect(() => {
		void form.setFieldValue('omics', userOmics.reduce((omics: Record<string, boolean[]>, omic) => {
			omics[omic.type] = omic.levels.map(level => level.selected);
			return omics;
		}, {}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userOmics]);

	useEffect(() => {
		dispatch(doiSlice.actions.getUserOmics());
		dispatch(doiSlice.actions.getDoiConfig());
	}, [dispatch]);

	const onPublishClick = useCallback(() => {
		setIsConfirmOpen(false);
		form.values.published = true;
		form.handleSubmit();
	}, [form]);

	const onSaveClick = useCallback(() => {
		form.values.published = false;
		form.handleSubmit();
	}, [form]);

	const onDownloadClick = useCallback(() => {
		dispatch(doiSlice.actions.downloadCreateFileList(
			Object.entries(form.values.omics).reduce((selectedOmics: DownloadCreateFileListRequest, [omicKey, omicLevelStates]) => {
				const levels = omicLevelStates
					// eslint-disable-next-line no-confusing-arrow
					.map((state, index) => state ? String(index + 1) : null)
					.filter((state): state is string => state !== null);

				if (levels.length > 0) {
					selectedOmics[omicKey] = levels;
				}

				return selectedOmics;
			}, {})
		));
	}, [dispatch, form]);

	const isSomeLevelSelected = Object.values(form.values.omics).flat().some(levelState => levelState);

	const onListItemClick = (type: string, level: number) => {
		void form.setFieldValue(`omics[${type}][${level}]`,!form.values.omics[type][level]);
	};

	const onRemoveCancelClick = useCallback(() => {
		setIsConfirmOpen(false);
	}, []);

	return (
		<>
			<Box className={classes.containerWrapper}>
				<Box
					visibility={isOmicsLoading || isDoiConfigLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress />
				</Box>
				<Container className={classes.container}>
					{doiConfig !== null && !isOmicsLoading &&
					<Box display={ 'flex' } justifyContent={ 'space-between' }>
						<Box maxWidth={600}>
							<Box pt={2} />
							<Typography component="h1" variant="h3">
								{ 'Answer ALS DOI' }
							</Typography>
							<Box pb={2} />
							<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
								{ doiConfig.text.createPage.metadataSection.title }
							</Typography>
							<Box pb={2} />
							<Typography component="p" variant="body1" >
								{ doiConfig.text.createPage.metadataSection.description }
							</Typography>
							<Box width={520} py={3} >
								<TextField
									name="title"
									id={ 'doi-form-field-title' }
									label={ 'Title' }
									variant="outlined"
									fullWidth
									value={ form.values.title }
									onChange={ form.handleChange }
									error={ form.touched.title && Boolean(form.errors.title) }
									helperText={ form.touched.title && form.errors.title }
								/>
								<Box pb={3} />
								<TextField
									name="principalInvestigator"
									id={ 'doi-form-field-principalInvestigator' }
									label={ 'Principal Investigator' }
									variant="outlined"
									fullWidth
									value={ form.values.principalInvestigator }
									onChange={ form.handleChange }
									error={ form.touched.principalInvestigator && Boolean(form.errors.principalInvestigator) }
									helperText={ form.touched.principalInvestigator && form.errors.principalInvestigator }
								/>
								<Box pb={3} />
								<TextField
									classes={{ root: classes.textareaRoot }}
									name="description"
									id={ 'doi-form-field-description' }
									label={ 'Description' }
									variant="outlined"
									multiline
									rows={4}
									fullWidth
									value={ form.values.description }
									onChange={ form.handleChange }
									error={ form.touched.description && Boolean(form.errors.description) }
									helperText={ form.touched.description && form.errors.description }
								/>
							</Box>
							<Box pb={5} />
							<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
								{ doiConfig.text.createPage.dataSection.title }
							</Typography>
							<Box pb={2} />
							<Typography component="p" variant="body1" >
								{ userOmics.length > 0 ? doiConfig.text.createPage.dataSection.description : doiConfig.text.createPage.dataSection.notification }
							</Typography>
							<Box pb={4} />
							{isOmicsInitialized &&
							<>
								<Box maxWidth={520}>
									{Object.keys(form.values.omics).length > 0 && userOmics.map(omic =>
										<Box key={omic.type} pb={2}>
											<DoiOmicLevels
												omic={omic}
												form={form}
												onOmicLevelClick={onListItemClick}
											/>
										</Box>
									)}
								</Box>
								<Box pt={3} pb={5}>
									<LoaderButton
										isLoading={isDownloadCreateFileListLoading}
										wrapperBoxProps={{
											style: { display: 'inline' }
										}}
										ButtonProps={{
											disabled: !isSomeLevelSelected,
											size: 'large',
											variant: 'outlined',
											color: 'primary',
											onClick: onDownloadClick
										}}
									>
										{ 'Download File List' }
									</LoaderButton>
									<Box component="span" pr={2} />
									<LoaderButton
										isLoading={isSaveUserDoiLoading}
										wrapperBoxProps={{
											style: { display: 'inline' }
										}}
										ButtonProps={{
											disabled: !isSomeLevelSelected,
											size: 'large',
											variant: 'outlined',
											color: 'primary',
											onClick: onSaveClick
										}}
									>
										{ 'Save DOI' }
									</LoaderButton>
									<Box component="span" pr={2} />
									<LoaderButton
										isLoading={isCreateUserDoiLoading}
										wrapperBoxProps={{
											style: { display: 'inline' }
										}}
										ButtonProps={{
											disabled: !isSomeLevelSelected,
											size: 'large',
											variant: 'contained',
											color: 'primary',
											onClick: () => setIsConfirmOpen(true)
										}}
									>
										{ 'Publish DOI' }
									</LoaderButton>
								</Box>
							</>
							}
						</Box>
						<Box width={480} ml={5} mt={7} >
							<Box pt={1.5} />
							<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
								{ doiConfig.text.createPage.helperSection.title }
							</Typography>
							<Box pb={2} />
							<Typography component="p" variant="body1" >
								{ doiConfig.text.createPage.helperSection.specification }
							</Typography>
							<Box pt={2}>
								<Box>
									{ doiConfig.text.createPage.helperSection.description.split('.').map((str, index) =>
										<Box key={ index } pb={1}>
											<Typography component="p" variant="body1" >
												{ `${index + 1}. ${str}.` }
											</Typography>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
					}
				</Container>
				<Box pb={4} />
				{doiConfig !== null && !isOmicsLoading &&
				<Footer
					links={doiConfig.footer.links}
				/>
				}
			</Box>
			<Dialog
				aria-labelledby="published-set-modal-header"
				open={isConfirmOpen}
				onEscapeKeyDown={onRemoveCancelClick}
			>
				<DialogTitle disableTypography>
					<Box position="relative">
						<Typography id="published-set-modal-header" component="h2" variant="h5">
							{ 'Confirm publishing DOI' }
						</Typography>
						<Box position="absolute" top={-12} right={-12} color={grey['800']}>
							<IconButton onClick={onRemoveCancelClick} aria-label="Close" component="span">
								<Close />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Typography component="span" variant="subtitle1">
						{ 'Please confirm publishing DOI. This action could not be revoked.' }
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={onRemoveCancelClick} color="primary" variant="outlined" size="large">
						{ 'CANCEL' }
					</Button>
					<Button onClick={onPublishClick} color="primary" variant="contained" size="large">
						{ 'CONFIRM' }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
