import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { selectAddiDataRequestDetails } from '../selectors';
import { addiDataRequestDetailsSlice } from '../slices';
import { alertSlice } from '../../../../alert/slices';
import { updateAddiData } from '../api';
import { getAddiDataRequestDetailsSaga } from './getAddiDataRequestDetailsSaga';
import { UpdateAddiDataRequest } from '../types';

export function* updateAddiDataRequestSaga({ payload }: PayloadAction<UpdateAddiDataRequest>): SagaIterator {
	const addiDataRequest = (yield select(selectAddiDataRequestDetails)) as ReturnType<typeof selectAddiDataRequestDetails>;

	if (addiDataRequest !== null) {
		try {
			yield put(addiDataRequestDetailsSlice.actions.updateAddiDataRequestStart());

			yield call(updateAddiData, payload);

			// Retrieve saved ADDI data request to verify saved successfully.
			yield call(getAddiDataRequestDetailsSaga, addiDataRequest.id);

			yield put(addiDataRequestDetailsSlice.actions.updateAddiDataRequestEnd());

			yield put(alertSlice.actions.showAlert({ message: 'ADDI data has been successfully updated.' }));
		} catch (e) {
			yield put(addiDataRequestDetailsSlice.actions.updateAddiDataRequestEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
