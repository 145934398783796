import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { statisticsSlice } from '../slices';
import { selectMetadataDownloads } from '../selectors';
import { GetMetadataDownloadsResponse } from '../types';
import { getMetadataDownloads } from '../api/getMetadataDownloads';

export function* getMetadataDownloadsSaga(): SagaIterator {
	try {
		yield put(statisticsSlice.actions.getMetadataDownloadsStart());

		const metadataDownloads = (yield select(selectMetadataDownloads)) as ReturnType<typeof selectMetadataDownloads>;

		if (metadataDownloads === null) {
			const response = (yield call(getMetadataDownloads)) as AxiosResponse<GetMetadataDownloadsResponse>;
			yield put(statisticsSlice.actions.getMetadataDownloadsEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(statisticsSlice.actions.getMetadataDownloadsEnd({ endState: SagaEndState.Success, data: metadataDownloads }));
		}
	} catch (e) {
		yield put(statisticsSlice.actions.getMetadataDownloadsEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
