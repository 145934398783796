import { RefObject, useEffect } from 'react';
import { FormikProps } from 'formik';

interface FieldInfo<Name> {
	name: Name
	ref: RefObject<HTMLInputElement>
}

interface UseErrorFocusOnSubmitProps<Values> {
	form: FormikProps<Values>
	fieldsInfo: FieldInfo<keyof Values>[]
}

export const useErrorFocusOnSubmit = <Values>({
	form,
	fieldsInfo
}: UseErrorFocusOnSubmitProps<Values>): void => {
	useEffect(() => {
		if (!form.isSubmitting) {
			return;
		}

		const firstErrorIndex = fieldsInfo.findIndex(item => {
			const errorMessage = form.errors[item.name];

			return typeof errorMessage === 'string' && errorMessage.length !== 0;
		});

		if (firstErrorIndex !== -1) {
			const firstErrorRef = fieldsInfo[firstErrorIndex].ref;

			if (firstErrorRef.current) {
				firstErrorRef.current.focus();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form.isSubmitting, form.errors]);
};
