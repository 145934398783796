const K = 1000;
const MAX_DIGITS = 3;
const SIZES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function formatBytes(bytes: number): string {
	if (bytes === 0) {
		return `0 ${SIZES[0]}`;
	}

	const sizeIndex = Math.floor(Math.log(bytes) / Math.log(K));

	const size = bytes / Math.pow(K, sizeIndex);
	const isInteger = Number.isInteger(size);

	return `${parseFloat(String(size).slice(0, isInteger ? MAX_DIGITS : MAX_DIGITS + 1))} ${SIZES[sizeIndex]}`;
}
