import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { GetPossibleUserRolesResponse } from '../types';

export function* getPossibleUserRoles(): SagaIterator<AxiosResponse<GetPossibleUserRolesResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'accept': 'text/plain',
		}
	};

	return (yield call(networkManager.fetch, Urls.GetPossibleUserRoles, requestOptions)) as AxiosResponse<GetPossibleUserRolesResponse>;
}
