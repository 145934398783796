import { DetailsFacetsConfig, DetailsFacetsItemConfig, FacetsGroupItem, FacetsDetails } from '../types';

export const mapFacetsConfigToFacetsGroup = (data: DetailsFacetsItemConfig[]): DetailsFacetsConfig => data.map(detailsConfigItem => detailsConfigItem.groups)
	.flat(Infinity)
	.map(facetsGroupItem => {
		const facetGroupItem = facetsGroupItem as FacetsGroupItem;
		return facetGroupItem.facets;
	})
	.flat(Infinity)
	.reduce((detailsConfig, facet) => {
		const detailsConfigValue = detailsConfig as DetailsFacetsConfig;
		const facetValue = facet as FacetsDetails;
		detailsConfigValue[facetValue.name] = { ...facetValue };
		return detailsConfig;
	}, {}) as DetailsFacetsConfig;
