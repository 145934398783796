import React, { FunctionComponent, useEffect } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box, Container, LinearProgress, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
	selectTotalNumberOfUsers,
	selectTotalNumberOfNewUsers,
	selectIsGetTotalNumberOfUsersLoading,
	selectIsGetTotalNumberOfNewUsersLoading,
	selectTotalAmountOfDownloadedData,
	selectIsGetTotalAmountOfDownloadedDataLoading,
	selectMetadataDownloads,
	selectIsGetMetadataDownloadsLoading,
	selectStepsToDownload,
	selectIsGetStepsToDownloadLoading,
	selectIsGetOmicsYearlyDownloadsDataLoading,
	selectOmicsYearlyDownloadsData
} from '../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { statisticsSlice } from '../slices';
import { COLORS_METADATA_DOWNLOADS,
	COLORS_STEPS_TO_DOWNLOAD,
	COLORS_TOTAL_AMOUNT_OF_DOWNLOADED_DATA, COLORS_TOTAL_NUMBER_OF_NEW_USERS, COLORS_TOTAL_NUMBER_OF_USERS } from '../constants';
import { ColumnChartContainer } from './ColumnChartContainer';
import { ColumnChartWithDrilldownContainer } from './ColumnChartWithDrilldownContainer';
import { PyramidChartContainer } from './PyramidChartContainer';

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		backgroundColor: grey[100],
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		paddingLeft: theme.spacing(5),
		padding: 0,
	},
	chartWrapper: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		width: '100%',
		height: '100%'
	}
}));

export const Statistics: FunctionComponent = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const totalNumberOfUsers = useSelector(selectTotalNumberOfUsers);
	const totalNumberOfNewUsers = useSelector(selectTotalNumberOfNewUsers);
	const isGetTotalNumberOfUsersLoading = useSelector(selectIsGetTotalNumberOfUsersLoading);
	const isGetTotalNumberOfNewUsersLoading = useSelector(selectIsGetTotalNumberOfNewUsersLoading);
	const isGetTotalAmountOfDownloadedDataLoading = useSelector(selectIsGetTotalAmountOfDownloadedDataLoading);
	const totalAmountOfDownloadedData = useSelector(selectTotalAmountOfDownloadedData);
	const metadataDownloads = useSelector(selectMetadataDownloads);
	const isGetMetadataDownloadsLoading = useSelector(selectIsGetMetadataDownloadsLoading);
	const stepsToDownload = useSelector(selectStepsToDownload);
	const isGetStepsToDownloadLoading = useSelector(selectIsGetStepsToDownloadLoading);
	const omicsYearlyDownloadsData = useSelector(selectOmicsYearlyDownloadsData);
	const isGetOmicsYearlyDownloadsDataLoading = useSelector(selectIsGetOmicsYearlyDownloadsDataLoading);

	const isStatisticsPageLoading = isGetTotalNumberOfUsersLoading
		|| isGetTotalNumberOfNewUsersLoading
		|| isGetTotalAmountOfDownloadedDataLoading
		|| isGetMetadataDownloadsLoading
		|| isGetStepsToDownloadLoading
		|| isGetOmicsYearlyDownloadsDataLoading
	;

	useEffect(() => {
		dispatch(statisticsSlice.actions.getTotalNumberOfUsers());
		dispatch(statisticsSlice.actions.getTotalNumberOfNewUsers());
		dispatch(statisticsSlice.actions.getMetadataDownloads());
		dispatch(statisticsSlice.actions.getTotalAmountOfDownloadedData());
		dispatch(statisticsSlice.actions.getStepsToDownload());
		dispatch(statisticsSlice.actions.getOmicsYearlyDownloadsData());
	}, [dispatch]);

	return (
		<Box className={classes.containerWrapper}>
			<Box>
				<Box
					visibility={isStatisticsPageLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress/>
				</Box>
				<Container className={classes.container}>
					<Box pt={2} pb={3}>
						<Typography component="h1" variant="h4">
							{'Statistics dashboard'}
						</Typography>
						<Box pb={3}/>
						<Box className={classes.chartWrapper}>
							<ColumnChartContainer isLoading={isGetTotalNumberOfUsersLoading} data={totalNumberOfUsers} colors={COLORS_TOTAL_NUMBER_OF_USERS} title={'Total number of users'}/>
							<ColumnChartContainer isLoading={isGetTotalNumberOfNewUsersLoading} data={totalNumberOfNewUsers} colors={COLORS_TOTAL_NUMBER_OF_NEW_USERS} title={'Total number of new users'}/>
							<ColumnChartContainer isLoading={isGetMetadataDownloadsLoading} data={metadataDownloads} colors={COLORS_METADATA_DOWNLOADS} title={'Metadata Downloads'}/>
							<ColumnChartWithDrilldownContainer isLoading={isGetTotalAmountOfDownloadedDataLoading} data={totalAmountOfDownloadedData} colors={COLORS_TOTAL_AMOUNT_OF_DOWNLOADED_DATA} periodOfData={'months'} title={'Omics monthly downloaded data'}/>
							<PyramidChartContainer isLoading={isGetStepsToDownloadLoading} data={stepsToDownload} colors={COLORS_STEPS_TO_DOWNLOAD} title={'Steps to Download'}/>
							<ColumnChartWithDrilldownContainer isLoading={isGetOmicsYearlyDownloadsDataLoading} data={omicsYearlyDownloadsData} colors={COLORS_TOTAL_AMOUNT_OF_DOWNLOADED_DATA} periodOfData={'years'} title={'Omics yearly downloaded data'}/>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	);
};
