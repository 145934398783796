import { call, put, select, StrictEffect } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { CreateManagementConfig, CreateManagementInformation } from '../types';
import { sendCreateManagementRequest } from '../api/sendCreateManagementRequest';
import { indexManagementSlice } from '../slices';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';
import { selectCreateManagementConfig } from '../selectors';

export function* sendCreateManagementRequestSaga({ payload: formData }: PayloadAction<CreateManagementInformation>): Generator<StrictEffect, void> {
	try {
		yield call(sendCreateManagementRequest, formData);

		yield put(indexManagementSlice.actions.sendFormDataSuccess());

		const pageConfiguration = (yield select(selectCreateManagementConfig)) as CreateManagementConfig;
		if (pageConfiguration.successSubmit) {
			yield put(alertSlice.actions.showAlert({ message: pageConfiguration.successSubmit }));
		}

	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(indexManagementSlice.actions.sendFormDataFailure());
	}
}
