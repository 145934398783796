/* eslint-disable arrow-body-style */
import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';

import { UserInfo, UserDataAccessStatuses } from './types';
import { ADMIN_ROLES, DataRequestStatus, UserRoles } from './constants';

export const selectIsUserAuthorized = (state: RootState): boolean => state.account.isUserAuthorized;
export const selectIsAccountLoading = createSelector(
	(state: RootState) => state.account.isInitLoading,
	(state: RootState) => state.account.isAccountDetailsLoading,
	(isInitLoading, isAccountDetailsLoading): boolean => isInitLoading || isAccountDetailsLoading
);
export const selectUserInfo = (state: RootState): UserInfo => state.account.userInfo;
export const selectUserRoles = (state: RootState): UserRoles[] => state.account.userInfo.userRoles;
export const selectIsUserDataSubmitter = createSelector(
	selectUserRoles,
	(userRoles): boolean => {
		return userRoles
			? userRoles.some(role => role === UserRoles.DataSubmitter)
			: false;
	});
export const selectIsUserHasAccessToAdminPortal = createSelector(
	selectUserRoles,
	(userRoles): boolean => {
		if (!userRoles) {
			return false;
		}
		return userRoles.some(role => ADMIN_ROLES.includes(role));
	}
);
export const selectUserDataAccessStatus = createSelector(
	selectUserInfo,
	(userInfo): UserDataAccessStatuses => {
		const isUserRequested = userInfo.userRoles.some(role => role === UserRoles.Requested);
		const isUserResearcher = userInfo.userRoles.some(role => role === UserRoles.Researcher);
		const isUserAdmin = userInfo.userRoles.some(role => role === UserRoles.Admin);
		const isUserRejected = userInfo.dataRequestStatus === DataRequestStatus.Rejected;

		if (isUserResearcher || isUserAdmin) {
			return UserDataAccessStatuses.HasAccess;
		}
		if (isUserRequested) {
			return UserDataAccessStatuses.Requested;
		}
		if (isUserRejected) {
			return UserDataAccessStatuses.Rejected;
		}
		return UserDataAccessStatuses.NoAccess;
	}
);
