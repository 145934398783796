import { Task } from 'redux-saga';
import { StrictEffect, fork, take, cancel } from 'redux-saga/effects';

import { totpAuthenticationCodeSlice } from '../slices';

import { updateCodeAndTimerSaga } from './updateCodeAndTimerSaga';

export function* invokeCodeUpdaterSaga(): Generator<StrictEffect, void> {
	const codeRemainingTimerSaga = (yield fork(updateCodeAndTimerSaga)) as Task;

	yield take(totpAuthenticationCodeSlice.actions.stopCodeUpdater.type);

	yield cancel(codeRemainingTimerSaga);
}
