import React, { ElementType, SyntheticEvent, useState, useEffect, useCallback, useRef, FunctionComponent } from 'react';

import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { TypographyVariant } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

interface ExpandableTextPanelProps {
	text: string
	linesCount?: number
	component?: ElementType
	variant?: TypographyVariant
	ellipsis?: string
	moreText?: string
	lessText?: string
}

export const ExpandableTextPanel: FunctionComponent<ExpandableTextPanelProps> = ({
	text,
	linesCount = 2,
	component = 'p',
	variant = 'caption',
	ellipsis = '...',
	moreText = 'more',
	lessText = 'less'
// eslint-disable-next-line sonarjs/cognitive-complexity
}: ExpandableTextPanelProps) => {
	const [isExpanded, setIsExpanded] = useState(false);
	const [isShouldBeClamped, setIsShouldBeClamped] = useState(true);
	const [componentText, setComponentText] = useState(text.substring(0, 1));

	const elementRef = useRef<HTMLElement | null>(null);
	const originalTextRef = useRef(text);

	const lineHeightRef = useRef(0);
	const startPositionRef = useRef(0);
	const middlePositionRef = useRef(0);
	const endPositionRef = useRef(0);

	const getDisplayTextString = useCallback(() =>
		`${originalTextRef.current.slice(0, middlePositionRef.current - (ellipsis.length * 2 + (isExpanded ? lessText.length : moreText.length)))}${isShouldBeClamped ? ellipsis : ''}`,
	[ellipsis, isExpanded, isShouldBeClamped, lessText.length, moreText.length]);

	const clampLines = useCallback(() => {
		if (!elementRef.current) {
			return;
		}

		// display = block need for correct measuring of the clientHeight of the element
		elementRef.current.style.display = 'block';

		const maxHeight = lineHeightRef.current * linesCount + 1;

		startPositionRef.current = 0;
		middlePositionRef.current = 0;
		endPositionRef.current = originalTextRef.current.length;

		while (startPositionRef.current <= endPositionRef.current) {
			middlePositionRef.current = Math.floor((startPositionRef.current + endPositionRef.current) / 2);
			elementRef.current.innerText = originalTextRef.current.slice(0, middlePositionRef.current);
			if (middlePositionRef.current === originalTextRef.current.length) {
				setComponentText(originalTextRef.current);
				setIsShouldBeClamped(false);

				// display = inline need for correct displaying expand button
				elementRef.current.style.display = 'inline';
				return;
			}

			if (elementRef.current && elementRef.current.clientHeight <= maxHeight) {
				startPositionRef.current = middlePositionRef.current + 1;
			} else {
				endPositionRef.current = middlePositionRef.current - 1;
			}
		}

		const displayText = getDisplayTextString();

		// display = inline need for correct displaying expand button
		elementRef.current.style.display = 'inline';
		setComponentText(displayText);
	},
	[getDisplayTextString, linesCount]);

	const toggleExpand = useCallback((e: SyntheticEvent) => {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}

		if (isExpanded) {
			clampLines();
		} else {
			setComponentText(originalTextRef.current);
		}

		setIsExpanded(!isExpanded);
	},
	[clampLines, isExpanded]);

	useEffect(() => {
		if (text) {
			lineHeightRef.current = elementRef.current?.clientHeight ?? 0 + 1;

			clampLines();
		}
	},
	// eslint-disable-next-line react-hooks/exhaustive-deps
	[]);

	const setElementRef = useCallback((element: HTMLElement | null) => {
		elementRef.current = element;
	},
	[]);

	return (
		<Box>
			<Typography
				ref = {setElementRef}
				component={component}
				variant={variant}
				style={{
					display: 'block',
					wordWrap: 'break-word',
					whiteSpace: 'pre-wrap'
				}}>
				{componentText}
			</Typography>
			{ isShouldBeClamped &&
				<Link
					style={{
						height: lineHeightRef.current,
						paddingLeft: '4px',
						textDecoration: 'underline',
						color: grey['600']
					}}
					aria-label={'show more'}
					aria-expanded={isExpanded}
					variant={variant}
					component='button'
					onClick={toggleExpand}
				>
					{isExpanded ? lessText : moreText}
				</Link>
			}
		</Box>);
};
