import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { remove } from 'lodash';
import {
	DefaultFacetsConfig,
	DetailsFacetsConfig,
	Facets, SelectedFacets,
	SelectedFacetValue,
	DetailsFacetsItemConfig,
	SearchViewMode,
	DisplayedFacetsInCardView
} from '../types';
import { ACTIONS_PREFIX } from '../constants';
import { compareFacetValues } from '../utils';

interface FacetsState {
	totalCount: number
	defaultFacetsConfig: DefaultFacetsConfig
	detailsFacetsConfig: DetailsFacetsConfig
	detailsFacetsListPanel: DetailsFacetsItemConfig[]
	facets: Facets
	selectedFacets: SelectedFacets
	displayedFacetsInCardView: DisplayedFacetsInCardView
	isDisplayedFacetsInCardViewInitialized: boolean
	isSearchLoading: boolean
	viewMode: SearchViewMode
	isLoadingDownloadMetadata: boolean
	filterQueryString: string
	isFacetsConfigLoading: boolean
	isInitialSearchLoading: boolean
}

const initialState: FacetsState = {
	totalCount: 0,
	defaultFacetsConfig: {
		cardHeight: 0,
		cardWidth: 0,
		defaultCards: [],
		statusFacets: [],
		availableFacets: [],
		facetValuesMaxCount: 0
	},
	detailsFacetsListPanel: [],
	facets: {},
	displayedFacetsInCardView: [],
	isDisplayedFacetsInCardViewInitialized: false,
	selectedFacets: {},
	detailsFacetsConfig: {},
	isSearchLoading: false,
	viewMode: SearchViewMode.Card,
	isLoadingDownloadMetadata: false,
	filterQueryString: '',
	isFacetsConfigLoading: false,
	isInitialSearchLoading: false
};

export const facetsSlice = createSlice({
	name: `${ACTIONS_PREFIX}.facets`,
	initialState,
	reducers: {
		searchRequest: (state: FacetsState) => {
			state.isSearchLoading = true;
		},
		searchSuccess: (state: FacetsState, { payload }: PayloadAction<{
			totalCount: number
			facets: Facets
		}>) => {
			state.isSearchLoading = false;
			state.totalCount = payload.totalCount;
			state.facets = payload.facets;
		},
		searchFail: (state: FacetsState) => {
			state.isSearchLoading = false;
		},

		getFacetsConfigRequest: (state: FacetsState) => {
			state.isFacetsConfigLoading = true;
		},
		getFacetsConfigSuccess: (state: FacetsState, { payload }: PayloadAction<{
			defaultFacetsConfig: DefaultFacetsConfig
			detailsFacetsConfig: DetailsFacetsConfig
			detailsFacetsListPanel: DetailsFacetsItemConfig[]
		}>) => {
			state.defaultFacetsConfig = payload.defaultFacetsConfig;
			state.detailsFacetsConfig = payload.detailsFacetsConfig;
			if (!state.isDisplayedFacetsInCardViewInitialized) {
				state.displayedFacetsInCardView = payload.defaultFacetsConfig.defaultCards;
				state.isDisplayedFacetsInCardViewInitialized = true;
			}
			state.detailsFacetsListPanel = payload.detailsFacetsListPanel;
			state.isFacetsConfigLoading = false;
		},
		getFacetsConfigFail: (state: FacetsState) => {
			state.isFacetsConfigLoading = false;
		},

		initialSearchRequest: (state: FacetsState) => {
			state.isInitialSearchLoading = true;
		},
		initialSearchSuccess: (state: FacetsState) => {
			state.isInitialSearchLoading = false;
		},
		initialSearchFail: (state: FacetsState) => {
			state.isInitialSearchLoading = false;
		},

		getDownloadMetadataRequest: (state: FacetsState) => {
			state.isLoadingDownloadMetadata = true;
		},
		getDownloadMetadataSuccess: (state: FacetsState) => {
			state.isLoadingDownloadMetadata = false;
		},
		getDownloadMetadataFail: (state: FacetsState) => {
			state.isLoadingDownloadMetadata = false;
		},

		selectValue: (state: FacetsState, { payload: {
			facetName,
			selectedValue
		} }: PayloadAction<{
			facetName: string
			selectedValue: SelectedFacetValue
		}>) => {
			const selectedValues = state.selectedFacets[facetName];

			if (selectedValues) {
				if (selectedValues.some(value => compareFacetValues(value, selectedValue)) === false) {
					selectedValues.push(selectedValue);
				}
			} else {
				state.selectedFacets[facetName] = [selectedValue];
			}

			if (!state.displayedFacetsInCardView.some(displayedFacetName => displayedFacetName === facetName)) {
				state.displayedFacetsInCardView.unshift(facetName);
			}
		},
		unselectValue: (state: FacetsState, { payload: {
			facetName,
			unselectedValue
		} }: PayloadAction<{
			facetName: string
			unselectedValue: SelectedFacetValue
		}>) => {
			const selectedValues = state.selectedFacets[facetName];
			if (selectedValues) {
				remove(selectedValues, selectedValue => compareFacetValues(selectedValue, unselectedValue));
				if (selectedValues.length === 0) {
					delete state.selectedFacets[facetName];
				}
			}
		},
		unselectAllValues: (state: FacetsState) => {
			state.selectedFacets = initialState.selectedFacets;
		},
		getDownloadMetadataLink: (state: FacetsState) => state,
		setViewMode: (state: FacetsState, { payload }: PayloadAction<SearchViewMode>) => {
			state.viewMode = state.viewMode = payload;
		},
		setFilterQueryString: (state: FacetsState, { payload }: PayloadAction<string>) => {
			state.filterQueryString = payload;
		},
		unselectExtCaseSet: (state: FacetsState) => {
			state.filterQueryString = '';
		},

		showFacetInCardView: (state: FacetsState, { payload: facetName }: PayloadAction<string>) => {
			if (!state.displayedFacetsInCardView.some(displayedFacetName => displayedFacetName === facetName)) {
				state.displayedFacetsInCardView.unshift(facetName);
			}
		},
		hideFacetInCardView: (state: FacetsState, { payload: facetName }: PayloadAction<string>) => {
			state.displayedFacetsInCardView = state.displayedFacetsInCardView
				.filter(displayedFacetName => displayedFacetName !== facetName);
		},
		changeDisplayedFacetsOrder: (state: FacetsState, { payload: displayedFacets }: PayloadAction<DisplayedFacetsInCardView>) => {
			state.displayedFacetsInCardView = displayedFacets;
		}
	}
});
