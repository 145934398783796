import { Column, ColumnTypes } from 'components/CustomTable';

import { SearchDetailsFieldConfig } from '../types';

export const mapFieldsConfigToColumns = (
	fieldsConfig: SearchDetailsFieldConfig[],
	indexKeyColumnName: string
): Column[] => fieldsConfig
	.map(fieldConfig => ({
		...fieldConfig,
		type: fieldConfig.name === indexKeyColumnName ? ColumnTypes.Static : ColumnTypes.Flex,
		isVisible: true
	}))
;
