import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { Column } from 'components/CustomTable';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { searchDetailsSlice } from '../slices';
import { getDefaultFieldsConfig } from '../api';
import { FieldsConfigResponse, SearchDetailsFieldConfig } from '../types';
import { selectSearchDetailsIndexKeyColumnName } from '../selectors';
import { mapFieldsConfigToColumns } from '../utils';

export function* getFieldsConfigSaga(): SagaIterator {
	try {
		const searchDetailsIndexKeyColumnName = (yield select(selectSearchDetailsIndexKeyColumnName)) as ReturnType<typeof selectSearchDetailsIndexKeyColumnName>;
		const fieldsConfigResponse = (yield call(getDefaultFieldsConfig)) as AxiosResponse<FieldsConfigResponse>;

		const fieldsConfig: SearchDetailsFieldConfig[] = fieldsConfigResponse.data.map(fieldConfig => ({
			...fieldConfig,
			displayName: fieldConfig.displayName ?? fieldConfig.name,
			description: fieldConfig.description ?? '',
		}));

		const tableColumns: Column[] = mapFieldsConfigToColumns(fieldsConfig, searchDetailsIndexKeyColumnName);

		yield put(searchDetailsSlice.actions.setTableColumns(tableColumns));
		yield put(searchDetailsSlice.actions.getFieldsConfigSuccess(fieldsConfig));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(searchDetailsSlice.actions.getFieldsConfigFail());
	}
}
