import type { RootState } from 'app/store';
import { ColumnChartData, ColumnChartWithDrilldownData, PyramidChartData } from './types';

export const selectTotalNumberOfUsers = (state: RootState): ColumnChartData | null => state.administration.statistics.totalNumberOfUsers;
export const selectTotalNumberOfNewUsers = (state: RootState): ColumnChartData | null => state.administration.statistics.totalNumberOfNewUsers;
export const selectMetadataDownloads = (state: RootState): ColumnChartData | null => state.administration.statistics.metadataDownloads;
export const selectTotalAmountOfDownloadedData = (state: RootState): ColumnChartWithDrilldownData | null => state.administration.statistics.totalAmountOfDownloadedData;
export const selectStepsToDownload = (state: RootState): PyramidChartData | null => state.administration.statistics.stepsToDownload;
export const selectOmicsYearlyDownloadsData = (state: RootState): ColumnChartWithDrilldownData | null => state.administration.statistics.omicsYearlyDownloadsData;
export const selectIsGetTotalNumberOfUsersLoading = (state: RootState): boolean => state.administration.statistics.isGetTotalNumberOfUsersLoading;
export const selectIsGetTotalNumberOfNewUsersLoading = (state: RootState): boolean => state.administration.statistics.isGetTotalNumberOfNewUsersLoading;
export const selectIsGetTotalAmountOfDownloadedDataLoading = (state: RootState): boolean => state.administration.statistics.isGetTotalAmountOfDownloadedDataLoading;
export const selectIsGetMetadataDownloadsLoading = (state: RootState): boolean => state.administration.statistics.isGetMetadataDownloadsLoading;
export const selectIsGetStepsToDownloadLoading = (state: RootState): boolean => state.administration.statistics.isGetStepsToDownloadLoading;
export const selectIsGetOmicsYearlyDownloadsDataLoading = (state: RootState): boolean => state.administration.statistics.isGetOmicsYearlyDownloadsDataLoading;
