/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/prefer-await-to-callbacks */
import { AuthError, LogoutSettings, PublicClientApplication, TokenInfo } from 'auth-library-web';

import { store } from 'app/store';

import { accountSlice } from './slice';
import { B2CErrors, POLICY_RESET_PASSWORD } from './constants';
import { authConfiguration } from './authConfiguration';
import { AuthManager, AuthManagerTypes } from './types';

class PortalAuthManager implements AuthManager {
	private readonly authClient: PublicClientApplication;

	constructor(authClient: PublicClientApplication) {
		this.authClient = authClient;

		void Promise.resolve()
			.then(() => store.dispatch(accountSlice.actions.initStart()))
			.then(() => this.authClient.handleRedirectPromise())
			.then(() => this.onHandleRedirectPromiseResolved())
			.catch(error => this.onHandleRedirectPromiseRejected(error as AuthError))
			.finally(() => store.dispatch(accountSlice.actions.initComplete()));
	}

	async getTokenInfo(): Promise<TokenInfo | null> {
		let tokenInfo: TokenInfo | null;

		try {
			tokenInfo = await this.authClient.getTokenInfo();
		} catch (e) {
			store.dispatch(accountSlice.actions.setIsAuthorized(false));
			tokenInfo = null;
		}

		return tokenInfo;
	}

	signIn(): void {
		void this.authClient.loginRedirect();
	}

	signOut(settings?: LogoutSettings): void {
		void this.authClient.logoutRedirect(settings);
	}

	private onHandleRedirectPromiseResolved() {
		const accountInfo = this.authClient.getAccountInfo();

		if (accountInfo !== null) {
			if (accountInfo.tfp === POLICY_RESET_PASSWORD) {
				this.authClient.clearInfoCache();
				this.signIn();
			} else {
				store.dispatch(accountSlice.actions.setUserInfo({
					userName: accountInfo.name,
					userEmails: accountInfo.emails
				}));
				store.dispatch(accountSlice.actions.getUserDetails());
			}
		}
	}

	private onHandleRedirectPromiseRejected(error: AuthError) {
		if (error.errorCode === `${B2CErrors.FORGOT_PASSWORD}`) {
			void this.authClient.loginRedirect({ policy: POLICY_RESET_PASSWORD });
		}
	}
}

const portalAuthClient = new PublicClientApplication({
	...authConfiguration,
	name: AuthManagerTypes.Portal
});

export const portalAuthManager = new PortalAuthManager(portalAuthClient);
