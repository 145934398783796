import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { ConfigurationResponse } from '../types';

export const getPageConfiguration = async (): Promise<AxiosResponse<ConfigurationResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch(Urls.GetDuaConfig, requestOptions);
};
