import { SagaIterator } from 'redux-saga';
import { call, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { SearchState } from '../../../types';
import { getSearchStateSaga } from '../../../sagas/getSearchStateSaga';
import { resetPaginationSaga } from '../../../sagas/resetPaginationSaga';
import { searchRequestSaga } from '../../../sagas/searchRequestSaga';

import { CaseSetEntity, ValidateCaseSetResponse } from '../types';
import { validateCaseSet } from '../api';

export function* searchRequestWithCaseSetSaga(caseSet: CaseSetEntity): SagaIterator<CaseSetEntity> {
	const searchState = (yield call(getSearchStateSaga)) as SearchState;
	const selectedCaseSetIndex = searchState.selectedCaseSetEntities.findIndex(selectedEntity => selectedEntity.id === caseSet.id);
	if (selectedCaseSetIndex === -1) {
		searchState.selectedCaseSetEntities.push(caseSet);
	} else {
		searchState.selectedCaseSetEntities[selectedCaseSetIndex] = caseSet;
	}

	yield call(resetPaginationSaga);

	const [validationResponse] = (yield all([
		call(validateCaseSet, {
			identifierType: caseSet.identifierType,
			identifiers: caseSet.values
		}),
		call(searchRequestSaga, searchState)
	])) as [AxiosResponse<ValidateCaseSetResponse>];

	caseSet.notFoundIdentifiers = validationResponse.data.notFoundIdentifiers;

	return caseSet;
}
