import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { downloadBlob } from 'utils';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { indexManagementSlice } from '../slices';
import { exportParticipantsIntersecList } from '../api';
import { ExportParticipantsIntersecListResponse } from '../types';

export function* exportParticipantsIntersecListSaga(): SagaIterator {
	try {

		yield put(indexManagementSlice.actions.exportParticipantsIntersecListStart());

		const { data } = (yield call(exportParticipantsIntersecList)) as AxiosResponse<ExportParticipantsIntersecListResponse>;

		yield call(downloadBlob,'participants_intersections.csv', data);

		yield put(indexManagementSlice.actions.exportParticipantsIntersecListEnd());
	} catch (e) {
		yield put(indexManagementSlice.actions.exportParticipantsIntersecListEnd());
		yield call(handleErrorSaga, e);
	}
}
