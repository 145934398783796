import { base64UrlEncode } from '../Utils';

import { Codes } from './types';

export async function getCodes(verifierLength = 43): Promise<Codes> {
	if (verifierLength < 43 || verifierLength > 128) {
		throw new Error(`Invalid verifier length value. Correct values are [43 .. 128]`);
	}

	const codeVerifier = getRandomString(verifierLength);

	const codeChallengeValues = await window.crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
	const codeChallenge = base64UrlEncode(bufferToString(codeChallengeValues));

	return {
		codeVerifier,
		codeChallenge
	}
}

export function getRandomString(length: number) {
	const randomValues = window.crypto.getRandomValues(new Uint8Array(Math.ceil(length * 3 / 4)));
	return base64UrlEncode(bufferToString(randomValues)).slice(0, length);
}

function bufferToString(buffer: ArrayBuffer) {
	return String.fromCharCode(...Array.from(new Uint8Array(buffer)));
}
