import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { grey } from '@material-ui/core/colors';
import LinearProgress from '@material-ui/core/LinearProgress';

import { Routes } from 'app/Routes';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';
import { CustomTable } from 'components/CustomTable';
import { mapCellsDataToNodes } from 'components/CustomTable/utils';

import { DOI_LIST_TABLE_COLUMNS, DOI_LIST_TABLE_ROWS_PER_PAGE_OPTIONS } from '../constants';
import {
	selectDoiConfig,
	selectIsGetUserDoiListLoading,
	selectUserDoiList,
	selectUserDoiListCurrentPage,
	selectUserDoiListRowsPerPage,
	selectUserDoiListTotalCount
} from '../selectors';
import { doiSlice } from '../slices';

import { DoiListTableCell } from './DoiListTableCell';
import { Footer } from 'features/footer';

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		height: '100%',
		minHeight: 'inherit',
		backgroundColor: theme.palette.common.white
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		padding: theme.spacing(3)
	}
}));

export const DoiList: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();

	const userDoiList = useSelector(selectUserDoiList);
	const userDoiListTotalCount = useSelector(selectUserDoiListTotalCount);
	const isGetUserDoiListLoading = useSelector(selectIsGetUserDoiListLoading);
	const userDoiListCurrentPage = useSelector(selectUserDoiListCurrentPage);
	const userDoiListRowsPerPage = useSelector(selectUserDoiListRowsPerPage);
	const doiConfig = useSelector(selectDoiConfig);

	const tableRows = useMemo(() =>
		mapCellsDataToNodes(userDoiList, DOI_LIST_TABLE_COLUMNS, (row, column) =>
			<DoiListTableCell doi={row} tableColumnName={column.name} />
		)
	, [userDoiList]);

	const handleChangePage = useCallback((handleChangePageEvent: unknown, newPage: number) => {
		dispatch(doiSlice.actions.setUserDoiListCurrentPage(newPage));
	}, [dispatch]);
	const handleChangeRowsPerPage = useCallback((handleChangeRowsEvent: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(doiSlice.actions.setUserDoiListRowsPerPage(parseInt(handleChangeRowsEvent.target.value, 10)));
	}, [dispatch]);

	useEffect(() => {
		dispatch(doiSlice.actions.getUserDoiList());
		dispatch(doiSlice.actions.getDoiConfig());
	}, [dispatch]);

	const isTableInitialized = !(userDoiListTotalCount === 0 && isGetUserDoiListLoading);

	return (
		<Box className={classes.containerWrapper} display="flex" flexDirection="column" justifyContent="space-between">
			<Box>
				<Box
					visibility={isGetUserDoiListLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress />
				</Box>
				{ doiConfig !== null && !isGetUserDoiListLoading &&
					<Container className={classes.container}>
						<Box pt={2}/>
						<Typography component="h1" variant="h3">
							{'Answer ALS DOI'}
						</Typography>
						<Box pb={2}/>
						<Box>
							<Typography component="p" variant="body1">
								{doiConfig.text.createPage.metadataSection.specification}
							</Typography>
						</Box>
						<Box pb={1}/>
						<Box display="flex" justifyContent="flex-end" pb={0.5}>
							{tableRows.length > 0 &&
							<Button
								color="primary"
								variant="text"
								size="large"
								component={Link}
								to={`${Routes.Doi}/create`}
							>
								{'Create New'}
							</Button>
							}
						</Box>
						{isTableInitialized &&
						<CustomTable
							columns={DOI_LIST_TABLE_COLUMNS}
							rows={tableRows}
							noRowsElement={
								<Box pt={14} color={grey['600']}>
									<Typography
										component="p"
										variant="h3"
										align="center"
										style={{ whiteSpace: 'pre-wrap' }}
									>
										{'No digital object identifiers found'}
									</Typography>
									<Box pt={4} display="flex" justifyContent="center">
										<Button
											color="primary"
											variant="contained"
											size="large"
											component={Link}
											to={`${Routes.Doi}/create`}
										>
											{'Create New'}
										</Button>
									</Box>
								</Box>
							}
							paginationProps={{
								component: 'div',
								rowsPerPageOptions: DOI_LIST_TABLE_ROWS_PER_PAGE_OPTIONS,
								count: userDoiListTotalCount,
								rowsPerPage: userDoiListRowsPerPage,
								page: userDoiListCurrentPage,
								onPageChange: handleChangePage,
								// onChangePage: handleChangePage,
								onChangeRowsPerPage: handleChangeRowsPerPage
							}}
							tableProps={{ id: 'doi-list-table' }}
							tableContainerProps={{
								style: {
									maxHeight: `calc(100vh - ${theme.spacing(HEADER_HEIGHT_SPACING)}px - 200px)`
								}
							}}
						/>
						}
					</Container>
				}
				<Box pb={4} />
			</Box>
			{doiConfig !== null && !isGetUserDoiListLoading &&
			<Footer
				links={doiConfig.footer.links}
			/>
			}
		</Box>
	);
};
