import React, { FunctionComponent, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { convertBytes } from 'utils';

import { User, UserEditFormFields } from '../types';
import { selectIsUsersManagementLoading, selectPossibleUserRoles } from '../selectors';

const useStyles = makeStyles((theme: Theme) => ({
	dialogContent: {
		display: 'flex',
		flexDirection: 'column'
	},
	inputLabel: {
		backgroundColor: '#ffffff',
		paddingRight: theme.spacing(1),
		paddingLeft: theme.spacing(1)
	}
}));

interface UserInfoEditSectionProps {
	user: User
	form: FormikProps<UserEditFormFields>
}

export const UserInfoEditSection: FunctionComponent<UserInfoEditSectionProps> = ({
	user,
	form
}) => {
	const classes = useStyles();
	const theme = useTheme();

	const possibleUserRoles = useSelector(selectPossibleUserRoles);
	const isUsersManagementLoading = useSelector(selectIsUsersManagementLoading);

	const getStylesForSelectedRole = useCallback((userRole: string) => (
		{
			fontWeight:
				possibleUserRoles.includes(userRole)
					? theme.typography.fontWeightRegular
					: theme.typography.fontWeightMedium
		}), [theme.typography.fontWeightMedium, theme.typography.fontWeightRegular, possibleUserRoles]);

	const tableData: { name: string; data: string }[] = [
		{ name: 'ID', data: user.id },
		{ name: 'Registration Date', data: new Intl.DateTimeFormat('default', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}).format(new Date(user.registrationDateTime)) },
		{ name: 'Registration Email', data: user.registrationEmail },
		{ name: 'Downloaded (GB)', data: String(convertBytes(user.downloaded ?? 0)) },
	];

	return (
		<Box width={606} mb={4} mr={4}>
			<Paper>
				<Box p={2}>
					<Box mb={1.5}>
						<Typography
							variant="h5"
							component="h2"
						>
							{ 'User Information' }
						</Typography>
					</Box>
					<Box mb={2}>
						<Table size="small">
							<TableBody>
								{tableData.map(row =>
									<TableRow key={row.name}>
										<TableCell component="th">
											{ row.name }
										</TableCell>
										<TableCell>
											{ row.data }
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</Box>
					<Box display="flex" flexDirection="column">
						<TextField
							name="userName"
							id="userName-input"
							label={ 'Username' }
							margin='normal'
							InputLabelProps={{
								shrink: true,
							}}
							variant='outlined'
							value={ form.values.userName }
							onChange={ form.handleChange }
							onBlur={form.handleBlur}
							error={ form.touched.userName && Boolean(form.errors.userName) }
							helperText={ form.touched.userName && form.errors.userName }
							disabled={isUsersManagementLoading}
						/>
						<FormControl variant='outlined' margin='normal' disabled={isUsersManagementLoading}>
							<InputLabel id='user-roles-select-label' classes={{ root: classes.inputLabel }} shrink variant='outlined'>{ 'User Roles' }</InputLabel>
							<Select
								name="roles"
								labelId='user-roles-select-label'
								id='user-roles-select'
								multiple
								value={ form.values.roles }
								onChange={ form.handleChange }
								onBlur={form.handleBlur}
							>
								{possibleUserRoles.map(userRole =>
									<MenuItem key={userRole} value={userRole} style={getStylesForSelectedRole(userRole)}>
										{userRole}
									</MenuItem>
								)}
							</Select>
						</FormControl>
						<TextField
							name="threshold"
							id="threshold-input"
							label={ 'User Individual Threshold, GB' }
							margin='normal'
							InputLabelProps={{
								shrink: true,
							}}
							variant='outlined'
							value={ form.values.threshold }
							onChange={ form.handleChange }
							onBlur={form.handleBlur}
							error={ form.touched.threshold && Boolean(form.errors.threshold) }
							helperText={ form.touched.threshold && form.errors.threshold }
							disabled={isUsersManagementLoading}
						/>
						<FormControl disabled={isUsersManagementLoading}>
							<FormControlLabel
								control={
									<Checkbox
										name="legacy"
										checked={form.values.legacy}
										onChange={form.handleChange}
										onBlur={form.handleBlur}
										color="primary"
									/>
								}
								label={
									<Typography component="span" variant="body2" style={{ display: 'block' }}>
										{ 'Legacy user' }
									</Typography>
								}
							/>
						</FormControl>
					</Box>
				</Box>
			</Paper>
		</Box>
	);
};
