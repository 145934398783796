import React, { FunctionComponent } from 'react';

import Link from '@material-ui/core/Link';

interface CustomTableLinkCellProps {
	link: string
	value: string
}

export const CustomTableLinkCell: FunctionComponent<CustomTableLinkCellProps> = ({
	link,
	value
}) =>
	<Link href={ link } variant="body2">
		{ value }
	</Link>
;
