import { StrictEffect, takeEvery } from 'redux-saga/effects';

import { omicL4VisualizationSlice } from '../slices';

import { initHeatmapDataSaga } from './initHeatmapDataSaga';
import { updateHeatmapDataSaga } from './updateHeatmapDataSaga';
import { downloadHeatmapDataSaga } from './downloadHeatmapDataSaga';

export function* omicL4VisualizationRootSaga(): Generator<StrictEffect, void> {
	yield takeEvery(omicL4VisualizationSlice.actions.initHeatmapDataRequest.type, initHeatmapDataSaga);
	yield takeEvery(omicL4VisualizationSlice.actions.updateHeatmapDataRequest.type, updateHeatmapDataSaga);
	yield takeEvery(omicL4VisualizationSlice.actions.downloadHeatmapDataRequest.type, downloadHeatmapDataSaga);
}
