import React, { Fragment, FunctionComponent } from 'react';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { createStyles, Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { FooterLink } from '../types';

interface FooterProps {
	links: FooterLink[]
}

const useStyles = makeStyles(() =>
	createStyles({
		footerLinkRoot: {
			cursor: 'pointer',
			color: grey['700']
		},
		appBar: {
			boxShadow: 'none',
			backgroundColor: grey['100']
		}
	})
);

export const Footer: FunctionComponent<FooterProps> = ({
	links
}) => {
	const classes = useStyles();

	return (
		<AppBar component="footer" position="static" classes={{ root: classes.appBar }} >
			<Container>
				<Box py={3}>
					{links.map(({ link, target, text }, index) =>
						<Fragment key={index}>
							<Link
								href={link}
								target={target}
								classes={{ root: classes.footerLinkRoot }}
							>
								<Typography component="span" variant="caption">
									{ text }
								</Typography>
							</Link>
							<Box component="span" pr={4} />
						</Fragment>
					)}
				</Box>
			</Container>
		</AppBar>
	);
};
