import React, { FunctionComponent, useCallback, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { TooltipWrapper } from 'components';

import { Filter } from 'types';
import { FILTERS_DRAWER_OPENED_WIDTH_PX } from 'features/administration/constants';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MAX_GROUP_NAME_WIDTH = FILTERS_DRAWER_OPENED_WIDTH_PX - 74;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const DRAWER_VALUE_WIDTH = FILTERS_DRAWER_OPENED_WIDTH_PX - 100;
const MIN_LETTERS_FOR_FILTER_NAME_TOOLTIP = 15;
const MIN_LETTERS_FOR_FILTER_VALUE_TOOLTIP = 15;

const useStyles = makeStyles(() => ({
	accordionSummaryRoot: {
		backgroundColor: grey['100'],
		textTransform: 'uppercase'
	},
	accordionDetailsRoot: {
		backgroundColor: grey['50']
	},
	label: {
		color: grey['800'],
		'&:hover': {
			color: grey['900']
		}
	},
	labelRoot: {
		marginRight: 0
	}
}));

interface FiltersPanelGroupProps {
	filter: Filter
	onFilterValueToggle: (filterName: string, filterValueName: string, newState: boolean) => void
}

export const FiltersPanelGroup: FunctionComponent<FiltersPanelGroupProps> = ({
	filter,
	onFilterValueToggle
}) => {
	const classes = useStyles();

	const [isExpanded, setIsExpanded] = useState(true);

	const onToggleExpand = useCallback(() => {
		setIsExpanded(prevState => !prevState);
	}, []);

	const handleFilterValueToggle = useCallback(
		(filterValueName: string) =>
			(_: React.ChangeEvent<HTMLInputElement>, newState: boolean) => {
				onFilterValueToggle(filter.name, filterValueName, newState);
			}, [onFilterValueToggle, filter.name]
	);

	return (
		<>
			<Accordion
				square
				expanded={isExpanded}
				onChange={onToggleExpand}>
				<AccordionSummary
					classes={{ root: classes.accordionSummaryRoot }}
				>
					<Box width='100%' display='flex' justifyContent='space-between'>
						<Box display='flex' alignItems='center' mr={1} width='100%'>
							<Box mr={1} color={grey['A700']} display='flex' alignItems='center'>
								{!isExpanded && <ExpandMore />}
								{isExpanded && <ExpandLess />}
							</Box>
							<Box maxWidth={MAX_GROUP_NAME_WIDTH} color={grey['900']}>
								<TooltipWrapper title={filter.displayName} disabled={filter.displayName.length < MIN_LETTERS_FOR_FILTER_NAME_TOOLTIP}>
									<Typography component='h3' variant='h6' noWrap={true}>{filter.displayName}</Typography>
								</TooltipWrapper>
							</Box>
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
					<Box width='100%'>
						<Box pl={5.75} pr={2} pt={1} pb={1.5} width='100%' color={grey['800']}>
							{filter.values.map(filterValue =>
								<Box key={filterValue.name}>
									<FormControlLabel
										classes={{ label: classes.label, root: classes.labelRoot }}
										control={
											<Checkbox
												color="primary"
												checked={filterValue.isSelected}
												onChange={handleFilterValueToggle(filterValue.name)}
											/>
										}
										label={
											<Box width={DRAWER_VALUE_WIDTH} color={grey['800']}>
												<TooltipWrapper title={filterValue.displayName} disabled={filterValue.displayName.length < MIN_LETTERS_FOR_FILTER_VALUE_TOOLTIP}>
													<Typography
														display="block"
														component="span"
														variant="body1"
														noWrap={true}>
														{filterValue.displayName}
													</Typography>
												</TooltipWrapper>
											</Box>
										}
									/>
								</Box>
							)}
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
			<Divider />
		</>
	);
};
