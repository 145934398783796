import type { RootState } from 'app/store';
import { AddiDataRequest, AddiDataRequestShort } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { getGeneralLoaderCombiner } from 'utils';
import { AddiDataTableColumnNames } from './types/AddiDataTableColumnNames';
import { Filter, Sort } from 'types';

export const selectAddiDataRequestsList = (state: RootState): AddiDataRequestShort[] => state.administration.addiDataRequests.list.addiDataRequests;
export const selectFilters = (state: RootState): Filter[] => state.administration.addiDataRequests.list.filters;
export const selectTableRowsPerPage = (state: RootState): number => state.administration.addiDataRequests.list.rowsPerPage;
export const selectTableTotalRowsCount = (state: RootState): number => state.administration.addiDataRequests.list.totalRowsCount;
export const selectTableCurrentPage = (state: RootState): number => state.administration.addiDataRequests.list.currentPage;
export const selectTableSort = (state: RootState): Sort<AddiDataTableColumnNames> => state.administration.addiDataRequests.list.sort;
export const selectTableSearchQuery = (state: RootState): string => state.administration.addiDataRequests.list.searchQuery;
export const selectAddiDataRequestsListLoading = (state: RootState): boolean => state.administration.addiDataRequests.list.isAddiDataRequestsLoading;
export const selectAddiDataRequestsExporting = (state: RootState): boolean => state.administration.addiDataRequests.list.isAddiDataRequestsExporting;

export const selectAddiDataRequestDetails = (state: RootState): AddiDataRequest | null => state.administration.addiDataRequests.details.addiDataRequest;
export const selectIsAddiDataRequestLoading = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestLoading;
export const selectIsAddiDataRequestApproving = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestApproving;
export const selectIsAddiDataRequestRejecting = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestRejecting;
export const selectIsAddiSignDataRequestSending = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiSignDataRequestSending;
export const selectIsAddiDataRequestUpdating = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDataRequestUpdating;
export const selectIsAddiDocuSignDownloading = (state: RootState): boolean => state.administration.addiDataRequests.details.isAddiDocuSignDownloading;

export const selectIsAddiManagementLoading = createSelector(
	selectAddiDataRequestsListLoading,
	selectIsAddiDataRequestLoading,
	selectIsAddiDataRequestApproving,
	selectIsAddiDataRequestRejecting,
	selectIsAddiSignDataRequestSending,
	selectIsAddiDataRequestUpdating,
	selectIsAddiDocuSignDownloading,
	getGeneralLoaderCombiner
);
