import React, { FunctionComponent } from 'react';

import imgDiagram from './../../../assets/images/diagram-dua.png';
import { Box } from '@material-ui/core';

export interface FlowDiagramProps {
	flowDiagramHtml: string
}

export const FlowDiagram: FunctionComponent<FlowDiagramProps> =
	({ flowDiagramHtml }: FlowDiagramProps) =>
		<>
			<img src={imgDiagram as string} alt='' width={'590px'} />
			<Box dangerouslySetInnerHTML={{ __html: flowDiagramHtml }} />
		</>;
