/* eslint-disable sonarjs/no-duplicate-string */
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { delay } from 'redux-saga/effects';
import { CreateManagementConfigResponse } from '../types/';

export function* getCreateManagementConfig(): SagaIterator<AxiosResponse<CreateManagementConfigResponse>> {
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	yield delay(500);

	return {
		config: {},
		headers: {},
		status: 200,
		statusText: '',
		data: {
			title: 'Create Management',
			description: 'The following wizard will guide you through the process of creating a new index (data release).',
			indexTextNameDescription: 'Specify release name.',
			acceptedFileFormats: ['.csv'],
			successSubmit: 'New index was successfully created',
			datafiles: [
				{
					type: 'releaseDataFile',
					data: {
						title: 'releaseDataFile',
						description: 'Upload release data table CSV.',
						file: null,
						buttonText: 'Release data',
						helperText: 'You can upload CSV files only'
					}
				},
				{
					type: 'elementsDataFile',
					data: {
						title: 'elementsDataFile',
						description: 'Upload portal elements data table CSV. Should contain elements, their description, types, etc.',
						file: null,
						buttonText: 'Elements data',
						helperText: 'You can upload CSV files only'
					}
				},
				{
					type: 'hierarchyDataFile',
					data: {
						title: 'hierarchyDataFile',
						description: 'Upload facets search tree data table CSV. Should contain tabs, groups, and facets hierarchy.',
						file: null,
						buttonText: 'Hierarchy data',
						helperText: 'You can upload CSV files only'
					}
				}
			],
			errors: {
				emptyField: 'Please fill the index name',
				emptyFile: 'Please attach Data File',
				indexName: 'Index must start and end with alphanumeric characters and contain only lowercase letters, digits or dashes.',
				minLength: 'The value must have a length of at least 2'
			},
		},
	};
}
