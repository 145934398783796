import { takeEvery } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { wpFrameSlice } from '../slices';

import { getWPConfigSaga } from './getWPConfigSaga';

export function* rootPageSaga(): SagaIterator {
	yield takeEvery(wpFrameSlice.actions.getConfigRequest.type, getWPConfigSaga);
}
