import React, { FunctionComponent } from 'react';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { grey } from '@material-ui/core/colors';

interface CustomTableNoRowsProps {
	text: string
}

export const CustomTableNoRows: FunctionComponent<CustomTableNoRowsProps> = ({
	text
}) =>
	<Box pt={14} color={grey['600']}>
		<Typography
			component="p"
			variant="h3"
			align="center"
			style={{ whiteSpace: 'pre-wrap' }}
		>
			{ text }
		</Typography>
	</Box>
;
