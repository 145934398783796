import type { RootState } from 'app/store';

import { Doi, DoiConfig, DoiShort, Omic } from './types';

export const selectIsOmicsLoading = (state: RootState): boolean => state.doi.isOmicsLoading;
export const selectIsOmicsInitialized = (state: RootState): boolean => state.doi.isOmicsInitialized;
export const selectUserOmics = (state: RootState): Omic[] => state.doi.userOmics;
export const selectIsCreateUserDoiLoading = (state: RootState): boolean => state.doi.isCreateUserDoiLoading;
export const selectIsSaveUserDoiLoading = (state: RootState): boolean => state.doi.isSaveUserDoiLoading;
export const selectIsDownloadCreateFileListLoading = (state: RootState): boolean => state.doi.isDownloadCreateFileListLoading;
export const selectUserDoi = (state: RootState): Doi | null => state.doi.userDoi;
export const selectIsGetUserDoiLoading = (state: RootState): boolean => state.doi.isGetUserDoiLoading;
export const selectIsDownloadReadFileListLoading = (state: RootState): boolean => state.doi.isDownloadReadFileListLoading;
export const selectUserDoiList = (state: RootState): DoiShort[] => state.doi.userDoiList;
export const selectUserDoiListTotalCount = (state: RootState): number => state.doi.userDoiListTotalCount;
export const selectIsGetUserDoiListLoading = (state: RootState): boolean => state.doi.isGetUserDoiListLoading;
export const selectUserDoiListCurrentPage = (state: RootState): number => state.doi.userDoiListCurrentPage;
export const selectUserDoiListRowsPerPage = (state: RootState): number => state.doi.userDoiListRowsPerPage;
export const selectIsDoiConfigLoading = (state: RootState): boolean => state.doi.isDoiConfigLoading;
export const selectDoiConfig = (state: RootState): DoiConfig | null => state.doi.doiConfig;
