
import { PointOptionsObject } from 'highcharts';
import { some } from 'lodash';

import { FacetValue, SelectedFacetValue, FacetRangeType } from '../types';

export const getCountSeriesData = (facetRangeType: FacetRangeType | undefined, facetValues: FacetValue[], selectedFacetValues: SelectedFacetValue[]): PointOptionsObject[] =>
	facetValues.map(facetValue => ({
		y: facetValue.count,
		custom: {
			value: facetValue.value,
			from: facetValue.from,
			to: facetValue.to
		},
		selected: some(selectedFacetValues, selectedValue => {
			if (facetRangeType === FacetRangeType.Values) {
				return facetValue.from === selectedValue.from && facetValue.to === selectedValue.to;
			}
			return selectedValue.value === facetValue.value;
		})
	}));
