export const getFormattedDate = (date: string | null) => {
	return date !== null ?
		new Intl.DateTimeFormat('default', {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		}).format(new Date(date)) :
		'';
};
