import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { NOT_FOUND } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { releaseDataManagementSlice } from '../slices';
import { ReleaseOperationsResponse } from '../types';
import { getReleaseOperations } from '../api';

export function* getReleaseOperationsSaga(): SagaIterator {
	try {
		yield put(releaseDataManagementSlice.actions.getReleaseOperationsStart());

		const releaseOperationsResponse = (yield call(getReleaseOperations)) as AxiosResponse<ReleaseOperationsResponse>;

		yield put(releaseDataManagementSlice.actions.getReleaseOperationsEnd({
			endState: SagaEndState.Success,
			data: releaseOperationsResponse.data
		}));
	} catch (e) {
		if ((e as AxiosError).response?.status === NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'User not found' }));
		} else {
			yield call(handleErrorSaga, e);
		}
		yield put(releaseDataManagementSlice.actions.getReleaseOperationsEnd({ endState: SagaEndState.Fail }));
	}
}
