/* eslint-disable react/no-multi-comp */
import React, { FunctionComponent } from 'react';
import { Button, makeStyles } from '@material-ui/core';

interface Props {
	onDelete?: () => void
	onSetIndex?: () => void
}

const useStyles = makeStyles(() => ({
	deleteBtn: {
		color: '#DC004E'
	}
}));

export const IndexButtonsActions: FunctionComponent<Props> = ({ onDelete, onSetIndex }) => {
	const classes = useStyles();

	const buttonDelete = () => <Button onClick={onDelete} size="medium" className={classes.deleteBtn}>{ 'DELETE' }</Button>;
	const buttonSet = () => <Button onClick={onSetIndex} size="medium" color="primary">{ 'SET INDEX' }</Button>;

	return <>{buttonSet()}{' | '}{buttonDelete()}</>;
};
