import { put, call, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { proceedSlice } from '../slices';
import { getProceedTabs } from '../api';

export type ProceedResponseTab = {
	name: string
	displayName: string
};

export function* getProceedTabsSaga(): Generator<StrictEffect, void, AxiosResponse<ProceedResponseTab[]>> {
	try {
		const proceedTabs: AxiosResponse<ProceedResponseTab[]> = yield call(getProceedTabs);
		yield put(proceedSlice.actions.getProceedTabsSuccess(proceedTabs.data.map(tab => tab.displayName)));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(proceedSlice.actions.getProceedTabsFailure());
	}
}
