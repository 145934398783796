import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../network';
import { DataRequestStatus, UserRoles } from '../constants';

export interface GetUserRolesResponse {
	roles: UserRoles[]
	dataRequestStatus: DataRequestStatus | null
}

export const getUserRoles = async (): Promise<AxiosResponse<GetUserRolesResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch<GetUserRolesResponse>(Urls.GetUserRoles, requestOptions);
};
