import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { addiDataRequestDetailsSlice } from '../slices';
import { selectAddiDataRequestDetails } from '../selectors';

import { getAddiDataRequestDetailsSaga } from './getAddiDataRequestDetailsSaga';
import { sendAddiSignRequest } from '../api/sendAddiSignRequest';

export function* sendAddiSignDataRequestSaga(): SagaIterator {
	const addiDataRequest = (yield select(selectAddiDataRequestDetails)) as ReturnType<typeof selectAddiDataRequestDetails>;

	if (addiDataRequest !== null) {
		try {
			yield put(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequestStart());

			yield call(sendAddiSignRequest, { id: addiDataRequest.id });
			yield call(getAddiDataRequestDetailsSaga, addiDataRequest.id);

			yield put(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequestEnd());
		} catch (e) {
			yield put(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequestEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
