/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-tabs */
import React, { FunctionComponent, useCallback } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import data from './../data/text.json';
import { CommonGreeting } from './CommonGreeting';
import { CommonCapabilities } from './CommonCapabilities';
import { portalAuthManager } from 'auth/portalAuthManager';

const PADDINGS = 7;

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		width: '100%',
		maxWidth: 1200,
		justifyContent: 'center',
		minHeight: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(PADDINGS)
	},
	stepsTitle: {
		color: '#000',
		margin: '0px 0px 16px 0px',
		fontSize: '1.5rem',
		fontStyle: 'normal',
		fontWeight: 'normal',
		lineHeight: '1.5rem',
		letterSpacing: '0.01125rem'
	},
	descriptionWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	stepsList: {
		maxWidth: '70%',

		'& ol': {
			padding: 0,
			marginLeft: '1.25rem'
		},

		'& li': {
			fontSize: '1.2rem',
			lineHeight: '2rem',
			paddingBottom: '1rem'
		}
	},
	button: {
		padding: '15px 25px'
	}
}));

interface RequestDataAccessProps {
	showRequestDataButton: boolean
}

export const RequestDataAccess: FunctionComponent<RequestDataAccessProps> = ({
	showRequestDataButton
}: RequestDataAccessProps) => {
	const classes = useStyles();

	const signInHandler = useCallback(() => portalAuthManager.signIn(), []);

	return <Box className={classes.wrapper}>
		<CommonGreeting
			title={data.title}
			description={data.description}
		/>
		<Typography
			component="div"
			variant="h6"
			className={classes.stepsTitle}>
			{data.steps}
		</Typography>
		<Box pb={1} />
		<Box className={classes.descriptionWrapper}>
			<Box
				className={classes.stepsList}
				dangerouslySetInnerHTML={{ __html: data.stepsList }}/>
			<Box>
				{showRequestDataButton ?
					<Button
						className={classes.button}
						color="primary"
						variant="contained"
						size="large"
						href='/request-access'>
						{ data.buttonRequestAccessLabel }
					</Button> :
					<Button
						onClick={signInHandler}
						className={classes.button}
						color="primary"
						variant="contained"
						size="large">
						{ data.buttonSignInLabel }
					</Button>}
			</Box>
		</Box>
		<CommonCapabilities
			title={data.capabilitiesTitle}
			capabilities={data.capabilitiesList}
		/>
	</Box>;
};
