import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import { LoaderButton } from 'components';

import { doiSlice } from '../slices';
import { selectDoiConfig, selectIsDownloadReadFileListLoading, selectIsGetUserDoiLoading, selectUserDoi } from '../selectors';

import { DoiOmicLevels } from './DoiOmicLevels';
import { Footer } from 'features/footer';

const TEXTAREA_Y_PADDING = 2.3125;
const TEXTAREA_X_PADDING = 1.75;

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		height: '100%',
		minHeight: 'inherit',
		backgroundColor: theme.palette.common.white
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		padding: theme.spacing(3)
	},
	textareaRoot: {
		'& > div': {
			backgroundColor: 'transparent',
			zIndex: 1,
			padding: theme.spacing(TEXTAREA_Y_PADDING, 0, 0, 0),
			'& > textarea': {
				padding: theme.spacing(0, TEXTAREA_X_PADDING, TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
				resize: 'vertical',
				minHeight: '100px',
				'&::-webkit-scrollbar': {
					width: '10px'
				}
			}
		}
	},
	inputDisabled: {
		color: theme.palette.text.disabled
	}
}));

interface DoiReadMatchParams {
	doiId: string
}

type DoiReadProps = RouteComponentProps<DoiReadMatchParams>;

export const DoiRead: FunctionComponent<DoiReadProps> = ({
	match
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();

	const { doiId } = match.params;
	const userDoi = useSelector(selectUserDoi);
	const isGetUserDoiLoading = useSelector(selectIsGetUserDoiLoading);
	const isDownloadReadFileListLoading = useSelector(selectIsDownloadReadFileListLoading);
	const doiConfig = useSelector(selectDoiConfig);

	const onDownloadClick = useCallback(() => {
		dispatch(doiSlice.actions.downloadReadFileList({ id: doiId }));
	}, [dispatch, doiId]);

	useEffect(() => {
		dispatch(doiSlice.actions.getUserDoi({ id: doiId }));
		dispatch(doiSlice.actions.getDoiConfig());
	}, [dispatch, doiId]);

	return (
		<Box className={classes.containerWrapper}>
			<Box
				visibility={isGetUserDoiLoading ? 'visible' : 'hidden'}
				position="sticky"
				top={0}
				width="100%"
				zIndex={theme.zIndex.appBar}
			>
				<LinearProgress />
			</Box>
			<Container className={classes.container}>
				{userDoi !== null && doiConfig !== null &&
				<Box maxWidth={600}>
					<Box pt={2} />
					<Typography component="h1" variant="h3">
						{ 'Answer ALS DOI' }
					</Typography>
					<Box pb={2} />
					<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
						{ doiConfig.text.readPage.metadataSection.title }
					</Typography>
					<Box pb={2} />
					<Typography component="p" variant="body1" >
						{ doiConfig.text.readPage.metadataSection.description }
					</Typography>
					<Box width={520} py={3}>
						<TextField
							name="title"
							id={ 'doi-form-field-title' }
							label={ 'Title' }
							variant="outlined"
							fullWidth
							disabled
							value={ userDoi.title }
							InputProps={{
								classes: { disabled: classes.inputDisabled }
							}}
						/>
						<Box pb={3} />
						<TextField
							name="principalInvestigator"
							id={ 'doi-form-field-principalInvestigator' }
							label={ 'Principal Investigator' }
							variant="outlined"
							fullWidth
							disabled
							value={ userDoi.principalInvestigator }
							InputProps={{
								classes: { disabled: classes.inputDisabled }
							}}
						/>
						<Box pb={3} />
						<TextField
							classes={{ root: classes.textareaRoot }}
							name="description"
							id={ 'doi-form-field-description' }
							label={ 'Description' }
							variant="outlined"
							multiline
							rows={4}
							fullWidth
							disabled
							value={ userDoi.description }
							InputProps={{
								classes: { disabled: classes.inputDisabled }
							}}
						/>
					</Box>
					<Box pb={5} />
					<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
						{ doiConfig.text.readPage.dataSection.title }
					</Typography>
					<Box pb={2} />
					<Typography component="p" variant="body1" >
						{ doiConfig.text.readPage.dataSection.description }
					</Typography>
					<Box pb={4} />
					<Box maxWidth={520}>
						{userDoi && userDoi.omics && userDoi.omics.map(omic =>
							<Box key={omic.type} pb={2}>
								<DoiOmicLevels
									omic={omic}
									disabled={true}
								/>
							</Box>
						)}
					</Box>
					<Box pt={3} pb={5}>
						<LoaderButton
							isLoading={isDownloadReadFileListLoading}
							wrapperBoxProps={{
								style: { display: 'inline' }
							}}
							ButtonProps={{
								size: 'large',
								variant: 'contained',
								color: 'primary',
								onClick: onDownloadClick
							}}
						>
							{ 'Download File List' }
						</LoaderButton>
					</Box>
				</Box>
				}
			</Container>
			<Box pb={4} />
			{doiConfig !== null && !isGetUserDoiLoading &&
			<Footer
				links={doiConfig.footer.links}
			/>
			}
		</Box>
	);
};
