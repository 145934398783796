import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { Routes } from 'app/Routes';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';
import { RestrictedRoute } from 'components/RestrictedRoute';
import { Header } from 'features/header';
import { ADMIN_ROLES } from 'auth/constants';
import { selectIsAccountLoading, selectUserInfo } from 'auth/selectors';
import { NotFoundErrorPage } from 'features/errorHandling';

import { UsersManagement, UsersManagementUserPage } from '../features/usersManagement/components';
import { selectIsUsersManagementLoading } from '../features/usersManagement/selectors';

import { AdminNavigation } from './AdminNavigation';
import { IndexManagement, CreateManagement } from '../features/indexManagement/components';
import { Statistics } from '../features/statistics/components';
import { ReleaseDataManagement } from '../features/releaseData/components';
import { AddiDataRequests } from '../features/addiDataRequests/components';
import { AddiDataRequestDetails } from '../features/addiDataRequests/components/AddiDataRequestDetails';
import { selectIsAddiManagementLoading } from '../features/addiDataRequests/selectors';

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		width: '100%',
		height: 'max-content',
		display: 'flex'
	},
	headerAppBarRoot: {
		backgroundColor: '#212121'
	},
	loaderContainer: {
		position: 'absolute',
		top: theme.spacing(HEADER_HEIGHT_SPACING),
		width: '100%',
		zIndex: theme.zIndex.appBar
	},
	pagesContainer: {
		overflowX: 'hidden',
		width: '100%',
	}
}));

export const AdminLayout: FunctionComponent = () => {
	const classes = useStyles();

	const { userRoles } = useSelector(selectUserInfo);
	const isAccountLoading = useSelector(selectIsAccountLoading);
	const isUsersManagementLoading = useSelector(selectIsUsersManagementLoading);
	const isAddiManagementLoading = useSelector(selectIsAddiManagementLoading);

	const isLoading = isAccountLoading
		|| isUsersManagementLoading
		|| isAddiManagementLoading;

	return (
		<>
			<Header classes={{ appBarRoot: classes.headerAppBarRoot }} />
			<Box visibility={isLoading ? 'visible' : 'hidden'} className={classes.loaderContainer}>
				<LinearProgress />
			</Box>
			<Box className={classes.wrapper}>
				<AdminNavigation />
				<Box className={classes.pagesContainer}>
					{!isAccountLoading &&
					<Switch>
						<RestrictedRoute exact path={`${Routes.UsersManagement}/users/:userId`} component={UsersManagementUserPage} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={Routes.UsersManagement} component={UsersManagement} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={Routes.AddiDataRequests} component={AddiDataRequests} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={`${Routes.AddiDataRequests}/:id`} component={AddiDataRequestDetails} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={Routes.IndexManagement} component={IndexManagement} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={`${Routes.IndexManagement}/create`} component={CreateManagement} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={Routes.Statistics} component={Statistics} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))}/>
						<RestrictedRoute exact path={Routes.ReleaseDataManagement} component={ReleaseDataManagement} isForbidden={!userRoles.some(role => ADMIN_ROLES.includes(role))} />
						<Route component={NotFoundErrorPage}/>
					</Switch>
					}
				</Box>
			</Box>
		</>
	);
};
