import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../network';

import { UserMenuItem } from '../types';

export interface GetUserMenuItemsResponse {
	menuItems: UserMenuItem[]
}

export const getUserMenuItems = async (): Promise<AxiosResponse<GetUserMenuItemsResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch<GetUserMenuItemsResponse>(Urls.GetUserMenuItems, requestOptions);
};
