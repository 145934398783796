import React, { FunctionComponent, useRef, useState, useCallback } from 'react';
import ReactResizeDetector from 'react-resize-detector';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CHIP_HEIGHT_PX, CHIP_MAX_WIDTH_PX, CHIP_HORIZONTAL_MARGIN_PX, CHIP_VERTICAL_MARGIN_PX } from 'styles/constants.ui';

import { getFacetValueLabel } from '../utils';
import { SelectedFacets, SelectedFacetValue, DetailsFacetsConfig } from '../types';
import { CaseSetEntity, getCaseSetName } from '../features/caseSet';

interface SelectedFacetsPanelProps {
	selectedFacets: SelectedFacets
	detailsFacetsConfig: DetailsFacetsConfig
	unselectValue: (facetName: string, facetValue: SelectedFacetValue) => void
	unselectAllEntities: () => void
	selectedCaseSetEntities: CaseSetEntity[]
	unselectCaseSet: (id: string) => void
	filterQueryString: string
	unselectExtCaseSet: () => void
}

const RowHeightPx = CHIP_VERTICAL_MARGIN_PX * 2 + CHIP_HEIGHT_PX;
const SELECTED_FACETS_PANEL_MARGIN_BOTTOM = 0.5;
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			marginBottom: theme.spacing(SELECTED_FACETS_PANEL_MARGIN_BOTTOM),
			justifyContent: 'space-between'
		},
		expandButton: {
			alignSelf: 'start'
		},
		expandedPanel: {
			overflowY: 'auto'
		},
		collapsedPanel: {
			height: RowHeightPx,
			overflowY: 'hidden'
		},
		clearAllButton: {
			color: theme.palette.primary.main,
			borderRadius: '18px',
			backgroundColor: common.white,
			height: CHIP_HEIGHT_PX
		},
		chip: {
			margin: `${CHIP_VERTICAL_MARGIN_PX}px ${CHIP_HORIZONTAL_MARGIN_PX}px`,
			height: CHIP_HEIGHT_PX,
			maxWidth: CHIP_MAX_WIDTH_PX
		}
	})
);

export const SelectedFacetsPanel: FunctionComponent<SelectedFacetsPanelProps> = ({
	selectedFacets,
	detailsFacetsConfig,
	unselectValue,
	unselectAllEntities,
	selectedCaseSetEntities,
	unselectCaseSet,
	filterQueryString,
	unselectExtCaseSet
// eslint-disable-next-line sonarjs/cognitive-complexity
}: SelectedFacetsPanelProps) => {
	const classes = useStyles();
	const [isExpanded, setIsExpanded] = useState(true);
	const [isShowExpandButton, setIsShowExpandButton] = useState(false);
	const chipsPanelRef = useRef<HTMLElement | null>(null);

	const getUnselectValueFunction = useCallback((facetName: string, facetValue: SelectedFacetValue) =>
		() => unselectValue(facetName, facetValue),
	[unselectValue]);
	const getUnselectCaseSetFunction = useCallback(id =>
		() => unselectCaseSet(`${id}`),
	[unselectCaseSet]);

	const onResize = useCallback(() => {
		const scrollHeight = chipsPanelRef.current?.scrollHeight || 0;
		if (Math.floor(scrollHeight / RowHeightPx) > 1) {
			if (!isShowExpandButton) {
				setIsShowExpandButton(true);
			}
		} else {

			if (isShowExpandButton) {
				setIsShowExpandButton(false);
			}

			if (!isExpanded) {
				setIsExpanded(true);
			}
		}
	},
	[isExpanded, isShowExpandButton]);

	const toggleExpand = useCallback(() => {
		setIsExpanded(!isExpanded);
	},
	[isExpanded]);

	const setChipsPanelRef = useCallback((element: HTMLElement | null) => {
		chipsPanelRef.current = element;
	},
	[]);

	return (
		<div className={classes.root}>
			<ReactResizeDetector handleWidth handleHeight onResize={onResize}>
				<div
					ref={setChipsPanelRef}
					className={isExpanded ? classes.expandedPanel : classes.collapsedPanel}
					aria-expanded={isExpanded}>
					<Button
						className={classes.clearAllButton}
						variant='outlined'
						onClick={unselectAllEntities}
					>
						{'Clear All'}
					</Button>
					{
						filterQueryString.length > 0 && <Chip
							title={'External Case Set'}
							deleteIcon={<ClearIcon/>}
							className={classes.chip}
							label={'External Case Set'}
							onDelete={unselectExtCaseSet}
						/>
					}
					{
						selectedCaseSetEntities
							.map(caseSetEntity => {
								const title = getCaseSetName(caseSetEntity);
								const label = `Case Set: ${title}`;
								return (
									<Chip
										key={caseSetEntity.id}
										title={label}
										deleteIcon={<ClearIcon/>}
										className={classes.chip}
										label={label}
										onDelete={getUnselectCaseSetFunction(caseSetEntity.id)}
									/>
								);
							})
					}
					{
						Object.entries(selectedFacets).map(([facetName, facetValues]) => {
							const facetDetails = detailsFacetsConfig[facetName];
							return facetValues.map(facetValue => {
								const valueLabel = getFacetValueLabel(facetValue, facetDetails?.rangeType, facetDetails?.range);
								const label = `${facetDetails?.displayName || facetName}=${valueLabel}`;
								return (
									<Chip
										title={label}
										deleteIcon={<ClearIcon/>}
										className={classes.chip}
										key={`${facetName}-${valueLabel}`}
										label={label}
										onDelete={getUnselectValueFunction(facetName, facetValue)}
									/>
								);
							});
						})
					}
				</div>
			</ReactResizeDetector>
			{
				isShowExpandButton &&
					<IconButton aria-label='expand' className={classes.expandButton} onClick={toggleExpand}>
						{
							isExpanded
								? <ExpandLessIcon />
								: <ExpandMoreIcon />
						}
					</IconButton>
			}
		</div>
	);
};
