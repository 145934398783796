/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FunctionComponent, useCallback } from 'react';

import {
	Box,
	Paper,
	Typography
} from '@material-ui/core';

import { User } from '../types';
import { LoaderButton } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsUsersManagementLoading } from '../selectors';
import { usersManagementUserSlice } from '../slices';
import { getFormattedDateTime } from '../utils';

export interface ResearcherSurveyProps {
	user: User
}

export const ResearcherSurvey: FunctionComponent<ResearcherSurveyProps> = ({
	user
}) => {
	const dispatch = useDispatch();

	const isUsersManagementLoading = useSelector(selectIsUsersManagementLoading);

	const onSendResearcherSurveyClick = useCallback(() => {
		dispatch(usersManagementUserSlice.actions.sendResearcherSurvey());
	}, [dispatch]);

	return <Box>
		<Paper variant='outlined'>
			<Box p={2}>
				<Box display="flex" flexDirection="column">
					<Typography
						variant="h6"
						component="h6"
					>
						{'Researcher Survey'}
					</Typography>
					<Box pb={2} />
					<Box>
						{
							user.researcherSurveySentDate ?
								<Box mb={1}>
									<Typography>{`Researcher Survey has been sent ${getFormattedDateTime(user.researcherSurveySentDate)} by ${user.researcherSurveySentBy}`}</Typography>
								</Box> :
								null
						}
						<Typography variant='body2'>{'We send researcher survey automatically 4 months after Data Request approval. If you want to send Researcher Survey earlier, or send it once again please click Send Researcher Survey button.'}</Typography>
					</Box>
					<Box pb={2} />
					<Box display={'flex'}>
						<LoaderButton
							isLoading={
								isUsersManagementLoading
							}
							ButtonProps={{
								disabled: false,
								size: 'medium',
								color: 'primary',
								variant: 'outlined',
								onClick: onSendResearcherSurveyClick
							}}
						>
							{'Send Survey'}
						</LoaderButton>
					</Box>
				</Box>
			</Box>
		</Paper>
	</Box>;
};
