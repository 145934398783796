import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';
import { EntitiesExpandState, FacetSearchOption } from '../types';
import { getFacetsPanelEntityExpandInfo, traverseFacetsPanelEntitiesExpandInfo } from '../utils';

interface FacetsPanelState {
	facetSearchOptions: FacetSearchOption[]
	entitiesExpandState: EntitiesExpandState
	targetSearchId: string
	isShowFacetsWithoutValues: boolean
}

const initialState: FacetsPanelState = {
	facetSearchOptions: [],
	entitiesExpandState: {},
	targetSearchId: '',
	isShowFacetsWithoutValues: false
};

export const facetsPanelSlice = createSlice({
	name: `${ACTIONS_PREFIX}.facetsPanel`,
	initialState,
	reducers: {
		setEntitiesExpandState: (state: FacetsPanelState, { payload }: PayloadAction<EntitiesExpandState>) => {
			state.entitiesExpandState = payload;
		},
		setFacetSearchOptions: (state: FacetsPanelState, { payload }: PayloadAction<FacetSearchOption[]>) => {
			state.facetSearchOptions = payload;
		},

		setActiveTab: (state: FacetsPanelState, { payload }: PayloadAction<{
			tabName: string
		}>) => {
			Object.entries(state.entitiesExpandState).forEach(([key, value]) => {
				value.state = key === payload.tabName;
			});
		},
		toggleEntityExpand: (state: FacetsPanelState, { payload }: PayloadAction<{
			path: string[]
			state: boolean
		}>) => {
			const entity = getFacetsPanelEntityExpandInfo(state.entitiesExpandState, payload.path);
			entity.state = payload.state;
		},

		toggleEntitiesSubtree: (state: FacetsPanelState, { payload }: PayloadAction<{
			path: string[]
			state: boolean
		}>) => {
			(payload.path.length === 0
				? Object.values(state.entitiesExpandState)
				: [getFacetsPanelEntityExpandInfo(state.entitiesExpandState, payload.path)]
			).forEach(entity => {
				traverseFacetsPanelEntitiesExpandInfo(entity.childs, item => {
					item.state = payload.state;
				});
			});
		},

		setTargetSearchId: (state: FacetsPanelState, { payload }: PayloadAction<string>) => {
			state.targetSearchId = payload;
		},

		toggleShowFacetsWithoutValues: (state: FacetsPanelState, { payload }: PayloadAction<boolean>) => {
			state.isShowFacetsWithoutValues = payload;
		}
	}
});
