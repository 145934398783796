import { SagaIterator } from 'redux-saga';
import { call, cancelled, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementTableSlice } from '../slices';
import { getUsersList } from '../api';
import { GetUsersListResponse } from '../types';
import {
	selectFilters,
	selectTableCurrentPage,
	selectTableRowsPerPage,
	selectTableSearchQuery,
	selectTableSort
} from '../selectors';
import { getFilterParam } from '../utils';

const SKIP_PAGINATION_RESET_ACTION_TYPES = [usersManagementTableSlice.actions.setCurrentPage.type];

export function* updateUsersListSaga(action: PayloadAction): SagaIterator {
	try {
		yield put(usersManagementTableSlice.actions.updateUsersListStart());

		if (!SKIP_PAGINATION_RESET_ACTION_TYPES.some(saveCurrentPageActionType => action.type === saveCurrentPageActionType)) {
			yield put(usersManagementTableSlice.actions.resetPageSilent());
		}

		const currentPage = (yield select(selectTableCurrentPage)) as ReturnType<typeof selectTableCurrentPage>;
		const rowsPerPage = (yield select(selectTableRowsPerPage)) as ReturnType<typeof selectTableRowsPerPage>;
		const sort = (yield select(selectTableSort)) as ReturnType<typeof selectTableSort>;
		const searchQuery = (yield select(selectTableSearchQuery)) as ReturnType<typeof selectTableSearchQuery>;
		const filters = (yield select(selectFilters)) as ReturnType<typeof selectFilters>;

		const { data } = (yield call(getUsersList, {
			search: searchQuery,
			skip: currentPage * rowsPerPage,
			top: rowsPerPage,
			filter: getFilterParam(filters),
			orderBy: `${sort.orderBy} ${sort.order}`
		})) as AxiosResponse<GetUsersListResponse>;

		yield put(usersManagementTableSlice.actions.updateUsersListEnd({ endState: SagaEndState.Success, data }));
	} catch (e) {
		if (yield cancelled()) {
			yield put(usersManagementTableSlice.actions.updateUsersListEnd({ endState: SagaEndState.Cancel }));
		} else if ((e as AxiosError).response?.status === StatusCodes.NOT_FOUND) {
			yield put(usersManagementTableSlice.actions.updateUsersListEnd({
				endState: SagaEndState.Success,
				data: {
					totalCount: 0,
					userInformationList: []
				}
			}));
		} else {
			yield put(usersManagementTableSlice.actions.updateUsersListEnd({ endState: SagaEndState.Fail }));
			yield call(handleErrorSaga, e);
		}
	}
}
