import { useMemo } from 'react';

import { Column, ColumnTypes, StaticColumnAlign } from '../types';

export const useSortedColumns = (columns: Column[]): Column[] => {
	const staticStartColumns = useMemo<Column[]>(() => columns
		.filter(column => column.type === ColumnTypes.Static && column.align !== StaticColumnAlign.End),
	[columns]);
	const staticEndColumns = useMemo<Column[]>(() => columns
		.filter(column => column.type === ColumnTypes.Static && column.align === StaticColumnAlign.End),
	[columns]);
	const flexColumns = useMemo<Column[]>(() => columns
		.filter(column => column.type === ColumnTypes.Flex),
	[columns]);

	return [
		...staticStartColumns,
		...flexColumns,
		...staticEndColumns
	];
};
