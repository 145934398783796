import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Divider, Typography, Box, AppBar, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { DetailsFacetsItemConfig, Facets, FacetsBaseProps, SelectedFacets } from '../types';
import { selectFacetsPanelEntitiesExpandState, selectIsShowFacetsWithoutValues } from '../selectors';
import { facetsPanelSlice } from '../slices';
import { generateFacetPanelEntityId, getFacetTotalCount } from '../utils';
import { CaseSetGroup, caseSetSlice } from '../features/caseSet';

import { FacetsGroup } from './FacetsGroup';

interface TabPanelProps {
	children?: React.ReactNode
	index?: number | string
	value?: number | string
}

interface FacetsTabsProps extends FacetsBaseProps {
	selectDetailsConfig: DetailsFacetsItemConfig[]
	facets: Facets
	selectedFacets: SelectedFacets
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${String(index)}`}
			aria-labelledby={`scrollable-auto-tab-${String(index)}`}
			{...other}
		>
			{value === index &&
			<Box>
				<Typography component={'span'}>{children}</Typography>
			</Box>
			}
		</Box>
	);
}

function a11yProps(index: number) {
	return {
		style: { 'minWidth': 'inherit' },
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

const useStyles = makeStyles(() => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: grey['50'],
	},
	tabRoot: {
		color: grey['700'],
		maxWidth: '120px',
	},
	tabWrapper: {
		alignItems: 'start',
		display: 'block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	scrollButtonsDesktop: {
		color: grey['700'],
	}
}));

// eslint-disable-next-line react/no-multi-comp
export const FacetsTabs: FunctionComponent<FacetsTabsProps> = ({
	selectDetailsConfig,
	facets,
	selectedFacets,
	selectFacetValue,
	unselectFacetValue,
}: FacetsTabsProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [activeTab, setActiveTab] = useState(0);
	const [withoutValuesCounter, setWithoutValuesCounter] = useState(0);

	const facetsPanelEntitiesExpandState = useSelector(selectFacetsPanelEntitiesExpandState);
	const isShowFacetsWithoutValues = useSelector(selectIsShowFacetsWithoutValues);

	const displayedFacetsPanel = selectDetailsConfig.map(category => ({
		...category,
		groups: category.groups
			.map(group => ({
				...group,
				facets: group.facets
					.map(facetDetails => ({ ...facetDetails, values: [...facets[facetDetails.name]] }))
			})
			)
	}));

	useEffect(() => {
		const count = displayedFacetsPanel
			.map(category => category.groups)
			.reduce((flatFacetsGroupList, group) => flatFacetsGroupList.concat(group), [])
			.map(group => group.facets)
			.reduce((flatFacetsList, facet) => flatFacetsList.concat(facet), [])
			.filter(facet => getFacetTotalCount(facet.values, selectedFacets[facet.name] || []) === 0)
			.length;
		setWithoutValuesCounter(count);
	}, [facets, displayedFacetsPanel, selectedFacets]);

	const setIsCollapseAll = useCallback(() => {
		dispatch(caseSetSlice.actions.collapseCaseSetGroup());
		dispatch(facetsPanelSlice.actions.toggleEntitiesSubtree({ path: [], state: false }));
		dispatch(facetsPanelSlice.actions.setActiveTab({ tabName: displayedFacetsPanel[activeTab].name }));
	}, [dispatch, activeTab, displayedFacetsPanel]);
	const setIsShowFacetsWithoutValues = useCallback(() => {
		dispatch(facetsPanelSlice.actions.toggleShowFacetsWithoutValues(!isShowFacetsWithoutValues));
	}, [dispatch, isShowFacetsWithoutValues]);
	// eslint-disable-next-line no-shadow, @typescript-eslint/ban-types
	const handleChange = useCallback((event: React.ChangeEvent<{}>, newIndex: number) => {
		dispatch(facetsPanelSlice.actions.toggleEntitiesSubtree({ path: [], state: false }));
		dispatch(facetsPanelSlice.actions.setActiveTab({ tabName: displayedFacetsPanel[newIndex].name }));
	}, [dispatch, displayedFacetsPanel]);

	useEffect(() => {
		const index = displayedFacetsPanel.findIndex(category => facetsPanelEntitiesExpandState[category.name].state);
		setActiveTab(index === -1 ? 0 : index);
	}, [facetsPanelEntitiesExpandState, displayedFacetsPanel]);

	return (
		<Box className={classes.root}>
			<AppBar position="sticky" style={{ backgroundColor: grey['50'], boxShadow: 'none', top: '-1px' }}>
				<Tabs
					value={activeTab}
					onChange={handleChange}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="scrollable auto facets tabs"
					classes={{ scrollButtonsDesktop: classes.scrollButtonsDesktop }}
				>
					{ displayedFacetsPanel.map((item,index) =>
						<Tab
							classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, }}
							key={item.displayName}
							label={item.displayName}
							{...a11yProps(index)}
						/>
					) }
				</Tabs>
				<Divider />
			</AppBar>
			<Box display="flex" justifyContent="space-between" p={2}>
				<Button
					aria-label={ `${isShowFacetsWithoutValues ? 'Hide' : 'Show'} ${withoutValuesCounter} facets without values` }
					style={{ textTransform: 'lowercase', marginLeft: '-5px' }}
					size="small"
					variant="text"
					onClick={setIsShowFacetsWithoutValues}>
					<Typography color="primary" component="span" variant="body2">
						{ `${isShowFacetsWithoutValues ? 'Hide' : 'Show'} ${withoutValuesCounter} facets without values` }
					</Typography>
				</Button>
				<Button
					aria-label={ 'Collapse all facets' }
					style={{ textTransform: 'lowercase', marginRight: '-5px' }}
					size="small"
					variant="text"
					onClick={setIsCollapseAll}>
					<Typography color="primary" component="span" variant="body2">
						{ 'collapse all' }
					</Typography>
				</Button>
			</Box>
			<Divider />
			{ displayedFacetsPanel.map((category,index) =>
				<TabPanel key={generateFacetPanelEntityId([category.name])} value={activeTab} index={index}>
					{ category.default &&
						<CaseSetGroup />
					}
					{ category.groups.map(group => {
						const filteredFacets = isShowFacetsWithoutValues ?
							group.facets :
							group.facets.filter(facet => getFacetTotalCount(facet.values, selectedFacets[facet.name] || []) !== 0);

						return <FacetsGroup
							key={generateFacetPanelEntityId([category.name, group.name])}
							facets={filteredFacets}
							groupDescription={group?.description}
							groupName={group.name}
							groupDisplayName={group.displayName}
							selectFacetValue={selectFacetValue}
							unselectFacetValue={unselectFacetValue}
							selectedFacets={selectedFacets}
							panelPath={[category.name, group.name]}
						/>;
					})}
				</TabPanel>
			)}
		</Box>
	);
};
