import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../../../constants';

import { GetHeatmapDataRequest, GetHeatmapDataResponse, InitHeatmapDataRequest } from '../types';

export interface OmicL4VisualizationState {
	participants: string[]
	genes: string[]
	heatmapData: string
	isHeatmapDataInitialized: boolean
	isHeatmapDataLoading: boolean
	isHeatmapDataDownloading: boolean
}

const initialState: OmicL4VisualizationState = {
	participants: [],
	genes: [],
	heatmapData: '',
	isHeatmapDataInitialized: false,
	isHeatmapDataLoading: false,
	isHeatmapDataDownloading: false,
};

export const omicL4VisualizationSlice = createSlice({
	name: `${ACTIONS_PREFIX}.omicL4`,
	initialState,
	reducers: {
		initHeatmapDataRequest: (state: OmicL4VisualizationState, _: PayloadAction<InitHeatmapDataRequest>) => {
			state.isHeatmapDataLoading = true;
		},
		initHeatmapDataSuccess: (state: OmicL4VisualizationState, { payload }: PayloadAction<GetHeatmapDataResponse>) => {
			state.participants = payload.participants;
			state.genes = payload.genes;
			state.heatmapData = payload.mapping;
			state.isHeatmapDataInitialized = true;
			state.isHeatmapDataLoading = false;
		},
		initHeatmapDataFail: (state: OmicL4VisualizationState) => {
			state.isHeatmapDataLoading = false;
		},

		updateHeatmapDataRequest: (state: OmicL4VisualizationState, _: PayloadAction<GetHeatmapDataRequest>) => {
			state.isHeatmapDataLoading = true;
		},
		updateHeatmapDataSuccess: (state: OmicL4VisualizationState, { payload }: PayloadAction<GetHeatmapDataResponse>) => {
			state.participants = payload.participants;
			state.genes = payload.genes;
			state.heatmapData = payload.mapping;
			state.isHeatmapDataLoading = false;
		},
		updateHeatmapDataFail: (state: OmicL4VisualizationState) => {
			state.isHeatmapDataLoading = false;
		},

		downloadHeatmapDataRequest: (state: OmicL4VisualizationState) => {
			state.isHeatmapDataDownloading = true;
		},
		downloadHeatmapDataSuccess: (state: OmicL4VisualizationState) => {
			state.isHeatmapDataDownloading = false;
		},
		downloadHeatmapDataFail: (state: OmicL4VisualizationState) => {
			state.isHeatmapDataDownloading = false;
		},
	}
});
