import React, { PropsWithChildren, ReactElement, useCallback, useState } from 'react';

import { TableProps, TablePaginationProps, Theme, TableContainerProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { common } from '@material-ui/core/colors';

import { Sort } from 'types';

import { Column, Row } from '../types';

import { CustomTableHead } from './CustomTableHead';
import { CustomTableBody } from './CustomTableBody';

const useStyles = makeStyles((theme: Theme) => ({
	tableContainerRoot: {
		width: '100%',
		backgroundColor: common['white'],
		overflow: 'auto',

		'& th, & td': {
			maxWidth: '182px',
			padding: theme.spacing(1, 2),
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			lineHeight: `${theme.spacing(2.5)}px`,
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	},
	dragging: {
		'& th:first-child, & > td:first-child': {
			position: 'static'
		},
	},
}));

interface CustomTableProps<T extends string> {
	columns: Column<T>[]
	rows: Row<T>[]
	sort?: Sort<T>
	onColumnSort?: (column: Column<T>) => void
	onChangeColumns?: (columns: Column<T>[]) => void
	isHeaderRowSticky?: boolean
	isHeaderColumnSticky?: boolean
	noRowsElement: ReactElement
	paginationProps?: TablePaginationProps & { component?: React.ElementType }
	tableProps?: TableProps
	tableContainerProps?: TableContainerProps
}

export const CustomTable = <T extends string>({
	columns,
	rows,
	sort,
	onColumnSort = () => undefined,
	onChangeColumns = () => undefined,
	isHeaderRowSticky = true,
	isHeaderColumnSticky = true,
	noRowsElement,
	paginationProps,
	tableProps,
	tableContainerProps,
}: PropsWithChildren<CustomTableProps<T>>): ReactElement => {
	const classes = useStyles();

	const [isColumnDragging, setIsColumnDragging] = useState(false);

	const onTableHeadColumnSort = useCallback((column: Column) => {
		onColumnSort(column as Column<T>);
	}, [onColumnSort]);
	const onTableHeadColumnsReorder = useCallback((reorderedColumns: Column[]) => {
		onChangeColumns(reorderedColumns as Column<T>[]);
	}, [onChangeColumns]);

	if (rows.length === 0) {
		return noRowsElement;
	}
	return (
		<>
			<TableContainer classes={{ root: classes.tableContainerRoot }} {...tableContainerProps}>
				<Table {...tableProps}>
					<CustomTableHead
						columns={ columns }
						sort={ sort }
						onColumnSort={ onTableHeadColumnSort }
						onColumnsReorder={ onTableHeadColumnsReorder }
						isHeaderRowSticky={ isHeaderRowSticky }
						isHeaderColumnSticky={ !isColumnDragging && isHeaderColumnSticky }
						isColumnDragging={ isColumnDragging }
						onColumnDragToggle={ setIsColumnDragging }
					/>
					<CustomTableBody
						columns={ columns }
						rows={ rows }
						isHeaderColumnSticky={ !isColumnDragging && isHeaderColumnSticky }
					/>
				</Table>
			</TableContainer>
			{paginationProps && <TablePagination
				{...paginationProps}
			/>}
		</>
	);
};
