import { push } from 'connected-react-router';
import { put, call, select, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { Routes } from 'app/Routes';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { SearchRequestOptions, SearchState } from '../types';
import { selectActiveTab } from '../selectors';
import { saveSearchParameters } from '../api';
import { proceedSlice } from '../slices';

import { getSearchRequestOptionsSaga } from './getSearchRequestOptionsSaga';
import { getSearchStateSaga } from './getSearchStateSaga';

export type SaveSearchParametersResponse = {
	searchId: string
	releaseName: string
};

export function* proceedSaga(): Generator<StrictEffect, void> {
	try {
		const activeTab = (yield select(selectActiveTab)) as string;

		const searchState = (yield call(getSearchStateSaga)) as SearchState;

		const options = (yield call(getSearchRequestOptionsSaga, searchState)) as SearchRequestOptions;

		const { data } = (yield call(saveSearchParameters, options)) as AxiosResponse<SaveSearchParametersResponse>;

		yield put(proceedSlice.actions.saveSearchParametersSuccess(data));
		yield put(push(`${Routes.Proceed}/${data.releaseName}/${data.searchId}/${activeTab}`));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(proceedSlice.actions.saveSearchParametersFailure());
	}
}
