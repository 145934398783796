import * as yup from 'yup';
import { Configuration, UiFormFields } from '../types';

const PRINCIPAL_INVESTIGATOR = 'Principal Investigator';

export const getDuaValidationSchema = (pageConfiguration: Configuration) => yup.object().shape({
	confirmCodeOfConductAgreement: yup.boolean()
		.oneOf([true], pageConfiguration.markup.errors.uncheckedCodeOfConductAgreement),
	firstName: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.firstName.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	lastName: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.lastName.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	workingEmail: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.workingEmail.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField)
		.email(pageConfiguration.markup.errors.invalidEmailFormat),
	credentials: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.credentials.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	roleOnProject: yup.string()
		.trim()
		.required(pageConfiguration.markup.errors.emptyField)
		.test(
			'isPiSelected',
			pageConfiguration.markup.errors.invalidPiSelected,
			(value, context) => {
				const formFields = context.parent as UiFormFields;
				const roleOnProjectValues = [value, ...formFields.collaborators.map(m => m.roleOnProject)];

				return roleOnProjectValues.filter(m => m === PRINCIPAL_INVESTIGATOR).length === 1;
			}
		),
	dataUseProposal: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.dataUseProposal.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	institution: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.institution.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	country: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.country.maxLength, pageConfiguration.markup.errors.maxLengthError),
	projectTimeframe: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.projectTimeframe.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	businessOfficialName: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.businessOfficialName.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField),
	businessOfficialEmail: yup.string()
		.trim()
		.max(pageConfiguration.markup.formBlock.formFields.businessOfficialEmail.maxLength, pageConfiguration.markup.errors.maxLengthError)
		.required(pageConfiguration.markup.errors.emptyField)
		.email(pageConfiguration.markup.errors.invalidEmailFormat),
	confirmAgreements: yup.boolean().isTrue(pageConfiguration.markup.errors.uncheckedAgreement),
	collaborators: yup.array().of(
		yup.object().shape({
			name: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			title: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			roleOnProject: yup.string()
				.trim()
				.required(pageConfiguration.markup.errors.emptyField)
				.test(
					'isCollaboratorPiSelected',
					pageConfiguration.markup.errors.invalidPiSelected,
					(value, context) => {
						if (context.from) {
							const formFields = context.from[1].value as UiFormFields;
							const roleOnProjectValues = [formFields.roleOnProject, ...formFields.collaborators.map(m => m.roleOnProject)];

							return roleOnProjectValues.filter(m => m === PRINCIPAL_INVESTIGATOR).length === 1;
						}
					}
				),
			email: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField)
				.email(pageConfiguration.markup.errors.invalidEmailFormat)
				.test('isCollabEmailNotRequestorOrBO',
					pageConfiguration.markup.errors.uniqueEmail,
					(value, context) => {
						if (context.from) {
							const formFields = context.from[1].value as UiFormFields;
							const emailFields = [formFields.workingEmail, formFields.businessOfficialEmail];

							return !emailFields.includes(value);
						}
					}
				)
				.test('isCollabEmailUnique',
					pageConfiguration.markup.errors.uniqueEmail,
					(value, context) => {
						if (context.from) {
							const formFields = context.from[1].value as UiFormFields;

							return formFields.collaborators.filter(m => m.email === value).length === 1;
						}
					}
				)
		}))
});
