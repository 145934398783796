import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

interface Params {
	payload?: string
	data?: string
}

export function* setCurrentIndex(params: Params): SagaIterator<AxiosResponse> {
	const method: Method = 'PUT';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params.payload
	};

	const parameters = {
		...Urls.GetCurrentIndex,
		path: `${Urls.GetCurrentIndex.path}?indexName=${params.payload || ''}`
	};

	return (yield call(networkManager.fetch, parameters, requestOptions)) as AxiosResponse;
}
