import { combineReducers } from 'redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import { accountSlice } from 'auth/slice';
import {
	facetsSlice,
	searchDetailsSlice,
	proceedSlice,
	entitiesCountSlice,
	facetsPanelSlice
} from 'features/search';
import { caseSetSlice } from 'features/search/features/caseSet';
import { tabsSlice } from 'features/proceedSearchResult';
import { totpAuthenticationCodeSlice } from 'features/totpAuthentication';
import { alertSlice } from 'features/alert/slices';
import { wpFrameSlice } from 'features/wpFrame';
import { requestAccessSlice } from 'features/requestAccess/slices';
import { omicL4VisualizationSlice } from 'features/visualization/features/omicL4';
import {
	usersManagementTableSlice,
	usersManagementUserSlice,
	usersManagementSlice,
} from 'features/administration/features/usersManagement/slices';
import { doiSlice } from 'features/doi/slices';

import { rootSaga } from './rootSaga';
import { indexManagementSlice } from 'features/administration/features/indexManagement/slices';
import { statisticsSlice } from 'features/administration/features/statistics/slices';
import { submitDataSlice } from 'features/submitData/slices';
import { releaseDataManagementSlice } from 'features/administration/features/releaseData/slices';
import { addiDataRequestDetailsSlice, addiDataRequestsListSlice } from 'features/administration/features/addiDataRequests/slices';

export const browserHistory = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const middleware = [
	...getDefaultMiddleware({
		thunk: false,
		serializableCheck: {
			ignoredActions: [requestAccessSlice.actions.sendFormDataRequest.type, indexManagementSlice.actions.sendFormDataRequest.type]
		}
	}),
	routerMiddleware(browserHistory),
	sagaMiddleware
];

export const store = configureStore({
	middleware,
	reducer: {
		router: connectRouter(browserHistory), // as Reducer<RouterState>,
		administration: combineReducers({
			usersManagement: combineReducers({
				common: usersManagementSlice.reducer,
				table: usersManagementTableSlice.reducer,
				user: usersManagementUserSlice.reducer
			}),
			indexManagement: indexManagementSlice.reducer,
			statistics: statisticsSlice.reducer,
			releaseDataManagement: releaseDataManagementSlice.reducer,
			addiDataRequests: combineReducers({
				list: addiDataRequestsListSlice.reducer,
				details: addiDataRequestDetailsSlice.reducer
			})
		}),
		search: combineReducers({
			facets: facetsSlice.reducer,
			details: searchDetailsSlice.reducer,
			caseSet: caseSetSlice.reducer,
			proceed: proceedSlice.reducer,
			entitiesCount: entitiesCountSlice.reducer,
			facetsPanel: facetsPanelSlice.reducer
		}),
		account: accountSlice.reducer,
		proceedSearchResults: combineReducers({
			tabs: tabsSlice.reducer
		}),
		totpAuthentication: combineReducers({
			code: totpAuthenticationCodeSlice.reducer
		}),
		alert: alertSlice.reducer,
		wpFrame: wpFrameSlice.reducer,
		requestAccess: requestAccessSlice.reducer,
		submitData: submitDataSlice.reducer,
		visualization: combineReducers({
			omicL4: omicL4VisualizationSlice.reducer
		}),
		doi: doiSlice.reducer
	},
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
