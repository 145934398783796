import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { Routes } from 'app/Routes';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { createUserDoi } from '../api';
import { CreateUserDoiRequest, CreateUserDoiResponse } from '../types';

export function* createUserDoiSaga({ payload }: PayloadAction<CreateUserDoiRequest>): SagaIterator {
	try {
		yield put(doiSlice.actions.createUserDoiStart());

		const response = (yield call(createUserDoi, payload)) as AxiosResponse<CreateUserDoiResponse>;

		yield put(push(`${Routes.Doi}/${response.data.id}`));

		yield put(doiSlice.actions.createUserDoiEnd());
	} catch (e) {
		yield put(doiSlice.actions.createUserDoiEnd());
		yield call(handleErrorSaga, e);
	}
}
