import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { NOT_FOUND } from 'http-status-codes';
import { uniqueId } from 'lodash';

import { handleErrorSaga } from '../../../../errorHandling/sagas';
import { alertSlice } from '../../../../alert/slices';

import { resetPaginationSaga } from '../../../sagas/resetPaginationSaga';
import { definedSearchRequestSaga } from '../../../sagas/definedSearchRequestSaga';

import {
	CaseSetEntity,
	GetExternalCaseSetRequest,
	GetExternalCaseSetResponse
} from '../types';
import { caseSetSlice } from '../slices';
import { getExternalCaseSet } from '../api';
import { selectCaseSetEntities } from '../selectors';
import { getUniqueName } from '../utils';

import { searchRequestWithCaseSetSaga } from './searchRequestWithCaseSetSaga';

export function* getExternalCaseSetSaga({ payload }: PayloadAction<GetExternalCaseSetRequest>): SagaIterator {
	let externalCaseSetResponse: AxiosResponse<GetExternalCaseSetResponse> | undefined;

	try {
		externalCaseSetResponse = (yield call(getExternalCaseSet, payload)) as AxiosResponse<GetExternalCaseSetResponse>;
	} catch (e) {
		if ((e as AxiosError).response?.status === NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'Invalid external case set id' }));
		} else {
			yield call(handleErrorSaga, e);
		}
	}

	if (externalCaseSetResponse !== undefined) {
		const externalCaseSet = externalCaseSetResponse.data;

		const caseSetEntities = (yield select(selectCaseSetEntities)) as ReturnType<typeof selectCaseSetEntities>;

		try {
			const caseSet = (yield call(searchRequestWithCaseSetSaga, {
				id: (yield call(uniqueId)) as ReturnType<typeof uniqueId>,
				title: getUniqueName(externalCaseSet.name.trim(), caseSetEntities.map(entity => entity.title)),
				identifierType: externalCaseSet.identifierType,
				values: externalCaseSet.identifiers,
				notFoundIdentifiers: []
			})) as CaseSetEntity;

			yield put(caseSetSlice.actions.addCaseSet(caseSet));
			yield put(caseSetSlice.actions.silentSelectCaseSet({ id: caseSet.id }));

			if (caseSet.notFoundIdentifiers.length !== 0) {
				yield put(caseSetSlice.actions.setValidationError({ caseSetErrorId: caseSet.id }));
			}

			yield put(caseSetSlice.actions.showCaseSetsPanel());

			yield put(caseSetSlice.actions.getExternalCaseSetSuccess());
		} catch (e) {
			yield call(handleErrorSaga, e);
			yield put(caseSetSlice.actions.getExternalCaseSetFail());
		}
	} else {
		yield call(resetPaginationSaga);
		yield call(definedSearchRequestSaga);
	}
}
