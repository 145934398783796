import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { createStyles, IconButton, Theme, Typography, Drawer, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import PostAdd from '@material-ui/icons/PostAdd';

import { DRAWER_WIDTH, FACETS_PANEL_TEXT_COLOR } from 'styles/constants.ui';
import { LoaderButton } from 'components';
import { ReactComponent as OpenDrawerIcon } from 'assets/icons/RightArrowSquaredIcon.svg';
import { ReactComponent as CloseDrawerIcon } from 'assets/icons/LeftArrowSquaredIcon.svg';

import {
	DetailsFacetsItemConfig,
	Facets,
	FacetsBaseProps,
	SelectedFacets,
	FacetSearchOption
} from '../types';
import { selectFacetsPanelEntitiesExpandState, selectFacetsPanelSearchOptions } from '../selectors';
import { caseSetSlice, selectCaseSetModalInfo, CaseSetModalInfo } from '../features/caseSet';

import { FacetsTabs } from './FacetsTabs';
import { FacetsSearch } from './FacetsSearch';

interface FacetsListPanelProps extends FacetsBaseProps {
	detailsFacetsListPanel: DetailsFacetsItemConfig[]
	facets: Facets
	selectedFacets: SelectedFacets
}

const DRAWER_HEADER_PADDING = 2;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			flexShrink: 0,
			whiteSpace: 'nowrap',
			height: '100%'
		},
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			backgroundColor: grey['50'],
			color: FACETS_PANEL_TEXT_COLOR,
			paddingTop: theme.spacing(DRAWER_HEADER_PADDING),
			paddingBottom: theme.spacing(DRAWER_HEADER_PADDING),
			...theme.mixins.toolbar
		},
		drawerHeaderOpen: {
			justifyContent: 'space-between',
		},
		drawerHeaderClose: {
			justifyContent: 'center',
		},
		drawerPaper: {
			position: 'relative',
			height: '100%',
			backgroundColor: grey['50'],
			zIndex: 1
		},
		drawerOpen: {
			width: DRAWER_WIDTH
		},
		drawerClose: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			width: theme.spacing(7)
		},
		showFiltersText: {
			transform: 'rotate(270deg)',
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			marginTop: theme.spacing(6)
		},
		filterResultText: {
			paddingLeft: theme.spacing(DRAWER_HEADER_PADDING),
			display: 'inline-block'
		}
	})
);

export const FacetsListPanel: FunctionComponent<FacetsListPanelProps> = ({
	detailsFacetsListPanel,
	facets,
	selectFacetValue,
	unselectFacetValue,
	selectedFacets
}: FacetsListPanelProps) => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState(true);

	const dispatch = useDispatch();

	const modalInfo: CaseSetModalInfo = useSelector(selectCaseSetModalInfo);
	const facetsSearchOptions: FacetSearchOption[] = useSelector(selectFacetsPanelSearchOptions);
	const facetsPanelEntitiesExpandState = useSelector(selectFacetsPanelEntitiesExpandState);

	const openCaseSetModal = useCallback(
		() => dispatch(caseSetSlice.actions.startModalOpen()),
		[dispatch]
	);

	const handleToggleDrawer = useCallback(
		() => setIsDrawerOpen(!isDrawerOpen),
		[isDrawerOpen]
	);

	return (
		<aside>
			{ facets && Object.keys(facets).length > 0 && Object.keys(facetsPanelEntitiesExpandState).length > 0 &&
				<Drawer
					className={ clsx(classes.drawer, {
						[classes.drawerOpen]: isDrawerOpen,
						[classes.drawerClose]: !isDrawerOpen
					}) }
					variant='permanent'
					anchor='left'
					classes={{
						paper: clsx(classes.drawerPaper, {
							[classes.drawerOpen]: isDrawerOpen,
							[classes.drawerClose]: !isDrawerOpen
						})
					}}
				>
					<div className={ clsx(classes.drawerHeader, {
						[classes.drawerHeaderOpen]: isDrawerOpen,
						[classes.drawerHeaderClose]: !isDrawerOpen
					}) }>
						{ isDrawerOpen && <Typography className={classes.filterResultText} component='h2' variant='h5' aria-label={'Filter result by'}>
							{'Filter result by'}
						</Typography>
						}
						<IconButton id={'facets-list-panel'} aria-label={'show facets list'} aria-expanded={isDrawerOpen} onClick={handleToggleDrawer}>
							{
								isDrawerOpen
									? <CloseDrawerIcon />
									: <OpenDrawerIcon />
							}
						</IconButton>
					</div>
					{
						isDrawerOpen
							? <>
								<Box px={2} pb={1}>
									<FacetsSearch
										options={facetsSearchOptions}
										facets={facets}
									/>
								</Box>
								<Box px={2} py={1} bgcolor={grey['200']}>
									<LoaderButton
										ariaProgressText={ 'Opening modal window' }
										isLoading={modalInfo.isModalOpenLoading}
										ButtonProps={{
											fullWidth: true,
											size: 'large',
											variant: 'outlined',
											color: 'primary',
											startIcon: <PostAdd/>,
											onClick: openCaseSetModal
										}}
									>
										{ 'Upload case set' }
									</LoaderButton>
								</Box>
								<FacetsTabs
									selectDetailsConfig={detailsFacetsListPanel}
									facets={facets}
									selectFacetValue={selectFacetValue}
									unselectFacetValue={unselectFacetValue}
									selectedFacets={selectedFacets}
								/>
								<Box pb={14} />
							</>
							: <Typography component='span' className={classes.showFiltersText} variant='subtitle2'>{'SHOW FILTERS'}</Typography>
					}
				</Drawer>
			}
		</aside>
	);
};
