import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

import { downloadBlob, getFilterApiParam } from 'utils';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import {
	selectFilters,
	selectTableSearchQuery,
	selectTableSort
} from '../selectors';
import { addiDataRequestsListSlice } from '../slices';
import { exportAddiDataRequests } from '../api';
import { ExportAddiDataListResponse } from '../types';

export function* exportAddiDataRequestsSaga(): SagaIterator {
	try {
		yield put(addiDataRequestsListSlice.actions.exportAddiDataRequestsStart());

		const sort = (yield select(selectTableSort)) as ReturnType<typeof selectTableSort>;
		const searchQuery = (yield select(selectTableSearchQuery)) as ReturnType<typeof selectTableSearchQuery>;
		const filters = (yield select(selectFilters)) as ReturnType<typeof selectFilters>;

		const { data } = (yield call(exportAddiDataRequests, {
			search: searchQuery,
			filter: getFilterApiParam(filters),
			orderBy: `${sort.orderBy} ${sort.order}`
		})) as AxiosResponse<ExportAddiDataListResponse>;

		yield call(downloadBlob,'addi-data-requests.csv', data);

		yield put(addiDataRequestsListSlice.actions.exportAddiDataRequestsEnd());
	} catch (e) {
		yield put(addiDataRequestsListSlice.actions.exportAddiDataRequestsEnd());
		yield call(handleErrorSaga, e);
	}
}
