import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { GetExternalCaseSetRequest, GetExternalCaseSetResponse } from '../types';

export const getExternalCaseSet = async (params: GetExternalCaseSetRequest): Promise<AxiosResponse<GetExternalCaseSetResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch<GetExternalCaseSetResponse>(Urls.GetExternalCaseSet, requestOptions, params);
};
