import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
	Box,
	TextField,
	Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { selectCaseSetEntities, selectCaseSetModalInfo, selectEditCaseSetId } from '../selectors';
import { CaseSetEntity, CaseSetModalFormFields, CaseSetModalInfo } from '../types';

const TEXTAREA_Y_PADDING = 2.3125;
const TEXTAREA_X_PADDING = 1.75;

interface CaseSetModalIdentifiersProps {
	form: FormikProps<CaseSetModalFormFields>
}

const useStyles = makeStyles((theme: Theme) => ({
	identifiersRoot: {
		'& > div': {
			backgroundColor: 'transparent',
			zIndex: 1,
			padding: theme.spacing(TEXTAREA_Y_PADDING, 0, 0, 0),
			'& > textarea': {
				padding: theme.spacing(0, TEXTAREA_X_PADDING, TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
				resize: 'vertical',
				minHeight: '100px',
				'&::-webkit-scrollbar': {
					width: '10px'
				}
			}
		}
	},
	textareaWrapper: {
		position: 'relative'
	},
	textareaPlaceholder: {
		color: grey['500'],
		zIndex: 0,
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		padding: theme.spacing(TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
		whiteSpace: 'pre-wrap',
		lineHeight: '1.1876rem',
		letterSpacing: '0.03125rem'
	},
}));

// eslint-disable-next-line prefer-arrow-callback
export const CaseSetModalIdentifiers = React.forwardRef<HTMLInputElement, CaseSetModalIdentifiersProps>(function CaseSetModalIdentifiers({
	form
}, identifiersRef) {
	const classes = useStyles();
	const { t } = useTranslation();

	const modalInfo: CaseSetModalInfo = useSelector(selectCaseSetModalInfo);
	const editCaseSetId = useSelector(selectEditCaseSetId);
	const caseSetEntities: CaseSetEntity[] = useSelector(selectCaseSetEntities);

	const identifiersValuesType: keyof CaseSetModalFormFields = form.values.onlyNotFoundIdentifiers ? 'notFoundIdentifiers' : 'identifiers';
	const caseSetInfo = caseSetEntities.find(item => item.id === editCaseSetId);

	return (
		<>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography
					id="upload-case-set-modal-identifiers-label"
					component="span"
					variant="subtitle1"
					aria-hidden
				>
					{ t('caseSet.identifiersDescription') }
				</Typography>
				<Box pr={1} />
				<Box>
					{caseSetInfo !== undefined &&
						<FormControlLabel
							control={
								<Checkbox
									disabled={form.values.notFoundIdentifiers === ''}
									name="onlyNotFoundIdentifiers"
									size='medium'
									checked={form.values.onlyNotFoundIdentifiers}
									onChange={form.handleChange}
								/>
							}
							label={ <Typography variant="subtitle1" noWrap={true}>{ 'Show not found' }</Typography> }
						/>
					}
				</Box>
			</Box>
			<Box pb={1.5} />
			<div className={ classes.textareaWrapper }>
				<TextField
					disabled={modalInfo.isModalSubmitLoading || form.values.onlyNotFoundIdentifiers}
					inputRef={identifiersRef}
					name={identifiersValuesType}
					inputProps={{
						'aria-labelledby': 'upload-case-set-modal-identifiers-label',
						'aria-describedby': 'upload-case-set-modal-identifiers-description'
					}}
					InputLabelProps={{
						shrink: true
					}}
					classes={{ root: classes.identifiersRoot }}
					label={ t(`caseSet.${identifiersValuesType}Label`) }
					spellCheck={false}
					multiline
					fullWidth
					rows={4}
					variant="outlined"
					value={form.values[identifiersValuesType]}
					onChange={form.handleChange}
					error={form.touched[identifiersValuesType] && Boolean(form.errors[identifiersValuesType])}
					helperText={
						<span id="upload-case-set-modal-identifiers-description" style={{ whiteSpace: 'pre-wrap' }} >
							{ form.touched[identifiersValuesType] && form.errors[identifiersValuesType] || t(`caseSet.${identifiersValuesType}HelperText`) }
						</span>
					}
				/>
				{ identifiersValuesType === 'identifiers' && form.values.identifiers.length === 0 &&
					<div className={ classes.textareaPlaceholder }>
						{ t('caseSet.identifiersPlaceholder') }
					</div>
				}
			</div>
		</>
	);
});
