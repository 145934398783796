import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';
import { Sort } from 'types';
import { Column } from 'components/CustomTable/types';
import { getGeneralLoaderCombiner } from 'utils';

import { Filter, TableColumnNames, User, UserShort } from './types';

export const selectPossibleUserRoles = (state: RootState): string[] => state.administration.usersManagement.common.possibleUserRoles;
export const selectIsPossibleUserRolesInitialized = (state: RootState): boolean => state.administration.usersManagement.common.isPossibleUserRolesConfigInitialized;
export const selectIsExportUsersLoading = (state: RootState): boolean => state.administration.usersManagement.common.isExportUsersLoading;

export const selectIsTableInitLoading = (state: RootState): boolean => state.administration.usersManagement.table.isUsersManagementTableInitLoading;
export const selectIsInitialized = (state: RootState): boolean => state.administration.usersManagement.table.isUsersManagementTableInitialized;
export const selectIsUpdateUsersListLoading = (state: RootState): boolean => state.administration.usersManagement.table.isUpdateUsersListLoading;
export const selectTableColumns = (state: RootState): Column<TableColumnNames>[] => state.administration.usersManagement.table.columns;
export const selectUsersList = (state: RootState): UserShort[] => state.administration.usersManagement.table.usersList;
export const selectTableSort = (state: RootState): Sort<TableColumnNames> => state.administration.usersManagement.table.sort;
export const selectTableTotalRowsCount = (state: RootState): number => state.administration.usersManagement.table.totalRowsCount;
export const selectTableRowsPerPage = (state: RootState): number => state.administration.usersManagement.table.rowsPerPage;
export const selectTableCurrentPage = (state: RootState): number => state.administration.usersManagement.table.currentPage;
export const selectTableSearchQuery = (state: RootState): string => state.administration.usersManagement.table.searchQuery;
export const selectFilters = (state: RootState): Filter[] => state.administration.usersManagement.table.filters;
export const selectIsFiltersConfigInitialized = (state: RootState): boolean => state.administration.usersManagement.table.isFiltersConfigInitialized;

export const selectUser = (state: RootState): User | null => state.administration.usersManagement.user.user;
export const selectIsUsersManagementUserInitLoading = (state: RootState): boolean => state.administration.usersManagement.user.isUsersManagementUserInitLoading;
export const selectIsUserUpdateLoading = (state: RootState): boolean => state.administration.usersManagement.user.isUserUpdateLoading;
export const selectIsUserDuaApproving = (state: RootState): boolean => state.administration.usersManagement.user.isUserDuaApproving;
export const selectIsUserDuaRejecting = (state: RootState): boolean => state.administration.usersManagement.user.isUserDuaRejecting;
export const selectIsDownloadDocuSignUserAgreementLoading = (state: RootState): boolean => state.administration.usersManagement.user.isDownloadDocuSignUserAgreementLoading;
export const selectIsSendDuaSignRequestLoading = (state: RootState): boolean => state.administration.usersManagement.user.isDuaSignRequestSending;
export const selectIsResearcherSurveySending = (state: RootState): boolean => state.administration.usersManagement.user.isResearcherSurveySending;

export const selectIsUsersManagementLoading = createSelector(
	selectIsTableInitLoading,
	selectIsUsersManagementUserInitLoading,
	selectIsUpdateUsersListLoading,
	selectIsUserUpdateLoading,
	selectIsUserDuaApproving,
	selectIsUserDuaRejecting,
	selectIsSendDuaSignRequestLoading,
	selectIsResearcherSurveySending,
	getGeneralLoaderCombiner
);
