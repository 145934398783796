/* eslint-disable react/jsx-no-bind */
/* eslint-disable arrow-parens */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-extra-parens */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable sonarjs/cognitive-complexity */

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Container, FormControlLabel, LinearProgress, LinearProgressProps, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, capitalize, createStyles, styled, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatBytes } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsExportOmicsLoading, selectIsOmicsReleaseReadyLoading, selectIsReleaseOperationLoading, selectIsReleaseProcessStartLoading, selectOmicsReleaseReady, selectReleaseOperations } from '../selectors';
import { releaseDataManagementSlice } from '../slices';
import { LoaderButton } from 'components';
import { getIn, useFormik } from 'formik';
import { ExportOmicsRequest, OmicsReleaseReady, StartReleaseProcessRequest } from '../types';
import * as yup from 'yup';
import { indexManagementSlice } from '../../indexManagement/slices';
import { OperationsTable } from './OperationsTable';

const useStyles = makeStyles(() =>
	createStyles({
		checkbox: {
			padding: '4px',
			marginLeft: '12px'
		},
		accordionSummary: {
			'& .MuiAccordionSummary-content': {
				margin: '4px',
				padding: 0
			}
		}
	}));

interface OmicFilter {
	omic: string
	level: string
	selected: boolean
}

export const ReleaseDataManagement: FunctionComponent = () => {
	const componentName = 'Release Data Management';
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const isOmicsReleaseReadyLoading = useSelector(selectIsOmicsReleaseReadyLoading);
	const isReleaseOperationsLoading = useSelector(selectIsReleaseOperationLoading);
	const isReleaseProcessStartLoading = useSelector(selectIsReleaseProcessStartLoading);
	const isOmicsExportLoading = useSelector(selectIsExportOmicsLoading);

	const omicsReleaseReady = useSelector(selectOmicsReleaseReady);
	const releaseOperations = useSelector(selectReleaseOperations);

	const totalFilesCount = omicsReleaseReady.flatMap(f => f.levels.flatMap(m => m.files)).length;

	const getInitialOmicsFilter = useCallback(() => {
		const initialOmicsFilter: OmicFilter[] = [];

		omicsReleaseReady.forEach(omic => omic.levels.forEach(level => {
			initialOmicsFilter.push({
				omic: omic.name,
				level: level.name,
				selected: level.selected
			});
		}));
		return initialOmicsFilter;
	}, [omicsReleaseReady]);

	const [omicsFilter, setOmicsFilter] = useState<OmicFilter[]>();
	const [isProgressVisible, setIsProgressVisible] = useState(false);

	useEffect(() => {
		dispatch(releaseDataManagementSlice.actions.getReleaseOperations());
	}, [dispatch]);

	useEffect(() => {
		setOmicsFilter(getInitialOmicsFilter());
	}, [getInitialOmicsFilter, omicsReleaseReady]);

	useEffect(() => {
		if (releaseOperations?.canStartProcess) {
			dispatch(releaseDataManagementSlice.actions.getOmicsReleaseReady());
			setIsProgressVisible(false);
		}

		if (releaseOperations !== null && !releaseOperations.canStartProcess) {
			setIsProgressVisible(true);
		}
	}, [dispatch, releaseOperations]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!isReleaseOperationsLoading && !releaseOperations?.canStartProcess) {
				dispatch(releaseDataManagementSlice.actions.getReleaseOperations());
			}
		}, 10000);
		return () => {
			clearInterval(interval);
		};
	}, [dispatch, releaseOperations, isReleaseOperationsLoading]);

	const getStartReleaseProcessRequest = () => {
		const selectedOmics = Array.from(new Set(omicsFilter?.filter(f => f.selected === true).map(m => m.omic)));

		const request: StartReleaseProcessRequest = {};

		selectedOmics.map(omic => {
			if (!omicsFilter?.filter(f => f.omic === omic).every(e => e.selected === false)) {
				const levels = omicsFilter?.filter(f => f.omic === omic)
					.filter(f => f.selected === true)
					.map(o => Number(o.level));

				request[omic] = levels ? levels : [];
			}
		});

		return request;
	};

	const onReleaseData = () =>
		dispatch(releaseDataManagementSlice.actions.startReleaseProcess(getStartReleaseProcessRequest()));

	const onExportOmics = () => {
		dispatch(releaseDataManagementSlice.actions.exportOmics(getStartReleaseProcessRequest()));
	};

	const onRefreshReleaseOperations = () => {
		dispatch(releaseDataManagementSlice.actions.getReleaseOperations());
	};

	const onOmicLevelClick = (omic: string, level: string) => {
		setOmicsFilter(omicsFilter?.map(m => {
			if (m.omic === omic && m.level === level) {
				m.selected = !m.selected;
			}
			return m;
		}));
	};

	const getOperationInProgress = () => releaseOperations?.releaseOperations.find(f => f.status === 'In Progress');

	const getProcessingFilesTotalCount = (): number => {
		const operationInProgress = getOperationInProgress();

		return ((operationInProgress?.countCompleted ?? 0)
			+ (operationInProgress?.countError ?? 0)
			+ (operationInProgress?.countInProgress ?? 0));
	};

	const getCompletedFilesCount = (): number => getOperationInProgress()?.countCompleted ?? 0;

	const getErrorFilesCount = (): number => getOperationInProgress()?.countError ?? 0;

	return (
		<Box>
			<Box
				visibility={isOmicsReleaseReadyLoading || isReleaseOperationsLoading ? 'visible' : 'hidden'}
				position="sticky"
				top={0}
				width="100%"
				zIndex={theme.zIndex.appBar}
			>
				<LinearProgress />
			</Box>
			<Container>
				<Box pt={2} />
				<Typography component="h1" variant="h4">
					{componentName}
				</Typography>
				<Box pb={4} />
				<OperationsTable releaseOperations={releaseOperations?.releaseOperations} />
				{!isOmicsReleaseReadyLoading && releaseOperations?.canStartProcess ?
					<Box>
						<Box pb={4} />
						<Box dangerouslySetInnerHTML={{ __html: `There are <strong>${totalFilesCount}</strong> new files found in Staging which are ready for release. Choose omic types and particular levels you would like to move to Release storage.` }}>
						</Box>
						<Box pb={4} />
						<Box display='flex' flexDirection='row-reverse'>
							<LoaderButton
								isLoading={isReleaseProcessStartLoading}
								ButtonProps={{
									size: 'large',
									color: 'primary',
									variant: 'contained',
									disabled: totalFilesCount === 0,
									onClick: onReleaseData
								}}
							>
								{'Release Data'}
							</LoaderButton>
							<Box pr={2} />
							<LoaderButton
								isLoading={isOmicsExportLoading}
								ButtonProps={{
									size: 'large',
									color: 'primary',
									variant: 'outlined',
									disabled: totalFilesCount === 0,
									onClick: onExportOmics
								}}
							>
								{'Export to CSV'}
							</LoaderButton>
						</Box>
						<Box pb={4} />
						<Box>
							{omicsReleaseReady.map((omic, omicIndex) => {
								return <Box key={omicIndex}>
									<Paper>
										<Box p={2}>
											{/* Card Header */}
											<Box display='flex' justifyContent='space-between'>
												<Box display='flex' alignItems='center'>
													<Typography component='h5' variant='h5'>
														{capitalize(omic.name)}
													</Typography>
												</Box>
												<Box display='flex' alignItems='center'>
													<Typography component='p' variant='h6'>
														{`${omic.levels.flatMap(level => level.files).length} files,
																 ${formatBytes(omic.levels.flatMap(level => level.size).reduce((partialSum, s) => partialSum + s, 0))}`}
													</Typography>
												</Box>
											</Box>
											{/* Card Body */}
											<Box>
												{omic.levels.map((level, levelIndex) => {
													return <Accordion key={level.name}>
														<AccordionSummary
															expandIcon={<ExpandMoreIcon />}
															aria-label="Expand"
															aria-controls="additional-actions1-content"
															id="additional-actions1-header"
															className={classes.accordionSummary}
														>
															<Box display='flex' justifyContent='space-between' width='100%' alignItems='center' ml={2}
																style={{ borderBottom: '1px solid lightgray' }}>
																<Box display='flex' alignItems='center'>
																	<FormControlLabel
																		aria-label="Acknowledge"
																		onClick={(event) => event.stopPropagation()}
																		onFocus={(event) => event.stopPropagation()}
																		control={
																			<Checkbox
																				className={classes.checkbox}
																				color='primary'
																				onClick={() => onOmicLevelClick(omic.name, level.name)}
																				checked={omicsFilter?.find(f => f.omic === omic.name && f.level === level.name)?.selected === true}
																			/>
																		}
																		label={`Level ${level.name}`}
																	/>
																	<Box ml={2} />
																	<Typography>
																		{level.folder}
																	</Typography>
																</Box>
																<Typography>
																	{`${level.files.length} files, ${formatBytes(level.size)}`}
																</Typography>
															</Box>
														</AccordionSummary>
														<AccordionDetails>
															<Box display='flex' flexDirection='column' ml={4}>
																{
																	level.files.map(file => {
																		return (
																			<Typography color="textSecondary" key={file}>
																				{file}
																			</Typography>);
																	})
																}
															</Box>
														</AccordionDetails>
													</Accordion>;
												})}
											</Box>
										</Box>
									</Paper>
									<Box pb={2} />
								</Box>;
							})}
						</Box>
					</Box> :
					null
				}
				{isProgressVisible ?
					<Box pb={4}>
						<Box pb={4} />
						<Typography>
							{'There is a release process in progress. We refresh the progress each 10 sec, please use refresh button to force refresh the process status.'}
						</Typography>
						<Box pb={2} />
						<LinearProgress color='primary'
							variant='determinate'
							value={getProcessingFilesTotalCount() !== 0 ? 100 * (getCompletedFilesCount() + getErrorFilesCount()) / (getProcessingFilesTotalCount()) : 0} />
						<Box pb={2} />
						<Box>
							<Typography variant="body2"
								color="textSecondary">
								{`Successfully processed ${getCompletedFilesCount()}, with error ${getErrorFilesCount()} of total ${getProcessingFilesTotalCount()} files.`}
							</Typography>
						</Box>
						<Box pb={2} />
						<Box display="flex">
							<LoaderButton
								id='refresh-release-operations'
								isLoading={isReleaseOperationsLoading}
								ButtonProps={{
									size: 'medium',
									color: 'primary',
									variant: 'outlined',
									onClick: onRefreshReleaseOperations
								}}
							>
								{'Refresh'}
							</LoaderButton>
						</Box>
					</Box> : null
				}
			</Container>
		</Box>
	);
};
