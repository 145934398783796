import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { indexManagementSlice } from '../slices';
import { getCurrentIndex } from '../api';
import { IndexItem } from '../types';

export function* getCurrentIndexSaga(): SagaIterator {
	try {
		yield put(indexManagementSlice.actions.getCurrentIndexStart());
		const response = (yield call(getCurrentIndex)) as AxiosResponse<IndexItem>;
		yield put(indexManagementSlice.actions.getCurrentIndexEnd({ endState: SagaEndState.Success, data: response.data }));
	} catch (e) {
		yield put(indexManagementSlice.actions.getCurrentIndexEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
