import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementTableSlice } from '../slices';
import { DuaStatuses, Filter } from '../types';
import { selectFilters, selectIsFiltersConfigInitialized, selectPossibleUserRoles } from '../selectors';

import { getPossibleUserRolesConfigSaga } from './getPossibleUserRolesConfigSaga';

const statusFilter: Filter = {
	name: 'statuses',
	displayName: 'DUA Status',
	values: [
		{ name: DuaStatuses.Submitted, displayName: 'Submitted', isSelected: false },
		{ name: DuaStatuses.Verified, displayName: 'Verified', isSelected: false },
		{ name: DuaStatuses.Approved, displayName: 'Approved', isSelected: false },
		{ name: DuaStatuses.Rejected, displayName: 'Rejected', isSelected: false },
		{ name: DuaStatuses.Signed, displayName: 'Signed', isSelected: false }
	],
};

export function* getFiltersConfigSaga(): SagaIterator<Filter[]> {
	const isFiltersConfigInitialized = (yield select(selectIsFiltersConfigInitialized)) as ReturnType<typeof selectIsFiltersConfigInitialized>;
	let filters = (yield select(selectFilters)) as ReturnType<typeof selectFilters>;

	try {
		yield put(usersManagementTableSlice.actions.getFiltersConfigStart());

		if (!isFiltersConfigInitialized) {
			const possibleUserRoles = (yield call(getPossibleUserRolesConfigSaga)) as ReturnType<typeof selectPossibleUserRoles>;

			const userRolesFilter: Filter = {
				name: 'roles',
				displayName: 'Roles',
				values: possibleUserRoles.map(roleName => ({
					name: roleName,
					displayName: roleName,
					isSelected: false
				}))
			};

			filters = [
				statusFilter,
				userRolesFilter
			];
		}

		yield put(usersManagementTableSlice.actions.getFiltersConfigEnd({ endState: SagaEndState.Success, data: filters }));
	} catch (e) {
		yield put(usersManagementTableSlice.actions.getFiltersConfigEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}

	return filters;
}
