import React, { FunctionComponent } from 'react';
import { FORBIDDEN } from 'http-status-codes';

import { BaseErrorPage } from './BaseErrorPage';

export const ForbiddenPage: FunctionComponent = () =>
	<BaseErrorPage
		errorCode={ FORBIDDEN }
		errorText={ 'User has no access' }
		errorDescription={ '' }
	/>
	;
