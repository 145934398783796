import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SagaEndPayloadAction, SagaEndState, Sort, SortOrder } from 'types';
import { Column } from 'components/CustomTable/types';

import { ACTIONS_PREFIX, DEFAULT_ROWS_PER_PAGE, TABLE_COLUMNS } from '../constants';
import { Filter, GetUsersListResponse, TableColumnNames, UserShort } from '../types';

interface UsersManagementTableState {
	columns: Column<TableColumnNames>[]
	usersList: UserShort[]
	currentPage: number
	rowsPerPage: number
	totalRowsCount: number
	sort: Sort<TableColumnNames>
	searchQuery: string
	filters: Filter[]

	isUsersManagementTableInitLoading: boolean
	isUsersManagementTableInitialized: boolean
	isUpdateUsersListLoading: boolean
	isFiltersConfigLoading: boolean
	isFiltersConfigInitialized: boolean
}

const initialState: UsersManagementTableState = {
	columns: TABLE_COLUMNS,
	usersList: [],
	currentPage: 0,
	rowsPerPage: DEFAULT_ROWS_PER_PAGE,
	totalRowsCount: 0,
	sort: {
		orderBy: 'registrationDateTime',
		order: SortOrder.Desc
	},
	searchQuery: '',
	filters: [],

	isUsersManagementTableInitLoading: false,
	isUsersManagementTableInitialized: false,
	isUpdateUsersListLoading: false,
	isFiltersConfigLoading: false,
	isFiltersConfigInitialized: false,
};

export const usersManagementTableSlice = createSlice({
	name: `${ACTIONS_PREFIX}.table`,
	initialState,
	reducers: {
		initUsersManagementTable: () => undefined,
		initUsersManagementTableStart: (state: UsersManagementTableState) => {
			state.isUsersManagementTableInitLoading = true;
		},
		initUsersManagementTableEnd: (state: UsersManagementTableState, { payload }: SagaEndPayloadAction) => {
			if (payload.endState === SagaEndState.Success) {
				state.isUsersManagementTableInitialized = true;
			}
			state.isUsersManagementTableInitLoading = false;
		},

		getFiltersConfig: () => undefined,
		getFiltersConfigStart: (state: UsersManagementTableState) => {
			state.isFiltersConfigLoading = true;
		},
		getFiltersConfigEnd: (state: UsersManagementTableState, { payload }: SagaEndPayloadAction<Filter[]>) => {
			if (payload.endState === SagaEndState.Success) {
				state.filters = payload.data;
				state.isFiltersConfigInitialized = true;
			}
			state.isFiltersConfigLoading = false;
		},

		updateUsersList: () => undefined,
		updateUsersListStart: (state: UsersManagementTableState) => {
			state.isUpdateUsersListLoading = true;
		},
		updateUsersListEnd: (state: UsersManagementTableState, { payload }: SagaEndPayloadAction<GetUsersListResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.usersList = payload.data.userInformationList;
				state.totalRowsCount = payload.data.totalCount;
			}
			state.isUpdateUsersListLoading = false;
		},

		setSearchQuery: (state: UsersManagementTableState, { payload }: PayloadAction<string>) => {
			state.searchQuery = payload;
		},
		setSorting: (state: UsersManagementTableState, { payload }: PayloadAction<TableColumnNames>) => {
			const isAsc = state.sort.orderBy === payload && state.sort.order === SortOrder.Asc;
			state.sort = {
				orderBy: payload,
				order: isAsc ? SortOrder.Desc : SortOrder.Asc
			};
		},
		setRowsPerPage: (state: UsersManagementTableState, { payload }: PayloadAction<number>) => {
			state.rowsPerPage = payload;
		},
		setCurrentPage: (state: UsersManagementTableState, { payload }: PayloadAction<number>) => {
			state.currentPage = payload;
		},
		resetPageSilent: (state: UsersManagementTableState) => {
			state.currentPage = 0;
		},
		setColumns: (state: UsersManagementTableState, { payload }: PayloadAction<Column<TableColumnNames>[]>) => {
			state.columns = payload;
		},
		setFilterValue: (state: UsersManagementTableState, { payload }: PayloadAction<{
			filterName: string
			filterValueName: string
			newState: boolean
		}>) => {
			const filterIndex = state.filters.findIndex(filter => filter.name === payload.filterName);
			const filterValueIndex = state.filters[filterIndex].values.findIndex(filter => filter.name === payload.filterValueName);

			state.filters[filterIndex].values[filterValueIndex].isSelected = payload.newState;
		}
	},
});
