import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';

import { downloadBlob } from 'utils';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import {
	selectFilters,
	selectTableCurrentPage,
	selectTableRowsPerPage,
	selectTableSearchQuery,
	selectTableSort
} from '../selectors';
import { usersManagementSlice } from '../slices';
import { exportUsers } from '../api';
import { getFilterParam } from '../utils';
import { ExportUsersResponse } from '../types';

export function* exportUsersSaga(): SagaIterator {
	try {
		yield put(usersManagementSlice.actions.exportUsersStart());

		const currentPage = (yield select(selectTableCurrentPage)) as ReturnType<typeof selectTableCurrentPage>;
		const rowsPerPage = (yield select(selectTableRowsPerPage)) as ReturnType<typeof selectTableRowsPerPage>;
		const sort = (yield select(selectTableSort)) as ReturnType<typeof selectTableSort>;
		const searchQuery = (yield select(selectTableSearchQuery)) as ReturnType<typeof selectTableSearchQuery>;
		const filters = (yield select(selectFilters)) as ReturnType<typeof selectFilters>;
		const baseUrl = (yield call(() => window.location.host)) as string;

		const { data } = (yield call(exportUsers, {
			baseUserPageUrl: baseUrl,
			search: searchQuery,
			skip: currentPage * rowsPerPage,
			top: rowsPerPage,
			filter: getFilterParam(filters),
			orderBy: `${sort.orderBy} ${sort.order}`
		})) as AxiosResponse<ExportUsersResponse>;

		yield call(downloadBlob,'users.csv', data);

		yield put(usersManagementSlice.actions.exportUsersEnd());
	} catch (e) {
		yield put(usersManagementSlice.actions.exportUsersEnd());
		yield call(handleErrorSaga, e);
	}
}
