import React, { FunctionComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { makeStyles } from '@material-ui/core';
import { ColumnChartDataPlotting } from '../types';

const useStyles = makeStyles(() => ({
	charts: {
		'& > div': {
			overflow: 'hidden',
			height: '100%',
			width: '100%'
		},
	}
}));

interface ColumnChartProps {
	series: ColumnChartDataPlotting[] | null
	categories: string[]
	title: string
}

export const ColumnChart: FunctionComponent<ColumnChartProps> = ({
	series,
	categories,
	title
}) => {
	const classes = useStyles();

	const options = {
		chart: {
			type: 'column'
		},
		title: {
			text: title
		},
		xAxis: {
			categories,
			crosshair: true,
		},
		yAxis: {
			min: 0,
			title: {
				text: 'Users'
			}
		},
		tooltip: {
			outside: true,
			useHTML: true,
			backgroundColor: 'rgba(246, 246, 246, 1)',
			borderRadius: 30,
			borderColor: '#bbbbbb',
			borderWidth: 1.5,
			style: { opacity: 1, background: 'rgba(246, 246, 246, 1)' },
			headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
			pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
				'<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
			footerFormat: '</table>',
			shared: true,
		},
		plotOptions: {
			column: {
				pointPadding: 0.2,
				borderWidth: 0
			}
		},
		series,
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG'
					]
				}
			}
		}
	};

	return (
		<div className={classes.charts}>
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={'chart'}
				options={options}
				containerProps={{ style: { height: '400px', width: '520px' } }}
			/>
		</div>
	);
};
