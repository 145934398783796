import { DetailsFacetsItemConfig, FacetRangeType, Facets, FacetSearchOption, FacetSearchOptionEntity } from '../types';
import { FacetsSearchGroups } from '../types/SearchRequestOptions';

import { getFacetValueLabel } from './getFacetValueLabel';

const INDEXED_FACET_RANGE_TYPES = [
	FacetRangeType.NotApplicable,
	FacetRangeType.Values,
	FacetRangeType.Interval
];

// eslint-disable-next-line sonarjs/cognitive-complexity
export const mapFacetsConfigToSearchGroups = (detailsFacetsListPanel: DetailsFacetsItemConfig[], totalFacets: Facets): FacetSearchOption[] => {
	const facetGroups: FacetSearchOptionEntity[] = [];
	const facets: FacetSearchOptionEntity[] = [];
	const facetValues: FacetSearchOptionEntity[] = [];

	for (const tab of detailsFacetsListPanel) {
		for (const group of tab.groups) {
			facetGroups.push({
				name: group.displayName,
				path: [tab.name, group.name]
			});

			for (const facet of group.facets) {
				facets.push({
					name: facet.displayName ?? facet.name,
					path: [tab.name, group.name, facet.name]
				});

				if (INDEXED_FACET_RANGE_TYPES.some(rangeType => rangeType === facet.rangeType)) {
					facetValues.push(
						...totalFacets[facet.name].map(item => {
							const valueName = getFacetValueLabel(item, facet.rangeType, facet.range);

							return {
								name: valueName,
								path: [tab.name, group.name, facet.name, valueName]
							};
						})
					);
				}
			}
		}
	}

	return [
		{
			group: FacetsSearchGroups.FacetGroups,
			entities: facetGroups
		},
		{
			group: FacetsSearchGroups.Facets,
			entities: facets
		},
		{
			group: FacetsSearchGroups.FacetValues,
			entities: facetValues
		}
	].map(group => ({
		...group,
		entities: group.entities.sort((a, b) => a.name.localeCompare(b.name))
	}));
};
