import { Column, ColumnTypes } from 'components/CustomTable';

import { DoiListTableColumnNames } from './types';

export const ACTIONS_PREFIX = 'doi';
export const MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS = 500;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const DOI_LIST_TABLE_ROWS_PER_PAGE_OPTIONS: number[] = [10, 20, 50, 100];
export const DOI_LIST_TABLE_COLUMNS: Column<DoiListTableColumnNames>[] = [
	{
		name: 'modifiedDateTime',
		displayName: 'Modified Date',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'title',
		displayName: 'Title',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'principalInvestigator',
		displayName: 'Principal Investigator',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'publishedDateTime',
		displayName: 'Published Date',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	},
	{
		name: 'action',
		displayName: 'Action',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}
];
