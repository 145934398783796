import React, { FunctionComponent } from 'react';
import { CustomTableTextCell } from 'components/CustomTable';
import { IndexListTableColumnNames, IndexData } from '../types';

interface IndexListTableCellProps {
	indexList: IndexData
	tableColumnName: IndexListTableColumnNames
	content?: string | boolean | null
}

export const IndexListTableCell: FunctionComponent<IndexListTableCellProps> = ({
	indexList,
	tableColumnName,
	content
}) => <CustomTableTextCell value={content || indexList[tableColumnName]} />;

