import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from '../../../network';
import { SearchRequestOptions } from '../types';

export const searchPublic = async (options: SearchRequestOptions): Promise<AxiosResponse> => {

	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: options,
	};

	return networkManager.fetch(Urls.SearchPublic, requestOptions);
};
