import type { RootState } from 'app/store';

import { CreateManagementConfig, IndexData, IndexItem } from './types';

export const selectIndexList = (state: RootState): IndexData[] => state.administration.indexManagement.indexList;
export const selectCurrentIndex = (state: RootState): IndexItem | null => state.administration.indexManagement.currentIndex;
export const selectIsGetIndexListLoading = (state: RootState): boolean => state.administration.indexManagement.isGetIndexListLoading;
export const selectIndexListTotalCount = (state: RootState): number => state.administration.indexManagement.indexListTotalCount;
export const selectIsRequestLoading = (state: RootState): boolean => state.administration.indexManagement.isRequestLoading;
export const selectIsRequestSent = (state: RootState): boolean => state.administration.indexManagement.isRequestSent;
export const selectIsCreateManagementConfigLoading = (state: RootState): boolean => state.administration.indexManagement.isCreateManagementConfigLoading;
export const selectCreateManagementConfig = (state: RootState): CreateManagementConfig | null => state.administration.indexManagement.createManagementConfig;
export const selectIsExportReleasedFilesLoading = (state: RootState): boolean => state.administration.indexManagement.isExportingReleasedFilesList;
export const selectIsExportParticipantsIntersecLoading = (state: RootState): boolean => state.administration.indexManagement.isExportingParticipantsIntersecList;
export const selectIsExportUploadedFilesLoading = (state: RootState): boolean => state.administration.indexManagement.isExportingUploadedFilesList;
