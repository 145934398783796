import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { GetParticipantsListRequest, GetParticipantsListResponse } from '../types';

export const getParticipantsList = async (params: GetParticipantsListRequest): Promise<AxiosResponse<GetParticipantsListResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'accept': 'text/plain',
		}
	};

	return networkManager.fetch<GetParticipantsListResponse>(Urls.GetVisualizationParticipantsList, requestOptions, params);
};
