import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { getUserDoiList } from '../api';
import { GetUserDoiListResponse } from '../types';
import { selectUserDoiListCurrentPage, selectUserDoiListRowsPerPage } from '../selectors';

const SKIP_PAGINATION_RESET_ACTION_TYPES = [doiSlice.actions.setUserDoiListCurrentPage.type];

export function* getUserDoiListSaga(action: PayloadAction): SagaIterator {
	try {
		yield put(doiSlice.actions.getUserDoiListStart());

		if (!SKIP_PAGINATION_RESET_ACTION_TYPES.some(saveCurrentPageActionType => action.type === saveCurrentPageActionType)) {
			yield put(doiSlice.actions.resetUserDoiListPageSilent());
		}

		const userDoiListCurrentPage = (yield select(selectUserDoiListCurrentPage)) as ReturnType<typeof selectUserDoiListCurrentPage>;
		const userDoiListRowsPerPage = (yield select(selectUserDoiListRowsPerPage)) as ReturnType<typeof selectUserDoiListRowsPerPage>;

		const response = (yield call(getUserDoiList, {
			skip: userDoiListCurrentPage * userDoiListRowsPerPage,
			top: userDoiListRowsPerPage,
		})) as AxiosResponse<GetUserDoiListResponse>;

		yield put(doiSlice.actions.getUserDoiListEnd({ endState: SagaEndState.Success, data: response.data }));
	} catch (e) {
		yield put(doiSlice.actions.getUserDoiListEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
