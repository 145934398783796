import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { convertBytes } from 'utils';
import { ColumnChartWithDrilldownData, ColumnChartWithDrilldownDataPlotting,
	ColumnChartWithDrilldownDataShort,
	ColumnChartWithDrilldownPlotting, ColumnChartWithDrilldownSeriesPlotting } from '../types';
import { ColumnChartWithDrilldown } from './ColumnChartWithDrilldown';


const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	paperContainer: {
		margin: '10px',
		width: '520px',
		height: '400px'
	}
}));

interface ColumnChartWithDrilldownContainerProps{
	isLoading: boolean
	data: ColumnChartWithDrilldownData | null
	colors: string[]
	title: string
	periodOfData: keyof ColumnChartWithDrilldownDataShort
}

export const ColumnChartWithDrilldownContainer: FunctionComponent<ColumnChartWithDrilldownContainerProps> = ({
	isLoading,
	data,
	colors,
	title,
	periodOfData
}) => {
	const classes = useStyles();

	// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	const convertBytesToGB = (num: number): number => convertBytes(num, 0, 3, String(num).length > 9 ? 2 : 11 - String(num).length);

	const dataColumChartWithDrilldown = (obj: ColumnChartWithDrilldownData | null, period: keyof ColumnChartWithDrilldownDataShort): ColumnChartWithDrilldownPlotting | null | undefined => {
		if (!obj) {
			return null;
		}

		const periodArray = obj[period];

		const dataDrilldown: ColumnChartWithDrilldownDataPlotting[] | undefined = periodArray && periodArray.map((item, index) => (
			{
				name: item,
				y: convertBytesToGB(obj.data[index].total),
				drilldown: item
			}
		));

		const series: ColumnChartWithDrilldownSeriesPlotting[] | undefined = periodArray && periodArray.map((item, index) => (
			{
				name: item,
				id: item,
				data: obj.data[index].users.map(u => ({
					name: u.userName,
					y: convertBytesToGB(u.amount),
					email: u.email
				}))
			}
		));

		return {
			dataDrilldown,
			series
		};
	};

	return <Paper className={classes.paperContainer}>
		{isLoading
			? <Box className={classes.root}>
				<CircularProgress size={60} />
			</Box>
			: data &&
			<ColumnChartWithDrilldown
				data={dataColumChartWithDrilldown(data, periodOfData)} title={title} colors={colors} period={periodOfData}/>
		}
	</Paper>;
};
