import React, { FunctionComponent, useRef, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { sumBy } from 'lodash';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import {
	Box,
	Button,
	ClickAwayListener, IconButton,
	InputAdornment,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	TextField,
	Typography
} from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import Cancel from '@material-ui/icons/Cancel';

import { TooltipWrapper } from '../../../components';
import { MAX_VALUES_ON_COLLAPSED } from 'styles/constants.ui';

import { Facets, FacetSearchOption, FacetSearchOptionEntity } from '../types';
import { FacetsSearchGroups } from '../types/SearchRequestOptions';
import { facetsPanelSlice } from '../slices';
import { FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME } from '../constants';
import { generateFacetPanelEntityId } from '../utils';
import { selectIsShowFacetsWithoutValues } from '../selectors';

interface FacetsSearchProps {
	options: FacetSearchOption[]
	facets: Facets
}
interface CategoryEntitiesProps {
	entities: FacetSearchOptionEntity[]
	onEntityClick: (entityPath: string[]) => void
	keyword: string
}

const MIN_LETTERS_FOR_ITEM = 25;
const MIN_KEYWORD_LENGTH = 1;
const FACET_VALUES_PATH_LENGTH = 4;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		searchFieldRoot: {
			backgroundColor: theme.palette.common.white
		},
		searchFieldOpened: {
			borderBottomLeftRadius: 0,
			borderBottomRightRadius: 0
		},
		inputAdornmentRoot: {
			color: grey['500']
		},
		popperRoot: {
			zIndex: theme.zIndex.modal,
			width: '363px'
		},
		paperRoot: {
			maxHeight: 'calc(100vh - 290px)',
			overflowY: 'auto',
			overflowX: 'hidden'
		},
		entityItemRoot: {
			padding: theme.spacing(0, 1),
			color: theme.palette.primary.main
		}
	})
);

const CategoryEntities: FunctionComponent<CategoryEntitiesProps> = ({
	entities,
	onEntityClick,
	keyword
}: CategoryEntitiesProps) => {
	const classes = useStyles();

	const [isCategoryExpanded, setIsCategoryExpanded] = useState(false);

	const onCategoryToggle = useCallback(() => {
		setIsCategoryExpanded(prevState => !prevState);
	}, []);
	const onItemClick = useCallback((entity: FacetSearchOptionEntity) => () => {
		onEntityClick(entity.path);
	}, [onEntityClick]);

	useEffect(() => {
		setIsCategoryExpanded(false);
	}, [keyword]);

	return (
		<>
			<MenuList disablePadding>
				{(isCategoryExpanded ? entities : entities.slice(0, MAX_VALUES_ON_COLLAPSED)).map(entity =>
					<MenuItem key={generateFacetPanelEntityId(entity.path)} onClick={onItemClick(entity)}>
						<TooltipWrapper title={ entity.name } disabled={ entity.name.length < MIN_LETTERS_FOR_ITEM }>
							<Typography
								component="span"
								variant="subtitle1"
								classes={{ root: classes.entityItemRoot }}
								noWrap
							>
								{ entity.name }
							</Typography>
						</TooltipWrapper>
					</MenuItem>
				)}
			</MenuList>
			{entities.length > MAX_VALUES_ON_COLLAPSED &&
				<Box display="flex" justifyContent="flex-end" px={3}>
					<Button
						aria-expanded={isCategoryExpanded}
						aria-label={ 'Show all items' }
						style={{ textTransform: 'lowercase', marginRight: '-5px' }}
						size="small"
						variant="text"
						onClick={onCategoryToggle}>
						<Typography color="primary" component="span" variant="body2">
							{ isCategoryExpanded ?
								'show less' :
								`show ${entities.length - MAX_VALUES_ON_COLLAPSED} more`
							}
						</Typography>
					</Button>
				</Box>
			}
		</>
	);
};

// eslint-disable-next-line react/no-multi-comp
export const FacetsSearch: FunctionComponent<FacetsSearchProps> = ({
	options,
	facets
// eslint-disable-next-line sonarjs/cognitive-complexity
}: FacetsSearchProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const searchInputRef = useRef<HTMLDivElement>(null);
	const [searchGroupNames] = useState({
		[FacetsSearchGroups.FacetGroups]: 'Facet groups',
		[FacetsSearchGroups.Facets]: 'Facets',
		[FacetsSearchGroups.FacetValues]: 'Values'
	});
	const [isSearchPopperOpen, setIsSearchPopperOpen] = useState(false);
	const [keyword, setKeyword] = useState('');
	const [filteredOptions, setFilteredOptions] = useState<FacetSearchOption[]>([]);

	const isShowFacetsWithoutValues = useSelector(selectIsShowFacetsWithoutValues);

	const setPopperOpen = useCallback(() => {
		if (keyword.length >= MIN_KEYWORD_LENGTH) {
			setIsSearchPopperOpen(true);
		} else {
			setIsSearchPopperOpen(false);
		}
	}, [keyword]);

	const onInputFocus = useCallback(() => {
		setPopperOpen();
	}, [setPopperOpen]);
	const onClickAway = useCallback(() => {
		setIsSearchPopperOpen(false);
	}, []);
	const onInputChange = useCallback(({ target: { value } }: React.ChangeEvent<{ value: string }>) => {
		setKeyword(value);
	}, []);
	const onInputClear = useCallback(() => {
		setKeyword('');
	}, []);
	const onEntityClick = useCallback((path: string[]) => {
		dispatch(facetsPanelSlice.actions.toggleEntitiesSubtree({ path: [], state: false }));
		dispatch(facetsPanelSlice.actions.setActiveTab({ tabName: path[0] }));

		const expandableEntityPath = path.slice(0, FACET_VALUES_PATH_LENGTH - 1);

		if (path.length === FACET_VALUES_PATH_LENGTH) {
			dispatch(facetsPanelSlice.actions.toggleEntityExpand({
				path: [...expandableEntityPath, FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME],
				state: true
			}));
		}

		while (expandableEntityPath.length > 1) {
			dispatch(facetsPanelSlice.actions.toggleEntityExpand({ path: expandableEntityPath, state: true }));
			expandableEntityPath.pop();
		}

		dispatch(facetsPanelSlice.actions.setTargetSearchId(generateFacetPanelEntityId(path)));

		setIsSearchPopperOpen(false);
	}, [dispatch]);

	useEffect(() => {
		// eslint-disable-next-line no-shadow
		const isNameIncludesKeyword = (entityName: string, entityKeyword: string) => entityName.toLowerCase().includes(entityKeyword.toLowerCase());

		const filterCallbacks = {
			[FacetsSearchGroups.FacetGroups]: (entity: FacetSearchOptionEntity) => isNameIncludesKeyword(entity.name, keyword),
			[FacetsSearchGroups.Facets]: (entity: FacetSearchOptionEntity) => {
				if (isShowFacetsWithoutValues) {
					return isNameIncludesKeyword(entity.name, keyword);
				}
				return isNameIncludesKeyword(entity.name, keyword) && sumBy(facets[entity.path[2]], ({ count }) => count) !== 0;
			},
			[FacetsSearchGroups.FacetValues]: (entity: FacetSearchOptionEntity) => isNameIncludesKeyword(entity.name, keyword)
		};

		setFilteredOptions(options.map(groupItem => ({
			...groupItem,
			entities: groupItem.entities.filter(filterCallbacks[groupItem.group])
		})));
	}, [keyword, options, facets, isShowFacetsWithoutValues]);
	useEffect(() => {
		setPopperOpen();
	}, [keyword, setPopperOpen]);

	return (
		<ClickAwayListener onClickAway={onClickAway}>
			<Box position="relative">
				<TextField
					classes={{ root: classes.searchFieldRoot }}
					InputLabelProps={{
						shrink: true
					}}
					InputProps={{
						startAdornment:
							<InputAdornment position="start">
								<Search classes={{ root: classes.inputAdornmentRoot }} />
							</InputAdornment>,
						endAdornment: keyword.length > 0 &&
							<InputAdornment position="end">
								<IconButton size="small" onClick={onInputClear} aria-label="Clear search" component="span">
									<Cancel classes={{ root: classes.inputAdornmentRoot }} />
								</IconButton>
							</InputAdornment>
					}}
					inputProps={{
						'aria-label': 'Keywords'
					}}
					onFocus={onInputFocus}
					onChange={onInputChange}
					placeholder={ 'Search facet by keywords' }
					variant="outlined"
					fullWidth
					ref={searchInputRef}
					value={keyword}
				/>
				<Popper
					open={isSearchPopperOpen}
					anchorEl={searchInputRef.current}
					role={undefined}
					disablePortal
					container={searchInputRef.current}
					placement="bottom-start"
					className={clsx(classes.popperRoot)}
					keepMounted
					popperOptions={{
						modifiers: {
							preventOverflow: {
								enabled: false,
								boundariesElement: 'disabled',
							}
						}
					}}
				>
					<Paper square elevation={3} classes={{ root: classes.paperRoot }}>
						<Box width={searchInputRef.current ? searchInputRef.current.clientWidth : null} pt={2}>
							{filteredOptions.some(({ entities }) => entities.length > 0)
								? filteredOptions.map(({ group, entities }) =>
									entities.length > 0 &&
									<Box py={1} key={group}>
										<Box px={3} pb={1} color={grey['900']}>
											<Typography component="h3" variant="h6" style={{ textTransform: 'uppercase' }}>
												{ searchGroupNames[group] }
											</Typography>
										</Box>
										<CategoryEntities
											keyword={keyword}
											entities={entities}
											onEntityClick={onEntityClick}
										/>
									</Box>
								)
								:
								<Box pt={2} pb={4} textAlign="center">
									<Typography component="h3" variant="h6">
										{ 'No matches found' }
									</Typography>
								</Box>
							}
						</Box>
					</Paper>
				</Popper>
			</Box>
		</ClickAwayListener>
	);
};
