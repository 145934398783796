import React, { FunctionComponent, useCallback } from 'react';

import {
	Box,
	Button,
	Paper,
	Typography
} from '@material-ui/core';

import { LoaderButton, SkeletonWrapper } from 'components';
import { DuaStatuses } from '../../usersManagement/types';
import { addiDataRequestDetailsSlice } from '../slices';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddiDataRequestDetails, selectIsAddiDataRequestLoading, selectIsAddiDocuSignDownloading, selectIsAddiManagementLoading, selectIsAddiSignDataRequestSending } from '../selectors';

export const AddiDocuSign: FunctionComponent = () => {
	const docuSignViewLink = process.env.REACT_APP_DOCUSIGN_VIEW_ORIGIN;

	const dispatch = useDispatch();

	const isAddiManagementLoading = useSelector(selectIsAddiManagementLoading);
	const isAddiDataRequestLoading = useSelector(selectIsAddiDataRequestLoading);
	const addiDataRequest = useSelector(selectAddiDataRequestDetails);
	const isAddiSignDataRequestSending = useSelector(selectIsAddiSignDataRequestSending);
	const isAddiDocuSignDownloading = useSelector(selectIsAddiDocuSignDownloading);

	const sendAddiSignRequest = useCallback(() => {
		dispatch(addiDataRequestDetailsSlice.actions.sendAddiSignDataRequest());
	}, [dispatch]);

	const onDownloadDocuSign = useCallback(() => {
		dispatch(addiDataRequestDetailsSlice.actions.downloadAddiDocuSign());
	}, [dispatch]);

	return (
		<Box width={630} mb={4} mr={4}>
			{
				isAddiDataRequestLoading ?
					<SkeletonWrapper
						width={'100%'}
						height={150}
					/> :
					addiDataRequest && <Paper>
						<Box p={2}>
							<Box mb={2.5} display="flex" flexDirection="column">
								<Typography
									variant="h5"
									component="h2"
								>
									{'DocuSign'}
								</Typography>
								<Box pb={2} />
								{addiDataRequest?.status === DuaStatuses.Submitted ?
									<Box>
										<Box pb={2}>
											{'Please review the ADDI data request carefully and send for signing through the DocuSign. After this action the data request will become verified and will be waiting for signing by all involved parties.'}
										</Box>
										<Box pb={2} />
										<Box display="flex">
											<LoaderButton
												isLoading={isAddiSignDataRequestSending}
												wrapperBoxProps={{
													style: { display: 'inline' }
												}}
												ButtonProps={{
													disabled: isAddiManagementLoading,
													size: 'medium',
													variant: 'contained',
													color: 'primary',
													onClick: sendAddiSignRequest
												}}
											>
												{'Send for signing'}
											</LoaderButton>
										</Box>
									</Box> :
									<Box>
										<Box pb={2} />
										<Box pb={2}>
											{'You can download the data request PDF document or view data request in DocuSign.'}
										</Box>
										<Box display="flex">
											<LoaderButton
												isLoading={isAddiDocuSignDownloading}
												wrapperBoxProps={{
													style: { display: 'inline' }
												}}
												ButtonProps={{
													disabled: isAddiManagementLoading,
													size: 'medium',
													variant: 'outlined',
													color: 'primary',
													onClick: onDownloadDocuSign
												}}
											>
												{'Download PDF'}
											</LoaderButton>
											<Box pr={3} />
											<Button
												disabled={isAddiManagementLoading}
												color="primary"
												variant="outlined"
												size="medium"
												href={`${docuSignViewLink}/${addiDataRequest?.envelopeId ?? ''}`}
												target='_blank'
											>
												{'View in DocuSign'}
											</Button>
										</Box>
									</Box>
								}
							</Box>
						</Box>
					</Paper>
			}
		</Box>
	);
};
