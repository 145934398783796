/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { StrictEffect, put, call } from 'redux-saga/effects';
// import { AxiosError } from 'axios';

import { i18n } from '../../../locale';
import { getBlobText } from '../../../utils';

import { alertSlice } from '../../alert/slices';

// import { CustomErrorData /*, ErrorResponse */} from '../types';
import { CustomErrorData } from '../types';
import { FALLBACK_SERVER_ERROR_TEXT } from '../constants';

// export function* handleErrorSaga(error: AxiosError<ErrorResponse>): Generator<StrictEffect, void> {
export function* handleErrorSaga(error: any): Generator<StrictEffect, void> {
	let message: string | undefined;

	try {
		if (error.response?.data instanceof Blob) {
			// eslint-disable-next-line @typescript-eslint/unbound-method
			const text = (yield call(getBlobText, error.response.data)) as string;
			const responseData = (yield call(JSON.parse, text)) as CustomErrorData;
			message = responseData.error;
		} else {
			message = error.response?.data.error;
		}
		// eslint-disable-next-line no-empty
	} catch (e) {
	} finally {
		if (typeof message !== 'string' || !message) {
			message = i18n.t('errors.defaultAsyncErrorText') || FALLBACK_SERVER_ERROR_TEXT;
		}
	}

	yield put(alertSlice.actions.showAlert({ message }));
}
