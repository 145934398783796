import React, { FunctionComponent } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { withStyles } from '@material-ui/core';

interface SkeletonWrapperProps {
	width: string | number | undefined
	height: string | number | undefined
}

const SkeletonStyled = withStyles({
	root: {
		transform: 'none',
	}
})(Skeleton);

export const SkeletonWrapper: FunctionComponent<SkeletonWrapperProps> = ({
	width,
	height
}: SkeletonWrapperProps) =>
	<SkeletonStyled
		width={width}
		height={height}
		animation="pulse"
		variant="text"
	/>
;
