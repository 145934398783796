import React, { FunctionComponent, useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box, Button, ButtonBaseActions,
	IconButton
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Close from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { TooltipWrapper } from '../../../components';
import { MAX_VALUES_ON_COLLAPSED } from 'styles/constants.ui';

import { Facet, FacetRangeType, FacetsBaseProps, SelectedFacetValue } from '../types';
import { getFacetValueLabel, generateFacetPanelEntityId, getFacetsPanelEntityExpandInfo, getFacetTotalCount } from '../utils';
import {
	selectFacetsPanelEntitiesExpandState,
	selectFacetsPanelTargetSearchId,
	selectDisplayedFacetsInCardView
} from '../selectors';
import { facetsPanelSlice, facetsSlice } from '../slices';
import { FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME } from '../constants';

import { FacetValueItem } from './FacetValueItem';
import { ExpandableTextPanel } from './ExpandableTextPanel';

const MAX_FACET_NAME_WIDTH_IN_PX = 240; // used for noWrap work
const MIN_LETTERS_FOR_FACET_NAME_TOOLTIP = 20;

const useStyles = makeStyles((theme: Theme) => ({
	accordionRoot: {
		backgroundColor: 'inherit'
	},
	accordionSummaryRoot: {
		padding: 0
	},
	showOrHideFacetButton: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		marginLeft: theme.spacing(1.25),
		padding: 0
	}
}));

interface FacetItemProps extends FacetsBaseProps {
	facet: Facet
	selectedFacetValues: SelectedFacetValue[]
	panelPath: string[]
}

export const FacetItem: FunctionComponent<FacetItemProps> = ({
	facet,
	selectFacetValue,
	unselectFacetValue,
	selectedFacetValues,
	panelPath
// eslint-disable-next-line sonarjs/cognitive-complexity
}: FacetItemProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [isVisibleHideButton, setIsVisibleHideButton] = useState(false);

	const onHideButtonMouseEnter = useCallback(() => {
		setIsVisibleHideButton(true);
	}, []);

	const onHideButtonMouseLeave = useCallback(() => {
		setIsVisibleHideButton(false);
	}, []);

	const summaryRef = useRef<HTMLButtonElement>(null);
	const actions = useRef<ButtonBaseActions>(null);

	const targetSearchId = useSelector(selectFacetsPanelTargetSearchId);
	const isCardExpanded = getFacetsPanelEntityExpandInfo(useSelector(selectFacetsPanelEntitiesExpandState), panelPath).state;
	const isFacetValuesExpanded = getFacetsPanelEntityExpandInfo(useSelector(selectFacetsPanelEntitiesExpandState), [...panelPath, FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME]).state;

	const displayedFacetsInCardView = useSelector(selectDisplayedFacetsInCardView);

	const facetName = facet.displayName || facet.name;
	const facetDescription = facet.description || '';

	const selectedValuesCount = getFacetTotalCount(facet.values, selectedFacetValues);

	const isFacetVisibleInCardView = displayedFacetsInCardView.some(displayedFacetName => displayedFacetName === facet.name);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const toggleExpand = useCallback((e: React.ChangeEvent<{}>, expand: boolean) => {
		if (!expand) {
			dispatch(facetsPanelSlice.actions.toggleEntitiesSubtree({ path: panelPath, state: false }));
		}
		dispatch(facetsPanelSlice.actions.toggleEntityExpand({
			path: panelPath,
			state: expand
		}));
	}, [dispatch, panelPath]);

	const toggleFacetValuesExpand = useCallback(() => {
		dispatch(facetsPanelSlice.actions.toggleEntityExpand({
			path: [...panelPath, FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME],
			state: !isFacetValuesExpanded
		}));
	}, [dispatch, isFacetValuesExpanded, panelPath]);

	const showFacetInCardView = useCallback((clickEvent: React.MouseEvent) => {
		clickEvent.stopPropagation();
		dispatch(facetsSlice.actions.showFacetInCardView(facet.name));
	},
	[dispatch, facet.name]);

	const hideFacetInCardView = useCallback((clickEvent: React.MouseEvent) => {
		clickEvent.stopPropagation();
		setIsVisibleHideButton(false);
		dispatch(facetsSlice.actions.hideFacetInCardView(facet.name));
	},
	[dispatch, facet.name]);

	useEffect(() => {
		const currentEntityId = generateFacetPanelEntityId(panelPath);
		if (currentEntityId === targetSearchId && summaryRef && actions) {
			if (summaryRef.current && actions.current) {
				summaryRef.current.focus();
				actions.current.focusVisible();
			}
			dispatch(facetsPanelSlice.actions.setTargetSearchId(''));
		}
	}, [dispatch, targetSearchId, panelPath, summaryRef, actions]);

	return (
		<Accordion
			square
			TransitionProps={{
				unmountOnExit: true,
				...targetSearchId ? { timeout: 0 } : {}
			}}
			expanded={isCardExpanded}
			onChange={toggleExpand}
			classes={{ root: classes.accordionRoot }}
		>
			<AccordionSummary
				aria-label={ `Category for filtration is ${facetName}, number of matches is ${selectedValuesCount}` }
				id={generateFacetPanelEntityId(panelPath)}
				classes={{ root: classes.accordionSummaryRoot }}
				buttonRef={summaryRef}
				action={actions}
			>
				<Box width='100%' display='flex' justifyContent='space-between'>
					<Box display='flex' alignItems='center' mr={1} width='100%'>
						<Box mr={1} color={ selectedValuesCount === 0 ? grey['500'] : grey['700'] } display='flex' alignItems='center'>
							{!isCardExpanded && <ExpandMore/>}
							{isCardExpanded && <ExpandLess/>}
						</Box>
						<Box maxWidth={ MAX_FACET_NAME_WIDTH_IN_PX } color={selectedValuesCount === 0 ? grey['500'] : grey['900'] }>
							<TooltipWrapper title={ facetName } disabled={ facetName.length < MIN_LETTERS_FOR_FACET_NAME_TOOLTIP }>
								<Typography component='h4' variant='h6' noWrap={true}>{ facetName }</Typography>
							</TooltipWrapper>
						</Box>
					</Box>
					<Box
						display='flex'
						alignItems='center'
						color={ selectedValuesCount === 0 ? grey['500'] : grey['900'] }>
						<Typography component='h3' variant='body2' style={{ fontWeight: 'bold' }}>{ selectedValuesCount }</Typography>
					</Box>
					{
						isFacetVisibleInCardView ?
							<IconButton
								onMouseEnter={onHideButtonMouseEnter}
								onFocus={onHideButtonMouseEnter}
								onBlur={onHideButtonMouseLeave}
								onMouseLeave={onHideButtonMouseLeave}
								onClick={hideFacetInCardView}
								aria-label='hide facet in card view'
								edge='end'
								classes={{ edgeEnd: classes.showOrHideFacetButton }}>
								{
									isVisibleHideButton
										? <Close color='error' />
										: <CheckCircleOutline color='secondary' />
								}
							</IconButton>
							:
							<IconButton
								onClick={showFacetInCardView}
								color='primary'
								aria-label='show facet in card view'
								edge='end'
								classes={{ edgeEnd: classes.showOrHideFacetButton }}>
								<AddCircleOutline />
							</IconButton>
					}
				</Box>
			</AccordionSummary>
			<AccordionDetails>
				<Box width='100%'>
					<Box pl={4} pr={2} width='100%' color={selectedValuesCount === 0 ? grey['500'] : grey['800'] }>
						{facetDescription && <ExpandableTextPanel text={facetDescription} />}
					</Box>
					{ selectedValuesCount === 0
						? <Box pl={4} pr={2} py={2} width='100%' color={grey['800']}>
							<Typography component='p' variant='body1'>
								{ 'No matches for this field' }
							</Typography>
						</Box>
						: <Box pt={1} pb={1.5}>
							{(isFacetValuesExpanded ? facet.values : facet.values.slice(0, MAX_VALUES_ON_COLLAPSED))
								.map(facetValue => {
									const { from, to, value } = facetValue;
									const facetValueLabel = getFacetValueLabel({ from, to, value }, facet.rangeType, facet.range);

									return <FacetValueItem
										key={generateFacetPanelEntityId([...panelPath, facetValueLabel])}
										facetName={facet.name}
										facetValue={facetValue}
										facetValueLabel={facetValueLabel}
										selectFacetValue={selectFacetValue}
										unselectFacetValue={unselectFacetValue}
										selectedFacetValues={selectedFacetValues}
										ariaLabel={
											facet.rangeType === FacetRangeType.NotApplicable ?
												`Value for filtration is ${facetValueLabel}, number of matches is ${facetValue.count}` :
												`Range for filtration is ${facetValueLabel}, number of matches is ${facetValue.count}`
										}
										panelPath={[...panelPath, facetValueLabel]}
									/>;
								}
								)
							}
							{facet.values.length > MAX_VALUES_ON_COLLAPSED &&
							<Box display='flex' justifyContent='flex-end' pr={0}>
								<Button
									aria-expanded={isFacetValuesExpanded}
									aria-label={ 'Show all items' }
									style={{ textTransform: 'lowercase', marginRight: '-5px' }}
									size='small'
									variant='text'
									onClick={toggleFacetValuesExpand}>
									<Typography color='primary' component='span' variant='body2'>
										{ isFacetValuesExpanded ?
											'show less' :
											`show ${facet.values.length - MAX_VALUES_ON_COLLAPSED} more`
										}
									</Typography>
								</Button>
							</Box>}
						</Box>}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};
