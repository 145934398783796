import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, TextField, Theme, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';

import { LoaderButton } from '../../../../../components';

import { SidePanelUiFormFields } from '../types';
import { MAX_GENES_COUNT, MAX_PARTICIPANTS_COUNT } from '../constants';
import {
	selectGenes,
	selectIsHeatmapDataInitialized,
	selectIsHeatmapDataLoading,
	selectParticipants
} from '../selectors';
import { omicL4VisualizationSlice } from '../slices';

const TEXTAREA_Y_PADDING = 2.3125;
const TEXTAREA_X_PADDING = 1.75;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			flexShrink: 0,
			height: '100%',
			width: 330
		},
		drawerPaper: {
			position: 'relative',
			height: '100%',
			zIndex: 1
		},
		textareaRoot: {
			'& > div': {
				backgroundColor: 'transparent',
				zIndex: 1,
				padding: theme.spacing(TEXTAREA_Y_PADDING, 0, 0, 0),
				'& > textarea': {
					padding: theme.spacing(0, TEXTAREA_X_PADDING, TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
					resize: 'vertical',
					minHeight: '100px',
					'&::-webkit-scrollbar': {
						width: '10px'
					}
				}
			}
		},
	})
);

const visualizationDataSchema = yup.object().shape({
	genes: yup.string()
		.trim()
		.required('Please fill the data')
		.test(
			'genesCount',
			`Max Genes count is ${MAX_GENES_COUNT}`,
			input => Boolean(input === undefined ? true : input.split('\n').length <= MAX_GENES_COUNT)
		),
	participants: yup.string()
		.trim()
		.required('Please fill the data')
		.test(
			'participantsCount',
			`Max Participants count is ${MAX_PARTICIPANTS_COUNT}`,
			input => Boolean(input === undefined ? true : input.split('\n').length <= MAX_PARTICIPANTS_COUNT)
		),
});

function parseFormListValue(value: string): string[] {
	return value.trim().split('\n').map(item => item.trim());
}

export const SidePanel: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isHeatmapDataLoading = useSelector(selectIsHeatmapDataLoading);
	const isHeatmapDataInitialized = useSelector(selectIsHeatmapDataInitialized);
	const participants = useSelector(selectParticipants);
	const genes = useSelector(selectGenes);

	const visualizationDataForm = useFormik<SidePanelUiFormFields>({
		initialValues: {
			genes: '',
			participants: ''
		},
		validationSchema: visualizationDataSchema,
		onSubmit: values => {
			dispatch(omicL4VisualizationSlice.actions.updateHeatmapDataRequest({
				participants: parseFormListValue(values.participants),
				genes: parseFormListValue(values.genes)
			}));
		}
	});

	const onUpdateClick = useCallback(() => {
		visualizationDataForm.handleSubmit();
	}, [visualizationDataForm]);

	const formFieldsSkeleton: {
		formFieldName: keyof SidePanelUiFormFields
		label: string
	}[] = [
		{ formFieldName: 'genes', label: 'Transcriptomics Genes' },
		{ formFieldName: 'participants', label: 'Participant List' },
	];

	useEffect(() => {
		void visualizationDataForm.setFieldValue('participants', participants.slice(0, MAX_PARTICIPANTS_COUNT).join('\n'), false);
		void visualizationDataForm.setFieldValue('genes', genes.slice(0, MAX_GENES_COUNT).join('\n'), false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participants, genes]);

	return (
		<aside>
			<Drawer
				className={ classes.drawer }
				classes={{ paper: classes.drawerPaper }}
				variant='permanent'
				anchor='left'
			>
				<Box p={2.5}>
					<Box pt={1.5}>
						<Typography component='h2' variant='h5'>
							{'Visualization Data'}
						</Typography>
					</Box>
					<Box pb={4} />
					{formFieldsSkeleton.map((formField, formFieldIndex) =>
						// eslint-disable-next-line @typescript-eslint/no-magic-numbers
						<Box key={formFieldIndex} mb={formFieldIndex + 1 === formFieldsSkeleton.length ? 3 : 4}>
							<TextField
								name={ formField.formFieldName }
								classes={{ root: classes.textareaRoot }}
								InputLabelProps={{
									shrink: true
								}}
								label={ formField.label }
								spellCheck={false}
								multiline
								fullWidth
								rows={8}
								variant="outlined"
								value={ visualizationDataForm.values[formField.formFieldName] }
								onChange={ visualizationDataForm.handleChange }
								error={ visualizationDataForm.touched[formField.formFieldName] && Boolean(visualizationDataForm.errors[formField.formFieldName]) }
								helperText={ visualizationDataForm.touched[formField.formFieldName] && visualizationDataForm.errors[formField.formFieldName] }
								disabled={ isHeatmapDataLoading }
							/>
						</Box>
					)}
					<Box display="flex" justifyContent="flex-end">
						<LoaderButton
							isLoading={ isHeatmapDataInitialized && isHeatmapDataLoading }
							ButtonProps={{
								size: 'large',
								variant: 'contained',
								color: 'primary',
								onClick: onUpdateClick,
								disabled: !isHeatmapDataInitialized
							}}
						>
							{ 'Update' }
						</LoaderButton>
					</Box>
					<Box pb={8} />
				</Box>
			</Drawer>
		</aside>
	);
};
