import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Box, Theme, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { LoaderButton } from '../../../../../components';

import { omicL4VisualizationSlice } from '../slices';
import {
	selectGenes,
	selectIsHeatmapDataDownloading,
	selectIsHeatmapDataLoading,
	selectParticipants
} from '../selectors';
import { generatePortalBackUrl } from '../utils';

const useStyles = makeStyles((theme: Theme) => ({
	appBar: {
		top: 'auto',
		bottom: 0,
		backgroundColor: '#ffffff'
	},
	footerToolBar: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		height: theme.spacing(7.5)
	},
	divider: {
		opacity: 0.5
	},
	descriptionRoot: {
		[theme.breakpoints.up('xl')]: {
			fontSize: theme.typography.body1.fontSize,
			lineHeight: theme.typography.body1.lineHeight,
			letterSpacing: theme.typography.body1.letterSpacing,
			maxWidth: '1200px'
		}
	}
}));

export const BottomAppBar: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isHeatmapDataDownloading = useSelector(selectIsHeatmapDataDownloading);
	const isHeatmapDataLoading = useSelector(selectIsHeatmapDataLoading);
	const participants = useSelector(selectParticipants);
	const genes = useSelector(selectGenes);

	const onDownloadClick = useCallback(() => {
		dispatch(omicL4VisualizationSlice.actions.downloadHeatmapDataRequest());
	}, [dispatch]);

	return (
		<AppBar component="div" position="fixed" color="default" className={classes.appBar}>
			<Toolbar className={ classes.footerToolBar }>
				<Box width="100%" display="flex" flexDirection="flex-row" justifyContent="space-between" alignItems="center">
					<Typography component="p" variant="body2" classes={{ root: classes.descriptionRoot }}>
						{ 'The current map is generated using your selected participants and the ClinVar genes for ALS. You can change either list by pasting your new selection into the boxes. Your new list can be used to search the data portal using the "Explore In Portal" button to the right.' }
					</Typography>
					<Box display="flex" alignItems="center" pl={4}>
						<Button
							size="large"
							variant="outlined"
							color="primary"
							disabled={isHeatmapDataLoading || !participants.length}
							component={RouterLink}
							to={generatePortalBackUrl(participants)}
						>
							<Box component="span" whiteSpace="nowrap">
								{ 'Explore In Portal' }
							</Box>
						</Button>
						<Box component="span" pr={2} />
						<LoaderButton
							isLoading={isHeatmapDataDownloading}
							ButtonProps={{
								size: 'large',
								variant: 'outlined',
								color: 'primary',
								disabled: isHeatmapDataLoading || !participants.length || !genes.length,
								onClick: onDownloadClick
							}}
						>
							<Box component="span" whiteSpace="nowrap">
								{ 'Download CSV' }
							</Box>
						</LoaderButton>
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};
