import { BaseFacetValue, FacetRangeType } from '../types';

export const getFacetValueLabel = (
	{ from, to, value }: BaseFacetValue,
	facetRangeType?: FacetRangeType,
	facetRange?: string): string => {
	if (facetRangeType === FacetRangeType.Values) {
		if (from !== undefined && to !== undefined) {
			return `${from} — ${to}`;
		}
		if (from && !to) {
			return `${from} <`;
		}
		if (to && !from) {
			return `< ${to}`;
		}
	}

	if (facetRangeType === FacetRangeType.Interval) {
		const leftRangeValue = Number(value);
		const rightRangeValue = leftRangeValue + Number(facetRange);
		return `${leftRangeValue} — ${rightRangeValue}`;
	}

	return String(value ?? '');
};
