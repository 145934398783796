import React, { FunctionComponent, useMemo } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { common, grey } from '@material-ui/core/colors';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { Column, Row } from '../types';
import { useSortedColumns } from '../hooks';

const useStyles = makeStyles(() => ({
	tableCellRoot: {
		backgroundColor: common['white'],
		color: grey['900'],
	},
	tableColumnStickyRoot: {
		'& td:first-child': {
			position: 'sticky',
			zIndex: 1,
			left: 0,
		},
	},
}));

export interface CustomTableBodyProps {
	columns: Column[]
	rows: Row[]
	isHeaderColumnSticky: boolean
}

export const CustomTableBody: FunctionComponent<CustomTableBodyProps> = ({
	columns,
	rows,
	isHeaderColumnSticky
}) => {
	const classes = useStyles();

	const sortedColumns = useSortedColumns(columns);
	const sortedVisibleColumns = useMemo(() =>
		sortedColumns.filter(column => column.isVisible)
	, [sortedColumns]);

	return (
		<TableBody>
			{ rows.map((row, rowIndex) =>
				<TableRow
					key={rowIndex}
					classes={{ root: clsx({ [classes.tableColumnStickyRoot]: isHeaderColumnSticky }) }}
				>
					{sortedVisibleColumns.map(column => {
						const cellReactNode = row[column.name] || null;
						return (
							<TableCell
								key={`${rowIndex}-${column.name}`}
								align="left"
								classes={{ root: classes.tableCellRoot }}
							>
								{ cellReactNode }
							</TableCell>
						);
					})}
				</TableRow>
			)}
		</TableBody>
	);
};
