import { WPCategoryConfig, WPPageConfig } from '../types';
import { TOP_MENU_CATEGORY } from '../constants';

export const getTopMenuPagesCombiner = (pagesConfig: WPPageConfig[], categoriesConfig: WPCategoryConfig[]): WPPageConfig[] => {
	const topMenuCategory = categoriesConfig.find(category => category.name === TOP_MENU_CATEGORY);
	let topMenuPages: WPPageConfig[] = [];
	if (topMenuCategory) {
		topMenuPages = pagesConfig
			.filter(page => page.categories.some(categoryId => categoryId === topMenuCategory.id))
			.sort((prevPage, nextPage) => prevPage.menu_order - nextPage.menu_order);
	}
	return topMenuPages;
};
