import React, { PropsWithChildren, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { bindPopper, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { useFormik } from 'formik';
import { SortableContainer, SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import escapeRegExp from 'lodash/escapeRegExp';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { common, grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

import { Column, ColumnsPanelFormFields, ColumnTypes } from '../types';
import { DRAG_PRESS_DELAY } from '../constants';

import { DraggableCustomTableColumnsPanelItem } from './CustomTableColumnsPanelItem';

const POPPER_WIDTH = 330;

const useStyles = makeStyles((theme: Theme) => ({
	dialogTitleRoot: {
		padding: theme.spacing(2)
	},
	dialogContentRoot: {
		padding: theme.spacing(2),
		maxHeight: 'calc(100vh - 425px)',
		overflow: 'auto'
	},
	popper: {
		zIndex: theme.zIndex.modal
	},
	draggableItem: {
		zIndex: theme.zIndex.modal + 1,
		backgroundColor: common['white']
	}
}));

const DraggableContainer = SortableContainer((props: PropsWithChildren<void>) => <Box>{ props.children }</Box>);

interface CustomTableColumnsPanelProps<T> {
	id: string
	columns: Column<T>[]
	defaultColumns: Column<T>[]
	onChangeColumns: (columns: Column<T>[]) => void
}

export const CustomTableColumnsPanel = <T extends string>({
	id,
	columns,
	defaultColumns,
	onChangeColumns,
}: PropsWithChildren<CustomTableColumnsPanelProps<T>>): ReactElement => {
	const classes = useStyles();

	const popupState = usePopupState({
		variant: 'popper',
		popupId: id
	});

	const form = useFormik<ColumnsPanelFormFields>({
		initialValues: {
			tempColumns: [],
			filterQuery: ''
		},
		onSubmit: fields => {
			popupState.close();

			onChangeColumns(fields.tempColumns as Column<T>[]);
		}
	});

	const filteredFlexTempColumns = useMemo<Column[]>(() => {
		const filterRegExp = new RegExp(escapeRegExp(form.values.filterQuery), 'i');
		return form.values.tempColumns
			.filter(column =>
				column.type === ColumnTypes.Flex &&
				filterRegExp.test(column.displayName)
			);
	}, [form]);
	const isSomeFlexColumnShowed = useMemo<boolean>(() =>
		filteredFlexTempColumns.some(column => column.isVisible), [filteredFlexTempColumns]);
	const isSomeFlexColumnHidden = useMemo<boolean>(() =>
		filteredFlexTempColumns.some(column => !column.isVisible), [filteredFlexTempColumns]);

	const onCancel = useCallback(() => {
		popupState.close();
	}, [popupState]);
	const onSubmit = useCallback(() => {
		form.handleSubmit();
	}, [form]);

	const onFilterClear = useCallback(() => {
		void form.setFieldValue('filterQuery', '');
	}, [form]);

	const onDragStart = useCallback(() => {
		document.body.style.cursor = 'grabbing';
	}, []);
	const onDragEnd = useCallback(({ oldIndex: oldVisibleIndex, newIndex: newVisibleIndex }: SortEnd) => {
		const oldIndex = form.values.tempColumns
			.findIndex(tempColumn => tempColumn.name === filteredFlexTempColumns[oldVisibleIndex].name);
		const newIndex = form.values.tempColumns
			.findIndex(tempColumn => tempColumn.name === filteredFlexTempColumns[newVisibleIndex].name);

		const reorderedColumns = arrayMove(form.values.tempColumns, oldIndex, newIndex);

		void form.setFieldValue('tempColumns', reorderedColumns);
		document.body.style.cursor = '';
	}, [form, filteredFlexTempColumns]);

	const onCommonCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>, newState: boolean) => {
		const updatedTempColumns: Column[] = form.values.tempColumns.map(column => ({
			...column,
			isVisible: filteredFlexTempColumns.some(filteredFlexTempColumn => filteredFlexTempColumn.name === column.name) ?
				newState :
				column.isVisible
		}));
		void form.setFieldValue('tempColumns', updatedTempColumns);
	}, [form, filteredFlexTempColumns]);

	const onDefaultColumns = useCallback(() => {
		void form.setFieldValue('tempColumns', defaultColumns);
	}, [form, defaultColumns]);

	useEffect(() => {
		if (popupState.isOpen) {
			form.resetForm({
				values: {
					tempColumns: columns,
					filterQuery: ''
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [popupState.isOpen]);

	const popperProps = bindPopper(popupState);

	return (
		<ClickAwayListener onClickAway={onCancel}>
			<Box>
				<Button
					{...bindToggle(popupState)}
					color="primary"
					variant="text"
					size="large"
				>
					{ 'Customize columns' }
				</Button>
				<Popper
					{...popperProps}
					placement="bottom-end"
					className={classes.popper}
					disablePortal
					keepMounted
					role={undefined}
					container={popperProps.anchorEl}
				>
					<Paper elevation={3}>
						<Box width={POPPER_WIDTH}>
							<DialogTitle id="table-columns-title" disableTypography classes={{ root: classes.dialogTitleRoot }}>
								<Typography variant="h5">
									{ 'Columns' }
								</Typography>
							</DialogTitle>
							<DialogContent dividers={true} classes={{ root: classes.dialogContentRoot }}>
								<TextField
									name="filterQuery"
									id="table-columns-filter-input"
									placeholder="Search columns"
									variant="outlined"
									size="small"
									fullWidth={true}
									value={ form.values.filterQuery }
									onChange={ form.handleChange }
									InputProps={{
										startAdornment:
											<InputAdornment position="start">
												<SearchIcon style={{ color: grey['600'] }} />
											</InputAdornment>,
										endAdornment: form.values.filterQuery.length > 0 &&
											<InputAdornment position="end">
												<IconButton
													size="small"
													onClick={ onFilterClear }
													aria-label="Clear filter"
													component="span"
												>
													<ClearIcon style={{ color: grey['600'] }} />
												</IconButton>
											</InputAdornment>
									}}
								/>
								<Box pb={1.5} />
								<Box display="flex" justifyContent="space-between">
									<Box pl={1.5}>
										<Checkbox
											inputProps={{
												'aria-label': isSomeFlexColumnShowed ? 'Unselect all items' : 'Select all items'
											}}
											color="primary"
											disabled={filteredFlexTempColumns.length === 0}
											checked={isSomeFlexColumnShowed}
											indeterminate={isSomeFlexColumnShowed && isSomeFlexColumnHidden}
											onChange={onCommonCheckboxChange}
										/>
									</Box>
									<Box display="flex" alignItems="center">
										<Button onClick={onDefaultColumns} color="primary" variant="text" size="small">
											{ 'Restore Default' }
										</Button>
									</Box>
								</Box>
								{filteredFlexTempColumns.length > 0 ?
									<DraggableContainer
										axis="y"
										lockAxis="y"
										pressDelay={DRAG_PRESS_DELAY}
										lockToContainerEdges
										helperClass={classes.draggableItem}
										onSortStart={onDragStart}
										onSortEnd={onDragEnd}
									>
										{filteredFlexTempColumns.map((column, index) =>
											<DraggableCustomTableColumnsPanelItem
												key={column.name}
												index={index}
												column={column}
												form={form}
											/>
										)}
									</DraggableContainer> :
									<Box pt={3} pb={2} textAlign="center">
										<Typography variant="h6">
											{ 'No matches found' }
										</Typography>
									</Box>
								}
							</DialogContent>
							<DialogActions>
								<Button onClick={onCancel} color="primary" variant="outlined">
									{ 'Cancel' }
								</Button>
								<Button onClick={onSubmit} color="primary" variant="contained">
									{ 'Submit' }
								</Button>
							</DialogActions>
						</Box>
					</Paper>
				</Popper>
			</Box>
		</ClickAwayListener>
	);
};
