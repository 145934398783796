import React, { FunctionComponent } from 'react';

import {
	Box,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
	Typography
} from '@material-ui/core';

import { useSelector } from 'react-redux';
import {
	selectAddiDataRequestDetails,
	selectIsAddiDataRequestLoading
} from '../selectors';
import { SkeletonWrapper } from 'components';
import { getFormattedDate } from 'utils';

export const AddiGeneralInfo: FunctionComponent = () => {
	const isAddiDataRequestLoading = useSelector(selectIsAddiDataRequestLoading);
	const addiDataRequest = useSelector(selectAddiDataRequestDetails);

	const tableData: { name: string; data: string }[] = [
		{ name: 'ID', data: addiDataRequest?.id ?? '' },
		{ name: 'ADDI Request Code', data: addiDataRequest?.requestCode ?? '' }
	];

	if (addiDataRequest?.envelopeId) {
		tableData.push({ name: 'DocuSign Envelope Id', data: addiDataRequest.envelopeId });
	}

	if (addiDataRequest?.updatedBy) {
		tableData.push(
			{ name: 'Updated By', data: addiDataRequest?.updatedBy ?? '' },
			{ name: 'Updated Date', data: getFormattedDate(addiDataRequest?.updatedDate ?? null) }
		);
	}

	if (addiDataRequest?.verifiedBy) {
		tableData.push(
			{ name: 'Verified By', data: addiDataRequest?.verifiedBy ?? '' },
			{ name: 'Verified Date', data: getFormattedDate(addiDataRequest?.verifiedDate ?? null) }
		);
	}

	if (addiDataRequest?.approvedBy) {
		tableData.push(
			{ name: 'Approved By', data: addiDataRequest?.approvedBy ?? '' },
			{ name: 'Approved Date', data: getFormattedDate(addiDataRequest?.approvedDate ?? null) }
		);
	}

	if (addiDataRequest?.rejectedBy) {
		tableData.push(
			{ name: 'Rejected By', data: addiDataRequest?.rejectedBy ?? '' },
			{ name: 'Rejected Date', data: getFormattedDate(addiDataRequest?.rejectedDate ?? null) }
		);
	}

	return (
		<Box width={630} mb={4} mr={4}>
			{
				isAddiDataRequestLoading ?
					<SkeletonWrapper
						width={'100%'}
						height={150}
					/> :
					addiDataRequest && <Paper>
						<Box p={2}>
							<Box mb={1.5}>
								<Typography
									variant="h5"
									component="h2"
								>
									{'General Information'}
								</Typography>
							</Box>
							<Box mb={2}>
								<Table size="small">
									<TableBody>
										{tableData.map(row =>
											<TableRow key={row.name}>
												<TableCell component="th">
													{row.name}
												</TableCell>
												<TableCell>
													{row.data}
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Box>
						</Box>
					</Paper>
			}
		</Box>
	);
};
