import { DetailsFacetsConfig, SearchRequestOptions, FacetRangeType } from '../types';
import { SEARCH_TABLE_DEFAULT_ROWS_PER_PAGE } from '../constants';

interface GetSearchOptions {
	searchFacets: string[]
	detailsFacetsConfig: DetailsFacetsConfig
	filterString: string
	orderbyString: string
	selectFields: string[]
	facetValuesMaxCount: number
	pageNumber?: number
	rowsPerPage?: number
}

export const getSearchOptions = ({
	searchFacets,
	detailsFacetsConfig,
	filterString,
	orderbyString,
	selectFields,
	facetValuesMaxCount,
	pageNumber = 0,
	rowsPerPage = SEARCH_TABLE_DEFAULT_ROWS_PER_PAGE
}: GetSearchOptions): SearchRequestOptions => ({
	count: true,
	facets: searchFacets.map(facet => {
		const facetDetails = detailsFacetsConfig[facet];
		return facetDetails && (facetDetails.rangeType === FacetRangeType.Interval || facetDetails.rangeType === FacetRangeType.Values)
			? `${facet},${facetDetails.rangeType}:${facetDetails.range || ''}`
			: `${facet},count:${facetValuesMaxCount}`;
	}),
	filter: filterString,
	orderby: orderbyString,
	select: selectFields.join(','),
	skip: pageNumber * rowsPerPage,
	top: rowsPerPage,
});
