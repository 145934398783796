import { put, StrictEffect, select, delay, call, race, take } from 'redux-saga/effects';

import { selectTotpAuthenticationCode } from '../selectors';
import { totpAuthenticationCodeSlice } from '../index';
import { TotpAuthenticationCodeState } from '../slices';

const DELAY = 1000;

export function* updateCodeAndTimerSaga(): Generator<StrictEffect, void> {
	while (true) {
		yield put(totpAuthenticationCodeSlice.actions.generateCodeRequest());

		yield race([
			take(totpAuthenticationCodeSlice.actions.generateCodeSuccess.type),
			take(totpAuthenticationCodeSlice.actions.generateCodeFail.type)
		]);

		const { isCliUserAuthorized, codeLifeTimeInSec, codeGenerateTimestamp } = (yield select(selectTotpAuthenticationCode)) as TotpAuthenticationCodeState;

		if (!isCliUserAuthorized) {
			break;
		}

		while (true) {
			const currentTimestamp = Number(yield call(Date.now));

			const passedTimeInSec = Math.round((currentTimestamp - codeGenerateTimestamp) / 1000);
			const updatedRemainingTimeInSec = Math.max(0, codeLifeTimeInSec - passedTimeInSec);

			yield put(totpAuthenticationCodeSlice.actions.setCodeRemainingTime(updatedRemainingTimeInSec));

			if (updatedRemainingTimeInSec === 0) {
				break;
			}

			yield delay(DELAY);
		}
	}
}
