import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';

import { WPCategoryConfig, WPPageConfig } from './types';
import { getTopMenuPagesCombiner } from './utils';

export const selectIsWPConfigLoading = (state: RootState): boolean => state.wpFrame.isLoading;
export const selectWPPagesConfig = (state: RootState): WPPageConfig[] => state.wpFrame.pagesConfig;
export const selectWPCategoriesConfig = (state: RootState): WPCategoryConfig[] => state.wpFrame.categoriesConfig;
export const selectWPTopMenuPages = createSelector(selectWPPagesConfig, selectWPCategoriesConfig, getTopMenuPagesCombiner);
