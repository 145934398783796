/* eslint-disable promise/prefer-await-to-callbacks */
import { ReactNode } from 'react';

import { Column, Row } from '../types';

export const mapCellsDataToNodes = <
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	D extends Record<string, any> = Record<string, unknown>,
	C extends string = string
>(
	rowsData: D[],
	columns: Column<C>[],
	callback: (rowData: D, column: Column<C>) => ReactNode
): Row<C>[] =>
	rowsData.map(row =>
		columns.reduce((rowElements: Row, column) => {
			rowElements[column.name] = callback(row, column);
			return rowElements;
		}, {})
	)
;
