import { makeStyles, Box, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import img1 from './../../../assets/images/img1.png';
import img2 from './../../../assets/images/img2.png';
import img3 from './../../../assets/images/img3.png';
import img5 from './../../../assets/images/img5.png';

const useStyles = makeStyles(() => ({
	descriptionListTitle: {
		color: '#000',
		margin: '0px 0px 16px 0px',
		fontSize: '1.5rem',
		fontStyle: 'normal',
		fontWeight: 'normal',
		lineHeight: '1.5rem',
		letterSpacing: '0.01125rem'
	},
	descriptionsListItem: {
		paddingTop: 0,
		paddingBottom: 0,
		listStyleType: 'disc',
		marginLeft: '22px',
		fontSize: '1.2rem',
		lineHeight: '2rem'
	},
	image1: {
		position: 'absolute',
		zIndex: 1,
		top: '0',
		left: '0'
	},
	image2: {
		position: 'absolute',
		zIndex: 2,
		top: '100px',
		right: '0'
	},
	image3: {
		position: 'absolute',
		zIndex: 3,
		top: '30%',
		left: '25%'
	},
	image4: {
		position: 'absolute',
		zIndex: 4,
		left: '110px',
		bottom: '166px'
	},
	imagesBox: {
		position: 'relative',
		width: '100%',
		height: '800px'
	}
}));

interface CommonCapabilitiesProps {
	title: string
	capabilities: Array<string>
}

export const CommonCapabilities: FunctionComponent<CommonCapabilitiesProps> = ({ title, capabilities }: CommonCapabilitiesProps) => {
	const classes = useStyles();

	return (
		<Box>
			<List component="ol">
				<Typography variant="h6" component="div"
					className={classes.descriptionListTitle}>
					{title}
				</Typography>
				<Box pb={2} />
				{capabilities.map(reason => <ListItem
					disableGutters
					className={classes.descriptionsListItem}
					style={{ display: 'list-item' }} key={reason}>
					<ListItemText primary={`${reason}`}
						disableTypography
					/>
				</ListItem>)}
			</List>
			<Box pb={4} />
			<Box className={classes.imagesBox}>
				<img src={img1 as string} className={classes.image1} alt="'Portal Data Search'" />
				<img src={img2 as string} className={classes.image2} alt="'Big Data Download'" />
				<img src={img3 as string} className={classes.image3} alt="'Visualizations Tool'" />
				<img src={img5 as string} className={classes.image4} alt="'Order Samples'" />
			</Box>
		</Box>
	);
};
