import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';

interface ProceedState {
	isLoadingTabs: boolean
	isSavingSearchParameters: boolean
	activeTabIndex: number
	tabs: string[]
	searchId: string
	releaseName: string
}

const initialState: ProceedState = {
	isLoadingTabs: false,
	isSavingSearchParameters: false,
	activeTabIndex: 0,
	tabs: [],
	searchId: '',
	releaseName: ''
};

export const proceedSlice = createSlice({
	name: `${ACTIONS_PREFIX}.proceed`,
	initialState,
	reducers: {
		getProceedTabsRequest: (state: ProceedState) => {
			state.isLoadingTabs = true;
		},
		getProceedTabsSuccess: (state: ProceedState, { payload }: PayloadAction<string[]>) => {
			state.isLoadingTabs = false;
			state.tabs = payload;
		},
		getProceedTabsFailure: (state: ProceedState) => {
			state.isLoadingTabs = false;
		},

		saveSearchParametersRequest: (state: ProceedState) => {
			state.isSavingSearchParameters = true;
		},
		saveSearchParametersSuccess: (state: ProceedState, { payload: { searchId, releaseName } }: PayloadAction<{
			searchId: string
			releaseName: string
		}>) => {
			state.isSavingSearchParameters = false;
			state.searchId = searchId;
			state.releaseName = releaseName;
		},
		saveSearchParametersFailure: (state: ProceedState) => {
			state.isSavingSearchParameters = false;
			state.searchId = '';
			state.releaseName = '';
		},
		setActiveTabIndex: (state: ProceedState, { payload }: PayloadAction<number>) => {
			state.activeTabIndex = payload;
		}
	}
});
