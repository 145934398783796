import React, { FunctionComponent, useCallback, useState } from 'react';

import {
	Box,
	Button,
	Paper,
	Typography
} from '@material-ui/core';

import { usersManagementUserSlice } from '../slices';
import { useDispatch, useSelector } from 'react-redux';
import { DialogModal, LoaderButton } from 'components';
import {
	selectIsDownloadDocuSignUserAgreementLoading,
	selectIsSendDuaSignRequestLoading,
	selectIsUserUpdateLoading,
	selectIsUsersManagementUserInitLoading
} from '../selectors';

import { Alert } from '@material-ui/lab';
import { DuaStatuses, User, UserEditFormFields } from '../types';
import { FormikProps } from 'formik';
import { mapUserToEditFormFields } from '../utils';
import { isEqual } from 'lodash';

export interface UserUserDocuSignProps {
	docuSignViewLink: string
	user: User
	form: FormikProps<UserEditFormFields>
}

export const UserDocuSign: FunctionComponent<UserUserDocuSignProps> = ({
	docuSignViewLink,
	user,
	form
}) => {
	const dispatch = useDispatch();

	const [isSendDocuSignModalOpen, setSendDocuSignModalOpen] = useState(false);

	const isDownloadDocuSignUserAgreementLoading = useSelector(selectIsDownloadDocuSignUserAgreementLoading);
	const isSendDuaSignRequestLoading = useSelector(selectIsSendDuaSignRequestLoading);
	const isUserInitLoading = useSelector(selectIsUsersManagementUserInitLoading);
	const isUserUpdateLoading = useSelector(selectIsUserUpdateLoading);

	const openSendDocuSignModal = useCallback(() => setSendDocuSignModalOpen(true), [setSendDocuSignModalOpen]);
	const closeSendDocuSignModal = useCallback(() => setSendDocuSignModalOpen(false), [setSendDocuSignModalOpen]);

	const onDownloadDocuSignUserAgreementClick = useCallback(() => {
		dispatch(usersManagementUserSlice.actions.downloadDocuSignUserAgreement());
	}, [dispatch]);

	const sendDuaSignRequest = useCallback(() => {
		const inititialUserFormValues = mapUserToEditFormFields(user);

		if (isEqual(form.values, inititialUserFormValues) &&
			Object.entries(form.errors).length === 0) {
			dispatch(usersManagementUserSlice.actions.sendDuaSignRequest());
		} else {
			openSendDocuSignModal();
		}
	}, [dispatch, form, user, openSendDocuSignModal]);


	return (
		<>
			<Box width={630}>
				<Box mr={3}>
					<Paper>
						<Box p={2}>
							<Box mb={2.5} display="flex" flexDirection="column">
								<Typography
									variant="h5"
									component="h2"
								>
									{'DocuSign'}
								</Typography>
								<Box pb={2} />
								{user.status === DuaStatuses.Submitted ?
									<Box>
										<Box pb={2}>
											{'Please review the data request carefully and send for signing through the DocuSign. After this action the data request will become verified and will be waiting for signing by all involved parties.'}
										</Box>
										<Box pb={2} />
										<Box display="flex">
											<LoaderButton
												isLoading={isSendDuaSignRequestLoading}
												wrapperBoxProps={{
													style: { display: 'inline' }
												}}
												ButtonProps={{
													disabled:
														isUserInitLoading ||
														isUserUpdateLoading,
													size: 'medium',
													variant: 'contained',
													color: 'primary',
													onClick: sendDuaSignRequest
												}}
											>
												{'Send for signing'}
											</LoaderButton>
										</Box>
									</Box> :
									<Box>
										<Alert severity="warning">
											<Box dangerouslySetInnerHTML={{ __html: `Please note, that currently we don't synchronize your changes with DocuSign automatically. If you modify the data request, please review and adjust the data in <a href="${docuSignViewLink}${user.envelopeId ?? ''}" target="_blank">DocuSign portal</a> manually.` }} />
										</Alert>
										<Box pb={2} />
										<Box pb={2}>
											{'You can download the data request PDF document or view data request in DocuSign.'}
										</Box>
										<Box display="flex">
											<LoaderButton
												isLoading={isDownloadDocuSignUserAgreementLoading}
												wrapperBoxProps={{
													style: { display: 'inline' }
												}}
												ButtonProps={{
													disabled: false,
													size: 'medium',
													variant: 'outlined',
													color: 'primary',
													onClick: onDownloadDocuSignUserAgreementClick
												}}
											>
												{'Download PDF'}
											</LoaderButton>
											<Box pr={3} />
											<Button
												color="primary"
												variant="outlined"
												size="medium"
												href={`${docuSignViewLink}${user.envelopeId ?? ''}`}
												target='_blank'
											>
												{'View in DocuSign'}
											</Button>
										</Box>
									</Box>
								}
							</Box>
						</Box>
					</Paper>
				</Box>
				<Box pb={3} />
			</Box>
			<DialogModal
				isOpen={isSendDocuSignModalOpen}
				headerText={'Send DocuSign'}
				cancelButtonText={'Ok'}
				onCancel={closeSendDocuSignModal}
			>
				<Typography>
					{'Your changes to user profile have not been saved yet. Please save your changes before sending DocuSign request.'}
				</Typography>
			</DialogModal>
		</>
	);
};
