import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';

import { selectViewMode } from 'features/search/selectors';
import { SearchViewMode } from 'features/search/types';

import { Routes } from 'app/Routes';

const useStyles = makeStyles((theme: Theme) => ({
	skipMain: {
		left: '-999px',
		position: 'absolute',
		top: 'auto',
		width: '1px',
		height: '1px',
		overflow: 'hidden',
		zIndex: -999,

		'&:focus': {
			backgroundColor: common.white,
			color: common.black,
			border: '2px solid #1F4FAE',
			boxSizing: 'border-box',
			borderRadius: theme.shape.borderRadius,
			left: '386px',
			top: '26px',
			width: 'auto',
			height: 'auto',
			overflow: 'auto',
			fontStyle: 'normal',
			letterSpacing: '1.25px',
			textDecoration: 'none',
			padding: '2px 4px',
			textTransform: 'uppercase',
			fontWeight: '500',
			fontSize: '0.875rem',
			lineHeight: '1rem',
			zIndex: 999,
		}
	},
	skipFilters: {
		top: '4px!important',
	},
	skipContent: {
		top: '26px!important',
	},
	skipActions: {
		top: '52px!important',
	},
	skipLink: {
		zIndex: 555,
	},
}));

export const SkipMenu: FunctionComponent = () => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const viewMode = useSelector(selectViewMode);

	if (pathname === `${Routes.Search}`) {
		return <div className={classes.skipLink}>
			<a className={`${classes.skipMain} ${classes.skipFilters}`} href="#facets-list-panel">{'Skip to filters'}</a>
			<a
				className={`${classes.skipMain} ${classes.skipContent}`}
				href={viewMode === SearchViewMode.Card ? '#first-facet-card' : '#search-details-table'}
			>
				{'Skip to main content'}
			</a>
			<a className={`${classes.skipMain} ${classes.skipActions}`} href="#download-metadata-btn">{'Skip to actions'}</a>
		</div>;
	}

	return <a className={classes.skipMain} href="#static-page-frame">{'Skip to main content'}</a>;
};
