// Theme
export const PRIMARY_MAIN_COLOR = '#1f4fae';
export const PRIMARY_LIGHT_COLOR = '#5e7ae1';
export const PRIMARY_DARK_COLOR = '#0C2A6A';
export const PRIMARY_CONTRAST_TEXT = '#ffffff';
export const SECONDARY_MAIN_COLOR = '#88d79e';
export const SECONDARY_LIGHT_COLOR = '#91f9aa';
export const SECONDARY_DARK_COLOR = '#26944d';
export const SECONDARY_CONTRAST_TEXT = '#000000';
export const BACKGROUND_DEFAULT_COLOR = '#ffffff';
export const BACKGROUND_PAPER_COLOR = '#ffffff';
export const ERROR_MAIN_COLOR = '#B00020';
export const ERROR_CONTRAST_TEXT = '#ffffff';
export const OUTLINED_BUTTON_HOVER_BACKGROUND = '#e4eaf5';
export const PAGE_BACKGROUND_COLOR = '#f9f9f9';
export const SCROLLBAR_THUMB_COLOR = '#E0E0E0';

// Layout
export const HEADER_HEIGHT_SPACING = 10;
export const FOOTER_HEIGHT_SPACING = 7.5;

// Histogram
export const SELECTED_BAR_COLOR = '#56C174';
export const UNSELECTED_BAR_COLOR = '#e0e0e0';
export const FOCUSED_BAR_BORDER_COLOR = '#000000';
export const BAR_HOVER_COLOR = '#f5f5f5';
export const BAR_TEXT_COLOR = '#333333';

export const DEFAULT_BAR_HEIGHT = 24;
export const DEFAULT_BAR_PADDING = 3;
export const FOCUSED_BAR_BORDER_WIDTH = 2;
export const HIGHCHARTS_FOOTER_HEIGHT = 45;

// Facet
export const DEFAULT_FACET_WIDTH = 350;
export const DEFAULT_FACET_HEIGHT = 400;

// Selected Values Panel
export const CHIP_HEIGHT_PX = 32;
export const CHIP_MAX_WIDTH_PX = 300;
export const CHIP_VERTICAL_MARGIN_PX = 3;
export const CHIP_HORIZONTAL_MARGIN_PX = 5;

// Facets List Panel
export const DRAWER_WIDTH = 396;
export const DRAWER_VALUE_WIDTH = 230;
export const MAX_VALUES_ON_COLLAPSED = 5;
export const FACETS_PANEL_TEXT_COLOR = '#333333';

// Tooltip
export const TOOLTIP_BACKGROUND_COLOR = '#232F34';
export const TOOLTIP_FONT_COLOR = '#ffffff';

// Global Css Classes
export const FACET_CARD_DRAG_ICON_GLOBAL_CLASS = 'facetCardDragIcon';
export const FACET_CARD_HIGHCHARTS_X_AXIS_CROSSHAIR_CLASS = 'facetCardHighchartsXAxisCrosshair';
