import { createSlice } from '@reduxjs/toolkit';
import { SagaEndPayloadAction, SagaEndState } from 'types';
import { ACTIONS_PREFIX } from '../constants';
import { ColumnChartData, ColumnChartWithDrilldownData,
	GetTotalAmountOfDownloadedDataResponse, GetTotalNumberOfNewUsersResponse, GetTotalNumberOfUsersResponse, GetMetadataDownloadsResponse, PyramidChartData, GetStepsToDownloadResponse, GetOmicsYearlyDownloadsDataResponse } from '../types';

interface StatisticsState {
	totalNumberOfUsers: ColumnChartData | null
	totalNumberOfNewUsers: ColumnChartData | null
	totalAmountOfDownloadedData: ColumnChartWithDrilldownData | null
	metadataDownloads: ColumnChartData | null
	stepsToDownload: PyramidChartData | null
	omicsYearlyDownloadsData: ColumnChartWithDrilldownData | null
	isGetMetadataDownloadsLoading: boolean
	isGetTotalNumberOfUsersLoading: boolean
	isGetTotalNumberOfNewUsersLoading: boolean
	isGetTotalAmountOfDownloadedDataLoading: boolean
	isGetStepsToDownloadLoading: boolean
	isGetOmicsYearlyDownloadsDataLoading: boolean
}

const initialState: StatisticsState = {
	totalNumberOfUsers: null,
	totalNumberOfNewUsers: null,
	totalAmountOfDownloadedData: null,
	metadataDownloads: null,
	stepsToDownload: null,
	omicsYearlyDownloadsData: null,
	isGetTotalNumberOfUsersLoading: false,
	isGetTotalNumberOfNewUsersLoading: false,
	isGetTotalAmountOfDownloadedDataLoading: false,
	isGetMetadataDownloadsLoading: false,
	isGetStepsToDownloadLoading: false,
	isGetOmicsYearlyDownloadsDataLoading: false
};

export const statisticsSlice = createSlice({
	name: ACTIONS_PREFIX,
	initialState,
	reducers: {
		getTotalNumberOfUsers: () => undefined,
		getTotalNumberOfUsersStart: (state: StatisticsState) => {
			state.isGetTotalNumberOfUsersLoading = true;
		},
		getTotalNumberOfUsersEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetTotalNumberOfUsersResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.totalNumberOfUsers = payload.data;
			}
			state.isGetTotalNumberOfUsersLoading = false;
		},
		getTotalNumberOfNewUsers: () => undefined,
		getTotalNumberOfNewUsersStart: (state: StatisticsState) => {
			state.isGetTotalNumberOfNewUsersLoading = true;
		},
		getTotalNumberOfNewUsersEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetTotalNumberOfNewUsersResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.totalNumberOfNewUsers = payload.data;
			}
			state.isGetTotalNumberOfNewUsersLoading = false;
		},
		getTotalAmountOfDownloadedData: () => undefined,
		getTotalAmountOfDownloadedDataStart: (state: StatisticsState) => {
			state.isGetTotalAmountOfDownloadedDataLoading = true;
		},
		getTotalAmountOfDownloadedDataEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetTotalAmountOfDownloadedDataResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.totalAmountOfDownloadedData = payload.data;
			}
			state.isGetTotalAmountOfDownloadedDataLoading = false;
		},
		getMetadataDownloads: () => undefined,
		getMetadataDownloadsStart: (state: StatisticsState) => {
			state.isGetMetadataDownloadsLoading = true;
		},
		getMetadataDownloadsEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetMetadataDownloadsResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.metadataDownloads = payload.data;
			}
			state.isGetMetadataDownloadsLoading = false;
		},
		getStepsToDownload: () => undefined,
		getStepsToDownloadStart: (state: StatisticsState) => {
			state.isGetStepsToDownloadLoading = true;
		},
		getStepsToDownloadEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetStepsToDownloadResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.stepsToDownload = payload.data;
			}
			state.isGetStepsToDownloadLoading = false;
		},
		getOmicsYearlyDownloadsData: () => undefined,
		getOmicsYearlyDownloadsDataStart: (state: StatisticsState) => {
			state.isGetOmicsYearlyDownloadsDataLoading = true;
		},
		getOmicsYearlyDownloadsDataEnd: (state: StatisticsState, { payload }: SagaEndPayloadAction<GetOmicsYearlyDownloadsDataResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.omicsYearlyDownloadsData = payload.data;
			}
			state.isGetOmicsYearlyDownloadsDataLoading = false;
		},
	},
});
