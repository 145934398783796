import React, { FunctionComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { makeStyles } from '@material-ui/core';
import drilldown from 'highcharts/modules/drilldown';
import { ColumnChartWithDrilldownDataShort, ColumnChartWithDrilldownPlotting } from '../types';

drilldown(Highcharts);

const useStyles = makeStyles(() => ({
	charts: {
		'& > div': {
			overflow: 'hidden',
			height: '100%',
			width: '100%'
		},
	}
}));

interface ColumnChartWithDrilldownProps {
	data: ColumnChartWithDrilldownPlotting | null | undefined
	title: string
	colors: string[]
	period: keyof ColumnChartWithDrilldownDataShort
}

export const ColumnChartWithDrilldown: FunctionComponent<ColumnChartWithDrilldownProps> = ({
	data,
	title,
	colors,
	period
}) => {
	const classes = useStyles();

	if (!data) {
		return null;
	}

	const correctPeriod = period[0].toLocaleUpperCase() + period.slice(1, period.length - 1);

	const options = {
		colors,
		chart: {
			type: 'column'
		},
		title: {
			text: title
		},
		accessibility: {
			announceNewData: {
				enabled: true
			}
		},
		xAxis: {
			type: 'category'
		},
		yAxis: {
			title: {
				text: 'GB'
			}

		},
		legend: {
			enabled: false
		},
		plotOptions: {
			series: {
				borderWidth: 0,
				dataLabels: {
					enabled: true,
					format: '{point.y}GB'
				},
			},
			column: {
				colorByPoint: true
			}
		},
		tooltip: {
			outside: true,
			useHTML: true,
			backgroundColor: 'rgba(246, 246, 246, 1)',
			borderRadius: 30,
			borderColor: '#bbbbbb',
			borderWidth: 1.5,
			style: { opacity: 1, background: 'rgba(246, 246, 246, 1)' },
			headerFormat: '<span style="font-size:10px">{series.name}</span><br>',
			pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}GB</b> of total<br><span style="color:{point.color}">{point.email}</span>'
		},
		series: [
			{
				name: correctPeriod,
				colorByPoint: true,
				data: data.dataDrilldown
			}
		],
		drilldown: {
			drillUpButton: {
				position: {
					x: -2,
					y: -18,
				}
			},
			series: data.series
		},
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG'
					]
				}
			}
		}
	};

	return (
		<div className={classes.charts}>
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={'chart'}
				options={options}
				containerProps={{ style: { height: '400px', width: '520px' } }}
			/>
		</div>
	);
};
