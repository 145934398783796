import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { statisticsSlice } from '../slices';
import { selectOmicsYearlyDownloadsData } from '../selectors';
import { getOmicsYearlyDownloads } from '../api';
import { GetOmicsYearlyDownloadsDataResponse } from '../types';

export function* getOmicsYearlyDownloadsSaga(): SagaIterator {
	try {
		yield put(statisticsSlice.actions.getOmicsYearlyDownloadsDataStart());

		const omicsYearlyDownloadsData = (yield select(selectOmicsYearlyDownloadsData)) as ReturnType<typeof selectOmicsYearlyDownloadsData>;

		if (omicsYearlyDownloadsData === null) {
			const response = (yield call(getOmicsYearlyDownloads)) as AxiosResponse<GetOmicsYearlyDownloadsDataResponse>;
			yield put(statisticsSlice.actions.getOmicsYearlyDownloadsDataEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(statisticsSlice.actions.getOmicsYearlyDownloadsDataEnd({ endState: SagaEndState.Success, data: omicsYearlyDownloadsData }));
		}
	} catch (e) {
		yield put(statisticsSlice.actions.getOmicsYearlyDownloadsDataEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
