/* eslint-disable promise/prefer-await-to-callbacks */
import { EntitiesExpandState, EntityExpandState } from '../types';

export const traverseFacetsPanelEntitiesExpandInfo = (entitiesExpandState: EntitiesExpandState, callback: (entity: EntityExpandState) => void): void => {
	for (const entity of Object.values(entitiesExpandState)) {
		if (entity.childs && Object.keys(entity.childs).length > 0) {
			traverseFacetsPanelEntitiesExpandInfo(entity.childs, callback);
		}
		callback(entity);
	}
};
