import { call, put, select, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { downloadBlob } from '../../../../../utils';

import { handleErrorSaga } from '../../../../errorHandling/sagas';

import { omicL4VisualizationSlice } from '../slices';
import { downloadHeatmapData } from '../api';
import { selectGenes, selectParticipants } from '../selectors';

export function* downloadHeatmapDataSaga(): Generator<StrictEffect, void> {
	try {
		const participants = (yield select(selectParticipants)) as string[];
		const genes = (yield select(selectGenes)) as string[];

		const { data } = (yield call(downloadHeatmapData, {
			participants,
			genes
		})) as AxiosResponse<Blob>;

		yield call(downloadBlob, 'transcriptomics.zip', data);

		yield put(omicL4VisualizationSlice.actions.downloadHeatmapDataSuccess());
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(omicL4VisualizationSlice.actions.downloadHeatmapDataFail());
	}
}
