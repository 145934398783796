import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { DownloadReadFileListRequest, DownloadReadFileListResponse } from '../types';

export function* downloadReadFileList(params: DownloadReadFileListRequest): SagaIterator<AxiosResponse<DownloadReadFileListResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob'
	};

	return (yield call(networkManager.fetch, Urls.DownloadReadFileList, requestOptions, params)) as AxiosResponse<DownloadReadFileListResponse>;
}
