import React, { ChangeEvent, useState, useEffect, useCallback, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { createStyles, Theme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';

import { PluginsRegistry } from 'ext-plugins-library-web';

import { portalAuthManager } from '../../../auth/portalAuthManager';
import { selectIsUserAuthorized, selectUserDataAccessStatus } from '../../../auth/selectors';

import { selectReleaseName, selectSearchId } from '../../search';

import { tabsSlice, backToDataSearchAction } from '../slices';
import { selectProceedSearchResultTabs, selectProceedSearchResultPageHeader, selectIsLoadingProceedContent } from '../selectors';

interface ProceedSearchResultMatchParams {
	releaseName: string
	searchId: string
	activeTab: string
}

type ProceedSearchResultProps = RouteComponentProps<ProceedSearchResultMatchParams>;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			padding: theme.spacing(3)
		},
		backButton: {
			color: theme.palette.primary.main,
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			marginBottom: theme.spacing(3)
		},
		title: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			marginBottom: theme.spacing(4)
		},
		tabsFlexContainer: {
			borderBottom: '2px solid',
			borderBottomColor: grey['300']
		},
		tabRoot: {
			color: grey['700'],
			maxWidth: '140px',
		},
		tabWrapper: {
			alignItems: 'start',
			display: 'block',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
		},
	})
);

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProceedSearchResult: FunctionComponent<ProceedSearchResultProps> = ({ match }: ProceedSearchResultProps) => {
	const tabs = useSelector(selectProceedSearchResultTabs);
	const pageHeader = useSelector(selectProceedSearchResultPageHeader);
	const isLoadingProceedContent = useSelector(selectIsLoadingProceedContent);

	const savedSearchId = useSelector(selectSearchId);
	const savedRelease = useSelector(selectReleaseName);
	const isUserAuthorized = useSelector(selectIsUserAuthorized);
	const userDataAccess = useSelector(selectUserDataAccessStatus);

	const classes = useStyles();
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [release, setRelease] = useState('');
	const [search, setSearch] = useState('');
	const [userToken, setUserToken] = useState('');
	const [isLoadingUserToken, setIsLoadingUserToken] = useState(true);

	useEffect(() => {
		void (async () => {
			const tokenInfo = await portalAuthManager.getTokenInfo();
			if (tokenInfo !== null) {
				setUserToken(tokenInfo.accessToken);
			}
			setIsLoadingUserToken(false);
		})();
	}, [isUserAuthorized]);

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(tabsSlice.actions.getProceedContentRequest());
	}, [dispatch]);

	useEffect(() => {
		const { releaseName, searchId, activeTab } = match.params;
		if (activeTab) {
			const tabIndex = tabs.findIndex(tab => tab.displayName === activeTab);
			if (tabIndex !== -1) {
				setActiveTabIndex(tabIndex);
			}
		}
		setSearch(searchId);
		setRelease(releaseName);
	}, [userDataAccess, match, tabs]);

	// eslint-disable-next-line @typescript-eslint/ban-types
	const handleTabChange = useCallback((changeEvent: ChangeEvent<{}>, newActiveTabIndex: number) => {
		setActiveTabIndex(newActiveTabIndex);
	}, []);

	const backToDataSearch = useCallback(() => {
		dispatch(backToDataSearchAction());
	}, [dispatch]);

	return (
		<>
			<Box visibility={isLoadingProceedContent ? 'visible' : 'hidden'} position="sticky" top={0} zIndex={zIndex.appBar}>
				<LinearProgress />
			</Box>
			<Container className={classes.container}>
				{ !isLoadingProceedContent &&
				<>
					<Button
						disabled={ savedRelease !== release || savedSearchId !== search }
						classes={{ root: classes.backButton }}
						onClick={ backToDataSearch }
						variant='outlined'
						startIcon={<KeyboardArrowLeftIcon />}>
						{'Back to Data Search'}
					</Button>
					<Typography component='h1' variant='h4' classes={{ root: classes.title }}>
						{ pageHeader }
					</Typography>
					<Tabs
						classes={{ flexContainer: classes.tabsFlexContainer }}
						value={activeTabIndex}
						onChange={handleTabChange}
						indicatorColor='primary'
						textColor='primary'
						variant='scrollable'
						scrollButtons='auto'
						aria-label='scrollable tabs'
					>
						{ tabs.map(tab =>
							<Tab
								classes={{ root: classes.tabRoot, wrapper: classes.tabWrapper, }}
								key={tab.name}
								label={tab.displayName}
							/>
						) }
					</Tabs>
					{ !isLoadingUserToken &&
						tabs.map((tab, index) => {
							const isActiveTab = activeTabIndex === index;
							return (
								<Box
									key={tab.name}
									role='tabpanel'
									hidden={!isActiveTab}
									pt={1.5}
								>
									{ tab.externalPlugins.map(plugin => {
										const Component = PluginsRegistry[plugin.name];
										if (Component && release && search) {
											return (
												<Box key={plugin.name} paddingY={1.5}>
													<Component searchId={search} userToken={userToken} releaseName={release} />
												</Box>
											);
										}
									})
									}
								</Box>
							);
						})
					}
				</>
				}
			</Container>
		</>
	);
};
