import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { releaseDataManagementSlice } from '../slices';
import { ExportOmicsRequest, ExportOmicsResponse } from '../types';
import { PayloadAction } from '@reduxjs/toolkit';
import { exportOmics } from '../api/exportOmics';
import { downloadBlob } from 'utils';

export function* exportOmicsSaga({ payload }: PayloadAction<ExportOmicsRequest>): SagaIterator {
	try {
		yield put(releaseDataManagementSlice.actions.exportOmicsStart());

		const { data } = (yield call(exportOmics, payload)) as AxiosResponse<ExportOmicsResponse>;

		yield call(downloadBlob,'omics_release_ready.csv', data);

		yield put(releaseDataManagementSlice.actions.exportOmicsEnd());
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(releaseDataManagementSlice.actions.exportOmicsEnd());
	}
}
