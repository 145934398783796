import { call, put, all } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosError, AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { wpFrameSlice } from '../slices';
import { getPagesConfig, getCategoriesConfig } from '../api';
import { WPPageConfig, WPCategoryConfig } from '../types';
import { WP_CONFIG_PER_PAGE } from '../constants';
import { ErrorResponse } from 'features/errorHandling/types';

function generatePaginationCalls(requestsCount: number, api: (perPage: number, page: number) => Promise<AxiosResponse>) {
	return Array(requestsCount - 1)
		.fill(null)
		.map((_, index) => call(api, WP_CONFIG_PER_PAGE, 2 + index));
}

export function* getWPConfigSaga(): SagaIterator {
	try {
		const pagesConfig: WPPageConfig[] = [];
		const categoriesConfig: WPCategoryConfig[] = [];

		const [initialCategoriesResponse, initialPagesResponse] = (yield all([
			call(getCategoriesConfig, WP_CONFIG_PER_PAGE, 1),
			call(getPagesConfig, WP_CONFIG_PER_PAGE, 1)
		])) as [AxiosResponse<WPCategoryConfig[]>, AxiosResponse<WPPageConfig[]>];

		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		const totalCategoriesCount = Number(initialCategoriesResponse.headers['x-wp-totalpages']);
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		const totalPagesCount = Number(initialPagesResponse.headers['x-wp-totalpages']);

		const [categoryResponses, pageResponses] = (yield all([
			all(generatePaginationCalls(totalCategoriesCount, getCategoriesConfig)),
			all(generatePaginationCalls(totalPagesCount, getPagesConfig))
		])) as [AxiosResponse<WPCategoryConfig[]>[], AxiosResponse<WPPageConfig[]>[]];

		categoriesConfig.push(...initialCategoriesResponse.data, ...categoryResponses.map(response => response.data).flat());
		pagesConfig.push(...initialPagesResponse.data, ...pageResponses.map(response => response.data).flat());

		yield put(wpFrameSlice.actions.getConfigSuccess({
			pagesConfig,
			categoriesConfig
		}));
	} catch (e) {
		yield put(wpFrameSlice.actions.getConfigFail());
		yield call(handleErrorSaga, e as AxiosError<ErrorResponse>);
	}
}
