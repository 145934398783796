import React, { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { uniqueId } from 'lodash';

import {
	Box,
	BoxProps,
	Button,
	ButtonProps as MuiButtonProps,
	CircularProgress,
	CircularProgressProps
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface LoaderButtonProps {
	isLoading: boolean
	ariaProgressText?: string
	id?: string
	wrapperBoxProps?: BoxProps
	ButtonProps?: MuiButtonProps
	circularProgressProps?: CircularProgressProps
	preventClickOnLoading?: boolean
	children: ReactElement | string
}

const DISABLED_COLOR = 'rgba(0,0,0,0.26)';
const DISABLED_BACKGROUND_COLOR = 'rgba(0,0,0,0.12)';
const DISABLED_BORDER_COLOR = 'rgba(0,0,0,0.12)';

const useStyles = makeStyles(() => ({
	disabledButton: {
		cursor: 'default',
		color: DISABLED_COLOR,
		backgroundColor: DISABLED_BACKGROUND_COLOR,
		borderColor: DISABLED_BORDER_COLOR,
		boxShadow: 'none',
		'&:hover': {
			color: DISABLED_COLOR,
			backgroundColor: DISABLED_BACKGROUND_COLOR,
			borderColor: DISABLED_BORDER_COLOR,
			boxShadow: 'none'
		}
	}
}));

export const LoaderButton: FunctionComponent<LoaderButtonProps> = ({
	isLoading,
	ariaProgressText = '',
	id = '',
	wrapperBoxProps = {},
	ButtonProps = {},
	circularProgressProps = {},
	preventClickOnLoading = true,
	children
}: LoaderButtonProps) => {
	const classes = useStyles();

	const { onClick: onClickProp, ...parsedButtonProps } = ButtonProps;
	const { size = 24 } = circularProgressProps;

	const loaderOffset = typeof size === 'number' ? `${-size / 2}px` : size;

	const [progressTextId] = useState(() => uniqueId('loader-button-progress-text-'));
	const [progressTextContainer, setProgressTextContainer] = useState<HTMLElement | null>(null);

	const onClick = useCallback(e => {
		if (preventClickOnLoading && isLoading) {
			return;
		}
		if (typeof onClickProp === 'function') {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			onClickProp(e);
		}
	}, [onClickProp, preventClickOnLoading, isLoading]);

	useEffect(() => {
		const divEl = document.createElement('div');
		divEl.setAttribute('id', progressTextId);
		divEl.setAttribute('aria-live', 'polite');
		divEl.style.visibility = 'hidden';
		divEl.style.position = 'absolute';
		document.body.appendChild(divEl);

		setProgressTextContainer(divEl);

		return () => {
			divEl.remove();
		};
	}, [progressTextId]);

	useEffect(() => {
		if (progressTextContainer) {
			progressTextContainer.innerText = isLoading ? ariaProgressText : '';
		}
	}, [progressTextContainer, isLoading, ariaProgressText]);

	return (
		<Box position="relative" {...wrapperBoxProps}>
			<Button
				id={id}
				aria-describedby={progressTextId}
				aria-controls={progressTextId}
				disableRipple={isLoading}
				className={isLoading ? classes.disabledButton : ''}
				onClick={onClick}
				data-testid="button"
				{ ...parsedButtonProps }
			>
				{ children }
			</Button>
			{ isLoading &&
				<Box
					position="absolute"
					top="50%"
					left="50%"
					mt={loaderOffset}
					ml={loaderOffset}
				>
					<CircularProgress {...circularProgressProps}/>
				</Box>
			}
		</Box>
	);
};
