/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-shadow */
import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { AxiosResponse } from 'axios';
import { SagaEndState } from 'types';

import { deleteCurrentIndex, getIndexList } from '../api';
import { indexManagementSlice } from '../slices';
import { GetIndexListResponse } from '../types';

export function* deleteCurrentIndexSaga({ payload }: any): SagaIterator {
	try {
		const responseDelete = (yield call(deleteCurrentIndex, payload)) as AxiosResponse;
		if (responseDelete) {
			yield put(indexManagementSlice.actions.getIndexListStart());
			const response = (yield call(getIndexList)) as AxiosResponse<GetIndexListResponse>;

			yield put(indexManagementSlice.actions.getIndexListEnd({ endState: SagaEndState.Success, data: response.data }));
		}
	} catch (e) {
		yield call(handleErrorSaga, e);
	}
}
