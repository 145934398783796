import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { addiDataRequestDetailsSlice } from '../slices';
import { GetAddiDataDetailsResponse } from '../types';
import { getAddiDataRequestDetails } from '../api';

export function* getAddiDataRequestDetailsSaga(id: string): SagaIterator {
	try {
		yield put(addiDataRequestDetailsSlice.actions.getAddiDataRequestStart());

		const response = (yield call(getAddiDataRequestDetails, { id })) as AxiosResponse<GetAddiDataDetailsResponse>;

		yield put(addiDataRequestDetailsSlice.actions.getAddiDataRequestEnd({
			endState: SagaEndState.Success,
			data: {
				addiDataRequest: response.data
			}
		}));
	} catch (e) {
		if ((e as AxiosError).response?.status === StatusCodes.NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'ADDI data request not found' }));
		} else {
			yield call(handleErrorSaga, e);
		}
		yield put(addiDataRequestDetailsSlice.actions.getAddiDataRequestEnd({ endState: SagaEndState.Fail }));
	}
}
