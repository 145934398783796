import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ACTIONS_PREFIX } from '../constants';
import { ExportOmicsRequest, OmicsReleaseReady, ReleaseOperations, StartReleaseProcessRequest } from '../types';
import { SagaEndPayloadAction, SagaEndState } from 'types';

interface ReleaseDataManagementUserState {
	omicsReleaseReady: OmicsReleaseReady
	releaseOperations: ReleaseOperations | null
	isReleaseOperationsLoading: boolean
	isOmicsReleaseReadyLoading: boolean
	isStartReleaseProcessLoading: boolean
	isExportOmicsLoading: boolean
}

const initialState: ReleaseDataManagementUserState = {
	omicsReleaseReady: [],
	releaseOperations: null,
	isReleaseOperationsLoading: false,
	isOmicsReleaseReadyLoading: false,
	isStartReleaseProcessLoading: false,
	isExportOmicsLoading: false
};

export const releaseDataManagementSlice = createSlice({
	name: `${ACTIONS_PREFIX}`,
	initialState,
	reducers: {
		getReleaseOperations: () => undefined,
		getReleaseOperationsStart: (state: ReleaseDataManagementUserState) => {
			state.isReleaseOperationsLoading = true;
		},
		getReleaseOperationsEnd: (state: ReleaseDataManagementUserState, { payload }: SagaEndPayloadAction<ReleaseOperations>) => {
			if (payload.endState === SagaEndState.Success) {
				state.releaseOperations = payload.data;
			}
			state.isReleaseOperationsLoading = false;
		},
		getOmicsReleaseReady: () => undefined,
		getOmicsReleaseReadyStart: (state: ReleaseDataManagementUserState) => {
			state.isOmicsReleaseReadyLoading = true;
		},
		getOmicsReleaseReadyEnd: (state: ReleaseDataManagementUserState, { payload }: SagaEndPayloadAction<OmicsReleaseReady>) => {
			if (payload.endState === SagaEndState.Success) {
				state.omicsReleaseReady = payload.data;
			}
			state.isOmicsReleaseReadyLoading = false;
		},
		startReleaseProcess: (_state, _action: PayloadAction<StartReleaseProcessRequest>) => undefined,
		startReleaseProcessStart: (state: ReleaseDataManagementUserState) => {
			state.isStartReleaseProcessLoading = true;
		},
		startReleaseProcessEnd: (state: ReleaseDataManagementUserState) => {
			state.isStartReleaseProcessLoading = false;
		},
		exportOmics: (_state, _action: PayloadAction<ExportOmicsRequest>) => undefined,
		exportOmicsStart: (state: ReleaseDataManagementUserState) => {
			state.isExportOmicsLoading = true;
		},
		exportOmicsEnd: (state: ReleaseDataManagementUserState) => {
			state.isExportOmicsLoading = false;
		},
	},
});
