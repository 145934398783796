import { cloneDeep } from 'lodash';

import { DetailsFacetsConfig, FacetRangeType, Facets } from '../types';

export const getSortedFacetsValues = (facetsValues: Facets, detailsFacetsConfig: DetailsFacetsConfig): Facets => {
	const sortedFacetsValues = cloneDeep(facetsValues);

	Object.entries(detailsFacetsConfig).forEach(([facetName, facetDetails]) => {
		if (!sortedFacetsValues[facetName]) {
			return;
		}

		switch (facetDetails.rangeType) {
			case FacetRangeType.NotApplicable: {
				sortedFacetsValues[facetName].sort((a, b) => b.count - a.count);
				break;
			}
			case FacetRangeType.Values: {
				sortedFacetsValues[facetName].sort((a, b) => {
					const normalizedA = { from: a.from ?? -Infinity, to: a.to ?? Infinity };
					const normalizedB = { from: b.from ?? -Infinity, to: b.to ?? Infinity };

					const weightA = normalizedA.from + normalizedA.to;
					const weightB = normalizedB.from + normalizedB.to;

					return weightB - weightA;
				});
				break;
			}
			case FacetRangeType.Interval: {
				sortedFacetsValues[facetName].sort((a, b) => Number(b.value) - Number(a.value));
				break;
			}
		}
	});

	return sortedFacetsValues;
};
