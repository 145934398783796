import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { uniqueId } from 'lodash';

import { handleErrorSaga } from '../../../../errorHandling/sagas';
import { alertSlice } from '../../../../alert/slices';
import { i18n } from '../../../../../locale';

import { CaseSetEntity, SubmitCaseSetEntity } from '../types';
import { selectCaseSetEntities } from '../selectors';
import { caseSetSlice } from '../slices';
import { getUniqueName, normalizeCaseSetIdentifiers } from '../utils';

import { searchRequestWithCaseSetSaga } from './searchRequestWithCaseSetSaga';

export function* submitModalRequestSaga({ payload }: PayloadAction<SubmitCaseSetEntity>): SagaIterator {
	try {
		const caseSetEntities = (yield select(selectCaseSetEntities)) as ReturnType<typeof selectCaseSetEntities>;

		const caseSet = (yield call(searchRequestWithCaseSetSaga, {
			id: payload.id === null ?
				(yield call(uniqueId)) as ReturnType<typeof uniqueId> :
				payload.id,
			title: getUniqueName(
				payload.title.trim(),
				caseSetEntities
					.filter(entity => entity.id !== payload.id)
					.map(entity => entity.title)
			),
			identifierType: payload.identifierType,
			values: normalizeCaseSetIdentifiers(payload.values),
			notFoundIdentifiers: []
		})) as CaseSetEntity;

		if (payload.id !== null) {
			yield put(caseSetSlice.actions.updateCaseSet(caseSet));
		} else {
			yield put(caseSetSlice.actions.addCaseSet(caseSet));
		}
		yield put(caseSetSlice.actions.silentSelectCaseSet({ id: caseSet.id }));

		if (caseSet.notFoundIdentifiers.length !== 0) {
			yield put(caseSetSlice.actions.setValidationError({ caseSetErrorId: caseSet.id }));
		} else {
			yield put(alertSlice.actions.showAlert({ message: i18n.t('caseSet.submit.successAlert') }));
		}

		yield put(caseSetSlice.actions.showCaseSetsPanel());

		yield put(caseSetSlice.actions.submitModalSuccess());
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(caseSetSlice.actions.submitModalFail());
	}
}
