import React, { FunctionComponent } from 'react';
import { MarkupFormFields, UiFormFields } from '../types';
import { FormikProps } from 'formik';
import { Box, TextField, Typography } from '@material-ui/core';

export interface BusinessOfficialFieldsProps {
	markupConfiguration: MarkupFormFields
	form: FormikProps<UiFormFields>
}

export const BusinessOfficialFields: FunctionComponent<BusinessOfficialFieldsProps> = ({
	markupConfiguration,
	form
}) => {
	return <>
		<Typography component="h3" variant="subtitle1" style={{ fontSize: '1.5rem' }}>
			{markupConfiguration.businessOfficial.label}
		</Typography>
		<Box pb={2} />
		<Typography component="p" variant="body1">
			{markupConfiguration.businessOfficial.helperText}
		</Typography>
		<Box pb={2} />
		<Box maxWidth={'480px'} >
			<Box pb={4.5}>
				<TextField
					name={'businessOfficialName'}
					id={'request-access-form-field-businessOfficialName'}
					label={markupConfiguration.businessOfficialName.label}
					variant="outlined"
					fullWidth
					value={form.values.businessOfficialName}
					onChange={form.handleChange}
					helperText={form.touched.businessOfficialName && form.errors.businessOfficialName || markupConfiguration.businessOfficialName.helperText}
					error={form.touched.businessOfficialName && Boolean(form.errors.businessOfficialName)}
				/>
			</Box>
			<Box pb={4.5}>
				<TextField
					name={'businessOfficialTitle'}
					id={'request-access-form-field-businessOfficialTitle'}
					label={markupConfiguration.businessOfficialTitle.label}
					variant="outlined"
					fullWidth
					value={form.values.businessOfficialTitle}
					onChange={form.handleChange}
					helperText={form.touched.businessOfficialTitle && form.errors.businessOfficialTitle || markupConfiguration.businessOfficialTitle.helperText}
					error={form.touched.businessOfficialTitle && Boolean(form.errors.businessOfficialTitle)}
				/>
			</Box>
			<Box pb={4.5}>
				<TextField
					name={'businessOfficialEmail'}
					id={'request-access-form-field-businessOfficialEmail'}
					label={markupConfiguration.businessOfficialEmail.label}
					variant="outlined"
					fullWidth
					value={form.values.businessOfficialEmail}
					onChange={form.handleChange}
					helperText={form.touched.businessOfficialEmail && form.errors.businessOfficialEmail || markupConfiguration.businessOfficialEmail.helperText}
					error={form.touched.businessOfficialEmail && Boolean(form.errors.businessOfficialEmail)}
				/>
			</Box>
		</Box>
	</>;
};
