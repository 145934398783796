import * as yup from 'yup';
import {
	FIELD_ERROR_INVALID_VALUE,
	MAX_THRESHOLD_IN_GB,
	MAX_USER_NOTES_LENGTH
} from '../constants';
import { User, UserEditFormFields } from '../types';
import { Configuration } from 'features/requestAccess/types';

const PRINCIPAL_INVESTIGATOR = 'Principal Investigator';

export const getUserFormValidationSchema = (user: User | null, pageConfiguration: Configuration) =>
	yup.object().shape({
		threshold: yup.string()
			.matches(/^-?\d+$/, FIELD_ERROR_INVALID_VALUE)
			.test(
				'minValue',
				'The min threshold value is 0 GB',
				input => input === undefined || Number(input) >= 0
			)
			.test(
				'maxValue',
				`The max threshold value is ${MAX_THRESHOLD_IN_GB} GB`,
				input => input === undefined || Number(input) <= MAX_THRESHOLD_IN_GB
			),
		userName: yup.string().required('Username is required'),
		...user?.status !== null ? {
			notes: yup.string()
				.trim()
				.max(MAX_USER_NOTES_LENGTH, `Max notes length is ${MAX_USER_NOTES_LENGTH} characters.`),
			firstName: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.firstName.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			lastName: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.lastName.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			credentials: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.credentials.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			workingEmail: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.workingEmail.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField)
				.email(pageConfiguration.markup.errors.invalidEmailFormat),
			roleOnProject: yup.string()
				.trim()
				.required(pageConfiguration.markup.errors.emptyField)
				.test(
					'isPiSelected',
					pageConfiguration.markup.errors.invalidPiSelected,
					(value, context) => {
						const formFields = context.parent as UserEditFormFields;
						const roleOnProjectValues = [value, ...formFields.collaborators.map(m => m.roleOnProject)];

						return roleOnProjectValues.filter(m => m === PRINCIPAL_INVESTIGATOR).length === 1;
					}
				),
			institution: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.institution.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			country: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.country.maxLength, pageConfiguration.markup.errors.maxLengthError),
			businessOfficialName: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.businessOfficialName.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			businessOfficialEmail: yup.string()
				.max(pageConfiguration.markup.formBlock.formFields.businessOfficialEmail.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField)
				.email(pageConfiguration.markup.errors.invalidEmailFormat),
			dataUseProposal: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.dataUseProposal.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			projectTimeframe: yup.string()
				.trim()
				.max(pageConfiguration.markup.formBlock.formFields.projectTimeframe.maxLength, pageConfiguration.markup.errors.maxLengthError)
				.required(pageConfiguration.markup.errors.emptyField),
			collaborators: yup.array().of(
				yup.object().shape({
					name: yup.string()
						.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
						.required(pageConfiguration.markup.errors.emptyField),
					title: yup.string()
						.trim()
						.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
						.required(pageConfiguration.markup.errors.emptyField),
					roleOnProject: yup.string()
						.trim()
						.required(pageConfiguration.markup.errors.emptyField)
						.test(
							'isCollaboratorPiSelected',
							pageConfiguration.markup.errors.invalidPiSelected,
							(value, context) => {
								if (context.from) {
									const formFields = context.from[1].value as UserEditFormFields;
									const roleOnProjectValues = [formFields.roleOnProject, ...formFields.collaborators.map(m => m.roleOnProject)];

									return roleOnProjectValues.filter(m => m === PRINCIPAL_INVESTIGATOR).length === 1;
								}
							}
						),
					email: yup.string()
						.trim()
						.max(pageConfiguration.markup.formBlock.formFields.collaborators.maxLength, pageConfiguration.markup.errors.maxLengthError)
						.required(pageConfiguration.markup.errors.emptyField)
						.email(pageConfiguration.markup.errors.invalidEmailFormat)
						.test('isCollabEmailNotRequestorOrBO',
							pageConfiguration.markup.errors.uniqueEmail,
							(value, context) => {
								if (context.from) {
									const formFields = context.from[1].value as UserEditFormFields;
									const emailFields = [formFields.workingEmail, formFields.businessOfficialEmail];

									return !emailFields.includes(value);
								}
							}
						)
						.test('isCollabEmailUnique',
							pageConfiguration.markup.errors.uniqueEmail,
							(value, context) => {
								if (context.from) {
									const formFields = context.from[1].value as UserEditFormFields;

									return formFields.collaborators.filter(m => m.email === value).length === 1;
								}
							}
						)
				}))
		} : null
	});
