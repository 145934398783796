import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from 'network';

import { UpdateUserRequest } from '../types';

export function* updateUser(params: UpdateUserRequest): SagaIterator<AxiosResponse> {
	const method: Method = 'POST';

	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params.data
	};

	return (yield call(networkManager.fetch, Urls.UpdateUser, requestOptions, { id: params.id })) as AxiosResponse;
}
