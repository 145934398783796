import { SagaIterator } from 'redux-saga';
import { fork, takeEvery } from 'redux-saga/effects';

import { takeLatestWithDelay } from 'utils';

import { usersManagementSlice, usersManagementTableSlice, usersManagementUserSlice } from '../slices';
import { MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS } from '../constants';

import { initUsersManagementTableFlowSaga } from './initUsersManagementTableFlowSaga';
import { updateUsersListSaga } from './updateUsersListSaga';
import { initUsersManagementUserFlowSaga } from './initUsersManagementUserFlowSaga';
import { exportUsersSaga } from './exportUsersSaga';
import { updateUserSaga } from './updateUserSaga';
import { approveUserDuaSaga } from './approveUserDuaSaga';
import { rejectUserDuaSaga } from './rejectUserDuaSaga';
import { downloadDocuSignUserAgreementSaga } from './downloadDocuSignUserAgreement';
import { deleteUserSaga } from './deleteUserSaga';
import { sendDuaSignRequestSaga } from './sendDuaSignRequestSaga';
import { sendResearcherSurveySaga } from './sendResearcherSurveySaga';

export function* rootUsersManagementSaga(): SagaIterator {
	yield fork(initUsersManagementTableFlowSaga);
	yield fork(initUsersManagementUserFlowSaga);
	yield takeLatestWithDelay(MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS, [
		usersManagementTableSlice.actions.updateUsersList.type,
		usersManagementTableSlice.actions.setCurrentPage.type,
		usersManagementTableSlice.actions.setRowsPerPage.type,
		usersManagementTableSlice.actions.setSorting.type,
		usersManagementTableSlice.actions.setSearchQuery.type,
		usersManagementTableSlice.actions.setFilterValue.type
	], updateUsersListSaga);
	yield takeEvery(usersManagementSlice.actions.exportUsers.type, exportUsersSaga);
	yield takeEvery(usersManagementUserSlice.actions.updateUser.type, updateUserSaga);
	yield takeEvery(usersManagementUserSlice.actions.deleteUser.type, deleteUserSaga);
	yield takeEvery(usersManagementUserSlice.actions.approveUserDua.type, approveUserDuaSaga);
	yield takeEvery(usersManagementUserSlice.actions.rejectUserDua.type, rejectUserDuaSaga);
	yield takeEvery(usersManagementUserSlice.actions.downloadDocuSignUserAgreement.type, downloadDocuSignUserAgreementSaga);
	yield takeEvery(usersManagementUserSlice.actions.sendDuaSignRequest.type, sendDuaSignRequestSaga);
	yield takeEvery(usersManagementUserSlice.actions.sendResearcherSurvey.type, sendResearcherSurveySaga);
}
