/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// TODO: remove eslint disables, add needed types
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { downloadBlob } from '../../../utils';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { facetsSlice } from '../slices';
import { getDownloadMetadataLink } from '../api';
import { DetailsFacetsConfig, SelectedFacets } from '../types';
import {
	selectDetailsFacetsConfig,
	selectFilterQueryString,
	selectSelectedFacets
} from '../selectors';
import { getFilterString } from '../utils';
import { selectSelectedCaseSetEntities, CaseSetEntity } from '../features/caseSet';

export function* getDownloadMetadataSaga(): SagaIterator {
	try {
		yield put(facetsSlice.actions.getDownloadMetadataRequest());

		const detailsFacetsConfig: DetailsFacetsConfig = yield select(selectDetailsFacetsConfig);
		const selectedFacets: SelectedFacets = yield select(selectSelectedFacets);
		const selectedCaseSetEntities: CaseSetEntity[] = yield select(selectSelectedCaseSetEntities);
		const filterQueryString: string = yield select(selectFilterQueryString);

		const filterString: string = yield call(getFilterString, {
			selectedFacets,
			detailsFacetsConfig,
			selectedCaseSetEntities,
			filterQueryString,
		});
		const response: AxiosResponse = yield call(getDownloadMetadataLink, filterString);
		yield call(downloadBlob,'als-metadata.zip', response.data as Blob);
		yield put(facetsSlice.actions.getDownloadMetadataSuccess());
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(facetsSlice.actions.getDownloadMetadataFail());
	}
}
