import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddiDataRequestShort, GetAddiDataListResponse } from '../types';
import { Filter, SagaEndPayloadAction, SagaEndState, Sort, SortOrder } from 'types';
import { DuaStatuses } from '../../usersManagement/types';
import { AddiDataTableColumnNames } from '../types/AddiDataTableColumnNames';

interface AddiDataRequestsListState {
	addiDataRequests: AddiDataRequestShort[]
	currentPage: number
	rowsPerPage: number
	totalRowsCount: number
	filters: Filter[]
	sort: Sort<AddiDataTableColumnNames>
	searchQuery: string

	isAddiDataRequestsLoading: boolean
	isAddiDataRequestsExporting: boolean
}

const initialState: AddiDataRequestsListState = {
	addiDataRequests: [],
	currentPage: 0,
	rowsPerPage: 20,
	totalRowsCount: 0,
	filters: [
		{
			name: 'statuses',
			displayName: 'DUA Status',
			values: [
				{ name: DuaStatuses.Submitted, displayName: 'Submitted', isSelected: false },
				{ name: DuaStatuses.Verified, displayName: 'Verified', isSelected: false },
				{ name: DuaStatuses.Approved, displayName: 'Approved', isSelected: false },
				{ name: DuaStatuses.Rejected, displayName: 'Rejected', isSelected: false },
				{ name: DuaStatuses.Signed, displayName: 'Signed', isSelected: false }
			],
		}
	],
	sort: {
		orderBy: 'lastSubmitDate',
		order: SortOrder.Desc
	},
	searchQuery: '',

	isAddiDataRequestsLoading: false,
	isAddiDataRequestsExporting: false
};

export const addiDataRequestsListSlice = createSlice({
	name: 'addiDataRequests.list',
	initialState,
	reducers: {
		getAddiDataRequestsList: () => undefined,
		getAddiDataRequestsListStart: (state: AddiDataRequestsListState) => {
			state.isAddiDataRequestsLoading = true;
		},
		getAddiDataRequestsListEnd: (state: AddiDataRequestsListState, { payload }: SagaEndPayloadAction<GetAddiDataListResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.addiDataRequests = payload.data.addiDataRequests;
				state.totalRowsCount = payload.data.totalCount;
			}
			state.isAddiDataRequestsLoading = false;
		},
		setRowsPerPage: (state: AddiDataRequestsListState, { payload }: PayloadAction<number>) => {
			state.rowsPerPage = payload;
		},
		setCurrentPage: (state: AddiDataRequestsListState, { payload }: PayloadAction<number>) => {
			state.currentPage = payload;
		},
		setFilterValue: (state: AddiDataRequestsListState, { payload }: PayloadAction<{
			filterName: string
			filterValueName: string
			newState: boolean
		}>) => {
			const filterIndex = state.filters.findIndex(filter => filter.name === payload.filterName);
			const filterValueIndex = state.filters[filterIndex].values.findIndex(filter => filter.name === payload.filterValueName);

			state.filters[filterIndex].values[filterValueIndex].isSelected = payload.newState;
		},
		setSorting: (state: AddiDataRequestsListState, { payload }: PayloadAction<{
			orderBy: string
			order: string
		}>) => {
			state.sort = {
				orderBy: payload.orderBy as AddiDataTableColumnNames,
				order: payload.order as SortOrder
			};
		},
		setSearchQuery: (state: AddiDataRequestsListState, { payload }: PayloadAction<string>) => {
			state.searchQuery = payload;
		},
		exportAddiDataRequests: () => undefined,
		exportAddiDataRequestsStart: (state: AddiDataRequestsListState) => {
			state.isAddiDataRequestsExporting = true;
		},
		exportAddiDataRequestsEnd: (state: AddiDataRequestsListState) => {
			state.isAddiDataRequestsExporting = false;
		}
	}
});
