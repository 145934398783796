import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { downloadBlob } from 'utils';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { indexManagementSlice } from '../slices';
import { exportUploadedFilesList } from '../api';
import { ExportUploadedFilesListReponse } from '../types';

export function* exportUploadedFilesListSaga(): SagaIterator {
	try {

		yield put(indexManagementSlice.actions.exportUploadedFilesListStart());

		const { data } = (yield call(exportUploadedFilesList)) as AxiosResponse<ExportUploadedFilesListReponse>;

		yield call(downloadBlob,'staging_uploads.csv', data);

		yield put(indexManagementSlice.actions.exportUploadedFilesListEnd());
	} catch (e) {
		yield put(indexManagementSlice.actions.exportUploadedFilesListEnd());
		yield call(handleErrorSaga, e);
	}
}
