import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { NOT_FOUND } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { usersManagementUserSlice } from '../slices';
import { GetUserResponse } from '../types';
import { getUser } from '../api';

export function* getUserSaga(id: string): SagaIterator {
	try {
		yield put(usersManagementUserSlice.actions.getUserStart());

		const userResponse = (yield call(getUser, { id })) as AxiosResponse<GetUserResponse>;

		yield put(usersManagementUserSlice.actions.getUserEnd({
			endState: SagaEndState.Success,
			data: {
				user: userResponse.data
			}
		}));
	} catch (e) {
		if ((e as AxiosError).response?.status === NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'User not found' }));
		} else {
			yield call(handleErrorSaga, e);
		}
		yield put(usersManagementUserSlice.actions.getUserEnd({ endState: SagaEndState.Fail }));
	}
}
