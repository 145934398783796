import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';
import InsertDriveFileOutlined from '@material-ui/icons/InsertDriveFileOutlined';

import { LoaderButton, TooltipWrapper } from '../../../../../components';
import { getUrlString } from '../../../../../network/utils';
import { Urls } from '../../../../../network';

import {
	selectCaseSetConfig,
	selectCaseSetEntities,
	selectCaseSetModalInfo,
	selectEditCaseSetId,
	selectShowNotFounded
} from '../selectors';
import { caseSetSlice } from '../slices';
import { CaseSetConfig, CaseSetEntity, CaseSetModalFormFields, CaseSetModalInfo } from '../types';
import { getUniqueName, normalizeCaseSetIdentifiers } from '../utils';
import { useErrorFocusOnSubmit } from '../hooks';

import { CaseSetModalIdentifiers } from './CaseSetModalIdentifiers';
import { CaseSetSnackbar } from './CaseSetSnackbar';

const INITIAL_CASE_SET_TITLE = 'Case Set';
const UNDEFINED_CASE_SET_IDENTIFIERS_TYPE = 'none';
const ACCEPTED_FILE_FORMATS = ['.txt', '.csv'];
const MIN_LETTERS_FOR_IDENTIFIERS_FILE_NAME_TOOLTIP = 20;
const MAX_IDENTIFIERS_FILE_NAME_WIDTH = 250;

const useStyles = makeStyles(() => ({
	downloadTemplateStartIcon: {
		marginRight: '5px'
	},
	identifiersFileFormControlRoot: {
		margin: 0
	},
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CaseSetModal: FunctionComponent = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const config: CaseSetConfig = useSelector(selectCaseSetConfig);
	const modalInfo: CaseSetModalInfo = useSelector(selectCaseSetModalInfo);
	const caseSetEntities: CaseSetEntity[] = useSelector(selectCaseSetEntities);
	const editCaseSetId = useSelector(selectEditCaseSetId);
	const showNotFounded = useSelector(selectShowNotFounded);

	const caseSetInfo = caseSetEntities.find(item => item.id === editCaseSetId);

	const validationSchema = yup.object().shape({
		title: yup.string()
			.trim()
			.required(t('caseSet.errors.emptyTitle')),
		identifierType: yup.string()
			.test(
				'identifierType',
				t('caseSet.errors.emptyIdentifierType'),
				input => input !== UNDEFINED_CASE_SET_IDENTIFIERS_TYPE
			),
		identifiers: yup.string()
			.test(
				'identifiers',
				t('caseSet.errors.emptyIdentifiers'),
				input => normalizeCaseSetIdentifiers(input ?? '').length > 0
			),
	});

	const form = useFormik<CaseSetModalFormFields>({
		initialValues: {
			title: '',
			identifierType: UNDEFINED_CASE_SET_IDENTIFIERS_TYPE,
			identifiers: '',
			identifiersFile: null,
			onlyNotFoundIdentifiers: false,
			notFoundIdentifiers: '',
		},
		validationSchema,
		onSubmit: values => {
			dispatch(caseSetSlice.actions.submitModalRequest({
				id: editCaseSetId,
				title: values.title,
				identifierType: values.identifierType,
				values: values.identifiers
			}));
		}
	});

	const caseSetTitleRef = useRef<HTMLInputElement>(null);
	const identifierTypeRef = useRef<HTMLInputElement>(null);
	const identifiersRef = useRef<HTMLInputElement>(null);
	const identifiersFileRef = useRef<HTMLInputElement>(null);

	const [initialCaseSetTitle, setInitialCaseSetTitle] = useState('');
	const [isInDefaultState, setIsInDefaultState] = useState(true);
	const [caseSetTemplateUrl] = useState(getUrlString(Urls.GetCaseSetTemplate));

	const clearState = useCallback(() => {
		form.resetForm({
			values: {
				title: initialCaseSetTitle,
				identifierType: UNDEFINED_CASE_SET_IDENTIFIERS_TYPE,
				identifiers: '',
				identifiersFile: null,
				onlyNotFoundIdentifiers: false,
				notFoundIdentifiers: '',
			}
		});
	}, [form, initialCaseSetTitle]);

	const setInitialState = useCallback(() => {
		if (!caseSetInfo) {
			clearState();
		} else {
			form.resetForm({
				values: {
					title: caseSetInfo.title,
					identifierType: caseSetInfo.identifierType,
					identifiers: caseSetInfo.values.join('\n'),
					identifiersFile: null,
					onlyNotFoundIdentifiers: showNotFounded,
					notFoundIdentifiers: caseSetInfo.notFoundIdentifiers.join('\n'),
				}
			});
		}
	}, [form, caseSetInfo, clearState, showNotFounded]);

	const onClearAll = useCallback(() => {
		clearState();
		if (caseSetTitleRef.current) {
			caseSetTitleRef.current.focus();
		}
	}, [clearState, caseSetTitleRef]);

	const onCloseModal = useCallback(() => dispatch(caseSetSlice.actions.closeModal()), [dispatch]);

	const onIdentifiersFileUploadClick = useCallback((changeEvent: React.ChangeEvent<HTMLInputElement>) => {
		void form.setFieldValue('identifiersFile', changeEvent.currentTarget.files && changeEvent.currentTarget.files[0] || null);

		const fileReader = new FileReader();

		if (changeEvent.target.files && changeEvent.target.files[0]) {
			fileReader.readAsText(changeEvent.target.files[0]);
			fileReader.onload = e => {
				if (e.target) {
					void form.setFieldValue('identifiers', normalizeCaseSetIdentifiers(e.target.result as string).join('\n'));
					if (identifiersFileRef.current) {
						identifiersFileRef.current.value = '';
					}
				}
			};
		}
	}, [form, identifiersFileRef]);

	useErrorFocusOnSubmit<CaseSetModalFormFields>({
		form,
		fieldsInfo: [
			{ name: 'title', ref: caseSetTitleRef },
			{ name: 'identifierType', ref: identifierTypeRef },
			{ name: 'identifiers', ref: identifiersRef },
		]
	});

	useEffect(() => {
		if (modalInfo.isModalOpen) {
			setInitialState();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalInfo.isModalOpen]);

	useEffect(() => {
		setIsInDefaultState(
			Boolean(form.values.title === initialCaseSetTitle &&
				form.values.identifierType === UNDEFINED_CASE_SET_IDENTIFIERS_TYPE &&
				form.values.identifiers === '' &&
				form.values.identifiersFile === null)
		);
	}, [form.values, initialCaseSetTitle]);

	useEffect(() => {
		setInitialCaseSetTitle(getUniqueName(INITIAL_CASE_SET_TITLE, editCaseSetId === null ?
			caseSetEntities.map(entity => entity.title) :
			caseSetEntities.filter(entity => entity.id !== editCaseSetId).map(entity => entity.title)
		));
	}, [caseSetEntities, editCaseSetId]);

	return (
		<>
			<Dialog
				aria-labelledby="upload-case-set-modal-header"
				open={modalInfo.isModalOpen}
				fullWidth
				onEscapeKeyDown={onCloseModal}
			>
				<DialogTitle disableTypography>
					<Box position="relative">
						<Typography id="upload-case-set-modal-header" component="h2" variant="h5">
							{ t('caseSet.modalHeader') }
						</Typography>
						<Box position="absolute" top={-12} right={-12} color={grey['800']}>
							<IconButton onClick={onCloseModal} aria-label="Close" component="span">
								<Close />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<TextField
						disabled={modalInfo.isModalSubmitLoading}
						inputRef={caseSetTitleRef}
						InputLabelProps={{
							shrink: true
						}}
						name="title"
						id="upload-case-set-modal-title"
						label={ t('caseSet.titleLabel') }
						placeholder={ t('caseSet.titlePlaceholder') }
						variant="outlined"
						fullWidth
						value={form.values.title}
						onChange={form.handleChange}
						error={form.touched.title && Boolean(form.errors.title)}
						helperText={form.touched.title && form.errors.title}
					/>
					<Box pb={3} />
					<FormControl variant="outlined" fullWidth error={form.touched.identifierType && Boolean(form.errors.identifierType)}>
						<InputLabel id="upload-case-set-modal-identifier-type-label">{ t('caseSet.identifierTypeLabel') }</InputLabel>
						<Select
							disabled={modalInfo.isModalSubmitLoading}
							inputRef={identifierTypeRef}
							name="identifierType"
							id="upload-case-set-modal-identifier-type"
							labelId="upload-case-set-modal-identifier-type-label"
							SelectDisplayProps={{
								'aria-describedby': 'upload-case-set-modal-identifier-type-helper-text',
								'aria-invalid': form.touched.identifierType && Boolean(form.errors.identifierType)
							}}
							label={ t('caseSet.identifierTypeLabel') }
							value={form.values.identifierType}
							onChange={form.handleChange}
						>
							<MenuItem disabled value={UNDEFINED_CASE_SET_IDENTIFIERS_TYPE}>{ t('caseSet.identifierTypePlaceholder') }</MenuItem>
							{config.identifierTypes.map(({ name: identifierName, displayName }) =>
								<MenuItem key={identifierName} value={identifierName}>{displayName}</MenuItem>
							)}
						</Select>
						<FormHelperText id="upload-case-set-modal-identifier-type-helper-text">
							{ form.touched.identifierType && form.errors.identifierType }
						</FormHelperText>
					</FormControl>
					<Box pb={3} />
					<CaseSetModalIdentifiers
						ref={identifiersRef}
						form={form}
					/>
					<Box pb={3} />
					<Typography
						component="span"
						variant="subtitle1"
					>
						{ t('caseSet.fileUploadDescription') }
					</Typography>
					<Box pb={1.5} />
					<Box display="flex" justifyContent="space-between">
						<Box display="flex" alignItems="center">
							<FormControl error={ form.touched.identifiersFile && Boolean(form.errors.identifiersFile) }>
								<FormControlLabel
									classes={{ root: classes.identifiersFileFormControlRoot }}
									control={(
										<Box pr={2}>
											<input
												ref={identifiersFileRef}
												name="identifiersFile"
												style={{ display: 'none' }}
												accept={ACCEPTED_FILE_FORMATS.join(',')}
												type="file"
												onChange={onIdentifiersFileUploadClick}
											/>
											<Button
												color="primary"
												variant="outlined"
												component="span"
												style={{ whiteSpace: 'nowrap' }}
											>
												{ t('caseSet.fileUploadButton') }
											</Button>
										</Box>
									)}
									label={
										form.values.identifiersFile !== null ?
											<Box maxWidth={ MAX_IDENTIFIERS_FILE_NAME_WIDTH } color={grey['900']}>
												<TooltipWrapper title={ form.values.identifiersFile.name } disabled={ form.values.identifiersFile.name.length < MIN_LETTERS_FOR_IDENTIFIERS_FILE_NAME_TOOLTIP }>
													<Typography component="div" variant="subtitle1" noWrap={true}>
														{ form.values.identifiersFile.name }
													</Typography>
												</TooltipWrapper>
											</Box> :
											<Box color={grey['600']}>
												<Typography component="p" variant="caption" style={{ whiteSpace: 'pre-wrap' }}>
													{ t('caseSet.fileUploadPlaceholder') }
												</Typography>
											</Box>
									}
								/>
								<FormHelperText>
									{ form.touched.identifiersFile && form.errors.identifiersFile }
								</FormHelperText>
							</FormControl>
						</Box>
						<Box>
							<Button
								variant="text"
								size="small"
								color="primary"
								target="_blank"
								href={caseSetTemplateUrl}
								classes={{ startIcon: classes.downloadTemplateStartIcon }}
								startIcon={<InsertDriveFileOutlined />}
							>
								<Typography component="span" variant="caption" style={{ textTransform: 'none', whiteSpace: 'nowrap' }}>
									{ t('caseSet.downloadTemplateButton') }
								</Typography>
							</Button>
						</Box>
					</Box>
				</DialogContent>
				<DialogActions>
					<Box width="100%" display="flex" justifyContent="space-between">
						<Box>
							<Button disabled={isInDefaultState || modalInfo.isModalSubmitLoading} onClick={onClearAll} color="primary" size="large">
								{ t('caseSet.clearFormButton') }
							</Button>
						</Box>
						<Box>
							<Button onClick={onCloseModal} color="primary" variant="outlined" size="large">
								{ t('caseSet.cancelButton') }
							</Button>
							<Box display="inline" pr={1} />
							<LoaderButton
								isLoading={modalInfo.isModalSubmitLoading}
								wrapperBoxProps={{
									display: 'inline'
								}}
								ButtonProps={{
									disabled: form.values.onlyNotFoundIdentifiers,
									size: 'large',
									variant: 'contained',
									color: 'primary',
									onClick: () => form.handleSubmit()
								}}
							>
								{ t('caseSet.submitButton') }
							</LoaderButton>
						</Box>
					</Box>
				</DialogActions>
			</Dialog>
			<CaseSetSnackbar />
		</>
	);
};
