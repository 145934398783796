import React, { FunctionComponent } from 'react';

import Button from '@material-ui/core/Button';

import { Routes } from 'app/Routes';
import { CustomTableTextCell } from 'components/CustomTable';

import { DoiListTableColumnNames, DoiShort } from '../types';
import { Box } from '@material-ui/core';

interface DoiListTableCellProps {
	doi: DoiShort
	tableColumnName: DoiListTableColumnNames
}

export const DoiListTableCell: FunctionComponent<DoiListTableCellProps> = ({
	doi,
	tableColumnName
}) => {
	if (tableColumnName === 'modifiedDateTime') {
		return <CustomTableTextCell value={new Intl.DateTimeFormat('default').format(new Date(doi[tableColumnName]))} />;
	}
	if (tableColumnName === 'publishedDateTime' && doi.published) {
		return <CustomTableTextCell value={new Intl.DateTimeFormat('default').format(new Date(doi[tableColumnName]))} />;
	}
	if (tableColumnName === 'action') {
		return (
			<Box>
				{doi.published ? <Button
					color='primary'
					size='small'
					href={doi.id ? `${Routes.Doi}/${doi.id}` : `${Routes.Doi}/list`}
					target="_blank"
				>
					{'View'}
				</Button> : <Button
					color='primary'
					size='small'
					href={doi.id ? `${Routes.Doi}/save/${doi.id}` : `${Routes.Doi}/list`}
					target="_blank"
				>
					{'Edit'}
				</Button>}
			</Box>
		);
	}

	return <CustomTableTextCell value={doi[tableColumnName]} />;
};
