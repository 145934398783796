import React, { FunctionComponent } from 'react';

import Checkbox from '@material-ui/core/Checkbox';

interface CustomTableBooleanCellProps {
	value: boolean
}

export const CustomTableBooleanCell: FunctionComponent<CustomTableBooleanCellProps> = ({
	value
}) =>
	<Checkbox
		size="small"
		checked={ value }
		disabled
		inputProps={{
			'aria-label': 'Legacy'
		}}
	/>
;
