import React, { useEffect, FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme, Button } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

import { cliAuthManager } from '../../../auth/cliAuthManager';

import { totpAuthenticationCodeSlice } from '../index';
import { selectIsCliAccountLoading, selectTotpAuthenticationCode } from '../selectors';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		paperRoot: {
			width: '400px',
			minHeight: '300px',
			position: 'relative'
		},
		backdropRoot: {
			zIndex: theme.zIndex.modal,
			position: 'absolute',
			borderRadius: theme.shape.borderRadius
		}
	})
);

export const TotpAuthentication: FunctionComponent = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { isCliUserAuthorized, code, codeLifeTimeInSec, codeRemainingTimeInSec, isCliInitLoading } = useSelector(selectTotpAuthenticationCode);
	const isCliAccountLoading = useSelector(selectIsCliAccountLoading);

	useEffect(() => {
		if (!isCliInitLoading) {
			dispatch(totpAuthenticationCodeSlice.actions.startCodeUpdater());

			return () => {
				dispatch(totpAuthenticationCodeSlice.actions.stopCodeUpdater());
			};
		}
	}, [dispatch, isCliInitLoading]);

	const onSignInClick = useCallback(() => cliAuthManager.signIn(), []);

	// eslint-disable-next-line @typescript-eslint/no-magic-numbers
	const progress = codeRemainingTimeInSec * 100 / codeLifeTimeInSec;

	return (
		<Box
			display="flex"
			position="fixed"
			height="100%"
			width="100%"
			top={0}
			justifyContent="center"
			alignItems="center"
		>
			<Paper classes={{ root: classes.paperRoot }}>
				<Box px={5} py={6}>
					<Backdrop classes={{ root: classes.backdropRoot }} open={isCliAccountLoading}>
						<CircularProgress size={56} thickness={3} />
					</Backdrop>
					<Box color={grey['900']} mb={4} textAlign="center">
						<Typography variant="h3" component="h1">
							{ t('activateCli.header') }
						</Typography>
					</Box>
					{!isCliAccountLoading && (
						isCliUserAuthorized ?
							<>
								<Box color={grey['800']} mb={3}>
									<Typography variant="body1" component="p">
										{ t('activateCli.authorizedDescription') }
									</Typography>
								</Box>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mb={9.5}
									visibility={!codeRemainingTimeInSec ? 'hidden' : 'visible'}
								>
									<Box>
										<Typography variant="h3" component="p">
											{ code }
										</Typography>
									</Box>
									<Box position="relative" display="inline-flex">
										<Box
											top={0}
											left={0}
											bottom={0}
											right={0}
											position="absolute"
											display="flex"
											alignItems="center"
											justifyContent="center"
											borderRadius="50%"
											bgcolor={grey['100']}
											aria-hidden
										>
											<Typography variant="subtitle2" component="div" color="textPrimary">{codeRemainingTimeInSec}</Typography>
										</Box>
										<CircularProgress
											size={32}
											thickness={3}
											style={{ transform: 'scaleX(-1) rotate(-90deg)' }}
											color="primary"
											variant="static"
											value={progress}
											aria-valuenow={ codeRemainingTimeInSec }
										/>
									</Box>
								</Box>
							</>
							:
							<>
								<Box color={grey['800']} mb={3}>
									<Typography variant="body1" component="p">
										{ t('activateCli.unauthorizedDescription') }
									</Typography>
								</Box>
								<Box color={grey['800']} mb={3} display="flex" justifyContent="center">
									<Button
										variant="contained"
										color="primary"
										size="large"
										onClick={onSignInClick}
									>
										{ 'Sign In' }
									</Button>
								</Box>
							</>
					)}
				</Box>
			</Paper>
		</Box>
	);
};
