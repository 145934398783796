import React, { FunctionComponent, useCallback, useState } from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Fade from '@material-ui/core/Fade';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Box from '@material-ui/core/Box';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { TOOLTIP_BACKGROUND_COLOR, TOOLTIP_FONT_COLOR } from 'styles/constants.ui';
import { SortOrder } from 'types';

import { Column } from '../types';

const useStyles = makeStyles(() => ({
	headerTableCell: {
		backgroundColor: grey['50'],
		color: grey['800'],
		height: '37px'
	},
	headerTableCellTitle: {
		maxWidth: '115px',
		display: 'inline',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	tooltip: {
		backgroundColor: TOOLTIP_BACKGROUND_COLOR,
		color: TOOLTIP_FONT_COLOR
	},
	dragIcon: {
		cursor: 'grab'
	},
	sortLabel: {
		'&:focus svg': {
			opacity: 0.5
		}
	}
}));

interface CustomTableHeadColumnProps {
	column: Column
	onSort: (column: Column) => void
	order?: SortOrder
	isDraggable?: boolean
	disableTooltip?: boolean
}

export const CustomTableHeadColumn: FunctionComponent<CustomTableHeadColumnProps> = ({
	column,
	onSort,
	order,
	isDraggable = false,
	disableTooltip = false
}) => {
	const classes = useStyles();

	const [isColumnTooltipOpen, setIsColumnTooltipOpen] = useState<boolean>(false);

	const onColumnTooltipSetState = useCallback((state: boolean) => () => {
		setIsColumnTooltipOpen(state);
	}, []);

	const onSortClick = useCallback(() => {
		onSort(column);
	}, [onSort, column]);

	return (
		<TableCell tabIndex={isDraggable ? 0 : -1} align="left" classes={{ root: classes.headerTableCell }}>
			<Box display="flex" alignItems="center">
				{ isDraggable &&
					<Box
						display="flex"
						alignItems="center"
						color={ grey['400'] }
						ml={ -0.625 }
						className={ classes.dragIcon }
					>
						<DragIndicatorIcon fontSize="small" />
					</Box>
				}
				<Tooltip
					classes={{ tooltip: classes.tooltip }}
					TransitionComponent={ Fade }
					open={ isColumnTooltipOpen && !disableTooltip }
					onClose={ onColumnTooltipSetState(false) }
					onOpen={ onColumnTooltipSetState(true) }
					title={
						<>
							<Typography variant="subtitle2">
								{ column.displayName }
							</Typography>
							<Typography variant="caption">{ column.description ?? '' }</Typography>
						</>
					}>
					<Typography component="span" variant="caption" noWrap={ true } style={ { fontWeight: 'bold' } }>
						{
							column.sortable ?
								<TableSortLabel
									active={ order !== undefined }
									direction={ order }
									onClick={ onSortClick }
									classes={{ root: classes.sortLabel }}
								>
									<span className={ classes.headerTableCellTitle }>
										{ column.displayName }
									</span>
									{ order !== undefined &&
										<span className={ classes.visuallyHidden }>
											{ order === SortOrder.Desc ? 'sorted descending' : 'sorted ascending' }
										</span>
									}
								</TableSortLabel> :
								column.displayName
						}
					</Typography>
				</Tooltip>
			</Box>
		</TableCell>
	);
};

export const DraggableCustomTableHeadColumn = SortableElement(CustomTableHeadColumn);
