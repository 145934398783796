import { put, call, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { ProceedSearchResultContent } from '../types';
import { tabsSlice } from '../slices';
import { getProceedContent } from '../api';

export function* getProceedContentSaga(): Generator<StrictEffect, void, AxiosResponse<ProceedSearchResultContent>> {
	try {
		const response: AxiosResponse<ProceedSearchResultContent> = yield call(getProceedContent);
		yield put(tabsSlice.actions.getProceedContentSuccess(response.data));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(tabsSlice.actions.getProceedContentFailure());
	}
}
