import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from '../../../network';

export const getDefaultFacetsConfig = async (): Promise<AxiosResponse> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch(Urls.GetDefaultFacetsConfig, requestOptions);
};
