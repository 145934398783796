import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../../../constants';
import {
	CaseSetConfig,
	CaseSetIdentifierType,
	CaseSetModalInfo,
	CaseSetEntity,
	GetExternalCaseSetRequest,
	UpdateCaseSetEntity,
	AddCaseSetEntity,
	SubmitCaseSetEntity
} from '../types';

interface CaseSetState {
	config: CaseSetConfig
	modalInfo: CaseSetModalInfo
	caseSetEntities: CaseSetEntity[]
	selectedCaseSetIds: string[]
	isExternalCaseSetLoading: boolean
	editCaseSetId: null | string
	showNotFounded: boolean
	caseSetErrorId: string | null
	isCaseSetEntitiesGroupExpanded: boolean
}

const initialState: CaseSetState = {
	config: {
		identifierTypes: []
	},
	modalInfo: {
		isModalOpen: false,
		isModalOpenLoading: false,
		isModalSubmitLoading: false,
	},
	caseSetEntities: [],
	selectedCaseSetIds: [],
	isExternalCaseSetLoading: false,
	editCaseSetId: null,
	showNotFounded: false,
	caseSetErrorId: null,
	isCaseSetEntitiesGroupExpanded: false
};

export const caseSetSlice = createSlice({
	name: `${ACTIONS_PREFIX}.caseSet`,
	initialState,
	reducers: {
		startModalOpen: (state: CaseSetState, { payload }: PayloadAction<{
			editCaseSetId?: string
			showNotFounded?: boolean
		} | undefined>) => {
			if (payload?.editCaseSetId !== undefined) {
				state.editCaseSetId = payload.editCaseSetId;

				if (payload?.showNotFounded !== undefined) {
					state.showNotFounded = payload.showNotFounded;
				}
			}
			state.modalInfo.isModalOpenLoading = true;
		},
		modalOpenSuccess: (state: CaseSetState, { payload }: PayloadAction<{
			identifierTypes: CaseSetIdentifierType[]
		}>) => {
			state.modalInfo.isModalOpenLoading = false;
			state.config.identifierTypes = payload.identifierTypes;
			state.modalInfo.isModalOpen = true;
		},
		modalOpenFail: (state: CaseSetState) => {
			state.modalInfo.isModalOpenLoading = false;
		},
		closeModal: (state: CaseSetState) => {
			state.modalInfo.isModalOpen = false;
			state.editCaseSetId = null;
			state.showNotFounded = false;
		},

		submitModalRequest: (state: CaseSetState, _: PayloadAction<SubmitCaseSetEntity>) => {
			state.modalInfo.isModalSubmitLoading = true;
		},
		submitModalSuccess: (state: CaseSetState) => {
			state.modalInfo.isModalSubmitLoading = false;
			caseSetSlice.caseReducers.closeModal(state);
		},
		submitModalFail: (state: CaseSetState) => {
			state.modalInfo.isModalSubmitLoading = false;
			caseSetSlice.caseReducers.closeModal(state);
		},

		addCaseSet: (state: CaseSetState, { payload }: PayloadAction<AddCaseSetEntity>) => {
			if (state.caseSetEntities.some(entity => entity.id === payload.id)) {
				return;
			}
			state.caseSetEntities.push({
				notFoundIdentifiers: [],
				...payload
			});
		},
		updateCaseSet: (state: CaseSetState, { payload }: PayloadAction<UpdateCaseSetEntity>) => {
			const caseSetIndex = state.caseSetEntities.findIndex(entity => entity.id === payload.id);
			if (caseSetIndex === -1) {
				return;
			}
			state.caseSetEntities[caseSetIndex] = { ...state.caseSetEntities[caseSetIndex], ...payload };
		},
		removeCaseSet: (state: CaseSetState, { payload: {
			id
		} }: PayloadAction<{
			id: string
		}>) => {
			state.caseSetEntities = state.caseSetEntities.filter(entity => entity.id !== id);
			state.selectedCaseSetIds = state.selectedCaseSetIds.filter(selectedId => selectedId !== id);
		},
		selectCaseSet: (state: CaseSetState, { payload }: PayloadAction<{
			id: string
		}>) => {
			caseSetSlice.caseReducers.silentSelectCaseSet(state, caseSetSlice.actions.silentSelectCaseSet(payload));
		},
		silentSelectCaseSet: (state: CaseSetState, { payload: {
			id
		} }: PayloadAction<{
			id: string
		}>) => {
			if (state.selectedCaseSetIds.some(selectedId => selectedId === id)) {
				return;
			}
			const index = state.caseSetEntities.findIndex(entity => entity.id === id);
			if (index !== -1) {
				state.selectedCaseSetIds.push(id);
			}
		},
		unselectCaseSet: (state: CaseSetState, { payload: {
			id
		} }: PayloadAction<{
			id: string
		}>) => {
			state.selectedCaseSetIds = state.selectedCaseSetIds.filter(selectedId => selectedId !== id);
		},
		unselectAllCaseSetEntities: (state: CaseSetState) => {
			state.selectedCaseSetIds = initialState.selectedCaseSetIds;
		},

		getExternalCaseSetRequest: (state: CaseSetState, _: PayloadAction<GetExternalCaseSetRequest>) => {
			state.isExternalCaseSetLoading = true;
		},
		getExternalCaseSetSuccess: (state: CaseSetState) => {
			state.isExternalCaseSetLoading = false;
		},
		getExternalCaseSetFail: (state: CaseSetState) => {
			state.isExternalCaseSetLoading = false;
		},

		setValidationError: (state: CaseSetState, { payload }: PayloadAction<{ caseSetErrorId: string }>) => {
			state.caseSetErrorId = payload.caseSetErrorId;
		},
		resetValidationError: (state: CaseSetState) => {
			state.caseSetErrorId = null;
		},

		expandCaseSetGroup: (state: CaseSetState) => {
			state.isCaseSetEntitiesGroupExpanded = true;
		},
		collapseCaseSetGroup: (state: CaseSetState) => {
			state.isCaseSetEntitiesGroupExpanded = false;
		},

		showCaseSetsPanel: () => undefined,
	}
});
