import i18n, { TOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextBackend from 'i18next-http-backend';

import { getUrlString } from '../network/utils';
import { Urls } from '../network/Urls';

import { DEFAULT_LANGUAGE } from './constants';

type Translation = {
	value: unknown
};

void i18n
	.use(i18nextBackend)
	.use(initReactI18next)
	.init({
		debug: false,
		backend: {
			loadPath: getUrlString(Urls.GetTranslations),
			customHeaders: {
				Locale: DEFAULT_LANGUAGE
			},
			parse: (data: string) => {
				const parsedData = JSON.parse(data) as Translation;
				return parsedData.value;
			}
		},
		load: 'currentOnly',
		lowerCaseLng: true,
		lng: DEFAULT_LANGUAGE,
		fallbackLng: DEFAULT_LANGUAGE,
		react: {
			useSuspense: false
		},
		overloadTranslationOptionHandler(): TOptions {
			return { defaultValue: '' };
		}
	});

export default i18n;
