import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';
import { Configuration } from '../types';

export interface SubmitDataState {
	isConfigurationLoading: boolean
	isConfigurationInitialized: boolean
	configuration: Configuration
}

const initialState: SubmitDataState = {
	isConfigurationLoading: false,
	isConfigurationInitialized: false,
	configuration: {
		links: {
			linux: '',
			macOS: '',
			windows: ''
		},
		uploadDataLocaleText: {
			pageHeader: '',
			pageIntro: '',
			pageSubHeader: '',
			pageSubIntro: '',
			stepsContent: '',
			toolImage1base64: '',
			toolImage2base64: ''
		}
	}
};

export const submitDataSlice = createSlice({
	name: `${ACTIONS_PREFIX}.code`,
	initialState,
	reducers: {
		pageConfigurationRequest: (state: SubmitDataState) => {
			state.isConfigurationLoading = true;
		},
		pageConfigurationSuccess: (state: SubmitDataState, { payload }: PayloadAction<Configuration>) => {
			state.isConfigurationLoading = false;
			state.isConfigurationInitialized = true;
			state.configuration = payload;
		},
		pageConfigurationFailure: (state: SubmitDataState) => {
			state.isConfigurationLoading = false;
		}
	}
});
