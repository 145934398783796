import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { ValidateCaseSetRequest, ValidateCaseSetResponse } from '../types';

export const validateCaseSet = async (
	params: ValidateCaseSetRequest
): Promise<AxiosResponse<ValidateCaseSetResponse>> => {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params,
	};
	return networkManager.fetch<ValidateCaseSetResponse>(Urls.ValidateCaseSet, requestOptions);
};
