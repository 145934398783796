import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { NOT_FOUND } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { releaseDataManagementSlice } from '../slices';
import { OmicsReleaseReadyResponse } from '../types';
import { getOmicsReleaseReady } from '../api';

export function* getOmicsReleaseReadySaga(): SagaIterator {
	try {
		yield put(releaseDataManagementSlice.actions.getOmicsReleaseReadyStart());

		const omicsReleaseReadyResponse = (yield call(getOmicsReleaseReady)) as AxiosResponse<OmicsReleaseReadyResponse>;

		yield put(releaseDataManagementSlice.actions.getOmicsReleaseReadyEnd({
			endState: SagaEndState.Success,
			data: omicsReleaseReadyResponse.data
		}));
	} catch (e) {
		if ((e as AxiosError).response?.status === NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'User not found' }));
		} else {
			yield call(handleErrorSaga, e);
		}
		yield put(releaseDataManagementSlice.actions.getOmicsReleaseReadyEnd({ endState: SagaEndState.Fail }));
	}
}
