import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { ExportReleasedFilesListResponse } from '../types';

export function* exportReleasedFilesList(): SagaIterator<AxiosResponse<ExportReleasedFilesListResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
	};

	return (yield call(networkManager.fetch, Urls.ExportReleasedFilesList, requestOptions)) as AxiosResponse<ExportReleasedFilesListResponse>;
}
