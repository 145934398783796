import React, { FunctionComponent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	Button,
	Divider,
	Typography,
	Box,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
	Accordion,
	AccordionSummary,
	AccordionDetails
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Close from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { MAX_VALUES_ON_COLLAPSED } from 'styles/constants.ui';

import { alertSlice } from '../../../../alert/slices';

import { CaseSetEntity } from '../types';
import { selectCaseSetEntities, selectIsCaseSetEntitiesGroupExpanded, selectSelectedCaseSetIds } from '../selectors';
import { caseSetSlice } from '../slices';

import { CaseSetGroupItem } from './CaseSetGroupItem';

const useStyles = makeStyles(() => ({
	accordionSummaryRoot: {
		backgroundColor: grey['100'],
		textTransform: 'uppercase'
	},
	accordionDetailsRoot: {
		backgroundColor: grey['50']
	}
}));

export const CaseSetGroup: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const caseSetEntities: CaseSetEntity[] = useSelector(selectCaseSetEntities);
	const selectedCaseSetIds = useSelector(selectSelectedCaseSetIds);
	const isCaseSetEntitiesGroupExpanded = useSelector(selectIsCaseSetEntitiesGroupExpanded);

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [removeCaseSetId, setRemoveCaseSetId] = useState('');
	const [isCaseSetsExpanded, setCaseSetsExpanded] = useState(false);

	const toggleExpand = useCallback((e: React.ChangeEvent<Record<string, unknown>>, newState: boolean) => {
		if (newState) {
			dispatch(caseSetSlice.actions.expandCaseSetGroup());
		} else {
			dispatch(caseSetSlice.actions.collapseCaseSetGroup());
		}
	}, [dispatch]);

	const onUpload = useCallback(() => {
		dispatch(caseSetSlice.actions.startModalOpen());
	}, [dispatch]);

	const onSelectionChange = useCallback((id: string) => (e: React.SyntheticEvent, isChecked: boolean) => {
		if (isChecked) {
			dispatch(caseSetSlice.actions.selectCaseSet({ id }));
		} else {
			dispatch(caseSetSlice.actions.unselectCaseSet({ id }));
		}
	}, [dispatch]);

	const onCaseSetRemoveClick = useCallback((id: string) => () => {
		setRemoveCaseSetId(id);
		setIsConfirmOpen(true);
	}, []);
	const onCaseSetEditClick = useCallback((id: string) => () => {
		dispatch(caseSetSlice.actions.startModalOpen({ editCaseSetId: id }));
	}, [dispatch]);
	const onCaseSetRemoveConfirmClick = useCallback(() => {
		setIsConfirmOpen(false);
		dispatch(caseSetSlice.actions.removeCaseSet({ id: removeCaseSetId }));
		dispatch(alertSlice.actions.showAlert({ message: t('caseSet.delete.successAlert') }));
	}, [dispatch, removeCaseSetId, t]);
	const onCaseSetRemoveCancelClick = useCallback(() => {
		setIsConfirmOpen(false);
	}, []);

	const onCaseSetsExpand = useCallback(() => {
		setCaseSetsExpanded(!isCaseSetsExpanded);
	}, [isCaseSetsExpanded]);

	return (
		<>
			{caseSetEntities.length > 0 &&
				<>
					<Accordion
						square
						TransitionProps={{ unmountOnExit: true }}
						expanded={isCaseSetEntitiesGroupExpanded}
						onChange={toggleExpand}>
						<Box position="relative">
							<AccordionSummary
								aria-label={ 'Category for filtration is custom case set' }
								classes={{ root: classes.accordionSummaryRoot }}
							>
								<Box width="100%" display="flex" justifyContent="space-between">
									<Box display="flex" alignItems="center" mr={1} width="100%">
										<Box mr={1} color={ grey['A700'] } display="flex" alignItems="center">
											{!isCaseSetEntitiesGroupExpanded && <ExpandMore/>}
											{isCaseSetEntitiesGroupExpanded && <ExpandLess/>}
										</Box>
										<Typography component="h3" variant="h6">{ 'Case Sets' }</Typography>
									</Box>
								</Box>
							</AccordionSummary>
							<Box position="absolute" top={12} right={16}>
								<Button
									style={{ textTransform: 'none', marginRight: '-5px' }}
									size="small"
									variant="text"
									onClick={onUpload}>
									<Typography color="primary" component="span" variant="body2">
										{ 'Upload case set' }
									</Typography>
								</Button>
							</Box>
						</Box>
						<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
							<Box width="100%">
								<Box px={2} py={1} width="100%" color={grey['800']}>
									{caseSetEntities.slice(isCaseSetsExpanded ? 0 : -MAX_VALUES_ON_COLLAPSED).reverse().map(caseSetEntity => {
										const isSelected = selectedCaseSetIds.some(selectedId => selectedId === caseSetEntity.id);
										return (
											<CaseSetGroupItem
												key={caseSetEntity.id}
												caseSetEntity={caseSetEntity}
												isSelected={isSelected}
												onSelectionChange={onSelectionChange(caseSetEntity.id)}
												onCaseSetRemoveClick={onCaseSetRemoveClick(caseSetEntity.id)}
												onCaseSetEditClick={onCaseSetEditClick(caseSetEntity.id)}
											/>
										);
									})}
									{caseSetEntities.length > MAX_VALUES_ON_COLLAPSED &&
										<Box display="flex" justifyContent="flex-end" pr={0}>
											<Button
												aria-expanded={isCaseSetsExpanded}
												aria-label={ 'Show all items' }
												style={{ textTransform: 'lowercase', marginRight: '-5px' }}
												size="small"
												variant="text"
												onClick={onCaseSetsExpand}
											>
												<Typography color="primary" component="span" variant="body2">
													{ isCaseSetsExpanded ?
														'show less' :
														`show ${caseSetEntities.length - MAX_VALUES_ON_COLLAPSED} more`
													}
												</Typography>
											</Button>
										</Box>
									}
								</Box>
							</Box>
						</AccordionDetails>
					</Accordion>
					<Divider />
				</>
			}
			<Dialog
				aria-labelledby="delete-case-set-modal-header"
				open={isConfirmOpen}
				onEscapeKeyDown={onCaseSetRemoveCancelClick}
			>
				<DialogTitle disableTypography>
					<Box position="relative">
						<Typography id="delete-case-set-modal-header" component="h2" variant="h5">
							{ t('caseSet.delete.confirmation.header') }
						</Typography>
						<Box position="absolute" top={-12} right={-12} color={grey['800']}>
							<IconButton onClick={onCaseSetRemoveCancelClick} aria-label="Close" component="span">
								<Close />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Typography component="span" variant="subtitle1">
						{ t('caseSet.delete.confirmation.text') }
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCaseSetRemoveCancelClick} color="primary" variant="outlined" size="large">
						{ t('caseSet.delete.confirmation.cancelButton') }
					</Button>
					<Button onClick={onCaseSetRemoveConfirmClick} color="primary" variant="contained" size="large">
						{ t('caseSet.delete.confirmation.confirmButton') }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
