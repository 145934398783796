import React, { useCallback, FunctionComponent, useState, ChangeEvent } from 'react';

import { IconButton, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';

import { useDispatch, useSelector } from 'react-redux';
import { selectTableSearchQuery } from '../selectors';
import { usersManagementTableSlice } from '../slices';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
	searchInputMarginDense: {
		paddingTop: '0px',
		paddingBottom: '0px'
	},
	searchInputRoot: {
		width: '260px',
		height: '32px'
	},
	searchInputAdornmentStart: {
		paddingLeft: '6px'
	},
	searchInputAdornmentEnd: {
		paddingRight: '6px'
	},
	searchInputAdornmentPositionStart: {
		marginRight: '4px'
	},
	searchInputAdornmentPositionEnd: {
		marginLeft: '4px'
	}
}));

export const TableSearchPanel: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const searchQuery = useSelector(selectTableSearchQuery);

	const [tempSearchQuery, setTempSearchQuery] = useState(searchQuery);

	const submitSearchQuery = useCallback((newSearchQuery: string) => {
		if (searchQuery !== newSearchQuery) {
			dispatch(usersManagementTableSlice.actions.setSearchQuery(newSearchQuery));
		}
	}, [dispatch, searchQuery]);

	const onSearchButtonClick = useCallback(() => {
		submitSearchQuery(tempSearchQuery);
	}, [submitSearchQuery, tempSearchQuery]);
	const onSearchInputChange = useCallback((changeEvent: ChangeEvent<HTMLInputElement>) => {
		setTempSearchQuery(changeEvent.target.value);
	}, []);
	const onSearchInputKeyPress = useCallback((e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			submitSearchQuery(tempSearchQuery);
		}
	}, [submitSearchQuery, tempSearchQuery]);
	const onSearchInputClear = useCallback(() => {
		setTempSearchQuery('');
		submitSearchQuery('');
	}, [submitSearchQuery]);

	return (
		<Box display="flex" alignItems="center">
			<TextField
				id="admin-search-input"
				placeholder="Search…"
				variant="outlined"
				size="small"
				value={ tempSearchQuery }
				onChange={ onSearchInputChange }
				onKeyPress={ onSearchInputKeyPress }
				autoComplete="off"
				InputProps={{
					classes: {
						root: classes.searchInputRoot,
						inputMarginDense: classes.searchInputMarginDense,
						adornedStart: classes.searchInputAdornmentStart,
						adornedEnd: classes.searchInputAdornmentEnd
					},
					startAdornment:
						<InputAdornment position="start" classes={{ positionStart: classes.searchInputAdornmentPositionStart }}>
							<SearchIcon style={{ color: grey['600'] }} />
						</InputAdornment>,
					endAdornment:
						<Box visibility={tempSearchQuery.length > 0 ? 'visible' : 'hidden'}>
							<InputAdornment position="end" classes={{ positionEnd: classes.searchInputAdornmentPositionEnd }}>
								<IconButton
									size="small"
									onClick={ onSearchInputClear }
									aria-label="Clear search"
									component="span"
								>
									<ClearIcon style={{ color: grey['600'] }} />
								</IconButton>
							</InputAdornment>
						</Box>
				}}
			/>
			<Box pr={1} />
			<Button
				variant="outlined"
				color="primary"
				size="large"
				onClick={ onSearchButtonClick }
			>
				{ 'Search' }
			</Button>
		</Box>
	);
};
