import React, { FunctionComponent, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import clsx from 'clsx';

import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import UserManagementIcon from '@material-ui/icons/Group';
import AddiDataRequestsIcon from '@material-ui/icons/Assignment';
import IndexManagementIcon from '@material-ui/icons/Settings';
import StatisticsIcon from '@material-ui/icons/BarChart';
import ReleaseDataIcon from '@material-ui/icons/DataUsage';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { Routes } from 'app/Routes';
import { ReactComponent as OpenDrawerIcon } from 'assets/icons/RightArrowSquaredIcon.svg';
import { ReactComponent as CloseDrawerIcon } from 'assets/icons/LeftArrowSquaredIcon.svg';

import { NAV_DRAWER_OPENED_WIDTH_PX, NAV_DRAWER_CLOSED_WIDTH_PX } from '../constants';

const useStyles = makeStyles((theme: Theme) => ({
	drawer: {
		flexShrink: 0,
		whiteSpace: 'nowrap',
		height: 'transparent',
		minHeight: '88vh',
		width: NAV_DRAWER_OPENED_WIDTH_PX
	},
	drawerPaper: {
		position: 'relative',
		height: '100%',
		backgroundColor: grey['50'],
		zIndex: 1,
		overflowX: 'hidden'
	},
	listIcon: {
		minWidth: '40px'
	},

	drawerOpen: {
		width: NAV_DRAWER_OPENED_WIDTH_PX,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		width: NAV_DRAWER_CLOSED_WIDTH_PX,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		})
	}
}));

const menuItems = [
	{
		text: 'Users Management',
		Icon: UserManagementIcon,
		route: Routes.UsersManagement
	},
	{
		text: 'ADDI Data Requests',
		Icon: AddiDataRequestsIcon,
		route: Routes.AddiDataRequests
	},
	{
		text: 'Index Management',
		Icon: IndexManagementIcon,
		route: Routes.IndexManagement
	},
	{
		text: 'Release Data',
		Icon: ReleaseDataIcon,
		route: Routes.ReleaseDataManagement
	},
	{
		text: 'Statistics dashboard',
		Icon: StatisticsIcon,
		route: Routes.Statistics
	},
];

export const AdminNavigation: FunctionComponent = () => {
	const classes = useStyles();
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const onMenuItemClick = useCallback((index: number) => () => {
		dispatch(push(menuItems[index].route));
	}, [dispatch]);

	const handleToggleDrawer = useCallback(() => {
		setIsDrawerOpen(prevState => !prevState);
	}, []);

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: isDrawerOpen,
				[classes.drawerClose]: !isDrawerOpen,
			})}
			classes={{
				paper: clsx(classes.drawerPaper, {
					[classes.drawerOpen]: isDrawerOpen,
					[classes.drawerClose]: !isDrawerOpen,
				})
			}}
		>
			<Box pt={1} display="flex" justifyContent="space-between" alignItems="center" position="absolute" right={0} width={ NAV_DRAWER_OPENED_WIDTH_PX }>
				<Box pl={2}>
					<Typography component='h2' variant='h5'>
						{ 'Pages' }
					</Typography>
				</Box>
				<Box px={0.75}>
					<IconButton
						aria-label={ isDrawerOpen ? 'Collapse navigation' : 'Expand navigation' }
						onClick={handleToggleDrawer}
					>
						{
							isDrawerOpen
								? <CloseDrawerIcon />
								: <OpenDrawerIcon />
						}
					</IconButton>
				</Box>
			</Box>
			<Box pt={6.25} />
			<List component="nav" aria-label="administration menu">
				{
					menuItems.map(({ text, Icon, route }, index) =>
						<ListItem
							key={route}
							selected={pathname.startsWith(route)}
							button
							onClick={onMenuItemClick(index)}
						>
							<ListItemIcon classes={{ root: classes.listIcon }}><Icon /></ListItemIcon>
							<ListItemText primary={text} />
						</ListItem>
					)
				}
			</List>
		</Drawer>
	);
};
