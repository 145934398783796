import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';

import { downloadBlob } from 'utils';

import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { selectAddiDataRequestDetails } from '../selectors';
import { addiDataRequestDetailsSlice } from '../slices';
import { getAddiDocuSign } from '../api';

export function* getAddiDocuSignSaga(): SagaIterator {
	const addiDataRequest = (yield select(selectAddiDataRequestDetails)) as ReturnType<typeof selectAddiDataRequestDetails>;

	if (addiDataRequest !== null) {
		try {
			yield put(addiDataRequestDetailsSlice.actions.downloadingAddiDocuSignStart());

			const { data } = (yield call(getAddiDocuSign, { id: addiDataRequest.id })) as AxiosResponse<Blob>;

			const fileName = `ADDI_DocuSign_${addiDataRequest.firstName ?? ''}_${addiDataRequest.lastName ?? ''}.pdf`;
			yield call(downloadBlob, fileName, data);

			yield put(addiDataRequestDetailsSlice.actions.downloadingAddiDocuSignEnd());
		} catch (e) {
			if ((e as AxiosError).response?.status === StatusCodes.NOT_FOUND) {
				yield put(alertSlice.actions.showAlert({ message: 'Document not found' }));
			} else {
				yield call(handleErrorSaga, e);
			}
			yield put(addiDataRequestDetailsSlice.actions.downloadingAddiDocuSignEnd());
		}
	}
}
