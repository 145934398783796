import { call, put, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from '../../../../errorHandling/sagas';

import { omicL4VisualizationSlice } from '../slices';
import { getParticipantsList, getTranscriptomicsData } from '../api';
import { GetParticipantsListResponse, GetTranscriptomicsDataResponse, InitHeatmapDataRequest } from '../types';
import { DEFAULT_GENES, MAX_GENES_COUNT, MAX_PARTICIPANTS_COUNT } from '../constants';

export function* initHeatmapDataSaga({ payload }: PayloadAction<InitHeatmapDataRequest>): Generator<StrictEffect, void> {
	try {
		const { data: initialParticipants } = (yield call(getParticipantsList, {
			searchId: payload.searchId,
			releaseName: payload.releaseName
		})) as AxiosResponse<GetParticipantsListResponse>;

		if (!initialParticipants.length) {
			yield put(omicL4VisualizationSlice.actions.initHeatmapDataSuccess({
				participants: [],
				genes: DEFAULT_GENES,
				mapping: ''
			}));
		} else {
			const { data } = (yield call(getTranscriptomicsData, {
				participants: initialParticipants.slice(0, MAX_PARTICIPANTS_COUNT),
				genes: DEFAULT_GENES.slice(0, MAX_GENES_COUNT)
			})) as AxiosResponse<GetTranscriptomicsDataResponse>;

			yield put(omicL4VisualizationSlice.actions.initHeatmapDataSuccess(data));
		}
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(omicL4VisualizationSlice.actions.initHeatmapDataFail());
	}
}
