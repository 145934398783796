import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { Box, Button, Container, FormControl, FormControlLabel, FormHelperText, LinearProgress, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { LoaderButton, TooltipWrapper } from 'components';
import { useFormik } from 'formik';
import { CreateManagementInformation } from '../types';
import * as yup from 'yup';
import { indexManagementSlice } from '../slices';
import { useDispatch, useSelector } from 'react-redux';

import {
	selectIsRequestLoading,
	selectIsRequestSent,
	selectIsCreateManagementConfigLoading,
	selectCreateManagementConfig
} from '../selectors';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		height: '100%',
		minHeight: 'inherit',
		backgroundColor: theme.palette.common.white
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		paddingLeft: theme.spacing(5),
		paddingTop: 0
	},
	downloadTemplateStartIcon: {
		marginRight: '5px'
	},
	fileFormControlRoot: {
		margin: 0
	},
}));

const MIN_LETTERS_FOR_FILE_NAME_TOOLTIP = 20;
const MAX_FILE_NAME_WIDTH = 250;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const CreateManagement: FunctionComponent = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const isRequestLoading = useSelector(selectIsRequestLoading);
	const isRequestSent = useSelector(selectIsRequestSent);
	const isCreateManagementConfigLoading = useSelector(selectIsCreateManagementConfigLoading);
	const createManagementConfig = useSelector(selectCreateManagementConfig);

	const validationSchema = yup.object().shape({
		name: yup.string()
			.min(2, createManagementConfig ? createManagementConfig.errors.minLength : '')
			.matches(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, createManagementConfig ? createManagementConfig.errors.indexName : '')
			.required(createManagementConfig ? createManagementConfig.errors.emptyField : ''),
		files: yup.object().shape({
			releaseDataFile: yup.mixed()
				.required(createManagementConfig ? createManagementConfig.errors.emptyFile : ''),
			elementsDataFile: yup.mixed()
				.required(createManagementConfig ? createManagementConfig.errors.emptyFile : ''),
			hierarchyDataFile: yup.mixed()
				.required(createManagementConfig ? createManagementConfig.errors.emptyFile : ''),
		})
	});

	const formik = useFormik<CreateManagementInformation>({
		initialValues: {
			name: '',
			files: {}
		},
		validationSchema,
		onSubmit: values => {
			dispatch(indexManagementSlice.actions.sendFormDataRequest({
				name: values.name,
				files: createManagementConfig ? createManagementConfig.datafiles.reduce((files: Record<string, File | null>, file) => {
					files[file.type] = formik.values.files[file.type];
					return files;
				}, {}) : {}
			}));
		}
	});

	useEffect(() => {
		dispatch(indexManagementSlice.actions.getCreateManagementConfig());
	}, [dispatch]);

	useMemo(() => {
		void formik.setFieldValue('files', createManagementConfig && createManagementConfig.datafiles.reduce((files: Record<string, File | null>, file) => {
			files[file.type] = null;
			return files;
		}, {}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createManagementConfig]);

	const onSubmit = useCallback(() => {
		formik.handleSubmit();
	}, [formik]);

	const onFileUploadClick = (e: React.ChangeEvent<HTMLInputElement>) => {
		void formik.setFieldValue(`files[${e.currentTarget.name}]`, e.currentTarget.files && e.currentTarget.files[0] || null);
	};

	return (
		<Box className={classes.containerWrapper}>
			<Box>
				<Box
					visibility={isCreateManagementConfigLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress/>
				</Box>
				{ !isCreateManagementConfigLoading && formik.values.files && Object.keys(formik.values.files).length !== 0 && createManagementConfig !== null &&
					<Container className={classes.container}>
						<Box pt={2}>
							<Typography component="h1" variant="h4">
								{createManagementConfig.title}
							</Typography>
							<Box pb={3}/>
							<Box>
								<Typography component="h2" variant="h4" style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
									{createManagementConfig.description}
								</Typography>
							</Box>
							<Box pb={3}/>
							{createManagementConfig.datafiles.map((item, index) => {
								const file = formik.values.files[createManagementConfig.datafiles[index].type];
								return <Box key={createManagementConfig.datafiles[index].type}>
									<Box>
										<Typography component="h2" variant="h4" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
											{`${index + 1}. ${createManagementConfig.datafiles[index].data.description}`}
										</Typography>
									</Box>
									<Box pb={3}/>
									<Box display="flex" alignItems="center">
										<FormControl error={ formik.touched.files && formik.errors.files && formik.touched.files[createManagementConfig.datafiles[index].type] && Boolean(formik.errors.files[createManagementConfig.datafiles[index].type]) }>
											<FormControlLabel
												classes={{ root: classes.fileFormControlRoot }}
												control={(
													<Box pr={2}>
														<input
															name={createManagementConfig.datafiles[index].type}
															style={{ display: 'none' }}
															accept={createManagementConfig.acceptedFileFormats.join(',')}
															type="file"
															onChange={onFileUploadClick}
														/>
														<Button
															color="primary"
															variant="outlined"
															component="span"
															style={{ whiteSpace: 'nowrap', width: '170px' }}
														>
															{createManagementConfig.datafiles[index].data.buttonText}
														</Button>
													</Box>
												)}
												label={
													file !== null ?
														<Box maxWidth={MAX_FILE_NAME_WIDTH} color={grey['900']}>
															<TooltipWrapper
																title={file.name} disabled={file.name.length < MIN_LETTERS_FOR_FILE_NAME_TOOLTIP}>
																<Typography component="div" variant="subtitle1" noWrap={true}>
																	{file.name}
																</Typography>
															</TooltipWrapper>
														</Box> :
														<Box color={grey['600']}>
															<Typography component="p" variant="caption">
																{createManagementConfig.datafiles[index].data.helperText}
															</Typography>
														</Box>
												}
											/>
											<FormHelperText>
												{ formik.touched.files && formik.errors.files && formik.touched.files[createManagementConfig.datafiles[index].type] && formik.errors.files[createManagementConfig.datafiles[index].type] }
											</FormHelperText>
										</FormControl>
									</Box>
									<Box pb={3}/>
								</Box>;
							}
							)}
							<Box>
								<Typography component="h2" variant="h4" style={{ fontSize: '1rem', fontWeight: 'bold' }}>
									{`${(createManagementConfig.datafiles.length + 1).toString()}. ${createManagementConfig.indexTextNameDescription}`}
								</Typography>
							</Box>
							<Box pb={3}/>
							<Box width={450}>
								<TextField
									name="name"
									id="index-creation-release-name"
									label={'Release Name'}
									variant="outlined"
									fullWidth
									value={formik.values.name}
									onChange={formik.handleChange}
									error={formik.touched.name && Boolean(formik.errors.name)}
									helperText={ formik.touched.name && formik.errors.name }
								/>
							</Box>
							<Box pb={3}/>
							<Box>
								<LoaderButton
									isLoading={isRequestLoading}
									wrapperBoxProps={{
										style: { display: 'inline' }
									}}
									ButtonProps={{
										disabled: isRequestSent,
										size: 'large',
										variant: 'contained',
										color: 'primary',
										onClick: onSubmit
									}}
								>
									{'Create'}
								</LoaderButton>
							</Box>
							<Box pb={3}/>
						</Box>
					</Container>
				}
			</Box>
		</Box>
	);
};

