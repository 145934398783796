import { BrowserCacheItem, StorageType } from './types';

export class BrowserCache {
	private readonly name: string;
	private storage: Storage;
	private readonly storagePrefix: string;

	constructor(name: string, type: StorageType) {
		this.name = name;
		this.storage = window[type];
		this.storagePrefix = `BrowserCache_${this.name}_`;
	}

	getItem(key: string): BrowserCacheItem {
		const cacheKey = this.getCacheKey(key);
		let info: BrowserCacheItem;

		const storageItemStr = this.storage.getItem(cacheKey);

		if (storageItemStr === null) {
			throw new Error(`BrowserCache item with "${cacheKey}" key is not found`);
		}

		try {
			info = JSON.parse(storageItemStr);
		} catch (e) {
			throw new Error(`BrowserCache item format with "${cacheKey}" key is invalid`);
		}

		return info;
	}

	setItem(key: string, value: BrowserCacheItem): void {
		const cacheKey = this.getCacheKey(key);

		this.storage.setItem(cacheKey, JSON.stringify(value));
	}

	removeItem(key: string) {
		const cacheKey = this.getCacheKey(key);

		this.storage.removeItem(cacheKey);
	}

	private getCacheKey(key: string): string {
		return `${this.storagePrefix}${key}`;
	}
}
