/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import {
	Box,
	Button,
	Paper,
	Typography
} from '@material-ui/core';
import { LoaderButton, SkeletonWrapper } from 'components';
import { addiDataRequestsListSlice } from '../slices';
import { useDispatch, useSelector } from 'react-redux';
import {
	selectAddiDataRequestsExporting,
	selectAddiDataRequestsList,
	selectAddiDataRequestsListLoading,
	selectFilters,
	selectTableCurrentPage,
	selectTableRowsPerPage,
	selectTableSort,
	selectTableTotalRowsCount
} from '../selectors';
import { Routes } from 'app/Routes';
import { getFormattedDate } from 'utils/getFormattedDate';
import { FiltersPanel } from '../../../components/FiltersPanel';
import {
	DataGrid,
	GridCellParams,
	GridColDef,
	GridPageChangeParams,
	GridSortModel,
	GridSortModelParams,
	GridValueFormatterParams
} from '@material-ui/data-grid';
import { SortOrder } from 'types';
import { TableSearchPanel } from './TableSearchPanel';
import { ROWS_PER_PAGE_100, ROWS_PER_PAGE_20, ROWS_PER_PAGE_50 } from 'features/administration/constants';

export const AddiDataRequests: FunctionComponent = () => {
	const dispatch = useDispatch();

	const defaultRowsPerPageOptions = [
		ROWS_PER_PAGE_20,
		ROWS_PER_PAGE_50,
		ROWS_PER_PAGE_100
	];

	const isAddiDataRequestsLoading = useSelector(selectAddiDataRequestsListLoading);
	const isAddiDataRequestsExporting = useSelector(selectAddiDataRequestsExporting);
	const addiDataRequestList = useSelector(selectAddiDataRequestsList);

	const tableRowsPerPage = useSelector(selectTableRowsPerPage);
	const tableTotalRowsCount = useSelector(selectTableTotalRowsCount);
	const tableCurrentPage = useSelector(selectTableCurrentPage);
	const tableFilters = useSelector(selectFilters);
	const tableSort = useSelector(selectTableSort);

	const gridSortModel: GridSortModel = useMemo(() => [
		{
			field: tableSort.orderBy,
			sort: tableSort.order
		}
	], [tableSort]);

	useEffect(() => {
		dispatch(addiDataRequestsListSlice.actions.getAddiDataRequestsList());
	}, [dispatch]);

	const handleFilterValueToggle = useCallback((filterName: string, filterValueName: string, newState: boolean) => {
		dispatch(addiDataRequestsListSlice.actions.setFilterValue({
			filterName,
			filterValueName,
			newState
		}));
	}, [dispatch]);

	const handleGridPageChange = useCallback((gridPageChangeParams: GridPageChangeParams): void => {
		dispatch(addiDataRequestsListSlice.actions.setCurrentPage(gridPageChangeParams.page));
	}, [dispatch]);

	const handleGridPageSizeChange = useCallback((gridPageChangeParams: GridPageChangeParams): void => {
		dispatch(addiDataRequestsListSlice.actions.setRowsPerPage(gridPageChangeParams.pageSize));
	}, [dispatch]);

	const handleGridSortModelChange = useCallback((params: GridSortModelParams): void => {
		if (params.sortModel[0].field !== gridSortModel[0].field || params.sortModel[0].sort !== gridSortModel[0].sort) {
			dispatch(addiDataRequestsListSlice.actions.setSorting(
				{
					orderBy: params.sortModel[0].field,
					order: params.sortModel[0].sort ?? ''
				}
			));
		}
	}, [dispatch, gridSortModel]);

	const handleExportAddiDataRequests = useCallback((): void => {
		dispatch(addiDataRequestsListSlice.actions.exportAddiDataRequests());
	}, [dispatch]);

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'Action',
			width: 95,
			sortable: false,
			headerAlign: 'center',
			renderCell: (params: GridCellParams) =>
				<Button
					color='primary'
					size='small'
					href={`${Routes.AddiDataRequests}/${params.value?.toString()}`}
					target="_blank"
				>
					{'View'}
				</Button>,
		},
		{
			field: 'lastSubmitDate',
			headerName: 'Submit Date',
			flex: 1,
			valueFormatter: (params: GridValueFormatterParams) => {
				return getFormattedDate(params.value?.toString() ?? '');
			},
		},
		{
			field: 'firstName',
			headerName: 'First Name',
			flex: 1
		},
		{
			field: 'lastName',
			headerName: 'Last Name',
			flex: 1
		},
		{
			field: 'workingEmail',
			headerName: 'Working Email',
			flex: 1
		},
		{
			field: 'status',
			headerName: 'Status',
			width: 110
		}
	];

	return <Box display="flex" height="100%" >
		<Box pt={3} overflow="hidden" width="100%">
			<Box px={5} display="flex" justifyContent="space-between" alignItems="center">
				<Typography component='h1' variant='h4'>
					{'ADDI Data Requests'}
				</Typography>
				<LoaderButton
					isLoading={isAddiDataRequestsExporting}
					ButtonProps={{
						disabled: isAddiDataRequestsExporting,
						size: 'large',
						variant: 'outlined',
						color: 'primary',
						onClick: handleExportAddiDataRequests
					}}
				>
					{'Export to csv'}
				</LoaderButton>
			</Box>
			{
				isAddiDataRequestsLoading &&
				<Box px={5} pt={3}>
					<Box display={'flex'} pb={2}>
						<SkeletonWrapper width={250} height={30} />
						<Box mr={2} />
						<SkeletonWrapper width={100} height={30} />
					</Box>
					{Array.from({ length: 4 }).map((e, i) => <Box pb={2} key={i}>
						<SkeletonWrapper width={'100%'} height={50} />
					</Box>)}
				</Box>
			}
			{
				!isAddiDataRequestsLoading &&
				addiDataRequestList &&
				<Box px={5} width={'100%'} pt={3}>
					<TableSearchPanel />
					<Box pb={2} />
					<Paper style={{ 'overflowX': 'auto' }}>
						<Box minWidth={'800px'}>
							<DataGrid
								rows={addiDataRequestList}
								columns={columns}
								pagination
								rowsPerPageOptions={defaultRowsPerPageOptions}
								onPageChange={handleGridPageChange}
								onPageSizeChange={handleGridPageSizeChange}
								page={tableCurrentPage}
								pageSize={tableRowsPerPage}
								rowCount={tableTotalRowsCount}
								paginationMode='server'
								sortingOrder={[SortOrder.Asc, SortOrder.Desc]}
								sortModel={gridSortModel}
								onSortModelChange={handleGridSortModelChange}
								sortingMode='server'
								autoHeight
								disableSelectionOnClick
								disableColumnFilter
								disableColumnMenu
							/>
						</Box>
					</Paper>
				</Box>
			}
			<Box pb={2} />
		</Box>
		<FiltersPanel
			filters={tableFilters}
			onFilterValueToggle={handleFilterValueToggle}
		/>
	</Box>;
};
