/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { AZURE_IP_INDEX, INDEX_LIST_TABLE_COLUMNS } from '../constants';
import {
	selectIsGetIndexListLoading,
	selectIndexList,
	selectIndexListTotalCount,
	selectCurrentIndex
} from '../selectors';
import { indexManagementSlice } from '../slices';
import { IndexListTableCell } from './IndexListTableCell';
import { mapCellsDataToNodes } from 'components/CustomTable/utils';
import { CustomTable } from 'components/CustomTable';
import { grey } from '@material-ui/core/colors';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';
import { Routes } from 'app/Routes';
import { Link } from 'react-router-dom';
import { IndexButtonsActions } from './IndexButonsActions';
import { DialogModal } from 'components';
import { ReleaseDataTools } from './ReleaseDataTools';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		paddingLeft: theme.spacing(HEADER_HEIGHT_SPACING / 2),
		paddingTop: 0,
	}
}));

export const IndexManagement: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const indexList = useSelector(selectIndexList);
	const isGetIndexListLoading = useSelector(selectIsGetIndexListLoading);
	const indexListTotalCount = useSelector(selectIndexListTotalCount);
	const currentIndex = useSelector(selectCurrentIndex);
	const [choosenIndex, setChoosenIndex] = useState<string | null>(null);
	const [isModalOpen, setModalState] = useState<boolean>(false);
	const [removingIndex, setRemovingIndex] = useState<string>('');

	const onOpenModal = (value: string) => {
		setRemovingIndex(value);
		setModalState(true);
	};

	const tableRows = useMemo(() => mapCellsDataToNodes(indexList, INDEX_LIST_TABLE_COLUMNS, (row, column) => {
		return <><IndexListTableCell indexList={row} tableColumnName={column.name} />
			{column.name === 'current' && currentIndex?.name === row.name && <div>{'TRUE'}</div>}
			{column.name === 'actions' && currentIndex?.name !== row.name && row.name !== AZURE_IP_INDEX && <IndexButtonsActions
				onDelete={() => onOpenModal(row.name)}
				onSetIndex={() => setChoosenIndex(row.name)} />}</>;
	})
	, [indexList, currentIndex]);

	const isTableInitialized = !(indexListTotalCount === 0 && isGetIndexListLoading);

	useEffect(() => {
		if (choosenIndex) {
			dispatch(indexManagementSlice.actions.setCurrentIndex(choosenIndex));
		}
	}, [choosenIndex, dispatch]);

	useEffect(() => {
		dispatch(indexManagementSlice.actions.getIndexList());
	}, [dispatch]);

	useEffect(() => {
		dispatch(indexManagementSlice.actions.getCurrentIndex());
	}, [dispatch]);

	const deleteIndex = () => {
		dispatch(indexManagementSlice.actions.deleteCurrentIndex(removingIndex));
		setModalState(false);
	};

	return (
		<Box display="flex" flexDirection="column" justifyContent="space-between">
			<DialogModal
				isOpen={ isModalOpen }
				headerText={ 'Delete Confirmation' }
				cancelButtonText={ 'Cancel' }
				submitButtonText={ 'Submit' }
				onCancel={ () => setModalState(false) }
				onSubmit={ () => deleteIndex() }
			>
				<Typography component="span" variant="subtitle1">
					{ `Are you sure to delete index ${removingIndex}?` }
				</Typography>
			</DialogModal>
			<Box>
				<Box
					visibility={isGetIndexListLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress/>
				</Box>
				<Container className={classes.container}>
					<Box pt={2}/>
					<Typography component="h1" variant="h4">
						{'Index Management'}
					</Typography>
					<Box display="flex" justifyContent="space-between" pt={4} pb={2}>
						<Box>
							<Typography component="h6" variant="h6">
								{`Total indexes count: ${indexListTotalCount}`}
							</Typography>
							<Typography component="p" variant="inherit">
								{'Please note, that currently we support only 15 indexes. If the number exceed, please delete unused indexes.'}
							</Typography>
						</Box>
						<Box display="flex" justifyContent="flex-end" pb={0.5}>
							{tableRows.length > 0 &&
							<Button
								color="primary"
								variant="text"
								size="large"
								component={Link}
								to={`${Routes.IndexManagement}/create`}
							>
								{'Create Index'}
							</Button>
							}
						</Box>
					</Box>
					{isTableInitialized && !isGetIndexListLoading &&
					<CustomTable
						columns={INDEX_LIST_TABLE_COLUMNS}
						rows={tableRows}
						noRowsElement={
							<Box pt={14} color={grey['600']}>
								<Typography
									component="p"
									variant="h3"
									align="center"
									style={{ whiteSpace: 'pre-wrap' }}
								>
									{'No index items found'}
								</Typography>
								<Box pt={4} display="flex" justifyContent="center">
									<Button
										color="primary"
										variant="contained"
										size="large"
										component={Link}
										to={`${Routes.IndexManagement}/create`}
									>
										{'Create Index'}
									</Button>
								</Box>
							</Box>
						}
						tableProps={{ id: 'doi-list-table' }}
						tableContainerProps={{
							style: {
								maxHeight: `calc(100vh - ${theme.spacing(HEADER_HEIGHT_SPACING)}px - 200px)`
							}
						}}
					/>
					}
					<Box pb={4} />
					<ReleaseDataTools />
					<Box pb={4} />
				</Container>
			</Box>
		</Box>
	);
};
