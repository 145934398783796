import React, { FunctionComponent } from 'react';
import { StatusCodes } from 'http-status-codes';
import { useTranslation } from 'react-i18next';

import { BaseErrorPage } from './BaseErrorPage';


export const NotFoundErrorPage: FunctionComponent = () => {
	const { t } = useTranslation();

	return (
		<BaseErrorPage
			errorCode={ StatusCodes.NOT_FOUND }
			errorText={ t('errors.notFound.errorText') }
			errorDescription={ t('errors.notFound.errorDescription') }
		/>
	);
};
