import { SagaIterator } from 'redux-saga';
import { Effect } from '@redux-saga/types';
import { call, all, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { search, searchPublic } from '../api';
import {
	DefaultFacetsConfig,
	DetailsFacetsConfig,
	SelectedFacets,
	SearchResponse,
	Facets
} from '../types';
import { getFilterString, getSearchOptions } from '../utils';
import { CaseSetEntity } from '../features/caseSet';
import { selectUserDataAccessStatus } from 'auth/selectors';
import { UserDataAccessStatuses } from 'auth/types';

interface GetFilteredFacetsValuesSagaParams {
	defaultFacetsConfig: DefaultFacetsConfig
	detailsFacetsConfig: DetailsFacetsConfig
	selectedFacets: SelectedFacets
	selectedCaseSetEntities: CaseSetEntity[]
	filterQueryString: string
}

export function* getFilteredFacetsValuesSaga({
	defaultFacetsConfig,
	detailsFacetsConfig,
	selectedFacets,
	selectedCaseSetEntities,
	filterQueryString
}: GetFilteredFacetsValuesSagaParams): SagaIterator<Facets> {
	const facetFilterRequests: Effect[] = [];
	const facetNames: string[] = [];
	const filteredFacets: Facets = {};
	const userDataAccess = (yield select(selectUserDataAccessStatus)) as UserDataAccessStatuses;

	for (const facetName in selectedFacets) {
		const facetFilterString = (yield call(
			getFilterString, {
				selectedFacets,
				detailsFacetsConfig,
				selectedCaseSetEntities,
				excludeFacets: [facetName],
				filterQueryString
			}
		)) as ReturnType<typeof getFilterString>;
		const facetFilterOptions = (yield call(getSearchOptions, {
			searchFacets: [facetName],
			detailsFacetsConfig,
			filterString: facetFilterString,
			orderbyString: '',
			selectFields: defaultFacetsConfig.statusFacets,
			facetValuesMaxCount: defaultFacetsConfig.facetValuesMaxCount
		})) as ReturnType<typeof getSearchOptions>;

		facetFilterRequests.push(call(userDataAccess === UserDataAccessStatuses.HasAccess ? search : searchPublic , facetFilterOptions));
		facetNames.push(facetName);
	}

	const facetFilterResponses = (yield all(facetFilterRequests)) as AxiosResponse<SearchResponse>[];

	facetFilterResponses.forEach((facetFilterResponse, i) => {
		const facetName = facetNames[i];
		const facet = facetFilterResponse.data['@search.facets'][facetName];
		if (facet) {
			filteredFacets[facetName] = facet;
		}
	});

	return filteredFacets;
}
