import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { downloadBlob } from 'utils';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { downloadReadFileList } from '../api';
import { DownloadReadFileListRequest, DownloadReadFileListResponse } from '../types';

export function* downloadReadFileListSaga({ payload }: PayloadAction<DownloadReadFileListRequest>): SagaIterator {
	try {
		yield put(doiSlice.actions.downloadReadFileListStart());

		const response = (yield call(downloadReadFileList, payload)) as AxiosResponse<DownloadReadFileListResponse>;

		yield call(downloadBlob,'file-list.txt', response.data);

		yield put(doiSlice.actions.downloadReadFileListEnd());
	} catch (e) {
		yield put(doiSlice.actions.downloadReadFileListEnd());
		yield call(handleErrorSaga, e);
	}
}
