export const MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS = 500;

export const NAV_DRAWER_OPENED_WIDTH_PX = 264;
export const NAV_DRAWER_CLOSED_WIDTH_PX = 56;

export const FILTERS_DRAWER_OPENED_WIDTH_PX = 264;
export const FILTERS_DRAWER_CLOSED_WIDTH_PX = 56;

export const ROWS_PER_PAGE_20 = 20;
export const ROWS_PER_PAGE_50 = 50;
export const ROWS_PER_PAGE_100 = 100;
