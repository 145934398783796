import { FormikProps } from 'formik';
import { AddiDataRequestEditFormFields } from '../types/UpdateAddiDataRequest';
import React, { FunctionComponent } from 'react';
import { Box, Paper, TextField, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAddiDataRequestDetails, selectIsAddiDataRequestLoading, selectIsAddiManagementLoading } from '../selectors';
import { SkeletonWrapper } from 'components';

export interface UserNotesSectionProps {
	form: FormikProps<AddiDataRequestEditFormFields>
}

// eslint-disable-next-line arrow-body-style
export const AddiNotesSection: FunctionComponent<UserNotesSectionProps> = ({ form }) => {

	const isAddiDataRequestLoading = useSelector(selectIsAddiDataRequestLoading);
	const isAddiManagementLoading = useSelector(selectIsAddiManagementLoading);
	const addiDataRequest = useSelector(selectAddiDataRequestDetails);

	return (
		<Box width={630} mb={4} mr={4}>
			{
				isAddiDataRequestLoading ?
					<SkeletonWrapper
						width={'100%'}
						height={170}
					/> :
					addiDataRequest && form &&
					<Paper>
						<Box p={2}>
							<Box mb={2.5} display="flex" flexDirection="column">
								<Typography
									variant="h5"
									component="h2"
								>
									{'Notes'}
								</Typography>
								<Box pb={2} />
								<Box display="flex" justifyContent="space-between">
									<TextField
										name="notes"
										id="notes-input"
										label={'Notes'}
										margin='normal'
										InputLabelProps={{
											shrink: true,
										}}
										fullWidth
										variant='outlined'
										multiline
										disabled={isAddiManagementLoading}
										value={form.values.notes}
										onChange={form.handleChange}
										onBlur={form.handleBlur}
										error={form.touched.notes && Boolean(form.errors.notes)}
										helperText={form.touched.notes && form.errors.notes}
									/>
								</Box>
							</Box>
						</Box>
					</Paper>
			}
		</Box>
	);
};
