import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { GetUserDoiListRequest, GetUserDoiListResponse } from '../types';

export function* getUserDoiList(params: GetUserDoiListRequest): SagaIterator<AxiosResponse<GetUserDoiListResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};

	return (yield call(networkManager.fetch, Urls.GetUserDoiList, requestOptions, params)) as AxiosResponse<GetUserDoiListResponse>;
}
