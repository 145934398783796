import { takeEvery, StrictEffect } from 'redux-saga/effects';

import { tabsSlice, backToDataSearchAction } from '../slices';

import { getProceedContentSaga } from './getProceedContentSaga';
import { backToDataSearchSaga } from './backToDataSearchSaga';

export function* rootProceedSearchResultSaga(): Generator<StrictEffect, void> {
	yield takeEvery(tabsSlice.actions.getProceedContentRequest.type, getProceedContentSaga);
	yield takeEvery(backToDataSearchAction.type, backToDataSearchSaga);
}
