import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';
import { EntityCount } from '../types';

interface EntitiesCountState {
	entitiesCount: EntityCount[]
}

const initialState: EntitiesCountState = {
	entitiesCount: []
};

export const entitiesCountSlice = createSlice({
	name: `${ACTIONS_PREFIX}.entitiesCount`,
	initialState,
	reducers: {
		updateEntitiesCount: (state: EntitiesCountState, { payload }: PayloadAction<{
			entitiesCount: EntityCount[]
		}>) => {
			state.entitiesCount = payload.entitiesCount;
		}
	}
});
