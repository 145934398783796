import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../../../errorHandling/sagas';

import { getCaseSetConfig } from '../api';
import { GetCaseSetConfigResponse } from '../types';
import { selectCaseSetConfig } from '../selectors';
import { caseSetSlice } from '../slices';

export function* caseSetConfigSaga(): SagaIterator {
	try {
		const currentCaseSetConfig = (yield select(selectCaseSetConfig)) as ReturnType<typeof selectCaseSetConfig>;

		if (currentCaseSetConfig.identifierTypes.length > 0) {
			yield put(caseSetSlice.actions.modalOpenSuccess({
				identifierTypes: currentCaseSetConfig.identifierTypes
			}));
		} else {
			const response = (yield call(getCaseSetConfig)) as AxiosResponse<GetCaseSetConfigResponse>;

			yield put(caseSetSlice.actions.modalOpenSuccess({
				identifierTypes: response.data.identifierTypes
			}));
		}
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(caseSetSlice.actions.modalOpenFail());
	}
}
