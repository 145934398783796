import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { UserDuaInformation } from '../types';

export const sendAccessRequest = async (data: UserDuaInformation): Promise<AxiosResponse> => {
	const method: Method = 'POST';

	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data
	};
	return networkManager.fetch(Urls.SendDuaSubmitRequest, requestOptions);
};
