import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { SearchState } from '../types';

import { getSearchStateSaga } from './getSearchStateSaga';
import { searchRequestSaga } from './searchRequestSaga';

export function* definedSearchRequestSaga(): SagaIterator {
	const searchState = (yield call(getSearchStateSaga)) as SearchState;

	yield call(searchRequestSaga, searchState);
}
