import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementUserSlice } from '../slices';
import { sendResearcherSurvey } from '../api';
import { selectUser } from '../selectors';

import { getUserSaga } from './getUserSaga';
import { AxiosError } from 'axios';
import { ErrorResponse } from 'features/errorHandling/types';

export function* sendResearcherSurveySaga(): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.sendResearcherSurveyStart());

			yield call(sendResearcherSurvey, { id: user.id });
			yield call(getUserSaga, user.id);

			yield put(usersManagementUserSlice.actions.sendResearcherSurveyEnd());
		} catch (e) {
			yield put(usersManagementUserSlice.actions.sendResearcherSurveyEnd());
			yield call(handleErrorSaga, e as AxiosError<ErrorResponse>);
		}
	}
}
