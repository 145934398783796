import { call, put, StrictEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from '../../../../errorHandling/sagas';

import { omicL4VisualizationSlice } from '../slices';
import { getTranscriptomicsData } from '../api';
import { GetHeatmapDataRequest, GetTranscriptomicsDataResponse } from '../types';
import { ErrorResponse } from 'features/errorHandling/types';

export function* updateHeatmapDataSaga({ payload }: PayloadAction<GetHeatmapDataRequest>): Generator<StrictEffect, void> {
	try {
		const { data } = (yield call(getTranscriptomicsData, {
			participants: payload.participants,
			genes: payload.genes
		})) as AxiosResponse<GetTranscriptomicsDataResponse>;

		yield put(omicL4VisualizationSlice.actions.updateHeatmapDataSuccess(data));
	} catch (e) {
		yield call(handleErrorSaga, e as AxiosError<ErrorResponse>);
		yield put(omicL4VisualizationSlice.actions.updateHeatmapDataFail());
	}
}
