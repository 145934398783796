import React, { useState, useCallback, useEffect, useRef, FunctionComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
// import HighchartsCustomEvents from 'highcharts-custom-events';
import ReactResizeDetector from 'react-resize-detector';
import clsx from 'clsx';

import { Box, Button, IconButton, Typography, Paper, Theme } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { FACET_CARD_DRAG_ICON_GLOBAL_CLASS } from 'styles/constants.ui';

import { TooltipWrapper } from '../../../components';

import { Facet, SelectedFacetValue, FacetsBaseProps } from '../types';
import { getHighchartsOptions, getFacetTotalCount } from '../utils';
import { ACTIVE_FACET_CARD_ELEVATION, DEFAULT_FACET_CARD_ELEVATION } from '../constants.ui';

import { ExpandableTextPanel } from './ExpandableTextPanel';

// const highcharts = HighchartsCustomEvents(Highcharts);

const FACET_CARD_SPACING_PADDING = 2;
const FACET_CARD_FOOTER_SPACING_PADDING = 2;
const MIN_LETTERS_FOR_CARD_HEADER_TOOLTIP = 20;

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(FACET_CARD_SPACING_PADDING),
		position: 'relative',
		overflow: 'hidden',
		cursor: 'grab',
		'&:hover': {
			boxShadow: theme.shadows[ACTIVE_FACET_CARD_ELEVATION],
			[`& .${FACET_CARD_DRAG_ICON_GLOBAL_CLASS}`]: {
				display: 'block'
			}
		}
	},
	dragIcon: {
		display: 'none',
		cursor: 'grab'
	},
	header: {
		cursor: 'auto'
	},
	hideButton: {
		padding: 0
	},
	charts: {
		overflow: 'hidden',
		WebkitOverflowScrolling: 'auto'
	},
	footer: {
		padding: theme.spacing(FACET_CARD_FOOTER_SPACING_PADDING),
		paddingTop: 0,
		background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)',
		position: 'absolute',
		bottom: 0,
		left: 0,
		height: '3.375rem',
		width: '100%',
		borderRadius: theme.shape.borderRadius,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
	},
	footerButton: {
		position: 'absolute',
		right: theme.spacing(FACET_CARD_FOOTER_SPACING_PADDING),
		bottom: theme.spacing(FACET_CARD_FOOTER_SPACING_PADDING)
	},
	footerExpanded: {
		background: 'transparent',
		alignItems: 'flex-start'
	},
	footerButtonExpanded: {
		bottom: '3.625rem',
	}
}));

export interface FacetCardProps extends FacetsBaseProps {
	hideFacetInCardView: (facetName: string) => void
	facetName: string
	facet: Facet
	selectedFacetValues: SelectedFacetValue[]
	width: number
	height: number
	barHeight: number
	barPadding: number
	highchartFooterHeight: number
	id?: string
}

export const FacetCard: FunctionComponent<FacetCardProps> = ({
	hideFacetInCardView,
	facetName,
	facet,
	selectedFacetValues,
	selectFacetValue,
	unselectFacetValue,
	width,
	height,
	barHeight,
	barPadding,
	highchartFooterHeight,
	id
// eslint-disable-next-line sonarjs/cognitive-complexity
}: FacetCardProps) => {
	const classes = useStyles();
	const theme = useTheme();

	const [options, setOptions] = useState(() => getHighchartsOptions(
		facetName,
		facet,
		selectedFacetValues,
		barHeight,
		barPadding,
		highchartFooterHeight,
		selectFacetValue,
		unselectFacetValue));

	const [headerHeight, setHeaderHeight] = useState(0);
	const [isCardExpanded, setIsCardExpanded] = useState(false);
	const [isVisibleHideButton, setIsVisibleHideButton] = useState(false);

	const headerRef = useRef<HTMLDivElement>(null);
	const bodyRef = useRef<HTMLDivElement>(null);

	const facetTotalCount = getFacetTotalCount(facet.values,selectedFacetValues);

	const toggleCardSize = useCallback(() => {
		setIsCardExpanded(!isCardExpanded);
	},
	[isCardExpanded]);

	const handleChartOnFocus = useCallback((focusEvent: React.FocusEvent<HTMLElement | SVGUseElement>) => {
		const bar = focusEvent.target as SVGUseElement;
		if (bar.nodeName === 'rect' && bodyRef && bodyRef.current) {
			const chartViewPortHeight = bar.viewportElement?.scrollHeight || 0;
			if (chartViewPortHeight - bar.x.baseVal.value > bodyRef.current.clientHeight) {
				const focusScroll = chartViewPortHeight - bodyRef.current.clientHeight - bar.x.baseVal.value;

				setTimeout(() => {
					if (bodyRef && bodyRef.current) {
						bodyRef.current.scrollTop = focusScroll;
					}
				});
			}
		}
	}, []);

	const onHideButtonMouseEnter = useCallback(() => {
		setIsVisibleHideButton(true);
	}, []);

	const onHideButtonMouseLeave = useCallback(() => {
		setIsVisibleHideButton(false);
	}, []);

	const onResize = useCallback(() => {
		if (headerRef && headerRef.current) {
			setHeaderHeight(headerRef.current.clientHeight);
		}
	},
	[]);

	const hideFacet = useCallback(() => {
		hideFacetInCardView(facetName);
	},
	[facetName, hideFacetInCardView]);

	useEffect(() => {
		setOptions(getHighchartsOptions(
			facetName,
			facet,
			selectedFacetValues,
			barHeight,
			barPadding,
			highchartFooterHeight,
			selectFacetValue,
			unselectFacetValue));
	},[barHeight, barPadding, facet, facetName, highchartFooterHeight, selectFacetValue, selectedFacetValues, unselectFacetValue]);

	useEffect(() => {
		onResize();
	}, [facet.values.length, onResize]);

	const cardHeaderText = facet.displayName || facetName;

	return (
		<Paper id={id} elevation={DEFAULT_FACET_CARD_ELEVATION} style={{ width, height: isCardExpanded ? 'auto' : height, minHeight: height }} className={classes.container}>
			<ReactResizeDetector handleHeight onResize={onResize}>
				<header ref={headerRef} className={classes.header}>
					<Box
						position="relative"
						color={grey['900']}
						display='flex'
						flexDirection='row'
						alignItems='center'
						justifyContent='space-between'
					>
						<Box
							position="absolute"
							color={ grey['400'] }
							top={-14}
							left={-17}
							className={ clsx(FACET_CARD_DRAG_ICON_GLOBAL_CLASS, classes.dragIcon) }
						>
							<DragIndicatorIcon fontSize="small" />
						</Box>
						<TooltipWrapper title={ cardHeaderText } disabled={ cardHeaderText.length < MIN_LETTERS_FOR_CARD_HEADER_TOOLTIP }>
							<Typography
								aria-label={ `Category for filtration is ${cardHeaderText}, number of matches is ${facetTotalCount}` }
								component='h3' variant='h6' noWrap>
								{ cardHeaderText }
							</Typography>
						</TooltipWrapper>
						<Box position="relative">
							<>
								<Box
									position="absolute"
									width={38}
									height={38}
									right={-8}
									top={-8}
									onMouseEnter={onHideButtonMouseEnter}
									onMouseLeave={onHideButtonMouseLeave}
								/>
								{
									isVisibleHideButton
										? <IconButton
											aria-label='hide facet'
											onClick={ hideFacet }
											classes={{ root: classes.hideButton }}
											onMouseEnter={onHideButtonMouseEnter}
											onMouseLeave={onHideButtonMouseLeave}
										>
											<CloseIcon />
										</IconButton>
										: <Typography
											aria-hidden
											component='span'
											variant='subtitle1'>
											{facetTotalCount}
										</Typography>
								}
							</>
						</Box>
					</Box>
					<Box color={grey['800']}>
						{ facet.description && <ExpandableTextPanel text={facet.description} /> }
					</Box>
					<Box pb={3} />
				</header>
			</ReactResizeDetector>
			<div ref={bodyRef} className={classes.charts} onFocus={ handleChartOnFocus } style={{ height: isCardExpanded ? 'auto' : height - headerHeight - theme.spacing(FACET_CARD_SPACING_PADDING) }}>
				{facet.values && facet.values.length > 0 &&
						<HighchartsReact
							highcharts={ Highcharts }
							options={ options }
						/>
				}
			</div>
			{Number(options.chart?.height) + headerHeight + theme.spacing(FACET_CARD_SPACING_PADDING) > height &&
				<>
					{!isCardExpanded &&
						<Box className={ `${classes.footer} ${isCardExpanded ? classes.footerExpanded : ''}`} />
					}
					<Button
						aria-label={ 'Show all items' }
						aria-expanded={isCardExpanded}
						classes={{ root: `${classes.footerButton} ${isCardExpanded ? classes.footerButtonExpanded : ''}` }}
						variant='outlined'
						color='primary'
						onClick={toggleCardSize}>
						<Typography component='span' variant='button' style={{ fontSize: '0.75rem' }}>
							{ isCardExpanded ? 'Minimize' : 'Expand' }
						</Typography>
					</Button>
				</>
			}
		</Paper>
	);
};
