import { TokenInfo } from 'auth-library-web';

export enum AuthManagerTypes {
	Portal = 'Portal',
	Cli = 'Cli'
}

export interface AuthManager {
	getTokenInfo(): Promise<TokenInfo | null>
	signIn(): void
	signOut(): void
}
