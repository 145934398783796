import React, { FunctionComponent } from 'react';

import Typography from '@material-ui/core/Typography';

interface CustomTableTextCellProps {
	value: string | boolean | null
}

export const CustomTableTextCell: FunctionComponent<CustomTableTextCellProps> = ({
	value
}) =>
	<Typography component="span" variant="body2">
		{ value }
	</Typography>
;
