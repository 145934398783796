import { ClientSettings } from 'auth-library-web';

import {
	CLIENT_ID,
	POLICY_SIGN_IN_SIGN_UP,
	REGISTERED_REDIRECT_PATH,
	TENANT
} from './constants';

export const authConfiguration: ClientSettings = {
	tenant: TENANT,
	clientId: CLIENT_ID,
	loginRegisteredRedirectUri: `${window.location.origin}${REGISTERED_REDIRECT_PATH}`,
	codeScopes: ['openid', 'profile', 'offline_access', CLIENT_ID],
	tokenScopes: ['openid', 'profile', 'offline_access', CLIENT_ID],
	defaultPolicy: POLICY_SIGN_IN_SIGN_UP
};
