import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';

import { takeLatestWithDelay } from 'utils';

import { MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS } from '../constants';
import { doiSlice } from '../slices';

import { getUserOmicsSaga } from './getUserOmicsSaga';
import { createUserDoiSaga } from './createUserDoiSaga';
import { downloadCreateFileListSaga } from './downloadCreateFileListSaga';
import { getUserDoiSaga } from './getUserDoiSaga';
import { downloadReadFileListSaga } from './downloadReadFileListSaga';
import { getUserDoiListSaga } from './getUserDoiListSaga';
import { getDoiConfigSaga } from './getDoiConfigSaga';
import { saveUserDoiSaga } from './saveUserDoiSaga';

export function* rootDoiSaga(): SagaIterator {
	yield takeEvery(doiSlice.actions.getUserOmics.type, getUserOmicsSaga);
	yield takeEvery(doiSlice.actions.createUserDoi.type, createUserDoiSaga);
	yield takeEvery(doiSlice.actions.saveUserDoi.type, saveUserDoiSaga);
	yield takeEvery(doiSlice.actions.downloadCreateFileList.type, downloadCreateFileListSaga);
	yield takeEvery(doiSlice.actions.getUserDoi.type, getUserDoiSaga);
	yield takeEvery(doiSlice.actions.downloadReadFileList.type, downloadReadFileListSaga);
	yield takeLatestWithDelay(MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS, [
		doiSlice.actions.getUserDoiList.type,
		doiSlice.actions.setUserDoiListCurrentPage.type,
		doiSlice.actions.setUserDoiListRowsPerPage.type,
	], getUserDoiListSaga);
	yield takeEvery(doiSlice.actions.getDoiConfig.type, getDoiConfigSaga);
}
