import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { facetsPanelSlice, facetsSlice } from '../slices';
import { getDefaultFacetsConfig, getDetailsFacetsConfig } from '../api';
import { DefaultFacetsConfig, DetailsFacetsItemConfig } from '../types';
import { mapFacetsConfigToFacetsGroup, mapFacetsConfigToEntitiesExpandState } from '../utils';

import { initialSearchSaga } from './initialSearchSaga';

export function* getFacetsConfigSaga(): SagaIterator {
	try {
		const [defaultFacetsConfigResponse, detailsFacetsConfigResponse] = (yield all([
			call(getDefaultFacetsConfig),
			call(getDetailsFacetsConfig)
		])) as [AxiosResponse<{value: DefaultFacetsConfig}>, AxiosResponse<DetailsFacetsItemConfig[]>];

		const defaultFacetsConfig = defaultFacetsConfigResponse.data.value;
		const detailsFacetsListPanel = detailsFacetsConfigResponse.data;
		const detailsFacetsConfig = (yield call(mapFacetsConfigToFacetsGroup, detailsFacetsListPanel)) as ReturnType<typeof mapFacetsConfigToFacetsGroup>;

		yield put(facetsSlice.actions.getFacetsConfigSuccess({
			defaultFacetsConfig,
			detailsFacetsConfig,
			detailsFacetsListPanel
		}));

		const entitiesExpandState = (yield call(mapFacetsConfigToEntitiesExpandState, detailsFacetsListPanel)) as ReturnType<typeof mapFacetsConfigToEntitiesExpandState>;
		yield put(facetsPanelSlice.actions.setEntitiesExpandState(entitiesExpandState));

		yield call(initialSearchSaga);
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(facetsSlice.actions.getFacetsConfigFail());
	}
}
