import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { RejectAddiDataRequest } from '../types';

export function* rejectAddiData({ id, rejectionReason }: RejectAddiDataRequest): SagaIterator<AxiosResponse> {
	const method: Method = 'POST';

	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: { rejectionReason }
	};

	return (yield call(networkManager.fetch, Urls.RejectAddiDataRequest, requestOptions, { id })) as AxiosResponse;
}
