import { takeEvery, debounce, StrictEffect, call } from 'redux-saga/effects';

import { facetsSlice, searchDetailsSlice, proceedSlice } from '../slices';
import { DEBOUNCE_SEARCH_MS } from '../constants';

import { getFacetsConfigSaga } from './getFacetsConfigSaga';
import { resetPaginationSaga } from './resetPaginationSaga';
import { getDownloadMetadataSaga } from './getDownloadMetadataSaga';
import { getFieldsConfigSaga } from './getFieldsConfigSaga';
import { getProceedTabsSaga } from './getProceedTabsSaga';
import { proceedSaga } from './proceedSaga';
import { definedSearchRequestSaga } from './definedSearchRequestSaga';


export function* rootSearchSaga(): Generator<StrictEffect, void> {
	yield takeEvery(facetsSlice.actions.getFacetsConfigRequest.type, getFacetsConfigSaga);
	yield takeEvery(searchDetailsSlice.actions.getFieldsConfigRequest.type, getFieldsConfigSaga);
	yield debounce(DEBOUNCE_SEARCH_MS, [
		facetsSlice.actions.selectValue.type,
		facetsSlice.actions.unselectValue.type,
		facetsSlice.actions.unselectAllValues.type,
		searchDetailsSlice.actions.selectRowsPerPage.type,
		searchDetailsSlice.actions.requestSorting.type,
		facetsSlice.actions.unselectExtCaseSet.type
	], function* () {
		yield call(resetPaginationSaga);
		yield call(definedSearchRequestSaga);
	});
	yield debounce(DEBOUNCE_SEARCH_MS, searchDetailsSlice.actions.setPageNumber.type, definedSearchRequestSaga);
	yield debounce(DEBOUNCE_SEARCH_MS, [facetsSlice.actions.getDownloadMetadataLink.type], getDownloadMetadataSaga);

	yield takeEvery(proceedSlice.actions.getProceedTabsRequest.type, getProceedTabsSaga);
	yield takeEvery(proceedSlice.actions.saveSearchParametersRequest.type, proceedSaga);
}
