import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';

import { SortOrder } from '../../../types';

import {
	DefaultFacetsConfig,
	DetailsFacetsConfig,
	SelectedFacets,
	SearchRequestOptions
} from '../types';
import { selectSearchFieldsConfig } from '../selectors';
import { getFilterString, getSearchOptions, getOrderbyString } from '../utils';
import { CaseSetEntity } from '../features/caseSet';

interface GetSearchRequestOptionsSagaParams {
	defaultFacetsConfig: DefaultFacetsConfig
	detailsFacetsConfig: DetailsFacetsConfig
	selectedFacets: SelectedFacets
	selectedCaseSetEntities: CaseSetEntity[]
	filterQueryString: string
	pageNumber: number
	rowsPerPage: number
	order: SortOrder
	orderBy: string
}

export function* getSearchRequestOptionsSaga({
	defaultFacetsConfig,
	detailsFacetsConfig,
	selectedFacets,
	selectedCaseSetEntities,
	filterQueryString,
	pageNumber,
	rowsPerPage,
	order,
	orderBy,
}: GetSearchRequestOptionsSagaParams): SagaIterator<SearchRequestOptions> {
	const orderbyString = (yield call(getOrderbyString, orderBy, order)) as ReturnType<typeof getOrderbyString>;
	const filterString = (yield call(getFilterString, {
		selectedFacets,
		detailsFacetsConfig,
		selectedCaseSetEntities,
		filterQueryString
	})) as ReturnType<typeof getFilterString>;

	const fieldsConfig = (yield select(selectSearchFieldsConfig)) as ReturnType<typeof selectSearchFieldsConfig>;

	return (yield call(getSearchOptions, {
		searchFacets: defaultFacetsConfig.availableFacets,
		detailsFacetsConfig,
		filterString,
		orderbyString,
		selectFields: fieldsConfig.map(fieldConfig => fieldConfig.name),
		facetValuesMaxCount: defaultFacetsConfig.facetValuesMaxCount,
		pageNumber,
		rowsPerPage
	})) as ReturnType<typeof getSearchOptions>;
}
