import { SagaIterator } from 'redux-saga';
import { call, put, take, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { getPageConfigurationSaga } from 'features/requestAccess/sagas';

import { usersManagementUserSlice } from '../slices';

import { getPossibleUserRolesConfigSaga } from './getPossibleUserRolesConfigSaga';
import { getUserSaga } from './getUserSaga';

export function* initUsersManagementUserFlowSaga(): SagaIterator {
	const { payload } = (yield take(usersManagementUserSlice.actions.initUsersManagementUser.type)) as PayloadAction<{ id: string }>;

	yield put(usersManagementUserSlice.actions.initUsersManagementUserStart());

	yield all([
		call(getUserSaga, payload.id),
		call(getPossibleUserRolesConfigSaga),
		call(getPageConfigurationSaga)
	]);

	yield put(usersManagementUserSlice.actions.initUsersManagementUserEnd());
}
