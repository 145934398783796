/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
/* eslint-disable @typescript-eslint/no-extra-parens */
import { Box, LinearProgress } from '@material-ui/core';

import { Routes } from 'app/Routes';
import { RestrictedRoute } from 'components/RestrictedRoute';

import { selectIsAccountLoading, selectIsUserAuthorized, selectIsUserDataSubmitter, selectUserDataAccessStatus } from 'auth/selectors';
import { ProceedSearchResult } from 'features/proceedSearchResult';
import { TotpAuthentication } from 'features/totpAuthentication';
import { WPFrame, selectWPPagesConfig, selectIsWPConfigLoading } from 'features/wpFrame';
import type { WPPageConfig } from 'features/wpFrame/types';
import { RequestAccess } from 'features/requestAccess';
import { Header } from 'features/header';
import { NotFoundErrorPage } from 'features/errorHandling';
import { UserDataAccessStatuses } from 'auth/types';
import { SearchLayout } from 'features/search/components/SearchLayout';
import { SubmitData } from 'features/submitData/components';

export const PublicLayout: FunctionComponent = () => {
	const wpPagesConfig = useSelector(selectWPPagesConfig);
	const isWPConfigLoading = useSelector(selectIsWPConfigLoading);
	const isAccountLoading = useSelector(selectIsAccountLoading);
	const isUserAuthorized = useSelector(selectIsUserAuthorized);
	const isUserHasAccessToData = useSelector(selectUserDataAccessStatus) && UserDataAccessStatuses.HasAccess;
	const isUserDataSubmitter = useSelector(selectIsUserDataSubmitter);

	return (
		<>
			<Header />
			{isWPConfigLoading ?
				<Box position="sticky" top={0}>
					<LinearProgress />
				</Box> :
				!isAccountLoading && <Switch>
					<Route exact path='/'>
						<Redirect to={Routes.Home} />
					</Route>
					<RestrictedRoute exact path={`${Routes.Proceed}/:releaseName/:searchId/:activeTab?`} component={ProceedSearchResult} isForbidden={!isUserHasAccessToData} />
					<Route exact path={Routes.TotpAuthentication} component={TotpAuthentication} />
					<RestrictedRoute exact path={Routes.RequestAccess} component={RequestAccess} isForbidden={!isUserAuthorized} />
					<Route exact path={Routes.Search} component={SearchLayout} />
					<RestrictedRoute exact path={Routes.DataSubmit} component={SubmitData} isForbidden={!isUserDataSubmitter} />
					{wpPagesConfig.map(({ slug, link }: WPPageConfig) =>
						<Route key={slug} exact path={`/${slug}`} >
							<WPFrame link={link}/>
						</Route>
					)}
					<Route component={NotFoundErrorPage} />
				</Switch>
			}
		</>
	);
};
