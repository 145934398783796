import { Column, ColumnTypes } from 'components/CustomTable';
import { IndexListTableColumnNames } from './types';

export const ACTIONS_PREFIX = 'indexManagement';
export const INDEX_LIST_TABLE_COLUMNS: Column<IndexListTableColumnNames>[] = [
	{
		name: 'name',
		displayName: 'Name',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'documentCount',
		displayName: 'Document Count',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'current',
		displayName: 'Current',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	}, {
		name: 'actions',
		displayName: 'Actions',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false
	},
];
export const AZURE_IP_INDEX = 'azure-ipaddress-range-idx';
