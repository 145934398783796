import { SagaIterator } from 'redux-saga';
import { getIndexListSaga } from './getIndexListSaga';

import { indexManagementSlice } from '../slices';
import { takeEvery } from '@redux-saga/core/effects';
import { sendCreateManagementRequestSaga } from './sendCreateManagementRequestSaga';
import { getCreateManagementConfigSaga } from './getCreateManagementConfigSaga';
import { deleteCurrentIndexSaga } from './deleteCurrentIndexSaga';
import { setCurrentIndexSaga } from './setCurrentIndexSaga';
import { getCurrentIndexSaga } from './getCurrentIndexSaga';
import { exportReleasedFilesListSaga } from './exportReleasedFilesListSaga';
import { exportParticipantsIntersecListSaga } from './exportParticipantsIntersecSaga';
import { exportUploadedFilesListSaga } from './exportUploadedFilesSaga';

export function* rootIndexManagementSaga(): SagaIterator {
	yield takeEvery(indexManagementSlice.actions.getIndexList.type, getIndexListSaga);
	yield takeEvery(indexManagementSlice.actions.sendFormDataRequest.type, sendCreateManagementRequestSaga);
	yield takeEvery(indexManagementSlice.actions.getCreateManagementConfig.type, getCreateManagementConfigSaga);
	yield takeEvery(indexManagementSlice.actions.setCurrentIndex.type, setCurrentIndexSaga);
	yield takeEvery(indexManagementSlice.actions.deleteCurrentIndex.type, deleteCurrentIndexSaga);
	yield takeEvery(indexManagementSlice.actions.getCurrentIndex.type, getCurrentIndexSaga);
	yield takeEvery(indexManagementSlice.actions.exportReleasedFilesList.type, exportReleasedFilesListSaga);
	yield takeEvery(indexManagementSlice.actions.exportParticipantsIntersecList.type, exportParticipantsIntersecListSaga);
	yield takeEvery(indexManagementSlice.actions.exportUploadedFilesList.type, exportUploadedFilesListSaga);
}
