import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from 'network';

export function* getDocuSignUserAgreement(id: string): SagaIterator<AxiosResponse<Blob>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json'
		},
		responseType: 'blob'
	};

	return (yield call(networkManager.fetch, Urls.GetDocuSignUserAgreement, requestOptions, { id })) as AxiosResponse<Blob>;
}
