import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { statisticsSlice } from '../slices';
import { GetTotalNumberOfUsersResponse } from '../types';
import { getTotalNumberOfUsers } from '../api';
import { selectTotalNumberOfUsers } from '../selectors';

export function* getTotalNumberOfUsersSaga(): SagaIterator {
	try {
		yield put(statisticsSlice.actions.getTotalNumberOfUsersStart());

		const totalNumberOfUsers = (yield select(selectTotalNumberOfUsers)) as ReturnType<typeof selectTotalNumberOfUsers>;

		if (totalNumberOfUsers === null) {
			const response = (yield call(getTotalNumberOfUsers)) as AxiosResponse<GetTotalNumberOfUsersResponse>;
			yield put(statisticsSlice.actions.getTotalNumberOfUsersEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(statisticsSlice.actions.getTotalNumberOfUsersEnd({ endState: SagaEndState.Success, data: totalNumberOfUsers }));
		}
	} catch (e) {
		yield put(statisticsSlice.actions.getTotalNumberOfUsersEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
