import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';
import { Configuration, UserDuaInformation } from '../types';

export interface RequestAccessState {
	isConfigurationLoading: boolean
	isConfigurationInitialized: boolean
	configuration: Configuration
	isRequestLoading: boolean
	isRequestSent: boolean
}

const initialState: RequestAccessState = {
	isConfigurationLoading: false,
	isConfigurationInitialized: false,
	configuration: {
		docuSignViewLink: '',
		successSubmit: '',
		markup: {
			backButton: {
				link: '',
				text: ''
			},
			pageHeader: '',
			leftInfoSectionAccessHtml: '',
			leftInfoSectionNoAccessHtml: '',
			rightInfoSectionHtml: '',
			flowDiagramHtml: '',
			formBlock: {
				codeOfConductAgreementLabel: '',
				header: '',
				description: '',
				formFields: {
					firstName: { label: '', helperText: '', maxLength: 0 },
					lastName: { label: '', helperText: '', maxLength: 0 },
					credentials: { label: '', helperText: '', maxLength: 0 },
					roleOnProject: { label: '', helperText: '', values: [] },
					workingEmail: { label: '', helperText: '', maxLength: 0 },
					institution: { label: '', helperText: '', maxLength: 0 },
					country: { label: '', helperText: '', maxLength: 0 },
					isBusinessOfficial: { label: '', helperText: '' },
					businessOfficialName: { label: '', helperText: '', maxLength: 0 },
					businessOfficialTitle: { label: '', helperText: '', maxLength: 0 },
					businessOfficialEmail: { label: '', helperText: '', maxLength: 0 },
					dataUseProposal: { label: '', helperText: '', maxLength: 0 },
					projectTimeframe: { label: '', helperText: '', maxLength: 0 },
					collaborators: { label: '', helperText: '', maxLength: 0 },
					businessOfficial: { label: '', helperText: '' }
				},
				supportedDownloads: {
					header: '',
					description: '',
					omics: {
						genomics: {
							name: '',
							fields: {
								level1: { label: '', helperText: '' },
								level2: { label: '', helperText: '' },
								level3: { label: '', helperText: '' },
								level4: { label: '', helperText: '' },
							}
						},
						epigenomics: {
							name: '',
							fields: {
								level1: { label: '', helperText: '' },
								level2: { label: '', helperText: '' },
								level3: { label: '', helperText: '' },
								level4: { label: '', helperText: '' },
							}
						},
						transcriptomics: {
							name: '',
							fields: {
								level1: { label: '', helperText: '' },
								level2: { label: '', helperText: '' },
								level3: { label: '', helperText: '' },
								level4: { label: '', helperText: '' },
							}
						},
						proteomics: {
							name: '',
							fields: {
								level1: { label: '', helperText: '' },
								level2: { label: '', helperText: '' },
								level4: { label: '', helperText: '' },
							}
						},
					}
				},
				fileInfoSectionHtml: '',
				fileButtonText: '',
				fileFieldHelperText: '',
				agreementsSectionHtml: '',
				agreementsLabel: '',
				submitButtonText: ''
			},
			errors: {
				emptyField: '',
				uncheckedCodeOfConductAgreement: '',
				uncheckedAgreement: '',
				maxLengthError: '',
				uniqueEmail: '',
				summary: '',
				invalidEmailFormat: '',
				invalidPiSelected: ''
			},
			requested: {
				header: '',
				descriptionHtml: ''
			},
			rejected: {
				header: '',
				descriptionHtml: ''
			},
			hasAccess: {
				header: '',
				descriptionHtml: ''
			}
		},
		footer: {
			links: []
		}
	},
	isRequestLoading: false,
	isRequestSent: false
};

export const requestAccessSlice = createSlice({
	name: `${ACTIONS_PREFIX}.code`,
	initialState,
	reducers: {
		pageConfigurationRequest: (state: RequestAccessState) => {
			state.isConfigurationLoading = true;
		},
		pageConfigurationSuccess: (state: RequestAccessState, { payload }: PayloadAction<Configuration>) => {
			state.isConfigurationLoading = false;
			state.isConfigurationInitialized = true;
			state.configuration = payload;
		},
		pageConfigurationFailure: (state: RequestAccessState) => {
			state.isConfigurationLoading = false;
		},

		sendFormDataRequest: (state: RequestAccessState, _: PayloadAction<UserDuaInformation>) => {
			state.isRequestLoading = true;
		},
		sendFormDataSuccess: (state: RequestAccessState) => {
			state.isRequestLoading = false;
			state.isRequestSent = true;
		},
		sendFormDataFailure: (state: RequestAccessState) => {
			state.isRequestLoading = false;
		}
	}
});
