import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { CreateManagementInformation } from '../types';

export const sendCreateManagementRequest = async (data: CreateManagementInformation): Promise<AxiosResponse> => {
	const method: Method = 'POST';

	const formData = new FormData();
	formData.append('name', data.name);

	for (const file in data.files) {
		if (data.files[file] !== null) {
			formData.append(file, data.files[file] as File);
		}
	}

	const requestOptions = {
		method,
		headers: { 'Content-Type': 'multipart/form-data' },
		data: formData
	};
	return networkManager.fetch(Urls.SendCreateManagementRequest, requestOptions);
};
