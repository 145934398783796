import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { addiDataRequestDetailsSlice } from '../slices';
import { approveAddiData } from '../api';
import { selectAddiDataRequestDetails } from '../selectors';

import { getAddiDataRequestDetailsSaga } from './getAddiDataRequestDetailsSaga';

export function* approveAddiDataRequestSaga(): SagaIterator {
	const addiDataRequest = (yield select(selectAddiDataRequestDetails)) as ReturnType<typeof selectAddiDataRequestDetails>;

	if (addiDataRequest !== null) {
		try {
			yield put(addiDataRequestDetailsSlice.actions.approveAddiDataRequestStart());

			yield call(approveAddiData, { id: addiDataRequest.id });
			yield call(getAddiDataRequestDetailsSaga, addiDataRequest.id);

			yield put(addiDataRequestDetailsSlice.actions.approveAddiDataRequestEnd());
		} catch (e) {
			yield put(addiDataRequestDetailsSlice.actions.approveAddiDataRequestEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
