import React, { FunctionComponent } from 'react';
import { ColumnChartData, ColumnChartDataPlotting } from '../types';
import { ColumnChart } from './ColumnChart';
import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	paperContainer: {
		margin: '10px',
		width: '520px',
		height: '400px'
	}
}));

interface ColumnChartContainerProps {
	isLoading: boolean
	data: ColumnChartData | null
	colors: string[]
	title: string
}

export const ColumnChartContainer: FunctionComponent<ColumnChartContainerProps> = ({
	isLoading,
	data,
	colors,
	title
}) => {
	const classes = useStyles();

	const seriesColumnChart = (obj: Record<string,number[]> | null, color: string[]): ColumnChartDataPlotting[] | null => {
		if (!obj) {
			return null;
		}
		return Object.entries(obj).map((item, index) => (
			{
				name: item[0],
				data: item[1],
				color: color[index]
			}
		));
	};

	return <Paper className={classes.paperContainer}>
		{isLoading
			? <Box className={classes.root}>
				<CircularProgress size={60} />
			</Box>
			: data &&
			<ColumnChart
				series={seriesColumnChart(data.data, colors)}
				categories={data.months}
				title={title}/>
		}
	</Paper>;
};
