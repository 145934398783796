import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SagaEndPayloadAction, SagaEndState } from 'types';

import { ACTIONS_PREFIX, DOI_LIST_TABLE_ROWS_PER_PAGE_OPTIONS } from '../constants';
import {
	CreateUserDoiRequest,
	DownloadCreateFileListRequest,
	GetUserOmicsResponse,
	Omic,
	Doi,
	GetUserDoiRequest,
	GetUserDoiResponse,
	DownloadReadFileListRequest,
	GetUserDoiListResponse,
	DoiShort, DoiConfig
} from '../types';

interface DoiState {
	userOmics: Omic[]
	isOmicsLoading: boolean
	isOmicsInitialized: boolean
	isCreateUserDoiLoading: boolean
	isSaveUserDoiLoading: boolean
	isDownloadCreateFileListLoading: boolean
	userDoi: Doi | null
	isGetUserDoiLoading: boolean
	isDownloadReadFileListLoading: boolean
	userDoiList: DoiShort[]
	userDoiListTotalCount: number
	isGetUserDoiListLoading: boolean
	userDoiListCurrentPage: number
	userDoiListRowsPerPage: number
	isDoiConfigLoading: boolean
	doiConfig: DoiConfig | null
}

const initialState: DoiState = {
	userOmics: [],
	isOmicsLoading: false,
	isOmicsInitialized: false,
	isCreateUserDoiLoading: false,
	isSaveUserDoiLoading: false,
	isDownloadCreateFileListLoading: false,
	userDoi: null,
	isGetUserDoiLoading: false,
	isDownloadReadFileListLoading: false,
	userDoiList: [],
	userDoiListTotalCount: 0,
	isGetUserDoiListLoading: true,
	userDoiListCurrentPage: 0,
	userDoiListRowsPerPage: DOI_LIST_TABLE_ROWS_PER_PAGE_OPTIONS[1],
	isDoiConfigLoading: false,
	doiConfig: null
};

export const doiSlice = createSlice({
	name: ACTIONS_PREFIX,
	initialState,
	reducers: {
		getUserOmics: () => undefined,
		getUserOmicsStart: (state: DoiState) => {
			state.isOmicsLoading = true;
		},
		getUserOmicsEnd: (state: DoiState, { payload }: SagaEndPayloadAction<GetUserOmicsResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.userOmics = payload.data.omics;
				state.isOmicsInitialized = true;
			}
			state.isOmicsLoading = false;
		},

		createUserDoi: (_state, _action: PayloadAction<CreateUserDoiRequest>) => undefined,
		createUserDoiStart: (state: DoiState) => {
			state.isCreateUserDoiLoading = true;
		},
		createUserDoiEnd: (state: DoiState) => {
			state.isCreateUserDoiLoading = false;
		},

		saveUserDoi: (_state, _action: PayloadAction<CreateUserDoiRequest>) => undefined,
		saveUserDoiStart: (state: DoiState) => {
			state.isSaveUserDoiLoading = true;
		},
		saveUserDoiEnd: (state: DoiState) => {
			state.isSaveUserDoiLoading = false;
		},

		downloadCreateFileList: (_state, _action: PayloadAction<DownloadCreateFileListRequest>) => undefined,
		downloadCreateFileListStart: (state: DoiState) => {
			state.isDownloadCreateFileListLoading = true;
		},
		downloadCreateFileListEnd: (state: DoiState) => {
			state.isDownloadCreateFileListLoading = false;
		},

		getUserDoi: (_state, _action: PayloadAction<GetUserDoiRequest>) => undefined,
		getUserDoiStart: (state: DoiState) => {
			state.userDoi = null;
			state.isGetUserDoiLoading = true;
		},
		getUserDoiEnd: (state: DoiState, { payload }: SagaEndPayloadAction<GetUserDoiResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.userDoi = payload.data;
			}
			state.isGetUserDoiLoading = false;
		},

		downloadReadFileList: (_state, _action: PayloadAction<DownloadReadFileListRequest>) => undefined,
		downloadReadFileListStart: (state: DoiState) => {
			state.isDownloadReadFileListLoading = true;
		},
		downloadReadFileListEnd: (state: DoiState) => {
			state.isDownloadReadFileListLoading = false;
		},

		getUserDoiList: () => undefined,
		getUserDoiListStart: (state: DoiState) => {
			state.isGetUserDoiListLoading = true;
		},
		getUserDoiListEnd: (state: DoiState, { payload }: SagaEndPayloadAction<GetUserDoiListResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.userDoiList = payload.data.doiList;
				state.userDoiListTotalCount = payload.data.totalCount;
			}
			state.isGetUserDoiListLoading = false;
		},
		resetUserDoiListPageSilent: (state: DoiState) => {
			state.userDoiListCurrentPage = 0;
		},
		setUserDoiListCurrentPage: (state: DoiState, { payload }: PayloadAction<number>) => {
			state.userDoiListCurrentPage = payload;
		},
		setUserDoiListRowsPerPage: (state: DoiState, { payload }: PayloadAction<number>) => {
			state.userDoiListRowsPerPage = payload;
		},

		getDoiConfig: () => undefined,
		getDoiConfigStart: (state: DoiState) => {
			state.isDoiConfigLoading = true;
		},
		getDoiConfigEnd: (state: DoiState, { payload }: SagaEndPayloadAction<DoiConfig>) => {
			if (payload.endState === SagaEndState.Success) {
				state.doiConfig = payload.data;
			}
			state.isDoiConfigLoading = false;
		}
	},
});
