import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { DownloadHeatmapDataRequest } from '../types';

export const downloadHeatmapData = async (params: DownloadHeatmapDataRequest): Promise<AxiosResponse<Blob>> => {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json'
		},
		responseType: 'blob',
		data: params
	};

	return networkManager.fetch<Blob>(Urls.DownloadHeatmapData, requestOptions);
};
