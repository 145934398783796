import React, { FunctionComponent, ReactNode, useState } from 'react';
import { uniqueId } from 'lodash';

import {
	Button,
	Typography,
	Box,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	Dialog,
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import Close from '@material-ui/icons/Close';

interface DialogModalProps {
	isOpen: boolean
	headerText: string
	cancelButtonText: string
	submitButtonText?: string
	onCancel: () => void
	onSubmit?: () => void
	children: ReactNode
}

export const DialogModal: FunctionComponent<DialogModalProps> = ({
	isOpen,
	headerText,
	cancelButtonText,
	submitButtonText,
	onCancel,
	onSubmit,
	children
}: DialogModalProps) => {
	const [dialogId] = useState(() => uniqueId('dialog-modal-'));

	return (
		<Dialog
			aria-labelledby={ dialogId }
			open={isOpen}
			onEscapeKeyDown={onCancel}
		>
			<DialogTitle disableTypography>
				<Box position="relative">
					<Typography id={ dialogId } component="h2" variant="h5">
						{ headerText }
					</Typography>
					<Box position="absolute" top={-12} right={-12} color={grey['800']}>
						<IconButton onClick={onCancel} aria-label="Close" component="span">
							<Close />
						</IconButton>
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent dividers>
				{ children }
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel} color="primary" variant="outlined" size="large">
					{ cancelButtonText }
				</Button>
				{submitButtonText && onSubmit ?
					<Button onClick={onSubmit} color="primary" variant="contained" size="large">
						{ submitButtonText }
					</Button> :
					null
				}
			</DialogActions>
		</Dialog>
	);
};
