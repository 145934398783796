import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';
import { SortOrder } from 'types';
import { Column } from 'components/CustomTable';

import {
	SelectedFacets,
	DefaultFacetsConfig,
	DetailsFacetsConfig,
	Facets,
	DetailsFacetsItemConfig,
	SearchViewMode,
	SearchDetailsRow,
	SearchDetailsFieldConfig,
	EntityCount,
	FacetSearchOption,
	EntitiesExpandState,
	DisplayedFacetsInCardView
} from './types';

export const selectDefaultFacetsConfig = (state: RootState): DefaultFacetsConfig => state.search.facets.defaultFacetsConfig;
export const selectDetailsFacetsConfig = (state: RootState): DetailsFacetsConfig => state.search.facets.detailsFacetsConfig;
export const selectSelectedFacets = (state: RootState): SelectedFacets => state.search.facets.selectedFacets;
export const selectDisplayedFacetsInCardView = (state: RootState): DisplayedFacetsInCardView => state.search.facets.displayedFacetsInCardView;
export const selectFacets = (state: RootState): Facets => state.search.facets.facets;
export const selectIsSearchLoading = (state: RootState): boolean => state.search.facets.isSearchLoading;
export const selectIsFacetsConfigLoading = (state: RootState): boolean => state.search.facets.isFacetsConfigLoading;
export const selectIsInitialSearchLoading = (state: RootState): boolean => state.search.facets.isInitialSearchLoading;
export const selectFacetsListConfig = (state: RootState): DetailsFacetsItemConfig[] => state.search.facets.detailsFacetsListPanel;
export const selectDefaultFacetsCategory = createSelector(
	selectFacetsListConfig,
	(facetsListConfig): DetailsFacetsItemConfig | null => {
		const defaultCategory = facetsListConfig.find(config => config.default);
		return defaultCategory ? defaultCategory : null;
	}
);
export const selectViewMode = (state: RootState): SearchViewMode => state.search.facets.viewMode;
export const selectFacetsTotalCount = (state: RootState): number => state.search.facets.totalCount;
export const selectFilterQueryString = (state: RootState): string => state.search.facets.filterQueryString;
export const selectDownloadMetadataIsLoading = (state: RootState): boolean => state.search.facets.isLoadingDownloadMetadata;

export const selectSearchFieldsConfig = (state: RootState): SearchDetailsFieldConfig[] => state.search.details.fieldsConfig;
export const selectSearchDetailsTableColumns = (state: RootState): Column[] => state.search.details.tableColumns;
export const selectSearchDetailsRows = (state: RootState): SearchDetailsRow[] => state.search.details.rows;
export const selectSearchDetailsIndexKeyColumnName = (state: RootState): string => state.search.details.indexKeyColumnName;
export const selectSearchDetailsRowsPerPage = (state: RootState): number => state.search.details.rowsPerPage;
export const selectSearchDetailsPageNumber = (state: RootState): number => state.search.details.pageNumber;
export const selectSearchDetailsOrder = (state: RootState): SortOrder => state.search.details.order;
export const selectSearchDetailsOrderBy = (state: RootState): string => state.search.details.orderBy;

export const selectProceedTabs = (state: RootState): string[] => state.search.proceed.tabs;
export const selectIsLoadingProceedTabs = (state: RootState): boolean => state.search.proceed.isLoadingTabs;
export const selectIsSavingSearchParameters = (state: RootState): boolean => state.search.proceed.isSavingSearchParameters;
export const selectSearchId = (state: RootState): string => state.search.proceed.searchId;
export const selectReleaseName = (state: RootState): string => state.search.proceed.releaseName;
export const selectActiveTabIndex = (state: RootState): number => state.search.proceed.activeTabIndex;

export const selectActiveTab = createSelector(
	selectProceedTabs,
	selectActiveTabIndex,
	(tabs, activeTabIndex): string => tabs[activeTabIndex]
);

export const selectEntitiesCount = (state: RootState): EntityCount[] => state.search.entitiesCount.entitiesCount;

export const selectFacetsPanelSearchOptions = (state: RootState): FacetSearchOption[] => state.search.facetsPanel.facetSearchOptions;
export const selectFacetsPanelEntitiesExpandState = (state: RootState): EntitiesExpandState => state.search.facetsPanel.entitiesExpandState;
export const selectFacetsPanelTargetSearchId = (state: RootState): string => state.search.facetsPanel.targetSearchId;
export const selectIsShowFacetsWithoutValues = (state: RootState): boolean => state.search.facetsPanel.isShowFacetsWithoutValues;
