import React, { FunctionComponent } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import Highcharts from 'highcharts';
import HighchartsData from 'highcharts/modules/data';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import HighchartsExporting from 'highcharts/modules/exporting';

// Inject modules into global Highcharts object
HighchartsData(Highcharts);
HighchartsHeatmap(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import 'locale/i18n';

import { browserHistory, store } from 'app/store';

import { AppTheme } from 'styles/AppTheme';
import { Alert } from 'features/alert';

import { AppRouter } from './components/AppRouter';

export const App: FunctionComponent = () =>
	<Provider store={store}>
		<ConnectedRouter history={browserHistory}>
			<ThemeProvider theme={AppTheme}>
				<CssBaseline />
				<AppRouter />
				<Alert />
			</ThemeProvider>
		</ConnectedRouter>
	</Provider>
;
