import { SagaIterator } from 'redux-saga';
import { put, call, cancelled, select } from 'redux-saga/effects';

import { SagaEndState } from 'types';

import { addiDataRequestsListSlice } from '../slices';

import { getAddiDataRequestsList } from '../api';
import { AxiosError, AxiosResponse } from 'axios';
import { GetAddiDataListResponse } from '../types';
import { StatusCodes } from 'http-status-codes';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { selectFilters, selectTableCurrentPage, selectTableRowsPerPage, selectTableSearchQuery, selectTableSort } from '../selectors';
import { getFilterApiParam } from 'utils';

export function* getAddiDataRequestsListSaga(): SagaIterator {

	try {
		yield put(addiDataRequestsListSlice.actions.getAddiDataRequestsListStart());

		const currentPage = (yield select(selectTableCurrentPage)) as ReturnType<typeof selectTableCurrentPage>;
		const rowsPerPage = (yield select(selectTableRowsPerPage)) as ReturnType<typeof selectTableRowsPerPage>;
		const sort = (yield select(selectTableSort)) as ReturnType<typeof selectTableSort>;
		const filters = (yield select(selectFilters)) as ReturnType<typeof selectFilters>;
		const searchQuery = (yield select(selectTableSearchQuery)) as ReturnType<typeof selectTableSearchQuery>;

		const { data } = (yield call(getAddiDataRequestsList, {
			search: searchQuery,
			skip: currentPage * rowsPerPage,
			top: rowsPerPage,
			filter: getFilterApiParam(filters),
			orderBy: `${sort.orderBy} ${sort.order}`
		})) as AxiosResponse<GetAddiDataListResponse>;

		yield put(addiDataRequestsListSlice.actions.getAddiDataRequestsListEnd({ endState: SagaEndState.Success, data }));
	} catch (e) {
		if (yield cancelled()) {
			yield put(addiDataRequestsListSlice.actions.getAddiDataRequestsListEnd({ endState: SagaEndState.Cancel }));
		} else if ((e as AxiosError).response?.status === StatusCodes.NOT_FOUND) {
			yield put(addiDataRequestsListSlice.actions.getAddiDataRequestsListEnd({
				endState: SagaEndState.Success,
				data: {
					totalCount: 0,
					addiDataRequests: []
				}
			}));
		} else {
			yield put(addiDataRequestsListSlice.actions.getAddiDataRequestsListEnd({ endState: SagaEndState.Fail }));
			yield call(handleErrorSaga, e);
		}
	}
}
