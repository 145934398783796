import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { ExportOmicsRequest } from '../types';

export function* exportOmics(params: ExportOmicsRequest): SagaIterator<AxiosResponse> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params,
		responseType: 'blob'
	};

	return (yield call(networkManager.fetch, Urls.ExportOmicsReleaseReady, requestOptions)) as AxiosResponse;
}
