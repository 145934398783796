import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Column } from 'components/CustomTable';
import { SortOrder } from 'types';

import { SearchDetailsFieldConfig, SearchDetailsRow } from '../types';
import { ACTIONS_PREFIX, SEARCH_TABLE_DEFAULT_ROWS_PER_PAGE, SEARCH_TABLE_INITIAL_INDEX_FIELD } from '../constants';

interface SearchDetailsState {
	fieldsConfig: SearchDetailsFieldConfig[]
	order: SortOrder
	orderBy: string
	rows: SearchDetailsRow[]
	indexKeyColumnName: string
	rowsPerPage: number
	pageNumber: number
	isLoading: boolean
	tableColumns: Column[]
}

const initialState: SearchDetailsState = {
	rows: [],
	rowsPerPage: SEARCH_TABLE_DEFAULT_ROWS_PER_PAGE,
	pageNumber: 0,
	fieldsConfig: [],
	order: SortOrder.Asc,
	orderBy: SEARCH_TABLE_INITIAL_INDEX_FIELD,
	indexKeyColumnName: SEARCH_TABLE_INITIAL_INDEX_FIELD,
	isLoading: false,
	tableColumns: []
};

export const searchDetailsSlice = createSlice({
	name: `${ACTIONS_PREFIX}.details`,
	initialState,
	reducers: {
		getFieldsConfigRequest: (state: SearchDetailsState) => {
			state.isLoading = true;
		},
		getFieldsConfigSuccess: (state: SearchDetailsState, { payload }: PayloadAction<SearchDetailsFieldConfig[]>) => {
			state.isLoading = false;
			state.fieldsConfig = payload;
		},
		getFieldsConfigFail: (state: SearchDetailsState) => {
			state.isLoading = false;
		},

		setRows: (state: SearchDetailsState, { payload }: PayloadAction<SearchDetailsRow[]>) => {
			state.rows = payload;
		},
		selectRowsPerPage: (state: SearchDetailsState, { payload: { tableRowsPerPage } }: PayloadAction<{ tableRowsPerPage: number }>) => {
			state.rowsPerPage = tableRowsPerPage;
		},
		setPageNumber: (state: SearchDetailsState, { payload: { tablePageNumber } }: PayloadAction<{ tablePageNumber: number }>) => {
			state.pageNumber = tablePageNumber;
		},
		resetPageNumber: (state: SearchDetailsState) => {
			state.pageNumber = 0;
		},
		requestSorting: (state: SearchDetailsState, { payload: { fieldName } }: PayloadAction<{ fieldName: string }>) => {
			const isAsc = state.orderBy === fieldName && state.order === SortOrder.Asc;
			state.order = isAsc ? SortOrder.Desc : SortOrder.Asc;
			state.orderBy = fieldName;
		},

		setTableColumns: (state: SearchDetailsState, { payload }: PayloadAction<Column[]>) => {
			state.tableColumns = payload;
		},
	}
});
