import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Typography, Link, Theme, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';

import { Routes } from 'app/Routes';

type BaseErrorPageProps = {
	errorCode: number
	errorText: string
	errorDescription: string
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		pageWrapper: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '100%',
			minHeight: 'inherit'
		},
		errorCode: {
			fontSize: '7.5rem',
			lineHeight: 1,
			fontWeight: 300,
			color: grey['400'],
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			paddingBottom: theme.spacing(3)
		},
		errorText: {
			fontSize: '1.25rem',
			lineHeight: 1,
			fontWeight: 500,
			paddingBottom: theme.spacing(2)
		},
		errorDescription: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			paddingBottom: theme.spacing(7)
		}
	})
);

export const BaseErrorPage: FunctionComponent<BaseErrorPageProps> = ({ errorCode, errorText, errorDescription }: BaseErrorPageProps) => {
	const classes = useStyles();
	return (
		<div className={ classes.pageWrapper }>
			<Typography component='h2' classes={{ root: classes.errorCode }}>{errorCode}</Typography>
			<Typography component='h3' classes={{ root: classes.errorText }}>{errorText}</Typography>
			<Typography component='h4' classes={{ root: classes.errorDescription }}>{errorDescription}</Typography>
			<Link to={Routes.Home} component={RouterLink} underline='always'>
				<Typography component='span' variant='body1'>
					{'Go to Home page'}
				</Typography>
			</Link>
		</div>
	);
};
