import { takeEvery, debounce, StrictEffect, call } from 'redux-saga/effects';

import { DEBOUNCE_SEARCH_MS } from '../../../constants';
import { definedSearchRequestSaga } from '../../../sagas/definedSearchRequestSaga';
import { resetPaginationSaga } from '../../../sagas/resetPaginationSaga';

import { caseSetSlice } from '../slices';

import { caseSetConfigSaga } from './caseSetConfigSaga';
import { getExternalCaseSetSaga } from './getExternalCaseSetSaga';
import { submitModalRequestSaga } from './submitModalRequestSaga';
import { showCaseSetsPanelSaga } from './showCaseSetsPanelSaga';

export function* rootCaseSetSaga(): Generator<StrictEffect, void> {
	yield debounce(DEBOUNCE_SEARCH_MS, [
		caseSetSlice.actions.selectCaseSet.type,
		caseSetSlice.actions.unselectCaseSet.type,
		caseSetSlice.actions.unselectAllCaseSetEntities.type,
		caseSetSlice.actions.removeCaseSet.type,
	], function* () {
		yield call(resetPaginationSaga);
		yield call(definedSearchRequestSaga);
	});
	yield takeEvery(caseSetSlice.actions.submitModalRequest.type, submitModalRequestSaga);
	yield takeEvery(caseSetSlice.actions.startModalOpen.type, caseSetConfigSaga);
	yield takeEvery(caseSetSlice.actions.getExternalCaseSetRequest.type, getExternalCaseSetSaga);
	yield takeEvery(caseSetSlice.actions.showCaseSetsPanel.type, showCaseSetsPanelSaga);
}
