import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from '../../../network';

export const getProceedTabs = async (): Promise<AxiosResponse> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'accept': 'text/plain',
		}
	};

	return networkManager.fetch(Urls.GetPluginsGroups, requestOptions);
};
