import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
	title: {
		fontWeight: 700,
		fontSize: '24px',
		lineHeight: '24px',
		letterSpacing: '0.18px'
	},
	description: {
		fontSize: '1.2rem',
		lineHeight: '2rem'
	}
}));

interface CommonGreetingProps {
	title: string
	description: string
}

export const CommonGreeting: FunctionComponent<CommonGreetingProps> = ({ title, description }: CommonGreetingProps) => {
	const classes = useStyles();

	return (
		<>
			<Box>
				<Typography variant="h5"
					className={classes.title}
					gutterBottom>
					{title}
				</Typography>
				<Box pb={2} />
				<Typography className={ classes.description }>
					{ description }
				</Typography>
			</Box>
			<Box pb={4} />
		</>
	);
};
