/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Box, Button, Container, LinearProgress, makeStyles, Paper, Theme, Typography, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfiguration, selectIsConfigurationInitialized } from '../selectors';
import { submitDataSlice } from '../slices';
import zIndex from '@material-ui/core/styles/zIndex';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		padding: theme.spacing(3),
		bgcolor: '#ffffff',
		position: 'relative'
	},
	infoBlock: {
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '1rem',
		lineHeight: '1.5rem',
		letterSpacing: '0.009375rem',
		color: theme.palette.grey['800'],

		'& h2': {
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '1.5rem',
			lineHeight: '1.5rem',
			letterSpacing: '0.01125rem',
			color: theme.palette.common.black,
			margin: theme.spacing(0, 0, 2, 0)
		},
		'& p, & li': {
			margin: theme.spacing(2, 0)
		},
		'& ul, & ol': {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			padding: theme.spacing(0, 0, 0, 2.25)
		},
		'& a': {
			color: theme.palette.primary.main
		},

	},
}));

export const SubmitData: FunctionComponent = () => {

	const pageConfiguration = useSelector(selectConfiguration);
	const isConfigurationInitialized = useSelector(selectIsConfigurationInitialized);

	const theme = useTheme();
	const classes = useStyles();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(submitDataSlice.actions.pageConfigurationRequest());
	}, [dispatch]);

	return (
		<>
			<Box
				visibility={!isConfigurationInitialized ? 'visible' : 'hidden'}
				position="absolute"
				width="100%"
				zIndex={zIndex.appBar}
				top={theme.spacing(HEADER_HEIGHT_SPACING)}
			>
				<LinearProgress />
			</Box>
			<Container className={classes.container}>
				{ isConfigurationInitialized &&
					<>
						<Box pb={6} />
						<Typography component="h1" variant="h3">
							{pageConfiguration.uploadDataLocaleText.pageHeader}
						</Typography>
						<Box pb={6} />
						<Box display="flex" justifyContent="space-between" flexWrap="wrap">
							<Box
								className={classes.infoBlock}
								style={{ flex: '0 1 900px' }}>
								<Box dangerouslySetInnerHTML={{ __html: pageConfiguration.uploadDataLocaleText.pageIntro }} />
								<Box pb={4} />
								<Box display="flex" justifyContent="space-evenly">
									<Button
										size='large'
										color="primary"
										variant="outlined"
										target="_blank"
										href={pageConfiguration.links?.windows ? pageConfiguration.links?.windows : ''}
									>
										{'Windows'}
									</Button>
									<Button
										size='large'
										color="primary"
										variant="outlined"
										target="_blank"
										href={pageConfiguration.links?.macOS ? pageConfiguration.links?.macOS : ''}
									>
										{'Mac OS'}
									</Button>
									<Button
										size='large'
										color="primary"
										variant="outlined"
										target="_blank"
										href={pageConfiguration.links?.linux ? pageConfiguration.links?.linux : ''}
									>
										{'Linux'}
									</Button>
								</Box>
								<Box pb={4} />
								<Typography component="h2" variant="h2">
									{pageConfiguration.uploadDataLocaleText.pageSubHeader}
								</Typography>
								<Box pb={2} />
								<Box dangerouslySetInnerHTML={{ __html: pageConfiguration.uploadDataLocaleText.pageSubIntro }} />
								<Box pb={2} />
								<Box dangerouslySetInnerHTML={{ __html: pageConfiguration.uploadDataLocaleText.stepsContent }} />
								<Box pb={2} />
								<Paper elevation={3} style={{ padding: '10px' }}>
									<img src={pageConfiguration.uploadDataLocaleText.toolImage1base64} alt="tool 1" width={'880px'} />
								</Paper>
								<Box pb={1} />
								<Paper elevation={3} style={{ padding: '10px' }}>
									<img src={pageConfiguration.uploadDataLocaleText.toolImage2base64} alt="tool 1" width={'880px'} />
								</Paper>
							</Box>
						</Box>
					</>
				}
			</Container>
		</>
	);
};
