export enum AuthLibraryErrors {
	REDIRECT_PROMISE_DUPLICATE = 'REDIRECT_PROMISE_DUPLICATE',
	REDIRECT_PROMISE_INCOMPLETE = 'REDIRECT_PROMISE_INCOMPLETE',
	INVALID_STATE = 'INVALID_STATE',
	INVALID_STATE_FORMAT = 'INVALID_STATE_FORMAT',
	INVALID_GET_TOKEN_REQUEST = 'INVALID_GET_TOKEN_REQUEST',
	INVALID_REFRESH_TOKEN_REQUEST = 'INVALID_REFRESH_TOKEN_REQUEST',
	INVALID_ID_TOKEN_FORMAT = 'INVALID_ID_TOKEN_FORMAT',
	TOKEN_INFO_NOT_FOUND = 'TOKEN_INFO_NOT_FOUND'
}
