export class AuthError extends Error {
	readonly errorCode: string;
	readonly errorMessage: string;

	constructor(errorCode: string, errorMessage?: string) {
		super(errorMessage);
		Object.setPrototypeOf(this, AuthError.prototype);

		this.errorCode = errorCode;
		this.errorMessage = errorMessage ?? '';
		this.name = 'AuthError';
	}

	static ParseB2CErrorCode(message: string) {
		return message.split('%3a')[0].trim();
	}
}
