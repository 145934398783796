import { SagaIterator } from 'redux-saga';
import { take, put, call } from 'redux-saga/effects';

import { SagaEndState } from 'types';

import { usersManagementTableSlice } from '../slices';

import { getFiltersConfigSaga } from './getFiltersConfigSaga';

export function* initUsersManagementTableFlowSaga(): SagaIterator {
	yield take(usersManagementTableSlice.actions.initUsersManagementTable.type);

	yield put(usersManagementTableSlice.actions.initUsersManagementTableStart());

	yield call(getFiltersConfigSaga);

	yield put(usersManagementTableSlice.actions.updateUsersList());
	yield take(usersManagementTableSlice.actions.updateUsersListEnd.type);

	yield put(usersManagementTableSlice.actions.initUsersManagementTableEnd({ endState: SagaEndState.Success }));
}
