import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { downloadBlob } from 'utils';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { downloadCreateFileList } from '../api';
import { DownloadCreateFileListRequest, DownloadCreateFileListResponse } from '../types';

export function* downloadCreateFileListSaga({ payload }: PayloadAction<DownloadCreateFileListRequest>): SagaIterator {
	try {
		yield put(doiSlice.actions.downloadCreateFileListStart());

		const response = (yield call(downloadCreateFileList, payload)) as AxiosResponse<DownloadCreateFileListResponse>;

		yield call(downloadBlob, 'file-list.txt', response.data);

		yield put(doiSlice.actions.downloadCreateFileListEnd());
	} catch (e) {
		yield put(doiSlice.actions.downloadCreateFileListEnd());
		yield call(handleErrorSaga, e);
	}
}
