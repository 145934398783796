import { createSlice } from '@reduxjs/toolkit';

import { SagaEndPayloadAction, SagaEndState } from 'types';

import { ACTIONS_PREFIX } from '../constants';

interface UsersManagementState {
	possibleUserRoles: string[]

	isPossibleUserRolesConfigInitialized: boolean
	isPossibleUserRolesConfigLoading: boolean
	isExportUsersLoading: boolean
}

const initialState: UsersManagementState = {
	possibleUserRoles: [],

	isPossibleUserRolesConfigInitialized: false,
	isPossibleUserRolesConfigLoading: false,
	isExportUsersLoading: false,
};

export const usersManagementSlice = createSlice({
	name: `${ACTIONS_PREFIX}.common`,
	initialState,
	reducers: {
		getPossibleUserRolesConfig: () => undefined,
		getPossibleUserRolesConfigStart: (state: UsersManagementState) => {
			state.isPossibleUserRolesConfigLoading = true;
		},
		getPossibleUserRolesConfigEnd: (state: UsersManagementState, { payload }: SagaEndPayloadAction<string[]>) => {
			if (payload.endState === SagaEndState.Success) {
				state.possibleUserRoles = payload.data;
				state.isPossibleUserRolesConfigInitialized = true;
			}
			state.isPossibleUserRolesConfigLoading = false;
		},

		exportUsers: () => undefined,
		exportUsersStart: (state: UsersManagementState) => {
			state.isExportUsersLoading = true;
		},
		exportUsersEnd: (state: UsersManagementState) => {
			state.isExportUsersLoading = false;
		}
	},
});
