import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosError, AxiosResponse } from 'axios';
import { NOT_FOUND } from 'http-status-codes';

import { downloadBlob } from 'utils';

import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { selectUser } from '../selectors';
import { usersManagementUserSlice } from '../slices';
import { getDocuSignUserAgreement } from '../api/getDocuSignUserAgreement';

export function* downloadDocuSignUserAgreementSaga(): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.downloadDocuSignUserAgreementStart());

			const { data } = (yield call(getDocuSignUserAgreement, user.id)) as AxiosResponse<Blob>;

			const fileName = `DocuSign_${user.firstName ?? ''}_${user.lastName ?? ''}.pdf`;
			yield call(downloadBlob, fileName, data);

			yield put(usersManagementUserSlice.actions.downloadDocuSignUserAgreementEnd());
		} catch (e) {
			if ((e as AxiosError).response?.status === NOT_FOUND) {
				yield put(alertSlice.actions.showAlert({ message: 'Document not found' }));
			} else {
				yield call(handleErrorSaga, e);
			}
			yield put(usersManagementUserSlice.actions.downloadDocuSignUserAgreementEnd());
		}
	}
}
