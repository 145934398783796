import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { ExportAddiDataListRequest, ExportAddiDataListResponse } from '../types';

export function* exportAddiDataRequests(params: ExportAddiDataListRequest): SagaIterator<AxiosResponse<ExportAddiDataListResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
		data: params
	};

	return (yield call(networkManager.fetch, Urls.ExportAddiDataRequests, requestOptions)) as AxiosResponse<ExportAddiDataListResponse>;
}

