import { Box, Card, CardActions, CardContent, Typography, makeStyles } from '@material-ui/core';
import { LoaderButton } from 'components';
import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsExportParticipantsIntersecLoading, selectIsExportReleasedFilesLoading, selectIsExportUploadedFilesLoading } from '../selectors';
import { indexManagementSlice } from '../slices';

const useStyles = makeStyles({
	card: {
		width: 275,
		height: 275,
		marginRight: 12
	},
	cardContent: {
		height: 232
	}
});

export const ReleaseDataTools: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const isExportReleasedFilesLoading = useSelector(selectIsExportReleasedFilesLoading);
	const isExportParticipantsIntersecLoading = useSelector(selectIsExportParticipantsIntersecLoading);
	const isExportUploadedFilesLoading = useSelector(selectIsExportUploadedFilesLoading);

	const onExportReleasedFilesClick = useCallback(() => {
		dispatch(indexManagementSlice.actions.exportReleasedFilesList());
	}, [dispatch]);

	const onExportParticipantsIntersecClick = useCallback(() => {
		dispatch(indexManagementSlice.actions.exportParticipantsIntersecList());
	}, [dispatch]);

	const onExportUploadedFilesClick = useCallback(() => {
		dispatch(indexManagementSlice.actions.exportUploadedFilesList());
	}, [dispatch]);

	return (
		<>
			<Typography component="h1" variant="h4">
				{'Release Tools'}
			</Typography>
			<Box pb={4} />
			<Box display='flex'>
				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Typography component="h5" variant="h5">
							{'Released Omics'}
						</Typography>
						<Box pb={2} />
						<Typography component="p" variant="inherit">
							{'Export into .csv file the list of released omic data files.'}
						</Typography>
					</CardContent>
					<CardActions>
						<Box display="flex">
							<LoaderButton
								id='export-released-files'
								isLoading={isExportReleasedFilesLoading}
								ButtonProps={{
									size: 'medium',
									variant: 'outlined',
									color: 'primary',
									onClick: onExportReleasedFilesClick
								}}
							>
								{'Export'}
							</LoaderButton>
						</Box>
					</CardActions>
				</Card>

				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Typography component="h5" variant="h5">
							{'Participants Intersection'}
						</Typography>
						<Box pb={2} />
						<Typography component="p" variant="inherit">
							{'Export into .csv file the list of Participant IDs presented both in the Staging and Released omic data files.'}
						</Typography>
					</CardContent>
					<CardActions>
						<Box display="flex">
							<LoaderButton
								id='export-participants-intersection'
								isLoading={isExportParticipantsIntersecLoading}
								ButtonProps={{
									size: 'medium',
									variant: 'outlined',
									color: 'primary',
									onClick: onExportParticipantsIntersecClick
								}}
							>
								{'Export'}
							</LoaderButton>
						</Box>
					</CardActions>
				</Card>

				<Card className={classes.card}>
					<CardContent className={classes.cardContent}>
						<Typography component="h5" variant="h5">
							{'Uploaded Omics'}
						</Typography>
						<Box pb={2} />
						<Typography component="p" variant="inherit">
							{'Export into .csv the list of uploaded into Staging omic data files.'}
						</Typography>
					</CardContent>
					<CardActions>
						<Box display="flex">
							<LoaderButton
								id='export-uploaded-files'
								isLoading={isExportUploadedFilesLoading}
								ButtonProps={{
									size: 'medium',
									variant: 'outlined',
									color: 'primary',
									onClick: onExportUploadedFilesClick
								}}
							>
								{'Export'}
							</LoaderButton>
						</Box>
					</CardActions>
				</Card>
			</Box>
		</>
	);
};
