import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { ProceedTabWithPlugins, ProceedSearchResultContent } from '../types';
import { ACTIONS_PREFIX } from '../constants';

interface ProceedSearchResultTabsState {
	isLoadingProceedContent: boolean
	tabs: ProceedTabWithPlugins[]
	pageHeader: string
}


const initialState: ProceedSearchResultTabsState = {
	isLoadingProceedContent: false,
	tabs: [],
	pageHeader: ''
};

export const backToDataSearchAction = createAction(`${ACTIONS_PREFIX}.tabs/backToDataSearch`);
export const tabsSlice = createSlice({
	name: `${ACTIONS_PREFIX}.tabs`,
	initialState,
	reducers: {
		getProceedContentRequest: (state: ProceedSearchResultTabsState) => {
			state.isLoadingProceedContent = true;
		},
		getProceedContentSuccess: (state: ProceedSearchResultTabsState, { payload }: PayloadAction<ProceedSearchResultContent>) => {
			state.isLoadingProceedContent = false;
			state.tabs = payload.plugins;
			state.pageHeader = payload.page.header;
		},
		getProceedContentFailure: (state: ProceedSearchResultTabsState) => {
			state.isLoadingProceedContent = false;
		}
	}
});
