import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from 'network';

import { GetUserRequest, GetUserResponse } from '../types';

export function* getUser(params: GetUserRequest): SagaIterator<AxiosResponse<GetUserResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};

	return (yield call(networkManager.fetch, Urls.GetUser, requestOptions, params)) as AxiosResponse<GetUserResponse>;
}
