import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { DownloadCreateFileListRequest, DownloadCreateFileListResponse } from '../types';

export function* downloadCreateFileList(params: DownloadCreateFileListRequest): SagaIterator<AxiosResponse<DownloadCreateFileListResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		responseType: 'blob',
		data: { omics: params }
	};

	return (yield call(networkManager.fetch, Urls.DownloadCreateFileList, requestOptions)) as AxiosResponse<DownloadCreateFileListResponse>;
}
