function serializeStrToRegex(str: string) {
	return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export const getUniqueName = (newName: string, reservedNames: string[]): string => {
	const START_INDEX = 2;

	if (reservedNames.every(reservedName => reservedName !== newName)) {
		return newName;
	}

	let uniqueName = newName;

	while (reservedNames.some(reservedName => reservedName === uniqueName)) {
		const lastIndex = reservedNames.reduce((maxIndex, reservedName) => {
			const reservedNameWithIndexNumberMatch = RegExp(`^(${serializeStrToRegex(uniqueName)})\\s\\((\\d+)\\)$`).exec(reservedName);
			if (reservedNameWithIndexNumberMatch) {
				const index = parseInt(reservedNameWithIndexNumberMatch[2], 10);
				return index > maxIndex ? index : maxIndex;
			}
			return maxIndex;
		}, START_INDEX - 1);

		uniqueName += ` (${lastIndex + 1})`;
	}

	return uniqueName;
};
