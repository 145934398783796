export const ACTIONS_PREFIX = 'Statistics';

const MEDIUM_DARK_SHADE_OF_BLUE = '#5d61a2';
const SHADE_OF_BLUE = '#93cdd1';
const MEDIUM_LIGHT_PINK = '#d598a0';

export const COLORS_TOTAL_NUMBER_OF_USERS = [MEDIUM_DARK_SHADE_OF_BLUE, SHADE_OF_BLUE, MEDIUM_LIGHT_PINK];

const MEDIUM_DARK_SHADE_OF_GREEN_BLUE = '#3a7658';
const LIGHT_SHADE_GREEN = '#a7dcb2';
const SHADE_OF_GREEN = '#81be4d';

export const COLORS_TOTAL_NUMBER_OF_NEW_USERS = [MEDIUM_DARK_SHADE_OF_GREEN_BLUE, LIGHT_SHADE_GREEN, SHADE_OF_GREEN];

const DARK_SHADE_OF_RED_ORANGE = '#6f2205';
const MEDIUM_DARK_BROWN = '#a36c22';
const LIGHT_BROWN = '#eacc97';
const LIGHT_SHADE_OF_PINK = '#cec3c8';

export const COLORS_METADATA_DOWNLOADS = [DARK_SHADE_OF_RED_ORANGE, MEDIUM_DARK_BROWN, LIGHT_BROWN, LIGHT_SHADE_OF_PINK];

const MEDIUM_DARK_SHADE_OF_PINK = '#6a3a4f';
const MEDIUM_LIGHT_SHADE_OF_PINK_RED = '#c77a8c';
const SMALL_LIGHT_SHADE_OF_PINK = '#dab6c2';
const LIGHT_SHADE_OF_PURPLISH_BLUE = '#9885b2';
const SHADE_OF_PURPLE = '#745582';

export const COLORS_STEPS_TO_DOWNLOAD = [MEDIUM_DARK_SHADE_OF_PINK, MEDIUM_LIGHT_SHADE_OF_PINK_RED, SMALL_LIGHT_SHADE_OF_PINK, LIGHT_SHADE_OF_PURPLISH_BLUE, SHADE_OF_PURPLE];

const MEDIUM_LIGHT_SHADE_OF_BLUE = '#7eb8da';
const LIGHT_SHADE_OF_BLUE = '#92ddea';
const LIGHT_SHADE_OF_PURPLISH_PINK = '#ffa5d8';
const MEDIUM_SHADE_OF_PURPLISH_BLUE = '#be9ddf';
const DARK_SHADE_OF_PURPLISH_BLUE = '#9579d1';

export const COLORS_TOTAL_AMOUNT_OF_DOWNLOADED_DATA = [MEDIUM_LIGHT_SHADE_OF_BLUE, LIGHT_SHADE_OF_BLUE, LIGHT_SHADE_OF_PURPLISH_PINK, MEDIUM_SHADE_OF_PURPLISH_BLUE, DARK_SHADE_OF_PURPLISH_BLUE];
