import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { DuaSignRequest } from '../types';

export function* sendDuaSignRequest(params: DuaSignRequest): SagaIterator<AxiosResponse> {
	const method: Method = 'POST';

	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};

	return (yield call(networkManager.fetch, Urls.SendDuaSignRequest, requestOptions, params)) as AxiosResponse;
}
