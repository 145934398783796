import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { push } from 'connected-react-router';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementUserSlice } from '../slices';
import { alertSlice } from '../../../../alert/slices';
import { deleteUser } from '../api';
import { selectUser } from '../selectors';


export function* deleteUserSaga(): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.deleteUserStart());

			yield call(deleteUser, { id: user.id });

			yield put(usersManagementUserSlice.actions.deleteUserEnd());
			yield put(push('/admin/users-management'));
			yield put(alertSlice.actions.showAlert({ message: `User '${user.userName ?? ''}' has been successfully deleted.` }));

		} catch (e) {
			yield put(usersManagementUserSlice.actions.deleteUserEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
