import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { selectUser } from '../selectors';
import { usersManagementUserSlice } from '../slices';
import { alertSlice } from '../../../../alert/slices';
import { updateUser } from '../api';
import { UpdateUserRequest } from '../types';
import { getUserSaga } from './getUserSaga';

export function* updateUserSaga({ payload }: PayloadAction<UpdateUserRequest>): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.updateUserStart());

			yield call(updateUser, payload);

			// Retrieve saved user to verify saved successfully.
			yield call(getUserSaga, user.id);

			yield put(usersManagementUserSlice.actions.updateUserEnd());

			yield put(alertSlice.actions.showAlert({ message: 'User data has been successfully updated.' }));
		} catch (e) {
			yield put(usersManagementUserSlice.actions.updateUserEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
