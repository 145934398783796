import React, { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import times from 'lodash/times';

import { common } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import { SkeletonWrapper } from 'components';
import { selectIsWPConfigLoading, selectWPTopMenuPages } from 'features/wpFrame';

import { STATIC_LINKS } from '../constants';
import { NavLink } from '../types';

export const HeaderNavigation: FunctionComponent = () => {
	const { pathname } = useLocation();

	const topMenuPages = useSelector(selectWPTopMenuPages);
	const isWPConfigLoading = useSelector(selectIsWPConfigLoading);

	const wpLinks = useMemo<NavLink[]>(() =>
		topMenuPages.map(({ title: { rendered }, slug }) => ({
			name: rendered,
			to: `/${slug}`
		})), [topMenuPages]);

	return (
		<Box component="nav" display="flex">
			{isWPConfigLoading ?
				<Box py={0.5}>
					{
						// eslint-disable-next-line @typescript-eslint/no-magic-numbers
						times(3, index => <Box key={index} display="inline-block" pr={3}><SkeletonWrapper width={100} height={25} /></Box>)
					}
				</Box> :
				[...STATIC_LINKS, ...wpLinks].map(({ name: linkName, to }) => {
					const isCurrentPage = pathname === to;
					return (
						<Box key={to} py={0.5} mx={2} borderBottom={ `2px solid ${isCurrentPage ? common.white : 'transparent'}` }>
							<Link to={to} component={RouterLink} color="inherit" underline="none">
								<Typography component="span" variant="body1">
									{linkName}
								</Typography>
							</Link>
						</Box>
					);
				})
			}
		</Box>
	);
};
