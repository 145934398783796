import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';

import { Routes } from 'app/Routes';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { saveUserDoi } from '../api';
import { CreateUserDoiRequest, CreateUserDoiResponse } from '../types';

export function* saveUserDoiSaga({ payload }: PayloadAction<CreateUserDoiRequest>): SagaIterator {
	try {
		yield put(doiSlice.actions.saveUserDoiStart());

		const response = (yield call(saveUserDoi, payload)) as AxiosResponse<CreateUserDoiResponse>;

		yield put(push(`${Routes.Doi}/save/${response.data.id}`));

		yield put(doiSlice.actions.saveUserDoiEnd());
	} catch (e) {
		yield put(doiSlice.actions.saveUserDoiEnd());
		yield call(handleErrorSaga, e);
	}
}
