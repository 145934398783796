import { call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementUserSlice } from '../slices';
import { approveUserDua } from '../api';
import { selectUser } from '../selectors';

import { getUserSaga } from './getUserSaga';

export function* approveUserDuaSaga(): SagaIterator {
	const user = (yield select(selectUser)) as ReturnType<typeof selectUser>;

	if (user !== null) {
		try {
			yield put(usersManagementUserSlice.actions.approveUserDuaStart());

			yield call(approveUserDua, { id: user.id });
			yield call(getUserSaga, user.id);

			yield put(usersManagementUserSlice.actions.approveUserDuaEnd());
		} catch (e) {
			yield put(usersManagementUserSlice.actions.approveUserDuaEnd());
			yield call(handleErrorSaga, e);
		}
	}
}
