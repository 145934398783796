import { AxiosResponse, Method } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

import { networkManager, Urls } from 'network';

import { GetUserOmicsResponse } from '../types';

export function* getUserOmics(): SagaIterator<AxiosResponse<GetUserOmicsResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};

	return (yield call(networkManager.fetch, Urls.GetUserOmics, requestOptions)) as AxiosResponse<GetUserOmicsResponse>;
}
