import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';

interface AlertState {
	isShowed: boolean
	message: string
}

const initialState: AlertState = {
	isShowed: false,
	message: ''
};

export const alertSlice = createSlice({
	name: `${ACTIONS_PREFIX}.code`,
	initialState,
	reducers: {
		showAlert: (state: AlertState, { payload: {
			message
		} }: PayloadAction<{
			message: string
		}>) => {
			if (state.isShowed) {
				return;
			}

			state.isShowed = true;
			state.message = message;
		},
		hideAlert: (state: AlertState) => {
			state.isShowed = false;
		}
	}
});
