import { call, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { downloadBlob } from 'utils';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { indexManagementSlice } from '../slices';
import { exportReleasedFilesList } from '../api';
import { ExportReleasedFilesListResponse } from '../types';

export function* exportReleasedFilesListSaga(): SagaIterator {
	try {

		yield put(indexManagementSlice.actions.exportReleasedFilesListStart());

		const { data } = (yield call(exportReleasedFilesList)) as AxiosResponse<ExportReleasedFilesListResponse>;

		yield call(downloadBlob,'aals_released_files.csv', data);

		yield put(indexManagementSlice.actions.exportReleasedFilesListEnd());
	} catch (e) {
		yield put(indexManagementSlice.actions.exportReleasedFilesListEnd());
		yield call(handleErrorSaga, e);
	}
}
