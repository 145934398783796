import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { WPPageConfig } from '../types';

export const getPagesConfig = async (perPage: number, page: number): Promise<AxiosResponse<WPPageConfig[]>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Headers': 'Content-Type',
		}
	};
	const requestParams = {
		'per_page': perPage,
		page
	};
	return networkManager.fetch(Urls.GetPages, requestOptions, requestParams);
};
