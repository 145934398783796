export interface SearchRequestOptions {
	count: boolean
	facets: string[]
	filter: string
	orderby: string
	select: string
	skip: number
	top: number
}

export enum FacetsSearchGroups {
	FacetGroups,
	Facets,
	FacetValues
}

export interface FacetSearchOptionEntity {
	name: string
	path: string[]
}

export interface FacetSearchOption {
	group: FacetsSearchGroups
	entities: FacetSearchOptionEntity[]
}
