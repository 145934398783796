import React, { FunctionComponent, PropsWithChildren, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SortableElement, SortableContainer, SortEnd } from 'react-sortable-hoc';
import { useDispatch } from 'react-redux';
import arrayMove from 'array-move';

import { Grid, Box, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { makeStyles, Theme } from '@material-ui/core/styles';

import {
	DEFAULT_BAR_HEIGHT,
	DEFAULT_BAR_PADDING,
	DEFAULT_FACET_HEIGHT,
	DEFAULT_FACET_WIDTH,
	FACET_CARD_DRAG_ICON_GLOBAL_CLASS,
	HIGHCHARTS_FOOTER_HEIGHT
} from 'styles/constants.ui';

import { DetailsFacetsConfig, DefaultFacetsConfig, Facets, FacetsBaseProps, SelectedFacets, DisplayedFacetsInCardView } from '../types';
import { facetsSlice } from '../slices';
import { ACTIVE_FACET_CARD_ELEVATION } from '../constants.ui';

import { FacetCard, FacetCardProps } from './FacetCard';

const useStyles = makeStyles((theme: Theme) => ({
	draggingCard: {
		zIndex: theme.zIndex.appBar + 1,
		'& .MuiPaper-root': {
			boxShadow: theme.shadows[ACTIVE_FACET_CARD_ELEVATION],
			[`& .${FACET_CARD_DRAG_ICON_GLOBAL_CLASS}`]: {
				display: 'block'
			}
		}
	}
}));

const DraggableContainer = SortableContainer((props: PropsWithChildren<void>) =>
	<Grid container justify='center' spacing={ 4 }>
		{ props.children }
	</Grid>
);
const DraggableFacetCard = SortableElement((props: PropsWithChildren<FacetCardProps>) =>
	<Box tabIndex={0} m={2}>
		<FacetCard {...props} />
	</Box>
);

interface FacetsGridProps extends FacetsBaseProps {
	facets: Facets
	defaultFacetsConfig: DefaultFacetsConfig
	detailsFacetsConfig: DetailsFacetsConfig
	displayedFacetsInCardView: DisplayedFacetsInCardView
	selectedFacets: SelectedFacets
	hideFacetInCardView: (facetName: string) => void
}

export const FacetsGrid: FunctionComponent<FacetsGridProps> = ({
	facets,
	defaultFacetsConfig,
	detailsFacetsConfig,
	selectedFacets,
	displayedFacetsInCardView,
	hideFacetInCardView,
	selectFacetValue,
	unselectFacetValue
}: FacetsGridProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const classes = useStyles();

	const onDragStart = useCallback(() => {
		document.body.style.cursor = 'grabbing';
	}, []);
	const onDragEnd = useCallback(({ oldIndex, newIndex }: SortEnd) => {
		const reorderedDisplayedFacets = arrayMove(displayedFacetsInCardView, oldIndex, newIndex);
		dispatch(facetsSlice.actions.changeDisplayedFacetsOrder(reorderedDisplayedFacets));
		document.body.style.cursor = '';
	}, [dispatch, displayedFacetsInCardView]);

	if (Object.keys(facets).length === 0) {
		return null;
	}

	return (
		displayedFacetsInCardView.length === 0 ?
			<Box pt={14} color={grey['600']}>
				<Typography
					component="p"
					variant="h3"
					align="center"
					style={{ whiteSpace: 'pre-wrap' }}
				>
					{ t('facetCards.noCards') }
				</Typography>
			</Box>
			:
			<DraggableContainer
				axis="xy"
				pressDelay={200}
				onSortStart={onDragStart}
				onSortEnd={onDragEnd}
				helperClass={classes.draggingCard}
			>
				{
					displayedFacetsInCardView.map((facetName, index) => {
						const facet = facets[facetName];
						const details = detailsFacetsConfig[facetName];
						const selectedFacetValues = selectedFacets[facetName] || [];

						const facetDetails = {
							values: [...facet],
							...details
						};

						return (
							<DraggableFacetCard
								key={ facetName }
								index={ index }
								facetName={ facetName }
								facet={ facetDetails }
								selectedFacetValues={ selectedFacetValues }
								hideFacetInCardView={ hideFacetInCardView }
								selectFacetValue={ selectFacetValue }
								unselectFacetValue={ unselectFacetValue }
								width={ defaultFacetsConfig.cardWidth ?? DEFAULT_FACET_WIDTH }
								height={ defaultFacetsConfig.cardHeight ?? DEFAULT_FACET_HEIGHT }
								barHeight={ DEFAULT_BAR_HEIGHT }
								barPadding={ DEFAULT_BAR_PADDING }
								highchartFooterHeight={ HIGHCHARTS_FOOTER_HEIGHT }
								id={ index === 0 ? 'first-facet-card' : '' }
							/>
						);
					})
				}
			</DraggableContainer>
	);
};
