import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import { RouteProps } from 'react-router';

import { ForbiddenPage } from 'features/errorHandling';

type RestrictedRouteProps = Omit<RouteProps, 'render'> & Required<Pick<RouteProps, 'component'>> & {
	isForbidden: boolean
};

export const RestrictedRoute: FunctionComponent<RestrictedRouteProps> = ({
	component: Component,
	isForbidden,
	...rest
}) => {
	// eslint-disable-next-line react/no-multi-comp
	const renderFunction = (props: RouteComponentProps) =>
		// eslint-disable-next-line @typescript-eslint/no-extra-parens
		(!isForbidden
			? <Component { ...props } />
			: <ForbiddenPage />)
	;

	return (
		// eslint-disable-next-line react/jsx-no-bind
		<Route {...rest} render={ renderFunction } />
	);
};
