import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { GetCaseSetConfigResponse } from '../types';

export const getCaseSetConfig = async (): Promise<AxiosResponse<GetCaseSetConfigResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	return networkManager.fetch<GetCaseSetConfigResponse>(Urls.GetCaseSetConfiguration, requestOptions);
};
