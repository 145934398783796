import React, { FunctionComponent, ReactElement, useCallback, useState } from 'react';
import clsx from 'clsx';

import { Fade, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TOOLTIP_BACKGROUND_COLOR, TOOLTIP_FONT_COLOR } from 'styles/constants.ui';

interface TooltipWrapperProps {
	title: string
	disabled?: boolean
	children: ReactElement
}

const TITLE_LENGTH_BREAKPOINT = 80;

const useStyles = makeStyles(() => ({
	tooltip: {
		backgroundColor: TOOLTIP_BACKGROUND_COLOR,
		color: TOOLTIP_FONT_COLOR
	},
	maxTooltip: {
		maxWidth: '14rem'
	},
	smallTooltip: {
		maxWidth: '10.5rem'
	}
}));

export const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({
	title,
	disabled = false,
	children
}: TooltipWrapperProps) => {
	const classes = useStyles();

	const [isOpen, setIsOpen] = useState(false);

	const isTooltipCanBeOpened = !disabled && title.length > 0;
	const isMaxTooltip = title.length > TITLE_LENGTH_BREAKPOINT;

	const onOpen = useCallback(() => {
		if (isTooltipCanBeOpened) {
			setIsOpen(true);
		}
	}, [isTooltipCanBeOpened]);
	const onClose = useCallback(() => {
		setIsOpen(false);
	}, []);

	if (isTooltipCanBeOpened) {
		return (
			<Tooltip
				classes={{ tooltip: clsx(classes.tooltip, {
					[classes.maxTooltip]: isMaxTooltip,
					[classes.smallTooltip]: !isMaxTooltip
				}) }}
				TransitionComponent={Fade}
				open={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				title={
					<Typography variant="caption">
						{ title }
					</Typography>
				}>
				{ children }
			</Tooltip>
		);
	}
	return children;
};
