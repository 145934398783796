import axios, { Method, AxiosRequestConfig, AxiosResponse } from 'axios';

import { AuthManagerTypes } from '../auth/types';
import { portalAuthManager } from '../auth/portalAuthManager';
import { cliAuthManager } from '../auth/cliAuthManager';
import { getLanguage } from '../locale';

import { getUrlString, checkSuccessStatus } from './utils';
import { RequestParams, ApiUrl } from './types';

interface RequestOptions<T> {
	method: Method
	headers: Record<string, string>
	data?: T | null
}

interface FetchDataRequestConfig extends AxiosRequestConfig {
	headers: Record<string, string>
}

const fetchData = async<T> (
	url: ApiUrl,
	requestOptions: FetchDataRequestConfig,
	requestParams?: RequestParams
): Promise<AxiosResponse<T>> => {
	const urlString = getUrlString(url);
	const requestUrl = new URL(urlString);

	if (requestParams) {
		for (const key in requestParams) {
			const paramsValue = requestParams[key];
			if (Array.isArray(paramsValue)) {
				paramsValue.forEach(value => requestUrl.searchParams.append(key, value));
			} else if (paramsValue) {
				requestUrl.searchParams.append(key, paramsValue.toString());
			}
		}
	}

	requestOptions.headers = {
		Locale: getLanguage(),
		...requestOptions.headers
	};

	if (url.authManagerType !== undefined) {
		const managers = {
			[AuthManagerTypes.Portal]: portalAuthManager,
			[AuthManagerTypes.Cli]: cliAuthManager,
		};

		const tokenInfo = await managers[url.authManagerType].getTokenInfo();

		if (tokenInfo !== null) {
			requestOptions.headers['Authorization'] = `Bearer ${tokenInfo.accessToken}`;
		}
	}

	const res = await axios(requestUrl.href, requestOptions);
	if (res && !checkSuccessStatus(res.status)) {
		throw Error(`Request failed with status code ${res.status}`);
	}
	return res as AxiosResponse;
};

export default {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	fetch: async<T = any> (
		url: ApiUrl,
		requestOptions: RequestOptions<unknown>,
		requestParams?: RequestParams
	): Promise<AxiosResponse<T>> => fetchData<T>(url, requestOptions, requestParams),
};
