import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { getDoiConfig } from '../api';
import { DoiConfigResponse } from '../types';
import { doiSlice } from '../slices';
import { selectDoiConfig } from '../selectors';

export function* getDoiConfigSaga(): SagaIterator {
	try {
		yield put(doiSlice.actions.getDoiConfigStart());

		const doiConfig = (yield select(selectDoiConfig)) as ReturnType<typeof selectDoiConfig>;

		if (doiConfig === null) {
			const response = (yield call(getDoiConfig)) as AxiosResponse<DoiConfigResponse>;
			yield put(doiSlice.actions.getDoiConfigEnd({ endState: SagaEndState.Success, data: response.data }));
		} else {
			yield put(doiSlice.actions.getDoiConfigEnd({ endState: SagaEndState.Success, data: doiConfig }));
		}
	} catch (e) {
		yield put(doiSlice.actions.getDoiConfigEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
