import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SagaEndPayloadAction, SagaEndState } from 'types';

import { ACTIONS_PREFIX } from '../constants';
import { UpdateUserRequest, User } from '../types';

interface UsersManagementUserState {
	user: User | null

	isUsersManagementUserInitLoading: boolean
	isUserUpdateLoading: boolean
	isUserDuaApproving: boolean
	isUserDuaRejecting: boolean
	isDownloadDocuSignUserAgreementLoading: boolean
	isUserDeleting: boolean
	isDuaSignRequestSending: boolean
	isResearcherSurveySending: boolean
}

const initialState: UsersManagementUserState = {
	user: null,

	isUsersManagementUserInitLoading: false,
	isUserUpdateLoading: false,
	isUserDuaApproving: false,
	isUserDuaRejecting: false,
	isDownloadDocuSignUserAgreementLoading: false,
	isUserDeleting: false,
	isDuaSignRequestSending: false,
	isResearcherSurveySending: false
};

export const usersManagementUserSlice = createSlice({
	name: `${ACTIONS_PREFIX}.user`,
	initialState,
	reducers: {
		initUsersManagementUser: (_state, _payload: PayloadAction<{ id: string }>) => undefined,
		initUsersManagementUserStart: (state: UsersManagementUserState) => {
			state.isUsersManagementUserInitLoading = true;
		},
		initUsersManagementUserEnd: (state: UsersManagementUserState) => {
			state.isUsersManagementUserInitLoading = false;
		},

		getUser: (_state, _payload: PayloadAction<{ id: string }>) => undefined,
		getUserStart: (state: UsersManagementUserState) => {
			state.isUsersManagementUserInitLoading = true;
		},
		getUserEnd: (state: UsersManagementUserState, { payload }: SagaEndPayloadAction<{ user: User }>) => {
			if (payload.endState === SagaEndState.Success) {
				state.user = payload.data.user;
			}
			state.isUsersManagementUserInitLoading = false;
		},

		updateUser: (_state, _payload: PayloadAction<UpdateUserRequest>) => undefined,
		updateUserStart: (state: UsersManagementUserState) => {
			state.isUserUpdateLoading = true;
		},
		updateUserEnd: (state: UsersManagementUserState) => {
			state.isUserUpdateLoading = false;
		},

		deleteUser: () => undefined,
		deleteUserStart: (state: UsersManagementUserState) => {
			state.isUserDeleting = true;
		},
		deleteUserEnd: (state: UsersManagementUserState) => {
			state.isUserDeleting = false;
		},

		approveUserDua: () => undefined,
		approveUserDuaStart: (state: UsersManagementUserState) => {
			state.isUserDuaApproving = true;
		},
		approveUserDuaEnd: (state: UsersManagementUserState) => {
			state.isUserDuaApproving = false;
		},

		rejectUserDua: (_state: UsersManagementUserState, _: PayloadAction<{ rejectionReason: string }>) => undefined,
		rejectUserDuaStart: (state: UsersManagementUserState) => {
			state.isUserDuaRejecting = true;
		},
		rejectUserDuaEnd: (state: UsersManagementUserState) => {
			state.isUserDuaRejecting = false;
		},

		downloadDocuSignUserAgreement: (_state: UsersManagementUserState) => undefined,
		downloadDocuSignUserAgreementStart: (state: UsersManagementUserState) => {
			state.isDownloadDocuSignUserAgreementLoading = true;
		},
		downloadDocuSignUserAgreementEnd: (state: UsersManagementUserState) => {
			state.isDownloadDocuSignUserAgreementLoading = false;
		},

		sendDuaSignRequest: (_state: UsersManagementUserState) => undefined,
		sendDuaSigningRequestStart: (state: UsersManagementUserState) => {
			state.isDuaSignRequestSending = true;
		},
		sendDuaSignRequestEnd: (state: UsersManagementUserState) => {
			state.isDuaSignRequestSending = false;
		},
		sendResearcherSurvey: (_state: UsersManagementUserState) => undefined,
		sendResearcherSurveyStart: (state: UsersManagementUserState) => {
			state.isResearcherSurveySending = true;
		},
		sendResearcherSurveyEnd: (state: UsersManagementUserState) => {
			state.isResearcherSurveySending = false;
		}
	},
});
