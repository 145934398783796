import { call, put, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { getEntitiesCount } from '../api';
import { entitiesCountSlice } from '../slices';
import { EntityCount } from '../types';


export function* getEntitiesCountSaga(filter: string): Generator<StrictEffect, void, AxiosResponse<EntityCount[]>> {
	try {
		const entitiesCountResponse: AxiosResponse<EntityCount[]> = yield call(getEntitiesCount, filter);
		yield put(entitiesCountSlice.actions.updateEntitiesCount({ entitiesCount: entitiesCountResponse.data }));
	} catch (e) {
		yield call(handleErrorSaga, e);
	}
}
