import { call, put, select, StrictEffect } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { Configuration, ConfigurationResponse } from '../types';
import { submitDataSlice } from '../slices';
import { getPageConfiguration } from '../api';
import { selectConfiguration, selectIsConfigurationInitialized } from '../selectors';

export function* getPageConfigurationSaga(): Generator<StrictEffect, void> {
	try {
		const isConfigurationInitialized = (yield select(selectIsConfigurationInitialized)) as boolean;

		if (isConfigurationInitialized) {
			const configuration = (yield select(selectConfiguration)) as Configuration;
			yield put(submitDataSlice.actions.pageConfigurationSuccess(configuration));
			return;
		}

		const response = (yield call(getPageConfiguration)) as AxiosResponse<ConfigurationResponse>;

		yield put(submitDataSlice.actions.pageConfigurationSuccess(response.data));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(submitDataSlice.actions.pageConfigurationFailure());
	}
}
