import { DetailsFacetsItemConfig, EntitiesExpandState } from '../types';
import { FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME } from '../constants';

export const mapFacetsConfigToEntitiesExpandState = (detailsFacetsListPanel: DetailsFacetsItemConfig[]): EntitiesExpandState => {
	const entitiesExpandState: EntitiesExpandState = {};

	for (const tab of detailsFacetsListPanel) {
		entitiesExpandState[tab.name] = {
			state: false,
			childs: {}
		};
		for (const group of tab.groups) {
			entitiesExpandState[tab.name].childs[group.name] = {
				state: false,
				childs: {}
			};

			for (const facet of group.facets) {
				entitiesExpandState[tab.name].childs[group.name].childs[facet.name] = {
					state: false,
					childs: {}
				};

				// values used for show more functionality in facets
				entitiesExpandState[tab.name].childs[group.name].childs[facet.name].childs[FACET_PANEL_FACET_VALUES_EXPAND_STATE_NAME] = {
					state: false,
					childs: {}
				};
			}
		}
	}

	return entitiesExpandState;
};
