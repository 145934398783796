import { Box, CircularProgress, makeStyles, Paper } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { PyramidChartData, StepsToDownloadPlotting } from '../types';
import { PyramidChart } from './PyramidChart';


const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	paperContainer: {
		margin: '10px',
		width: '520px',
		height: '400px'
	}
}));

interface PyramidChartContainerProps {
	isLoading: boolean
	data: PyramidChartData | null
	colors: string[]
	title: string
}

export const PyramidChartContainer: FunctionComponent<PyramidChartContainerProps> = ({
	isLoading,
	data,
	colors,
	title
}) => {
	const classes = useStyles();

	const seriesPyramidChart = (obj: Record<string,Record<string, string | number>> | null): StepsToDownloadPlotting | null => {
		if (!obj) {
			return null;
		}
		return Object.values(obj).map(item => [item.title, item.count]);
	};

	return <Paper className={classes.paperContainer}>
		{isLoading
			? <Box className={classes.root}>
				<CircularProgress size={60} />
			</Box>
			: data &&
			<PyramidChart
				data={seriesPyramidChart(data.data)} title={title} colors={colors}/>
		}
	</Paper>;
};

