import React, { FunctionComponent, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SnackbarWrapper } from '../../../components';

import { Alert as AlertType } from '../types';
import { selectAlert } from '../selectors';
import { alertSlice } from '../slices';

export const Alert: FunctionComponent = () => {
	const dispatch = useDispatch();

	const { isShowed, message }: AlertType = useSelector(selectAlert);

	const onClose = useCallback(() => {
		dispatch(alertSlice.actions.hideAlert());
	}, [dispatch]);

	return (
		<SnackbarWrapper
			open={isShowed}
			message={message}
			onClose={onClose}
		/>
	);
};
