import React, { FunctionComponent, useCallback } from 'react';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { Typography, Box, FormControlLabel, Checkbox, IconButton, Menu } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';

import { TooltipWrapper } from '../../../../../components';
import { DRAWER_VALUE_WIDTH } from 'styles/constants.ui';

import { CaseSetEntity } from '../types';
import { getCaseSetName } from '../utils';

const MIN_LETTERS_TITLE_TOOLTIP = 15;

interface CaseSetGroupItemProps {
	caseSetEntity: CaseSetEntity
	isSelected: boolean
	onSelectionChange: (e: React.SyntheticEvent, isChecked: boolean) => void
	onCaseSetRemoveClick: () => void
	onCaseSetEditClick: () => void
}

export const CaseSetGroupItem: FunctionComponent<CaseSetGroupItemProps> = ({
	caseSetEntity,
	isSelected,
	onSelectionChange,
	onCaseSetRemoveClick,
	onCaseSetEditClick,
}) => {
	const itemMenuState = usePopupState({
		variant: 'popover',
		popupId: caseSetEntity.id,
	});

	const onItemMenuClick = useCallback(
		/* eslint-disable promise/prefer-await-to-callbacks */
		(callback: () => void) => () => {
			itemMenuState.close();
			callback();
		},
		[itemMenuState]
	);

	const title = getCaseSetName(caseSetEntity);

	return (
		<Box pl={4} pr={0} display="flex" justifyContent="space-between">
			<Box>
				<FormControlLabel
					control={<Checkbox color="primary" checked={isSelected} onChange={onSelectionChange} />}
					label={
						<Box width={DRAWER_VALUE_WIDTH} color={grey['800']}>
							<TooltipWrapper title={title} disabled={title.length < MIN_LETTERS_TITLE_TOOLTIP}>
								<Typography color="primary" display="block" component="span" variant="body1" noWrap={true}>
									{title}
								</Typography>
							</TooltipWrapper>
						</Box>
					}
				/>
			</Box>
			<Box display="flex" alignItems="center" color={grey['700']}>
				<IconButton
					aria-label="Open custom case set menu"
					component="span"
					size="small"
					style={{ marginRight: '-4px' }}
					{...bindTrigger(itemMenuState)}
				>
					<MoreVertIcon fontSize="default" />
				</IconButton>
			</Box>
			<Menu {...bindMenu(itemMenuState)}>
				<MenuItem onClick={onItemMenuClick(onCaseSetEditClick)}>{'Edit'}</MenuItem>
				<MenuItem onClick={onItemMenuClick(onCaseSetRemoveClick)}>{'Delete'}</MenuItem>
			</Menu>
		</Box>
	);
};
