import React, { useEffect, useCallback, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { replace } from 'connected-react-router';
import clsx from 'clsx';

import { Box, Button, ButtonGroup, Theme, Typography, createStyles, LinearProgress } from '@material-ui/core';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { common } from '@material-ui/core/colors';
import zIndex from '@material-ui/core/styles/zIndex';

import { ReactComponent as CardViewIcon } from '../assets/icons/CardViewIcon.svg';

import { DRAWER_WIDTH, HEADER_HEIGHT_SPACING, FOOTER_HEIGHT_SPACING } from 'styles/constants.ui';
import { Routes } from 'app/Routes';

import { SelectedFacetValue, SearchViewMode } from '../types';
import { facetsSlice, searchDetailsSlice } from '../slices';
import {
	selectSelectedFacets,
	selectDefaultFacetsConfig,
	selectDetailsFacetsConfig,
	selectIsSearchLoading,
	selectFacets,
	selectFacetsListConfig,
	selectViewMode,
	selectFilterQueryString,
	selectDisplayedFacetsInCardView,
	selectIsFacetsConfigLoading,
	selectIsInitialSearchLoading
} from '../selectors';
import { caseSetSlice, CaseSetModal, selectSelectedCaseSetEntities, CaseSetEntity } from '../features/caseSet';
import { proceedSlice } from 'features/search';

import { SearchFooterUpdated } from './SearchFooterUpdated';
import { FacetsListPanel } from './FacetsListPanel';
import { SelectedFacetsPanel } from './SelectedFacetsPanel';
import { SearchDetailsTable } from './SearchDetailsTable';
import { SearchCardView } from './SearchCardView';
import { selectUserDataAccessStatus } from 'auth/selectors';
import { UserDataAccessStatuses } from 'auth/types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		content: {
			flexGrow: 1,
			padding: theme.spacing(0, 2),
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			marginLeft: -DRAWER_WIDTH,
		},
		tableViewContent: {
			overflow: 'hidden',
			paddingRight: theme.spacing(0),
		},
		contentShift: {
			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			}),
			marginLeft: 0,
		},
		viewModeIcon: {
			fill: theme.palette.grey['600'],
			color: theme.palette.grey['600']
		},
		viewModeIconActive: {
			fill: theme.palette.primary.main,
			color: theme.palette.primary.main
		},
		viewModeButtonActive: {
			backgroundColor: 'rgba(98, 0, 238, 0.12)'
		},
		wrapper: {
			padding: 0,
			width: '100%',
			height: `calc(100% - ${theme.spacing(HEADER_HEIGHT_SPACING + FOOTER_HEIGHT_SPACING)}px)`
		},
		whiteBackground: {
			backgroundColor: common['white']
		}
	})
);

export const Search: FunctionComponent = () => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const isSearchLoading = useSelector(selectIsSearchLoading);
	const isFacetsConfigLoading = useSelector(selectIsFacetsConfigLoading);
	const isInitialSearchLoading = useSelector(selectIsInitialSearchLoading);
	const defaultFacetsConfig = useSelector(selectDefaultFacetsConfig);
	const detailsFacetsConfig = useSelector(selectDetailsFacetsConfig);
	const selectedFacets = useSelector(selectSelectedFacets);
	const displayedFacetsInCardView = useSelector(selectDisplayedFacetsInCardView);
	const facets = useSelector(selectFacets);
	const detailsFacetsListPanel = useSelector(selectFacetsListConfig);
	const viewMode = useSelector(selectViewMode);
	const selectedCaseSetEntities: CaseSetEntity[] = useSelector(selectSelectedCaseSetEntities);
	const filterQueryString: string = useSelector(selectFilterQueryString);
	const isUserHasAccessToData = useSelector(selectUserDataAccessStatus) === UserDataAccessStatuses.HasAccess;

	useEffect(() => {
		dispatch(proceedSlice.actions.getProceedTabsRequest());
	}, [dispatch]);

	useEffect(() => {
		dispatch(facetsSlice.actions.getFacetsConfigRequest());
		dispatch(searchDetailsSlice.actions.getFieldsConfigRequest());
	}, [dispatch]);

	const hideFacetInCardView = useCallback((facetName: string) => dispatch(facetsSlice.actions.hideFacetInCardView(facetName)),
		[dispatch]
	);

	const selectValue = useCallback(
		(facetName: string, selectedValue: SelectedFacetValue) => dispatch(facetsSlice.actions.selectValue({ facetName, selectedValue })),
		[dispatch]
	);

	const unselectValue = useCallback((facetName: string, unselectedValue: SelectedFacetValue) => dispatch(facetsSlice.actions.unselectValue({ facetName, unselectedValue })),
		[dispatch]
	);

	const unselectCaseSet = useCallback((id: string) => dispatch(caseSetSlice.actions.unselectCaseSet({ id })),
		[dispatch]
	);

	const unselectAllEntities = useCallback(() => {
		dispatch(facetsSlice.actions.unselectAllValues());
		dispatch(caseSetSlice.actions.unselectAllCaseSetEntities());
		dispatch(facetsSlice.actions.setFilterQueryString(''));
		dispatch(replace({ pathname: Routes.Search, search: '', hash: '' }));
	}, [dispatch]);

	const unselectExtCaseSet = useCallback(() => {
		dispatch(facetsSlice.actions.unselectExtCaseSet());
		dispatch(replace({ pathname: Routes.Search, search: '', hash: '' }));
	}, [dispatch]);

	const getToggleViewModeHandler = useCallback((newMode: SearchViewMode) => () => {
		if (newMode !== viewMode) {
			dispatch(facetsSlice.actions.setViewMode(newMode));
		}
	}, [dispatch, viewMode]);

	const isLoading = isSearchLoading || isFacetsConfigLoading || isInitialSearchLoading;

	const tableViewVisible = isUserHasAccessToData;

	return (
		<>
			<Box visibility={isLoading ? 'visible' : 'hidden'} position='absolute' top={theme.spacing(HEADER_HEIGHT_SPACING)} width="100%" zIndex={zIndex.appBar}>
				<LinearProgress />
			</Box>
			<div className={ clsx(classes.wrapper, { [classes.whiteBackground]: viewMode === SearchViewMode.Table }) }>
				<Box display='flex' flexDirection='row' height='100%'>
					<FacetsListPanel
						detailsFacetsListPanel={detailsFacetsListPanel}
						facets={facets}
						selectFacetValue={selectValue}
						unselectFacetValue={unselectValue}
						selectedFacets={selectedFacets}
					/>
					<Box px={3} width='100%' overflow='auto'>
						<Box display='flex' justifyContent='space-between' flexDirection='row' py={3}>
							<Typography component='h1' variant='h4'>
								{ 'Data Search' }
							</Typography>
							<ButtonGroup color='primary' aria-label='set view mode'>
								<Button
									title='Card view'
									aria-label='Card view'
									onClick={getToggleViewModeHandler(SearchViewMode.Card)}
									className={clsx({ [classes.viewModeButtonActive]: viewMode === SearchViewMode.Card })}>
									<CardViewIcon className={clsx({
										[classes.viewModeIcon]: viewMode !== SearchViewMode.Card,
										[classes.viewModeIconActive]: viewMode === SearchViewMode.Card
									})} />
								</Button>
								{tableViewVisible && <Button
									title='Table view'
									aria-label='Table view'
									onClick={getToggleViewModeHandler(SearchViewMode.Table)}
									className={clsx({ [classes.viewModeButtonActive]: viewMode === SearchViewMode.Table })}>
									<FormatAlignJustify className={clsx({
										[classes.viewModeIcon]: viewMode !== SearchViewMode.Table,
										[classes.viewModeIconActive]: viewMode === SearchViewMode.Table
									})} />
								</Button>}
							</ButtonGroup>
						</Box>
						{ (Object.keys(selectedFacets).length > 0 || selectedCaseSetEntities.length > 0 || filterQueryString.length > 0) &&
						!isInitialSearchLoading && Object.keys(facets).length > 0 &&
							<SelectedFacetsPanel
								detailsFacetsConfig={detailsFacetsConfig}
								selectedFacets={selectedFacets}
								unselectValue={unselectValue}
								unselectAllEntities={unselectAllEntities}
								selectedCaseSetEntities={selectedCaseSetEntities}
								unselectCaseSet={unselectCaseSet}
								filterQueryString={filterQueryString}
								unselectExtCaseSet={unselectExtCaseSet}
							/>
						}
						<Box pb={0} display='flex' flexDirection='flex-row'>
							{ viewMode === SearchViewMode.Card
								? <main className={clsx(classes.content, classes.contentShift)}>
									<SearchCardView
										facets={ facets }
										defaultFacetsConfig={ defaultFacetsConfig }
										selectedFacets={ selectedFacets }
										detailsFacetsConfig={ detailsFacetsConfig }
										displayedFacetsInCardView={ displayedFacetsInCardView }
										hideFacetInCardView={ hideFacetInCardView }
										selectFacetValue={ selectValue }
										unselectFacetValue={ unselectValue }
									/>
								</main>
								: <main className={clsx(classes.content, classes.contentShift, classes.tableViewContent)}>
									{ Object.keys(facets).length > 0 && <SearchDetailsTable /> }
								</main>
							}
						</Box>
					</Box>
				</Box>
				<SearchFooterUpdated />
			</div>
			<CaseSetModal />
		</>
	);
};
