import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { facetsSlice } from '../slices';
import { caseSetSlice } from '../features/caseSet';
import { getExternalCaseSetSaga } from '../features/caseSet/sagas/getExternalCaseSetSaga';

import { definedSearchRequestSaga } from './definedSearchRequestSaga';
import { resetPaginationSaga } from './resetPaginationSaga';

export function* initialSearchSaga(): SagaIterator {
	try {
		yield put(facetsSlice.actions.initialSearchRequest());

		const query = (yield call(() => new URLSearchParams(window.location.search))) as URLSearchParams;

		const filterQueryStr = query.get('filter');
		if (filterQueryStr) {
			yield put(facetsSlice.actions.setFilterQueryString(filterQueryStr));
		}

		const caseSetId = query.get('caseSet');
		if (caseSetId) {
			yield call(getExternalCaseSetSaga, caseSetSlice.actions.getExternalCaseSetRequest({ id: caseSetId }));
		} else {
			yield call(resetPaginationSaga);
			yield call(definedSearchRequestSaga);
		}

		yield put(facetsSlice.actions.initialSearchSuccess());
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(facetsSlice.actions.initialSearchFail());
	}
}
