import { sumBy, some } from 'lodash';
import { FacetValue, SelectedFacetValue } from '../types';
import { compareFacetValues } from './compareFacetValues';

export const getFacetTotalCount = (facetValues: FacetValue[], selectedFacetValues: SelectedFacetValue[]): number =>
	sumBy(facetValues, value => {
		if (selectedFacetValues.length === 0 || some(selectedFacetValues, selectedValue => compareFacetValues(value, selectedValue))) {
			return value.count;
		}
		return 0;
	});
