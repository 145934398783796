import React, { FunctionComponent } from 'react';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { makeStyles, Theme } from '@material-ui/core/styles';
import data from './../data/text.json';
import { CommonGreeting } from './CommonGreeting';
import { Box, Button } from '@material-ui/core';
import { CommonCapabilities } from './CommonCapabilities';

const PADDINGS = 7;

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		width: '100%',
		maxWidth: 1200,
		justifyContent: 'center',
		minHeight: 'inherit',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(PADDINGS)
	},
	descriptionWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '50px',
		marginTop: '20px'
	},
	subtitle1: {
		fontWeight: 500,
		letterSpacing: '0.5px',
		maxWidth: '80%',
		fontSize: '1.2rem',
		lineHeight: '2rem'
	},
	button: {
		whiteSpace: 'nowrap',
		padding: '15px 25px'
	},
}));

export const RequestDataRejected: FunctionComponent = () => {
	const classes = useStyles();

	return <Box className={classes.wrapper}>
		<CommonGreeting
			title={data.title}
			description={data.description}
		/>
		<Box className={classes.descriptionWrapper}>
			<Box
				className={classes.subtitle1}
				dangerouslySetInnerHTML={{ __html: data.rejectedApplicationDescription }}
			/>
			<Box>
				<Button disabled
					className={classes.button}
					variant="contained"
					style={{
						background: '#DC004E',
						color: '#FFFDFD',
						border: '1px solid #DC004E',
					}}
					size="large">
					{data.buttonLabelRejected}
				</Button>
			</Box>
		</Box>
		<Box pb={4} />
		<CommonCapabilities
			title={data.capabilitiesTitle}
			capabilities={data.capabilitiesList}/>
	</Box>;
};
