import { SagaIterator } from 'redux-saga';

import { statisticsSlice } from '../slices';
import { takeEvery } from '@redux-saga/core/effects';
import { getTotalNumberOfUsersSaga } from './getTotalNumberOfUsersSaga';
import { getTotalNumberOfNewUsersSaga } from './getTotalNumberOfNewUsersSaga';
import { getTotalAmountOfDownloadedDataSaga } from './getTotalAmountOfDownloadedDataSaga';
import { getMetadataDownloadsSaga } from './getMetadataDownloadsSaga';
import { getStepsToDownloadSaga } from './getStepsToDownloadSaga';
import { getOmicsYearlyDownloadsSaga } from './getOmicsYearlyDownloadsSaga';

export function* rootStatisticsSaga(): SagaIterator {
	yield takeEvery(statisticsSlice.actions.getTotalNumberOfUsers.type, getTotalNumberOfUsersSaga);
	yield takeEvery(statisticsSlice.actions.getTotalNumberOfNewUsers.type, getTotalNumberOfNewUsersSaga);
	yield takeEvery(statisticsSlice.actions.getTotalAmountOfDownloadedData.type, getTotalAmountOfDownloadedDataSaga);
	yield takeEvery(statisticsSlice.actions.getMetadataDownloads.type, getMetadataDownloadsSaga);
	yield takeEvery(statisticsSlice.actions.getStepsToDownload.type, getStepsToDownloadSaga);
	yield takeEvery(statisticsSlice.actions.getOmicsYearlyDownloadsData.type, getOmicsYearlyDownloadsSaga);
}
