import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { getUserOmics } from '../api';
import { GetUserOmicsResponse } from '../types';

export function* getUserOmicsSaga(): SagaIterator {
	try {
		yield put(doiSlice.actions.getUserOmicsStart());

		const response = (yield call(getUserOmics)) as AxiosResponse<GetUserOmicsResponse>;

		yield put(doiSlice.actions.getUserOmicsEnd({ endState: SagaEndState.Success, data: response.data }));
	} catch (e) {
		yield put(doiSlice.actions.getUserOmicsEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
