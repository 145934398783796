import { SagaIterator } from 'redux-saga';
import { call, put, all } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { facetsSlice, searchDetailsSlice, facetsPanelSlice } from '../slices';
import { search } from '../api';
import {
	SearchRequestOptions,
	SearchResponse,
	Facets,
	SearchState
} from '../types';
import { getSortedFacetsValues, mapFacetsConfigToSearchGroups } from '../utils';

import { getSearchRequestOptionsSaga } from './getSearchRequestOptionsSaga';
import { getEntitiesCountSaga } from './getEntitiesCountSaga';
import { getFilteredFacetsValuesSaga } from './getFilteredFacetsValuesSaga';

export function* searchRequestSaga(searchState: SearchState): SagaIterator {
	try {
		yield put(facetsSlice.actions.searchRequest());

		const options = (yield call(getSearchRequestOptionsSaga, searchState)) as SearchRequestOptions;

		const [searchResponse, filteredFacets] = (yield all([
			call(search, options),
			call(getFilteredFacetsValuesSaga, searchState),
			call(getEntitiesCountSaga, options.filter)
		])) as [AxiosResponse<SearchResponse>, Facets];

		const allFacets = searchResponse.data['@search.facets'];
		const totalCount = searchResponse.data['@odata.count'];
		const rows = searchResponse.data['value'];

		yield put(searchDetailsSlice.actions.setRows(rows));

		const facets: Facets = {
			...allFacets,
			...filteredFacets
		};

		const sortedFacetsValues = (yield call(getSortedFacetsValues, facets, searchState.detailsFacetsConfig)) as ReturnType<typeof getSortedFacetsValues>;

		const facetSearchOptions = (yield call(mapFacetsConfigToSearchGroups, searchState.detailsFacetsListPanel, sortedFacetsValues)) as ReturnType<typeof mapFacetsConfigToSearchGroups>;
		yield put(facetsPanelSlice.actions.setFacetSearchOptions(facetSearchOptions));

		yield put(facetsSlice.actions.searchSuccess({ facets: sortedFacetsValues, totalCount }));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(facetsSlice.actions.searchFail());
	}
}
