export enum ByteUnitMultiples {
	Bytes = 0,
	KB,
	MB,
	GB,
	TB,
	PB
}

export const convertBytes = (
	amount: number,
	fromMultiple: ByteUnitMultiples = ByteUnitMultiples.Bytes,
	toMultiple: ByteUnitMultiples = ByteUnitMultiples.GB,
	decimals = 0
): number => {
	if (amount === 0 || fromMultiple === toMultiple) {
		return amount;
	}

	const baseMultiple = 1000;
	const fromValueInBytes = amount * baseMultiple ** fromMultiple;
	const accuracy = decimals < 0 ? 0 : decimals;

	return parseFloat((fromValueInBytes / baseMultiple ** toMultiple).toFixed(accuracy));
};
