import React, { FunctionComponent } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import funnel from 'highcharts/modules/funnel';
import { makeStyles } from '@material-ui/core';
import { StepsToDownloadPlotting } from '../types';

funnel(Highcharts);

const useStyles = makeStyles(() => ({
	charts: {
		'& > div': {
			overflow: 'hidden',
			height: '100%',
			width: '100%'
		},
	}
}));

interface PyramidChartProps {
	data: StepsToDownloadPlotting | null
	title: string
	colors: string[]
}

export const PyramidChart: FunctionComponent<PyramidChartProps> = ({
	data,
	title,
	colors
}) => {
	const classes = useStyles();

	const options = {
		colors,
		chart: {
			type: 'pyramid'
		},
		title: {
			text: title,
			x: -50
		},
		plotOptions: {
			series: {
				dataLabels: {
					enabled: true,
					format: '<b>{point.name}</b> ({point.y})',
					softConnector: true,
					y: -10,
					style: {
						width: '100%',
					}
				},
				center: ['40%', '50%'],
				width: '60%'
			}
		},
		tooltip: {
			outside: true,
			useHTML: true,
			backgroundColor: 'rgba(246, 246, 246, 1)',
			borderRadius: 30,
			borderColor: '#bbbbbb',
			borderWidth: 1.5,
			style: { opacity: 1, background: 'rgba(246, 246, 246, 1)' },
		},
		legend: {
			enabled: false
		},
		series: [
			{
				name: 'Steps to Download',
				data
			}
		],
		exporting: {
			buttons: {
				contextButton: {
					menuItems: [
						'printChart',
						'separator',
						'downloadPNG',
						'downloadJPEG',
						'downloadPDF',
						'downloadSVG'
					]
				}
			}
		}
	};

	return (
		<div className={classes.charts}>
			<HighchartsReact
				highcharts={Highcharts}
				constructorType={'chart'}
				options={options}
				containerProps={{ style: { height: '400px', width: '520px' } }}
			/>
		</div>
	);
};
