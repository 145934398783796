import React, { FunctionComponent } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { TextField } from '@material-ui/core';
import { FormikProps } from 'formik';
import { UserEditFormFields } from '../types';

export interface UserNotesSectionProps {
	form: FormikProps<UserEditFormFields>
}

// eslint-disable-next-line arrow-body-style
export const UserNotesSection: FunctionComponent<UserNotesSectionProps> = ({ form }) => {

	return (
		<Box width={630}>
			<Box mr={3}>
				<Paper>
					<Box p={2}>
						<Box mb={2.5} display="flex" flexDirection="column">
							<Typography
								variant="h5"
								component="h2"
							>
								{ 'Notes' }
							</Typography>
							<Box pb={2} />
							<Box display="flex" justifyContent="space-between">
								<TextField
									name="notes"
									id="notes-input"
									label={'Notes'}
									margin='normal'
									InputLabelProps={{
										shrink: true,
									}}
									fullWidth
									variant='outlined'
									multiline
									value={ form.values.notes }
									onChange={form.handleChange}
									onBlur={form.handleBlur}
									error={form.touched.notes && Boolean(form.errors.notes)}
									helperText={form.touched.notes && form.errors.notes}
								/>
							</Box>
						</Box>
					</Box>
				</Paper>
			</Box>
			<Box pb={3} />
		</Box>
	);
};
