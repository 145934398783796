import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { LoaderButton } from 'components';
import { CustomTable, Column, CustomTableColumnsPanel, CustomTableNoRows } from 'components/CustomTable';
import { mapCellsDataToNodes } from 'components/CustomTable/utils';

import { usersManagementSlice, usersManagementTableSlice } from '../slices';
import {
	selectIsInitialized,
	selectTableColumns,
	selectUsersList,
	selectTableSort,
	selectTableTotalRowsCount,
	selectTableRowsPerPage,
	selectTableCurrentPage,
	selectIsExportUsersLoading,
	selectFilters
} from '../selectors';
import { LIST_OF_ROWS_PER_PAGE_VALUES, TABLE_COLUMNS } from '../constants';
import { TableColumnNames } from '../types';

import { UsersManagementTableCell } from './UsersManagementTableCell';
import { TableSearchPanel } from './TableSearchPanel';
import { FiltersPanel } from 'features/administration/components/FiltersPanel';

export const UsersManagement: FunctionComponent = () => {
	const dispatch = useDispatch();

	const isUsersManagementTableInitialized = useSelector(selectIsInitialized);
	const tableColumns = useSelector(selectTableColumns);
	const usersList = useSelector(selectUsersList);
	const tableSort = useSelector(selectTableSort);
	const tableTotalRowsCount = useSelector(selectTableTotalRowsCount);
	const tableRowsPerPage = useSelector(selectTableRowsPerPage);
	const tableCurrentPage = useSelector(selectTableCurrentPage);
	const filters = useSelector(selectFilters);
	const isExportUsersLoading = useSelector(selectIsExportUsersLoading);

	useEffect(() => {
		dispatch(usersManagementTableSlice.actions.initUsersManagementTable());
	}, [dispatch]);

	const onChangeColumns = useCallback((columns: Column<TableColumnNames>[]) => {
		dispatch(usersManagementTableSlice.actions.setColumns(columns));
	}, [dispatch]);
	const onColumnSort = useCallback((column: Column<TableColumnNames>) => {
		dispatch(usersManagementTableSlice.actions.setSorting(column.name));
	}, [dispatch]);
	const onChangePage = useCallback((_, newPage: number) => {
		dispatch(usersManagementTableSlice.actions.setCurrentPage(newPage));
	}, [dispatch]);
	const onChangeRowsPerPage = useCallback((changeRowsPerPageEvent: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(usersManagementTableSlice.actions.setRowsPerPage(parseInt(changeRowsPerPageEvent.target.value, 10)));
	}, [dispatch]);

	const onExportUsersClick = useCallback(() => {
		dispatch(usersManagementSlice.actions.exportUsers());
	}, [dispatch]);

	const handleFilterValueToggle = useCallback((filterName: string, filterValueName: string, newState: boolean) => {
		dispatch(usersManagementTableSlice.actions.setFilterValue({
			filterName,
			filterValueName,
			newState
		}));
	}, [dispatch]);

	const tableRowComponents = useMemo(() =>
		mapCellsDataToNodes(usersList, tableColumns, (user, column) =>
			<UsersManagementTableCell
				user={user}
				tableColumnName={column.name}
			/>
		)
	, [tableColumns, usersList]);

	return (
		<Box display="flex" height="100%">
			<Box pt={3} overflow="hidden" width="100%">
				<Box px={5} display="flex" justifyContent="space-between" alignItems="center">
					<Typography component='h1' variant='h4'>
						{ 'Users Management' }
					</Typography>
					<LoaderButton
						isLoading={ isExportUsersLoading }
						ButtonProps={{
							size: 'large',
							variant: 'outlined',
							color: 'primary',
							onClick: onExportUsersClick
						}}
					>
						{ 'Export to csv' }
					</LoaderButton>
				</Box>
				{isUsersManagementTableInitialized &&
					<Box px={5} pt={3}>
						<Box display="flex" justifyContent="space-between" alignItems="center" pb={1}>
							<TableSearchPanel />
							<CustomTableColumnsPanel
								id="users-management-columns-panel"
								columns={ tableColumns }
								defaultColumns={ TABLE_COLUMNS }
								onChangeColumns={ onChangeColumns }
							/>
						</Box>
						<CustomTable
							columns={ tableColumns }
							rows={ tableRowComponents }
							sort={ tableSort }
							onChangeColumns={ onChangeColumns }
							onColumnSort={ onColumnSort }
							noRowsElement={ <CustomTableNoRows text={ 'No entries found' } /> }
							paginationProps={{
								component: 'div',
								rowsPerPageOptions: LIST_OF_ROWS_PER_PAGE_VALUES,
								count: tableTotalRowsCount,
								rowsPerPage: tableRowsPerPage,
								page: tableCurrentPage,
								onPageChange: onChangePage,
								// onChangePage,
								onChangeRowsPerPage
							}}
							tableContainerProps={{
								style: {
									maxHeight: 'calc(100vh - 255px)'
								}
							}}
						/>
					</Box>
				}
			</Box>
			<FiltersPanel
				filters={filters}
				onFilterValueToggle={handleFilterValueToggle} />
		</Box>
	);
};
