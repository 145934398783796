import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { WPCategoryConfig } from '../types';

export const getCategoriesConfig = async (perPage: number, page: number): Promise<AxiosResponse<WPCategoryConfig[]>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Headers': 'Content-Type',
		}
	};
	const requestParams = {
		'per_page': perPage,
		page
	};
	return networkManager.fetch(Urls.GetCategories, requestOptions, requestParams);
};
