import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Box,
	Divider,
	ButtonBaseActions
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import { TooltipWrapper } from '../../../components';

import { FacetsBaseProps, Facet, SelectedFacets } from '../types';
import { selectFacetsPanelEntitiesExpandState, selectFacetsPanelTargetSearchId } from '../selectors';
import { facetsPanelSlice } from '../slices';
import { generateFacetPanelEntityId, getFacetsPanelEntityExpandInfo } from '../utils';

import { FacetItem } from './FacetItem';

const MAX_GROUP_NAME_WIDTH = 300; // used for noWrap work

const useStyles = makeStyles(() => ({
	accordionSummaryRoot: {
		backgroundColor: grey['100'],
		textTransform: 'uppercase'
	},
	accordionDetailsRoot: {
		backgroundColor: grey['50']
	}
}));

interface GroupProps extends FacetsBaseProps {
	facets: Facet[]
	groupName: string
	groupDisplayName: string
	groupDescription: string
	selectedFacets: SelectedFacets
	panelPath: string[]
}

export const FacetsGroup: FunctionComponent<GroupProps> = ({
	facets,
	groupName,
	groupDisplayName,
	groupDescription,
	selectFacetValue,
	unselectFacetValue,
	selectedFacets,
	panelPath
}: GroupProps) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const summaryRef = useRef<HTMLButtonElement>(null);
	const actions = useRef<ButtonBaseActions>(null);

	const targetSearchId = useSelector(selectFacetsPanelTargetSearchId);
	const isExpand = getFacetsPanelEntityExpandInfo(useSelector(selectFacetsPanelEntitiesExpandState), panelPath).state;

	// eslint-disable-next-line @typescript-eslint/ban-types
	const toggleExpand = useCallback((e: React.ChangeEvent<{}>, expand: boolean) => {
		if (!expand) {
			dispatch(facetsPanelSlice.actions.toggleEntitiesSubtree({ path: panelPath, state: false }));
		}
		dispatch(facetsPanelSlice.actions.toggleEntityExpand({
			path: panelPath,
			state: expand
		}));
	}, [dispatch, panelPath]);

	useEffect(() => {
		const currentEntityId = generateFacetPanelEntityId(panelPath);
		if (currentEntityId === targetSearchId && summaryRef && actions) {
			if (summaryRef.current && actions.current) {
				summaryRef.current.focus();
				actions.current.focusVisible();
			}
			dispatch(facetsPanelSlice.actions.setTargetSearchId(''));
		}
	}, [dispatch, targetSearchId, panelPath, summaryRef, actions]);

	return (
		<>
			<Accordion
				square
				TransitionProps={{
					unmountOnExit: true,
					...targetSearchId ? { timeout: 0 } : {}
				}}
				expanded={isExpand}
				onChange={toggleExpand}>
				<AccordionSummary
					aria-label={ `Category for filtration is ${groupName}` }
					id={generateFacetPanelEntityId(panelPath)}
					classes={{ root: classes.accordionSummaryRoot }}
					buttonRef={summaryRef}
					action={actions}
				>
					<Box width='100%' display='flex' justifyContent='space-between'>
						<Box display='flex' alignItems='center' mr={1} width='100%'>
							<Box mr={1} color={ grey['A700'] } display='flex' alignItems='center'>
								{!isExpand && <ExpandMore/>}
								{isExpand && <ExpandLess/>}
							</Box>
							<Box maxWidth={ MAX_GROUP_NAME_WIDTH } color={ grey['900'] }>
								<TooltipWrapper title={groupDescription || ''}>
									<Typography component='h3' variant='h6' noWrap={true}>{groupDisplayName}</Typography>
								</TooltipWrapper>
							</Box>
						</Box>
					</Box>
				</AccordionSummary>
				<AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
					<Box width='100%'>
						<Box pl={5.25} pr={2} width='100%' color={grey['800']}>
							{facets.length > 0 ?
								facets.map(facet => {
									const selectedFacetValues = selectedFacets[facet.name] || [];
									return (
										<FacetItem
											facet={facet}
											key={generateFacetPanelEntityId([...panelPath, facet.name])}
											selectFacetValue={selectFacetValue}
											unselectFacetValue={unselectFacetValue}
											selectedFacetValues={selectedFacetValues}
											panelPath={[...panelPath, facet.name]}
										/>
									);
								}) :
								<Box pl={1} py={2} width='100%' color={grey['800']}>
									<Typography component='p' variant='body1'>
										{ 'No matches for this field' }
									</Typography>
								</Box>
							}
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion>
			<Divider />
		</>
	);
};
