import { put, StrictEffect, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { TokenInfo } from 'auth-library-web';

import { cliAuthManager } from '../../../auth/cliAuthManager';

import { handleErrorSaga } from '../../errorHandling/sagas';

import { totpAuthenticationCodeSlice } from '../slices';
import { TotpAuthenticationCodeResponse } from '../types';
import { getAuthenticationCode } from '../api';

export function* generateCodeSaga(): Generator<StrictEffect, void> {
	try {
		// eslint-disable-next-line @typescript-eslint/unbound-method
		const tokenInfo = (yield call([cliAuthManager, cliAuthManager.getTokenInfo])) as TokenInfo | null;

		if (tokenInfo === null) {
			yield put(totpAuthenticationCodeSlice.actions.generateCodeFail());
			return;
		}

		const codeGenerateTimestamp = Number(yield call(Date.now));

		const response = (yield call(getAuthenticationCode, tokenInfo.refreshToken)) as AxiosResponse;
		const data = response.data as TotpAuthenticationCodeResponse;

		yield put(totpAuthenticationCodeSlice.actions.generateCodeSuccess({
			code: data.code,
			codeLifeTimeInSec: data.remainingSeconds,
			codeGenerateTimestamp
		}));
	} catch (e) {
		yield call(handleErrorSaga, e);
		yield put(totpAuthenticationCodeSlice.actions.generateCodeFail());
	}
}
