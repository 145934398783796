import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { Configuration } from '../types';

export const getPageConfiguration = async (): Promise<AxiosResponse<Configuration>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};
	const requestParams = {
		locale: 'en-us'
	};
	return networkManager.fetch(Urls.GetSubmitDataConfig, requestOptions, requestParams);
};
