import React, { FunctionComponent } from 'react';
import { Switch, Route } from 'react-router-dom';

import { AuthCallback } from 'auth/components/AuthCallback';
import { AdminLayout } from 'features/administration/components';
import { PublicLayout } from 'features/publicLayout/components/PublicLayout';
import { DoiLayout } from 'features/doi/components';

import { Routes } from '../Routes';

export const AppRouter: FunctionComponent = () =>
	<Switch>
		<Route exact path={Routes.AuthCallback} component={AuthCallback} />
		<Route path='/admin'>
			<AdminLayout />
		</Route>
		<Route path={Routes.Doi}>
			<DoiLayout />
		</Route>
		<Route>
			<PublicLayout />
		</Route>
	</Switch>
;
