import { Column, ColumnTypes } from 'components/CustomTable';
import { StaticColumnAlign } from 'components/CustomTable/types';

import { TableColumnNames } from './types';

export const ACTIONS_PREFIX = 'usersManagement';
export const MIN_DELAY_BETWEEN_TABLE_UPDATE_REQUESTS_MS = 500;
export const DEFAULT_ROWS_PER_PAGE = 20;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const LIST_OF_ROWS_PER_PAGE_VALUES: number[] = [10, 15, 20, 25];
export const MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP = 18;
export const MIN_LETTERS_FOR_TABLE_BODY_ATTACHMENTS_CELL_TOOLTIP = 40;

export const FILTERS_DRAWER_OPENED_WIDTH_PX = 264;
export const FILTERS_DRAWER_CLOSED_WIDTH_PX = 56;

export const MAX_THRESHOLD_IN_GB = 9223372036;
export const MAX_REJECTION_REASON_LENGTH = 2000;
export const MAX_USER_NOTES_LENGTH = 2000;

export const FIELD_ERROR_INVALID_VALUE = 'Please define the correct value';

export const TABLE_COLUMNS: Column<TableColumnNames>[] = [
	{
		name: 'userName',
		displayName: 'Username',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: true
	}, {
		name: 'registrationDateTime',
		displayName: 'Registration Date',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'registrationEmail',
		displayName: 'Registration Email',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'roles',
		displayName: 'User Roles',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: false
	}, {
		name: 'downloaded',
		displayName: 'Downloaded (GB)',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'threshold',
		displayName: 'Thresholds (GB)',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'legacy',
		displayName: 'Legacy',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'lastSubmitDate',
		displayName: 'Submit Date',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'firstName',
		displayName: 'First Name',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'lastName',
		displayName: 'Last Name',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'credentials',
		displayName: 'Credentials',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'workingEmail',
		displayName: 'Working Email',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'institution',
		displayName: 'Institution/Company',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'status',
		displayName: 'DUA Status',
		isVisible: true,
		type: ColumnTypes.Flex,
		sortable: true
	}, {
		name: 'action',
		displayName: 'Action',
		isVisible: true,
		type: ColumnTypes.Static,
		sortable: false,
		align: StaticColumnAlign.End
	}
];
