import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

import {
	selectDefaultFacetsConfig,
	selectDetailsFacetsConfig,
	selectFacetsListConfig,
	selectFilterQueryString,
	selectSearchDetailsOrder,
	selectSearchDetailsOrderBy,
	selectSearchDetailsPageNumber,
	selectSearchDetailsRowsPerPage,
	selectSelectedFacets
} from '../selectors';
import { SearchState } from '../types';
import { selectSelectedCaseSetEntities } from '../features/caseSet';

export function* getSearchStateSaga(): SagaIterator<SearchState> {
	const defaultFacetsConfig = (yield select(selectDefaultFacetsConfig)) as ReturnType<typeof selectDefaultFacetsConfig>;
	const detailsFacetsConfig = (yield select(selectDetailsFacetsConfig)) as ReturnType<typeof selectDetailsFacetsConfig>;
	const selectedFacets = (yield select(selectSelectedFacets)) as ReturnType<typeof selectSelectedFacets>;
	const selectedCaseSetEntities = (yield select(selectSelectedCaseSetEntities)) as ReturnType<typeof selectSelectedCaseSetEntities>;
	const filterQueryString = (yield select(selectFilterQueryString)) as ReturnType<typeof selectFilterQueryString>;
	const detailsFacetsListPanel = (yield select(selectFacetsListConfig)) as ReturnType<typeof selectFacetsListConfig>;
	const pageNumber = (yield select(selectSearchDetailsPageNumber)) as ReturnType<typeof selectSearchDetailsPageNumber>;
	const rowsPerPage = (yield select(selectSearchDetailsRowsPerPage)) as ReturnType<typeof selectSearchDetailsRowsPerPage>;
	const order = (yield select(selectSearchDetailsOrder)) as ReturnType<typeof selectSearchDetailsOrder>;
	const orderBy = (yield select(selectSearchDetailsOrderBy)) as ReturnType<typeof selectSearchDetailsOrderBy>;

	return {
		defaultFacetsConfig,
		detailsFacetsConfig,
		selectedFacets,
		selectedCaseSetEntities,
		filterQueryString,
		detailsFacetsListPanel,
		pageNumber,
		rowsPerPage,
		order,
		orderBy
	};
}
