import { call, put, all, StrictEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import { handleErrorSaga } from '../../features/errorHandling/sagas';

import { accountSlice } from '../slice';
import { getUserMenuItems, getUserRoles, GetUserRolesResponse, GetUserMenuItemsResponse } from '../api';

export function* getUserDetailsSaga(): Generator<StrictEffect, void> {
	try {
		const { menuItemsResponse, rolesResponse } = (yield all({
			menuItemsResponse: call(getUserMenuItems),
			rolesResponse: call(getUserRoles)
		})) as {
			menuItemsResponse: AxiosResponse<GetUserMenuItemsResponse>
			rolesResponse: AxiosResponse<GetUserRolesResponse>
		};

		yield put(accountSlice.actions.setUserInfo({
			userMenuItems: menuItemsResponse.data.menuItems,
			userRoles: rolesResponse.data.roles,
			dataRequestStatus: rolesResponse.data.dataRequestStatus
		}));

		yield put(accountSlice.actions.setIsAuthorized(true));
		yield put(accountSlice.actions.getUserDetailsSuccess());
	} catch (e) {
		yield put(accountSlice.actions.setIsAuthorized(false));
		yield put(accountSlice.actions.getUserDetailsFail());

		if ((e as AxiosError).response?.statusText !== 'Unauthorized') {
			yield call(handleErrorSaga, e);
		}
	}
}
