/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable promise/prefer-await-to-callbacks */
import { AuthError, LogoutSettings, PublicClientApplication, TokenInfo } from 'auth-library-web';

import { store } from 'app/store';
import { totpAuthenticationCodeSlice } from '../features/totpAuthentication';

import { authConfiguration } from './authConfiguration';
import { B2CErrors, POLICY_RESET_PASSWORD } from './constants';
import { AuthManager, AuthManagerTypes } from './types';

class CliAuthManager implements AuthManager {
	private readonly authClient: PublicClientApplication;
	private tokenInfo: TokenInfo | null;

	constructor(authClient: PublicClientApplication) {
		this.authClient = authClient;
		this.tokenInfo = null;

		void Promise.resolve()
			.then(() => store.dispatch(totpAuthenticationCodeSlice.actions.initStart()))
			.then(() => this.authClient.handleRedirectPromise())
			.then(() => this.onHandleRedirectPromiseResolved())
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			.catch(error => this.onHandleRedirectPromiseRejected(error))
			.finally(() => store.dispatch(totpAuthenticationCodeSlice.actions.initComplete()));
	}

	async getTokenInfo(): Promise<TokenInfo | null> {
		if (this.tokenInfo !== null) {
			const currentTimeInSec = Math.ceil(Date.now() / 1000);

			if (this.tokenInfo.expiresOn - currentTimeInSec <= 0) {
				this.tokenInfo = null;
			}
		} else {
			try {
				this.tokenInfo = await this.authClient.getTokenInfo();
			} catch (e) {
				this.tokenInfo = null;
			}

			this.authClient.clearInfoCache();
		}

		return this.tokenInfo;
	}

	signIn(): void {
		void this.authClient.loginRedirect();
	}

	signOut(settings?: LogoutSettings): void {
		void this.authClient.logoutRedirect(settings);
	}

	private async onHandleRedirectPromiseResolved() {
		const accountInfo = this.authClient.getAccountInfo();

		if (accountInfo !== null) {
			if (accountInfo.tfp === POLICY_RESET_PASSWORD) {
				this.authClient.clearInfoCache();
				this.signIn();
			} else {
				await this.authClient.getTokenInfo();
			}
		}
	}

	private onHandleRedirectPromiseRejected(error: AuthError) {
		if (error.errorCode === `${B2CErrors.FORGOT_PASSWORD}`) {
			void this.authClient.loginRedirect({ policy: POLICY_RESET_PASSWORD });
		}
	}
}

const cliAuthClient = new PublicClientApplication({
	...authConfiguration,
	name: AuthManagerTypes.Cli
});

export const cliAuthManager = new CliAuthManager(cliAuthClient);
