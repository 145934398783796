import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { GetStepsToDownloadResponse } from '../types';

export function* getStepsToDownload(): SagaIterator<AxiosResponse<GetStepsToDownloadResponse>> {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' }
	};

	return (yield call(networkManager.fetch, Urls.GetStepsToDownload, requestOptions)) as AxiosResponse<GetStepsToDownloadResponse>;
}
