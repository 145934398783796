import React, { FunctionComponent } from 'react';

import Button from '@material-ui/core/Button';

import { Routes } from 'app/Routes';
import { TooltipWrapper } from 'components';
import { CustomTableTextCell, CustomTableLinkCell, CustomTableBooleanCell } from 'components/CustomTable';
import { convertBytes } from 'utils';

import {
	TableColumnNames,
	UserShort
} from '../types';
import { MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP } from '../constants';
import { checkIsDuaExists, getFormattedDate } from '../utils';

interface UsersManagementTableCellProps {
	user: UserShort
	tableColumnName: TableColumnNames
}

export const UsersManagementTableCell: FunctionComponent<UsersManagementTableCellProps> = ({
	user,
	tableColumnName
// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const isDuaExists = checkIsDuaExists(user);

	switch (tableColumnName) {
		case 'registrationDateTime':
			return <CustomTableTextCell value={getFormattedDate(user[tableColumnName])} />;
		case 'userName': {
			const value = user[tableColumnName];
			return (
				<TooltipWrapper title={value} disabled={value.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableTextCell value={value} /></span>
				</TooltipWrapper>
			);
		}
		case 'registrationEmail': {
			const value = user[tableColumnName];
			return (
				<TooltipWrapper title={value} disabled={value.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableLinkCell link={`mailto:${value}`} value={value} /></span>
				</TooltipWrapper>
			);
		}
		case 'roles': {
			const value = (user[tableColumnName] ?? []).join('; ');
			return (
				<TooltipWrapper title={value} disabled={value.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableTextCell value={value} /></span>
				</TooltipWrapper>
			);
		}
		case 'threshold':
		case 'downloaded': {
			const value = user[tableColumnName];
			const valueStr = String(value === null ? '' : convertBytes(value));
			return (
				<TooltipWrapper title={valueStr} disabled={valueStr.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableTextCell value={valueStr} /></span>
				</TooltipWrapper>
			);
		}
		case 'lastSubmitDate': {
			// const value = user[tableColumnName];
			return null;
			// return value === null || !isDuaExists ? null : <CustomTableTextCell value={getFormattedDate(value)} />;
		}
		case 'legacy': {
			const value = user[tableColumnName];
			return value === null ? null : <CustomTableBooleanCell value={value} />;
		}
		case 'firstName':
		case 'lastName':
		case 'credentials':
		case 'institution': {
			const value = user[tableColumnName];
			return value === null || !isDuaExists ? null :
				<TooltipWrapper title={value} disabled={value.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableTextCell value={value} /></span>
				</TooltipWrapper>
			;
		}
		case 'workingEmail': {
			const value = user[tableColumnName];
			return value === null || !isDuaExists ? null :
				<TooltipWrapper title={value} disabled={value.length < MIN_LETTERS_FOR_TABLE_BODY_CELL_TOOLTIP}>
					<span><CustomTableLinkCell link={`mailto:${value}`} value={value} /></span>
				</TooltipWrapper>
			;
		}
		case 'status': {
			const value = user[tableColumnName];
			return value === null ? null : <CustomTableTextCell value={value} />;
		}
		case 'action':
			return (
				<Button
					color='primary'
					size='small'
					href={`${Routes.UsersManagement}/users/${user.id}`}
					target="_blank"
				>
					{ 'View' }
				</Button>
			);
		default: return null;
	}
};
