import { SelectedFacets, FacetRangeType, DetailsFacetsConfig } from '../types';
import { CaseSetEntity } from '../features/caseSet';

const getLeftRangeCondition = (facetName: string, from: number): string => `${facetName} ge ${from}`;

const getRightRangeCondition = (facetName: string, to: number): string => `${facetName} lt ${to}`;

const getRangeCondition = (facetName: string, from: number | undefined, to: number | undefined): string => {
	if (from !== undefined && to !== undefined) {
		return `${getLeftRangeCondition(facetName, from)} and ${getRightRangeCondition(facetName, to)}`;
	}

	if (from !== undefined && to === undefined) {
		return getLeftRangeCondition(facetName, from);
	}

	if (to !== undefined && from === undefined) {
		return getRightRangeCondition(facetName, to);
	}

	return '';
};

interface GetFilterStringParams {
	selectedFacets: SelectedFacets
	detailsFacetsConfig: DetailsFacetsConfig
	selectedCaseSetEntities: CaseSetEntity[]
	excludeFacets?: string[]
	filterQueryString?: string
}

export const getFilterString = ({
	selectedFacets,
	detailsFacetsConfig,
	selectedCaseSetEntities,
	excludeFacets = [],
	filterQueryString = '',
}: GetFilterStringParams): string => {
	const facetsFilter = Object.entries(selectedFacets).reduce((filterString, [facetName, facetValues]) => {
		if (excludeFacets.includes(facetName)) {
			return filterString;
		}

		const facetDetails = detailsFacetsConfig[facetName];
		const facetFilter = facetValues.reduce((facetFilterValue, { value = '', from, to }) => {

			let condition: string;
			switch (facetDetails?.rangeType) {
				case FacetRangeType.Values: {
					condition = getRangeCondition(facetName, from, to);
					break;
				}
				case FacetRangeType.Interval: {
					const leftRangeValue = Number(value);
					const rightRangeValue = leftRangeValue + Number(facetDetails.range);
					condition = getRangeCondition(facetName, leftRangeValue, rightRangeValue);
					break;
				}
				default: {
					condition = `${facetName} eq ${
						typeof value === 'string'
							? `'${value.replace(/'/g, "''")}'`
							: value
					}`;
					break;
				}
			}

			return facetFilterValue
				? `${facetFilterValue} or (${condition})`
				: `(${condition})`;
		}, '');

		return filterString
			? `${filterString} and (${facetFilter})`
			: `(${facetFilter})`;
	}, '');

	const caseSetFilter = selectedCaseSetEntities.reduce((filterString, { identifierType, values }) => {
		// eslint-disable-next-line sonarjs/no-nested-template-literals
		const caseSetEntityFilter = `search.in(${identifierType}, '${values.map(value => `${value}`).join('\n')}', '\n')`;

		return filterString
			? `${filterString} and (${caseSetEntityFilter})`
			: `(${caseSetEntityFilter})`;
	}, '');

	return [filterQueryString, facetsFilter, caseSetFilter].filter(filter => filter.length > 0).join(' and ');
};
