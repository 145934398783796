import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';

import {
	CaseSetConfig,
	CaseSetModalInfo,
	CaseSetEntity,
} from './types';

export const selectCaseSetModalInfo = (state: RootState): CaseSetModalInfo => state.search.caseSet.modalInfo;
export const selectCaseSetConfig = (state: RootState): CaseSetConfig => state.search.caseSet.config;
export const selectCaseSetEntities = (state: RootState): CaseSetEntity[] => state.search.caseSet.caseSetEntities;
export const selectSelectedCaseSetIds = (state: RootState): string[] => state.search.caseSet.selectedCaseSetIds;
export const selectSelectedCaseSetEntities = createSelector(
	selectSelectedCaseSetIds,
	selectCaseSetEntities,
	(selectedCaseSetIds, caseSetEntities): CaseSetEntity[] => selectedCaseSetIds
		.map(selectedId => caseSetEntities.find(entity => entity.id === selectedId))
		.filter((entity): entity is CaseSetEntity => entity !== undefined)
);
export const selectEditCaseSetId = (state: RootState): string | null => state.search.caseSet.editCaseSetId;
export const selectCaseSetErrorId = (state: RootState): string | null => state.search.caseSet.caseSetErrorId;
export const selectShowNotFounded = (state: RootState): boolean => state.search.caseSet.showNotFounded;
export const selectIsCaseSetEntitiesGroupExpanded = (state: RootState): boolean => state.search.caseSet.isCaseSetEntitiesGroupExpanded;
