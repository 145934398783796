/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FunctionComponent, useCallback, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { Button, ButtonGroup, Step, StepLabel, Stepper, TextField } from '@material-ui/core';
import { DuaStatuses } from '../../usersManagement/types';
import { DialogModal, SkeletonWrapper } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { addiDataRequestDetailsSlice } from '../slices';
import { useFormik } from 'formik';
import { MAX_REJECTION_REASON_LENGTH } from '../../usersManagement/constants';
import * as yup from 'yup';
import {
	selectAddiDataRequestDetails,
	selectIsAddiDataRequestLoading,
	selectIsAddiManagementLoading
} from '../selectors';
import { getFormattedDate } from 'utils';
import { OmicCard, OmicCardsInfo } from 'features/requestAccess/components';

const rejectFormValidationSchema = yup.object().shape({
	rejectionReason: yup.string()
		.trim()
		.max(MAX_REJECTION_REASON_LENGTH, `Max length is ${MAX_REJECTION_REASON_LENGTH}`)
		.required('Please fill the data'),
});

export const AddiDataRequestForm: FunctionComponent = () => {
	const dispatch = useDispatch();

	const isAddiDataRequestLoading = useSelector(selectIsAddiDataRequestLoading);
	const addiDataRequest = useSelector(selectAddiDataRequestDetails);

	const isAddiManagementLoading = useSelector(selectIsAddiManagementLoading);

	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

	const defaultFields = {
		level1: { label: 'Level 1', helperText: '' },
		level2: { label: 'Level 2', helperText: '' },
		level3: { label: 'Level 3', helperText: '' },
		level4: { label: 'Level 4', helperText: '' }
	};

	const omicsConfiguration = {
		genomics: {
			name: 'Genomics',
			fields: defaultFields
		},
		epigenomics: {
			name: 'Epigenomics',
			fields: defaultFields
		},
		proteomics: {
			name: 'Proteomics',
			fields: defaultFields
		},
		transcriptomics: {
			name: 'Transcriptomics',
			fields: defaultFields
		},
	};

	const tableData: { name: string; data: string }[] = [
		{ name: 'Status', data: addiDataRequest?.status ?? '' },
		{ name: 'Submit Date', data: getFormattedDate(addiDataRequest?.lastSubmitDate ?? null) },
		{ name: 'First Name', data: addiDataRequest?.firstName ?? '' },
		{ name: 'Last Name', data: addiDataRequest?.lastName ?? '' },
		{ name: 'Credentials', data: addiDataRequest?.credentials ?? '' },
		{ name: 'Working Email', data: addiDataRequest?.workingEmail ?? '' },
		{ name: 'Institution', data: addiDataRequest?.institution ?? '' },
		{ name: 'Institution Country', data: addiDataRequest?.country ?? '' },
		{ name: 'Auth. Business Official Name', data: addiDataRequest?.businessOfficialName ?? '' },
		{ name: 'Auth. Business Official Title', data: addiDataRequest?.businessOfficialTitle ?? '' },
		{ name: 'Auth. Business Official Email', data: addiDataRequest?.businessOfficialEmail ?? '' },
		{ name: 'Data Use Proposal', data: addiDataRequest?.dataUseProposal ?? '' },
		{ name: 'Project Timeframe', data: addiDataRequest?.projectTimeframe ?? '' }
	];

	if (addiDataRequest && addiDataRequest?.status !== DuaStatuses.Submitted) {
		tableData.push(
			{ name: 'User Sign Status', data: addiDataRequest.userSignStatus ?? '' },
			{ name: 'User Sign Date', data: getFormattedDate(addiDataRequest.userSignDate ?? null) },
			{ name: 'Auth. Business Official Sign Status', data: addiDataRequest.businessOfficialSignStatus ?? '' },
			{ name: 'Auth. Business Official Sign Date', data: getFormattedDate(addiDataRequest.businessOfficialSignDate ?? null) },
			{ name: 'Answer ALS Official Sign Status', data: addiDataRequest.answerOfficialSignStatus ?? '' },
			{ name: 'Answer ALS Official Sign Date', data: getFormattedDate(addiDataRequest.answerOfficialSignDate ?? null) }
		);
	}

	const duaSteps = ['Submitted', 'Verified', 'Signed', 'Approved/Rejected'];
	const getActiveStep = () => {
		switch (addiDataRequest?.status) {
			case DuaStatuses.Submitted:
				return 0;
			case DuaStatuses.Verified:
				return 1;
			case DuaStatuses.Signed:
				return 2;
			case DuaStatuses.Approved:
			case DuaStatuses.Rejected:
				// eslint-disable-next-line @typescript-eslint/no-magic-numbers
				return 3;
		}
	};

	const rejectForm = useFormik<{ rejectionReason: string }>({
		initialValues: {
			rejectionReason: ''
		},
		validationSchema: rejectFormValidationSchema,
		onSubmit: values => {
			dispatch(addiDataRequestDetailsSlice.actions.rejectAddiDataRequest({
				rejectionReason: values.rejectionReason
			}));
		}
	});

	const onApproveClick = useCallback(() => {
		setIsApproveModalOpen(true);
	}, []);
	const onApproveConfirmationSubmit = useCallback(() => {
		dispatch(addiDataRequestDetailsSlice.actions.approveAddiDataRequest());
		setIsApproveModalOpen(false);
	}, [dispatch]);
	const onApproveConfirmationCancel = useCallback(() => {
		setIsApproveModalOpen(false);
	}, []);

	const onRejectClick = useCallback(() => {
		setIsRejectModalOpen(true);
	}, []);
	const onRejectConfirmationSubmit = useCallback(() => {
		rejectForm.handleSubmit();
		if (rejectForm.isValid) {
			setIsRejectModalOpen(false);
		}
	}, [rejectForm]);
	const onRejectConfirmationCancel = useCallback(() => {
		setIsRejectModalOpen(false);
	}, []);

	return (
		<>
			<Box width={630} mb={4} mr={4}>
				{
					isAddiDataRequestLoading ?
						<SkeletonWrapper
							width={'100%'}
							height={350}
						/> :
						addiDataRequest &&
						<>
							<Box>
								<Paper>
									<Box p={2}>
										<Box mb={3.5} display="flex" justifyContent="space-between" alignItems="center">
											<Typography
												variant="h5"
												component="h2"
											>
												{'ADDI Data Request'}
											</Typography>
											<Box>
												<ButtonGroup disableElevation variant="outlined" color="primary" size="large">
													<Button
														disabled={
															isAddiManagementLoading ||
															addiDataRequest?.status === DuaStatuses.Rejected}
														size="medium"
														onClick={onRejectClick}
													>
														{'Reject'}
													</Button>
													<Button
														disabled={
															isAddiManagementLoading ||
															addiDataRequest?.status === DuaStatuses.Approved}
														size="medium"
														onClick={onApproveClick}
													>
														{'Approve'}
													</Button>
												</ButtonGroup>
											</Box>
										</Box>
										<Box mb={2} >
											<Stepper activeStep={getActiveStep()} alternativeLabel nonLinear >
												{duaSteps.map(label =>
													<Step key={label}>
														<StepLabel>{label}</StepLabel>
													</Step>
												)}
											</Stepper>
										</Box>
										<Box mb={2}>
											<Table size="small">
												<TableBody>
													{tableData.map(row =>
														<TableRow key={row.name}>
															<TableCell component="th">
																{row.name}
															</TableCell>
															<TableCell style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: row.data }}>
															</TableCell>
														</TableRow>
													)}
												</TableBody>
											</Table>
										</Box>
									</Box>
								</Paper>
							</Box>
							<Box pb={3} />
							{
								addiDataRequest.collaborators?.map((collaborator, index) => {
									const collaboratorTableData: { name: string; data: string }[] = [
										{ name: 'Name', data: collaborator?.name ?? '' },
										{ name: 'Title', data: collaborator?.title ?? '' },
										{ name: 'Role On Project', data: collaborator?.roleOnProject ?? '' },
										{ name: 'Email', data: collaborator?.email ?? '' }
									];

									if (collaborator.id) {
										collaboratorTableData.push({ name: 'ID', data: collaborator?.id ?? '' });
									}
									if (collaborator.signStatus) {
										collaboratorTableData.push({ name: 'Sign Status', data: collaborator?.signStatus ?? '' });
									}
									if (collaborator.signDate) {
										collaboratorTableData.push({ name: 'Sign Date', data: getFormattedDate(collaborator?.signDate ?? null) });
									}

									return <Box key={index} pb={3}>
										<Paper>
											<Box p={2}>
												<Typography
													variant="h5"
													component="h2"
												>
													{`Collaborator ${index + 1}`}
												</Typography>
												<Table size="small">
													<TableBody>
														{collaboratorTableData.map(row =>
															<TableRow key={row.name}>
																<TableCell component="th">
																	{row.name}
																</TableCell>
																<TableCell>
																	{row.data}
																</TableCell>
															</TableRow>
														)}
													</TableBody>
												</Table>
											</Box>
										</Paper>
									</Box>;
								})
							}
							<Box display="flex" flexWrap="wrap" justifyContent="space-between">
								<OmicCardsInfo
									omicsConfiguration={omicsConfiguration}
								>
									{({ formOmicCards }) => formOmicCards.map((cardInfo, cardIndex) =>
										<OmicCard
											key={cardIndex}
											cardName={cardInfo.name}
											levels={cardInfo.levels.map(level => ({
												...level,
												checkboxValue: Boolean(addiDataRequest[level.formFieldName])
											}))}
											wrapperBoxProps={{
												minWidth: 300,
												mb: 3,
												mr: 0
											}}
											isReadonly={true}
										/>
									)}
								</OmicCardsInfo>
							</Box>
						</>
				}
			</Box>
			<DialogModal
				isOpen={isApproveModalOpen}
				headerText={'Approve ADDI Data Request'}
				cancelButtonText={'Cancel'}
				submitButtonText={'Confirm'}
				onCancel={onApproveConfirmationCancel}
				onSubmit={onApproveConfirmationSubmit}
			>
				<Typography component="span" variant="subtitle1">
					{`Please confirm ADDI data request for user: ${addiDataRequest?.firstName} ${addiDataRequest?.lastName}.`}
				</Typography>
			</DialogModal>
			<DialogModal
				isOpen={isRejectModalOpen}
				headerText={'Reject User'}
				cancelButtonText={'Cancel'}
				submitButtonText={'Submit'}
				onCancel={onRejectConfirmationCancel}
				onSubmit={onRejectConfirmationSubmit}
			>
				<Box minWidth={500}>
					<Box mb={2}>
						<Typography component="span" variant="subtitle1">
							{'Please define the rejection reason.'}
						</Typography>
					</Box>
					<TextField
						name="rejectionReason"
						id={'request-access-form-field-rejectionReason'}
						label={'Rejection Reason'}
						variant="outlined"
						multiline
						fullWidth
						value={rejectForm.values.rejectionReason}
						onChange={rejectForm.handleChange}
						helperText={rejectForm.touched.rejectionReason && rejectForm.errors.rejectionReason || 'Entered information will be sent to the ADDI.'}
						error={rejectForm.touched.rejectionReason && Boolean(rejectForm.errors.rejectionReason)}
					/>
				</Box>
			</DialogModal>
		</>
	);
};
