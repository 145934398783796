export enum Routes {
	Home = '/home',
	Search = '/search',
	Proceed = '/proceed',
	TotpAuthentication = '/activate',
	UsersManagement = '/admin/users-management',
	AddiDataRequests = '/admin/addi-data-requests',
	AuthCallback = '/auth-callback',
	RequestAccess = '/request-access',
	VisualizationOmicL4 = '/visualization/omic-l4',
	Doi = '/doi',
	IndexManagement = '/admin/index-management',
	Statistics = '/admin/statistics',
	DataSubmit = '/submit-data',
	ReleaseDataManagement = '/admin/data-management',
}
