import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WPCategoryConfig, WPPageConfig } from '../types';
import { ACTIONS_PREFIX } from '../constants';

interface WPFrameState {
	pagesConfig: WPPageConfig[]
	categoriesConfig: WPCategoryConfig[]
	isLoading: boolean
}

const initialState: WPFrameState = {
	pagesConfig: [],
	categoriesConfig: [],
	isLoading: true,
};

export const wpFrameSlice = createSlice({
	name: `${ACTIONS_PREFIX}`,
	initialState,
	reducers: {
		getConfigRequest: (state: WPFrameState) => {
			state.isLoading = true;
		},
		getConfigSuccess: (state: WPFrameState, { payload }: PayloadAction<{
			pagesConfig: WPPageConfig[]
			categoriesConfig: WPCategoryConfig[]
		}>) => {
			state.isLoading = false;
			state.pagesConfig = payload.pagesConfig;
			state.categoriesConfig = payload.categoriesConfig;
		},
		getConfigFail: (state: WPFrameState) => {
			state.isLoading = false;
		},
	}
});
