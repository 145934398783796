import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { AxiosResponse } from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { doiSlice } from '../slices';
import { getUserDoi } from '../api';
import { GetUserDoiRequest, GetUserDoiResponse } from '../types';

export function* getUserDoiSaga({ payload }: PayloadAction<GetUserDoiRequest>): SagaIterator {
	try {
		yield put(doiSlice.actions.getUserDoiStart());

		const response = (yield call(getUserDoi, payload)) as AxiosResponse<GetUserDoiResponse>;

		yield put(doiSlice.actions.getUserDoiEnd({ endState: SagaEndState.Success, data: response.data }));
	} catch (e) {
		yield put(doiSlice.actions.getUserDoiEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}
}
