import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../../../network';

import { GetTranscriptomicsDataRequest, GetTranscriptomicsDataResponse } from '../types';

export const getTranscriptomicsData = async (params: GetTranscriptomicsDataRequest): Promise<AxiosResponse<GetTranscriptomicsDataResponse>> => {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'accept': 'text/plain',
		},
		data: params
	};

	return networkManager.fetch<GetTranscriptomicsDataResponse>(Urls.GetVisualizationTranscriptomicsData, requestOptions);
};
