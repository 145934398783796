import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AddiDataRequest, UpdateAddiDataRequest } from '../types';
import { SagaEndPayloadAction, SagaEndState } from 'types';

interface AddiDataRequestDetailsState {
	addiDataRequest: AddiDataRequest | null

	isAddiDataRequestLoading: boolean
	isAddiDataRequestApproving: boolean
	isAddiDataRequestRejecting: boolean
	isAddiSignDataRequestSending: boolean
	isAddiDataRequestUpdating: boolean
	isAddiDocuSignDownloading: boolean
}

const initialState: AddiDataRequestDetailsState = {
	addiDataRequest: null,

	isAddiDataRequestLoading: false,
	isAddiDataRequestApproving: false,
	isAddiDataRequestRejecting: false,
	isAddiSignDataRequestSending: false,
	isAddiDataRequestUpdating: false,
	isAddiDocuSignDownloading: false
};

export const addiDataRequestDetailsSlice = createSlice({
	name: 'addiDataRequests.details',
	initialState,
	reducers: {
		getAddiDataRequest: (_state, _payload: PayloadAction<{ id: string }>) => undefined,
		getAddiDataRequestStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestLoading = true;
		},
		getAddiDataRequestEnd: (state: AddiDataRequestDetailsState, { payload }: SagaEndPayloadAction<{ addiDataRequest: AddiDataRequest }>) => {
			if (payload.endState === SagaEndState.Success) {
				state.addiDataRequest = payload.data.addiDataRequest;
			}
			state.isAddiDataRequestLoading = false;
		},
		approveAddiDataRequest: _state => undefined,
		approveAddiDataRequestStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestApproving = true;
		},
		approveAddiDataRequestEnd: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestApproving = false;
		},
		rejectAddiDataRequest: (_state, _: PayloadAction<{ rejectionReason: string }>) => undefined,
		rejectAddiDataRequestStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestRejecting = true;
		},
		rejectAddiDataRequestEnd: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestRejecting = false;
		},
		sendAddiSignDataRequest: _state => undefined,
		sendAddiSignDataRequestStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiSignDataRequestSending = true;
		},
		sendAddiSignDataRequestEnd: (state: AddiDataRequestDetailsState) => {
			state.isAddiSignDataRequestSending = false;
		},
		updateAddiDataRequest: (_state, _payload: PayloadAction<UpdateAddiDataRequest>) => undefined,
		updateAddiDataRequestStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestUpdating = true;
		},
		updateAddiDataRequestEnd: (state: AddiDataRequestDetailsState) => {
			state.isAddiDataRequestUpdating = false;
		},
		downloadAddiDocuSign: _state => undefined,
		downloadingAddiDocuSignStart: (state: AddiDataRequestDetailsState) => {
			state.isAddiDocuSignDownloading = true;
		},
		downloadingAddiDocuSignEnd: (state: AddiDataRequestDetailsState) => {
			state.isAddiDocuSignDownloading = false;
		},
	}
});
