import React, { FunctionComponent, Fragment } from 'react';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import { grey } from '@material-ui/core/colors';

import { formatBytes } from 'utils';

import { DoiCreateFormFields, Omic } from '../types';
import { selectDoiConfig } from '../selectors';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
	listItemDense: {
		padding: 0
	},
	listItemIconRoot: {
		minWidth: 0,
		paddingRight: theme.spacing(1)
	}
}));

interface DoiOmicLevelsProps {
	omic: Omic
	form?: FormikProps<DoiCreateFormFields>
	disabled?: boolean
	onOmicLevelClick?: (type: string, level: number) => void
}

export const DoiOmicLevels: FunctionComponent<DoiOmicLevelsProps> = ({
	omic,
	form,
	disabled = false,
	onOmicLevelClick
// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const classes = useStyles();

	const doiConfig = useSelector(selectDoiConfig);

	if (doiConfig === null) {
		return null;
	}

	return (
		<Box p={0}>
			<Box style={{ textTransform: 'capitalize' }} color={grey['900']} mb={1}>
				<Typography
					variant="h5"
					component="h3"
				>
					{ omic.type }
				</Typography>
			</Box>
			<Box>
				{omic.levels.map((level, i) => {
					const formattedSize = formatBytes(level.size);
					return (
						<Fragment key={`${omic.type}${i}`} >
							{ disabled ? <List disablePadding>
								<ListItem
									role={undefined}
									dense
									classes={{ dense: classes.listItemDense }}
									/* eslint-disable-next-line react/jsx-no-bind */
									onClick={function () {
										return onOmicLevelClick ? onOmicLevelClick(omic.type, i) : level.selected;
									}}
								>
									<ListItemIcon classes={{ root: classes.listItemIconRoot }}>
										<Checkbox
											inputProps={{
												'aria-label': 'omics-level'
											}}
											name={ `omics[${omic.type}][${i}]` }
											color="primary"
											checked={ form ? form.values.omics[omic.type][i] : level.selected }
											onChange={ form?.handleChange }
											disabled={ disabled }
										/>
									</ListItemIcon>
									<Box display="flex" justifyContent="space-between" alignItems="center" width="100%" aria-hidden>
										<Box display="flex" alignItems="center" color={grey['800']}>
											<Box width={90} pr={2}>
												<Typography variant="body1" component="span">
													{ `Level ${level.name}` }
												</Typography>
											</Box>
											<Box>
												<Typography variant="body2" component="span">
													{ doiConfig.omics[omic.type][i + 1] }
												</Typography>
											</Box>
										</Box>
										<Box color={grey['600']} pr={1}>
											<Typography variant="body2" component="span">
												{ formattedSize }
											</Typography>
										</Box>
									</Box>
								</ListItem>
							</List>
								: <Box>
									<ListItem
										role={undefined}
										dense
										button
										classes={{ dense: classes.listItemDense }}
										/* eslint-disable-next-line react/jsx-no-bind */
										onClick={function () {
											return onOmicLevelClick ? onOmicLevelClick(omic.type, i) : level.selected;
										}}
									>
										<ListItemIcon classes={{ root: classes.listItemIconRoot }}>
											<Checkbox
												inputProps={{
													'aria-label': 'omics-level'
												}}
												name={ `omics[${omic.type}][${i}]` }
												color="primary"
												checked={ form ? form.values.omics[omic.type][i] : level.selected }
												onChange={ form?.handleChange }
												disabled={ disabled }
											/>
										</ListItemIcon>
										<Box display="flex" justifyContent="space-between" alignItems="center" width="100%" aria-hidden>
											<Box display="flex" alignItems="center" color={grey['800']}>
												<Box width={90} pr={2}>
													<Typography variant="body1" component="span">
														{ `Level ${level.name}` }
													</Typography>
												</Box>
												<Box>
													<Typography variant="body2" component="span">
														{ doiConfig.omics[omic.type][level.name][0] }
													</Typography>
												</Box>
											</Box>
											<Box color={grey['600']} pr={1}>
												<Typography variant="body2" component="span">
													{ formattedSize }
												</Typography>
											</Box>
										</Box>
									</ListItem>
								</Box>
							}
							{i !== omic.levels.length - 1 && <Divider />}
						</Fragment>
					);
				})}
			</Box>
		</Box>
	);
};
