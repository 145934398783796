import { Filter } from '../types';

export const getFilterApiParam = (filters: Filter[]): Record<string, string[]> =>
	filters.reduce((result: Record<string, string[]>, filter) => {
		const selectedFilterValueNames = filter.values
			.filter(filterValue => filterValue.isSelected)
			.map(filterValue => filterValue.name);

		result[filter.name] = selectedFilterValueNames;

		return result;
	}, {})
;
