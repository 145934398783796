import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Button, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { grey } from '@material-ui/core/colors';

import { LoaderButton } from 'components';
import { Footer } from 'features/footer';

import { doiSlice } from '../slices';
import {
	selectDoiConfig,
	selectIsDoiConfigLoading,
	selectIsDownloadReadFileListLoading,
	selectIsGetUserDoiLoading,
	selectUserDoi,
	selectIsCreateUserDoiLoading,
	selectIsSaveUserDoiLoading
} from '../selectors';

import { DoiOmicLevels } from './DoiOmicLevels';
import { useFormik } from 'formik';
import { DoiCreateFormFields, DownloadCreateFileListRequest } from '../types';

const FIELD_ERROR_TEXT = 'Please fill the data';
const TEXTAREA_Y_PADDING = 2.3125;
const TEXTAREA_X_PADDING = 1.75;

const useStyles = makeStyles((theme: Theme) => ({
	containerWrapper: {
		height: '100%',
		minHeight: 'inherit',
		backgroundColor: theme.palette.common.white
	},
	container: {
		// eslint-disable-next-line @typescript-eslint/no-magic-numbers
		padding: theme.spacing(3)
	},
	textareaRoot: {
		'& > div': {
			backgroundColor: 'transparent',
			zIndex: 1,
			padding: theme.spacing(TEXTAREA_Y_PADDING, 0, 0, 0),
			'& > textarea': {
				padding: theme.spacing(0, TEXTAREA_X_PADDING, TEXTAREA_Y_PADDING, TEXTAREA_X_PADDING),
				resize: 'vertical',
				minHeight: '100px',
				'&::-webkit-scrollbar': {
					width: '10px'
				}
			}
		}
	},
	inputDisabled: {
		color: theme.palette.text.primary
	}
}));

const validationSchema = yup.object().shape({
	title: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT),
	principalInvestigator: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT),
	description: yup.string()
		.trim()
		.required(FIELD_ERROR_TEXT)
});

interface DoiReadMatchParams {
	doiId: string
}

type DoiReadProps = RouteComponentProps<DoiReadMatchParams>;

export const DoiSave: FunctionComponent<DoiReadProps> = ({
	match
// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();

	const { doiId } = match.params;
	const userDoi = useSelector(selectUserDoi);
	const isGetUserDoiLoading = useSelector(selectIsGetUserDoiLoading);
	const isDownloadReadFileListLoading = useSelector(selectIsDownloadReadFileListLoading);
	const isDoiConfigLoading = useSelector(selectIsDoiConfigLoading);
	const doiConfig = useSelector(selectDoiConfig);
	const isCreateUserDoiLoading = useSelector(selectIsCreateUserDoiLoading);
	const isSaveUserDoiLoading = useSelector(selectIsSaveUserDoiLoading);

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	useEffect(() => {
		dispatch(doiSlice.actions.getUserDoi({ id: doiId }));
		dispatch(doiSlice.actions.getDoiConfig());
	}, [dispatch, doiId]);

	const form = useFormik<DoiCreateFormFields>({
		enableReinitialize: true,
		initialValues: {
			id: doiId,
			title: userDoi && userDoi.id === doiId ? userDoi.title : '',
			principalInvestigator: userDoi && userDoi.id === doiId ? userDoi.principalInvestigator : '',
			description: userDoi && userDoi.id === doiId ? userDoi.description : '',
			published: userDoi && userDoi.id === doiId ? userDoi.published : false,
			omics: {}
		},
		validationSchema,
		onSubmit: values => {
			if (form.values.published) {
				dispatch(doiSlice.actions.createUserDoi({
					id: doiId,
					title: values.title.trim(),
					principalInvestigator: values.principalInvestigator,
					description: values.description,
					published: true,
					omics: userDoi && userDoi.omics && userDoi.omics.map(omic => ({
						...omic,
						levels: omic.levels.map((omicLevel, index) => ({
							...omicLevel,
							selected: values.omics[omic.type][index]
						}))
					}))
				}));
			} else {
				dispatch(doiSlice.actions.saveUserDoi({
					id: doiId,
					title: form.values.title.trim(),
					principalInvestigator: form.values.principalInvestigator,
					description: form.values.description,
					published: false,
					omics: userDoi && userDoi.omics && userDoi.omics.map(omic => ({
						...omic,
						levels: omic.levels.map((omicLevel, index) => ({
							...omicLevel,
							selected: form.values.omics[omic.type][index]
						}))
					}))
				}));
			}
		}
	});

	const onDownloadClick = useCallback(() => {
		dispatch(doiSlice.actions.downloadCreateFileList(
			Object.entries(form.values.omics).reduce((selectedOmics: DownloadCreateFileListRequest, [omicKey, omicLevelStates]) => {
				const levels = omicLevelStates
					// eslint-disable-next-line no-confusing-arrow
					.map((state, index) => state ? String(index + 1) : null)
					.filter((state): state is string => state !== null);

				if (levels.length > 0) {
					selectedOmics[omicKey] = levels;
				}

				return selectedOmics;
			}, {})
		));
	}, [dispatch, form]);

	useEffect(() => {
		void form.setFieldValue('omics', userDoi && userDoi.omics ? userDoi.omics.reduce((omics: Record<string, boolean[]>, omic) => {
			omics[omic.type] = omic.levels.map(level => level.selected);
			return omics;
		}, {}) : {});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userDoi]);

	const onPublishClick = useCallback(() => {
		setIsConfirmOpen(false);
		form.values.published = true;
		form.handleSubmit();
	}, [form]);

	const onSaveClick = useCallback(() => {
		form.values.published = false;
		form.handleSubmit();
	}, [form]);

	const isSomeLevelSelected = Object.values(form.values.omics).flat().some(levelState => levelState);

	const onListItemClick = (type: string, level: number) => {
		void form.setFieldValue(`omics[${type}][${level}]`,!form.values.omics[type][level]);
	};

	const onRemoveCancelClick = useCallback(() => {
		setIsConfirmOpen(false);
	}, []);

	return (
		<>
			<Box className={classes.containerWrapper}>
				<Box
					visibility={isGetUserDoiLoading || isDoiConfigLoading ? 'visible' : 'hidden'}
					position="sticky"
					top={0}
					width="100%"
					zIndex={theme.zIndex.appBar}
				>
					<LinearProgress />
				</Box>
				<Container className={classes.container}>
					{userDoi !== null && userDoi.omics !== null && doiConfig !== null &&
					<Box maxWidth={600}>
						<Box pt={2} />
						<Typography component="h1" variant="h3">
							{ 'Answer ALS DOI' }
						</Typography>
						<Box pb={2} />
						<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
							{ doiConfig.text.readPage.metadataSection.title }
						</Typography>
						<Box pb={2} />
						<Typography component="p" variant="body1" >
							{ doiConfig.text.readPage.metadataSection.description }
						</Typography>
						<Box width={520} py={3}>
							<TextField
								name="title"
								id={ 'doi-form-field-title' }
								label={ 'Title' }
								variant="outlined"
								fullWidth
								value={ form.values.title }
								onChange={ form.handleChange }
								error={ form.touched.title && Boolean(form.errors.title) }
								helperText={ form.touched.title && form.errors.title }
							/>
							<Box pb={3} />
							<TextField
								name="principalInvestigator"
								id={ 'doi-form-field-principalInvestigator' }
								label={ 'Principal Investigator' }
								variant="outlined"
								fullWidth
								value={ form.values.principalInvestigator }
								onChange={ form.handleChange }
								error={ form.touched.title && Boolean(form.errors.title) }
								helperText={ form.touched.title && form.errors.title }
							/>
							<Box pb={3} />
							<TextField
								classes={{ root: classes.textareaRoot }}
								name="description"
								id={ 'doi-form-field-description' }
								label={ 'Description' }
								variant="outlined"
								multiline
								rows={4}
								fullWidth
								value={ form.values.description }
								onChange={ form.handleChange }
								error={ form.touched.title && Boolean(form.errors.title) }
								helperText={ form.touched.title && form.errors.title }
							/>
						</Box>
						<Box pb={5} />
						<Typography component="h2" variant="h4" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
							{ doiConfig.text.readPage.dataSection.title }
						</Typography>
						<Box pb={2} />
						<Typography component="p" variant="body1" >
							{ doiConfig.text.readPage.dataSection.description }
						</Typography>
						<Box pb={4} />
						{userDoi && userDoi.omics &&
						<>
							<Box maxWidth={520}>
								{Object.keys(form.values.omics).length > 0 && userDoi.omics.map(omic =>
									<Box key={omic.type} pb={2}>
										<DoiOmicLevels
											omic={omic}
											form={form}
											onOmicLevelClick={onListItemClick}
										/>
									</Box>
								)}
							</Box>
							<Box pt={3} pb={5}>
								<LoaderButton
									isLoading={isDownloadReadFileListLoading}
									wrapperBoxProps={{
										style: { display: 'inline' }
									}}
									ButtonProps={{
										disabled: !isSomeLevelSelected,
										size: 'large',
										variant: 'outlined',
										color: 'primary',
										onClick: onDownloadClick
									}}
								>
									{ 'Download File List' }
								</LoaderButton>
								<Box component="span" pr={2} />
								<LoaderButton
									isLoading={isSaveUserDoiLoading}
									wrapperBoxProps={{
										style: { display: 'inline' }
									}}
									ButtonProps={{
										disabled: !isSomeLevelSelected,
										size: 'large',
										variant: 'outlined',
										color: 'primary',
										onClick: onSaveClick
									}}
								>
									{ 'Save DOI' }
								</LoaderButton>
								<Box component="span" pr={2} />
								<LoaderButton
									isLoading={isCreateUserDoiLoading}
									wrapperBoxProps={{
										style: { display: 'inline' }
									}}
									ButtonProps={{
										disabled: !isSomeLevelSelected,
										size: 'large',
										variant: 'contained',
										color: 'primary',
										onClick: () => setIsConfirmOpen(true)
									}}
								>
									{ 'Publish DOI' }
								</LoaderButton>
							</Box>
						</>
						}
					</Box>
					}
				</Container>
				<Box pb={4} />
				{doiConfig !== null && !isGetUserDoiLoading &&
				<Footer
					links={doiConfig.footer.links}
				/>
				}
			</Box>
			<Dialog
				aria-labelledby="published-set-modal-header"
				open={isConfirmOpen}
				onEscapeKeyDown={onRemoveCancelClick}
			>
				<DialogTitle disableTypography>
					<Box position="relative">
						<Typography id="published-set-modal-header" component="h2" variant="h5">
							{ 'Confirm publishing DOI' }
						</Typography>
						<Box position="absolute" top={-12} right={-12} color={grey['800']}>
							<IconButton onClick={onRemoveCancelClick} aria-label="Close" component="span">
								<Close />
							</IconButton>
						</Box>
					</Box>
				</DialogTitle>
				<DialogContent dividers>
					<Typography component="span" variant="subtitle1">
						{ 'Please confirm publishing DOI. This action could not be revoked.' }
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={onRemoveCancelClick} color="primary" variant="outlined" size="large">
						{ 'CANCEL' }
					</Button>
					<Button onClick={onPublishClick} color="primary" variant="contained" size="large">
						{ 'CONFIRM' }
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
