import React, { FunctionComponent } from 'react';
import { FormikProps } from 'formik';
import { SortableElement } from 'react-sortable-hoc';

import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';

import { TooltipWrapper } from 'components';

import { Column, ColumnsPanelFormFields } from '../types';

const MIN_LETTERS_FOR_FACET_VALUE_TOOLTIP = 20;
const COLUMN_TEXT_WIDTH = 240;

const useStyles = makeStyles(() => ({
	dragIcon: {
		cursor: 'grab'
	}
}));

interface CustomTableColumnsPanelItemProps {
	column: Column
	form: FormikProps<ColumnsPanelFormFields>
}

const CustomTableColumnsPanelItem: FunctionComponent<CustomTableColumnsPanelItemProps> = ({
	column,
	form
}) => {
	const classes = useStyles();
	const index = form.values.tempColumns.findIndex(tempColumn => tempColumn.name === column.name);
	return (
		<Box tabIndex={0} display="flex" justifyContent="space-between">
			<Box
				display="flex"
				alignItems="center"
				color={ grey['400'] }
				ml={ -0.625 }
				pr={1}
				className={ classes.dragIcon }
			>
				<DragIndicatorIcon fontSize="small" />
			</Box>
			<FormControlLabel
				control={
					<Checkbox
						color="primary"
						name={ `tempColumns[${index}].isVisible` }
						checked={ form.values.tempColumns[index].isVisible }
						onChange={ form.handleChange }
					/>
				}
				label={
					<Box width={ COLUMN_TEXT_WIDTH } color={ grey['800'] }>
						<TooltipWrapper title={ column.displayName } disabled={ column.displayName.length < MIN_LETTERS_FOR_FACET_VALUE_TOOLTIP }>
							<Typography
								display="block"
								component="span"
								variant="body1"
								noWrap={ true }>
								{ column.displayName }
							</Typography>
						</TooltipWrapper>
					</Box>
				}
			/>
		</Box>
	);
};

export const DraggableCustomTableColumnsPanelItem = SortableElement(CustomTableColumnsPanelItem);
