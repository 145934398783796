import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'app/store';

import { TotpAuthenticationCodeState } from './slices';

export const selectTotpAuthenticationCode = (state: RootState): TotpAuthenticationCodeState => state.totpAuthentication.code;
export const selectIsCliAccountLoading = createSelector(
	(state: RootState) => state.totpAuthentication.code.isCliInitLoading,
	(state: RootState) => state.totpAuthentication.code.isCodeLoading,
	(isCliInitLoading, isCodeLoading): boolean => isCliInitLoading || isCodeLoading
);
