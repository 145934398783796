import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { NOT_FOUND } from 'http-status-codes';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { alertSlice } from 'features/alert/slices';

import { releaseDataManagementSlice } from '../slices';
import { StartReleaseProcessRequest } from '../types';
import { startReleaseProcess } from '../api';
import { PayloadAction } from '@reduxjs/toolkit';
import { getReleaseOperationsSaga } from './getReleaseOperationsSaga';

export function* startReleaseProcessSaga({ payload }: PayloadAction<StartReleaseProcessRequest>): SagaIterator {
	try {
		yield put(releaseDataManagementSlice.actions.startReleaseProcessStart());

		yield call(startReleaseProcess, payload);

		yield put(releaseDataManagementSlice.actions.startReleaseProcessEnd());

		yield call(getReleaseOperationsSaga);
	} catch (e) {
		if ((e as AxiosError).response?.status === NOT_FOUND) {
			yield put(alertSlice.actions.showAlert({ message: 'User not found' }));
		} else {
			yield call(handleErrorSaga, e);
		}
		yield put(releaseDataManagementSlice.actions.getOmicsReleaseReadyEnd({ endState: SagaEndState.Fail }));
	}
}
