import React, { FunctionComponent, PropsWithChildren, useCallback, useMemo } from 'react';
import { SortableContainer, SortEnd } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { grey } from '@material-ui/core/colors';

import { Sort } from 'types';

import { Column, ColumnTypes } from '../types';
import { DRAG_PRESS_DELAY } from '../constants';
import { useSortedColumns } from '../hooks';

import { CustomTableHeadColumn, DraggableCustomTableHeadColumn } from './CustomTableHeadColumn';

const useStyles = makeStyles(() => ({
	tableHeadRoot: {
		zIndex: 2,
	},
	tableHeadRowStickyRoot: {
		position: 'sticky',
		top: 0,
	},
	tableHeadColumnStickyRoot: {
		'& th:first-child': {
			position: 'sticky',
			zIndex: 1,
			left: 0,
		},
	},
	draggingColumn: {
		backgroundColor: grey['100'],
		zIndex: 2,
	},
}));

const DraggableContainer = SortableContainer((props: PropsWithChildren<void>) =>
	<TableRow>
		{ props.children }
	</TableRow>
);

interface CustomTableHeadProps {
	columns: Column[]
	sort?: Sort
	onColumnSort: (column: Column) => void
	onColumnsReorder: (columns: Column[]) => void
	isHeaderRowSticky: boolean
	isHeaderColumnSticky: boolean
	isColumnDragging: boolean
	onColumnDragToggle: (isDragging: boolean) => void
}

export const CustomTableHead: FunctionComponent<CustomTableHeadProps> = ({
	columns,
	sort,
	onColumnSort,
	onColumnsReorder,
	isHeaderRowSticky,
	isHeaderColumnSticky,
	isColumnDragging,
	onColumnDragToggle
}) => {
	const classes = useStyles();

	const sortedColumns = useSortedColumns(columns);
	const sortedVisibleColumns = useMemo(() =>
		sortedColumns.filter(column => column.isVisible)
	, [sortedColumns]);
	const flexVisibleColumns = useMemo(() =>
		sortedVisibleColumns.filter(column => column.type === ColumnTypes.Flex)
	, [sortedVisibleColumns]);

	const onDragStart = useCallback(() => {
		onColumnDragToggle(true);
		document.body.style.cursor = 'grabbing';
	}, [onColumnDragToggle]);
	const onDragEnd = useCallback(({ oldIndex: oldVisibleIndex, newIndex: newVisibleIndex }: SortEnd) => {
		const oldIndex = sortedColumns
			.findIndex(column => column.name === flexVisibleColumns[oldVisibleIndex].name);
		const newIndex = sortedColumns
			.findIndex(column => column.name === flexVisibleColumns[newVisibleIndex].name);

		const reorderedColumns = arrayMove(sortedColumns, oldIndex, newIndex);

		onColumnDragToggle(false);
		document.body.style.cursor = '';

		onColumnsReorder(reorderedColumns);
	}, [sortedColumns, flexVisibleColumns, onColumnsReorder, onColumnDragToggle]);

	return (
		<TableHead classes={{ root: clsx(classes.tableHeadRoot, {
			[classes.tableHeadRowStickyRoot]: isHeaderRowSticky,
			[classes.tableHeadColumnStickyRoot]: isHeaderColumnSticky,
		}) }}
		>
			<DraggableContainer
				helperClass={ classes.draggingColumn }
				axis="x"
				lockAxis="x"
				pressDelay={ DRAG_PRESS_DELAY }
				lockToContainerEdges={ true }
				onSortStart={ onDragStart }
				onSortEnd={ onDragEnd }
			>
				{(() => {
					let flexColumnsIndex = 0;
					return sortedVisibleColumns
						.map(column =>
							// eslint-disable-next-line @typescript-eslint/no-extra-parens
							(column.type === ColumnTypes.Static ?
								<CustomTableHeadColumn
									key={ column.name }
									column={ column }
									order={ column.name === sort?.orderBy ? sort.order : undefined }
									onSort={ onColumnSort }
									disableTooltip={ isColumnDragging }
								/> :
								<DraggableCustomTableHeadColumn
									index={ flexColumnsIndex++ }
									key={ column.name }
									column={ column }
									order={ column.name === sort?.orderBy ? sort.order : undefined }
									onSort={ onColumnSort }
									isDraggable={ true }
									disableTooltip={ isColumnDragging }
								/>)
						);
				})()}
			</DraggableContainer>
		</TableHead>
	);
};
