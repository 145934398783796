import { Box, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { addiDataRequestDetailsSlice } from '../slices';
import { selectAddiDataRequestDetails, selectIsAddiDataRequestUpdating, selectIsAddiManagementLoading } from '../selectors';
import { LoaderButton } from 'components';
import { AddiGeneralInfo } from './AddiGeneralInfo';
import { AddiNotesSection } from './AddiNotes';
import { useFormik } from 'formik';
import { AddiDataRequestEditFormFields, UpdateAddiDataRequest } from '../types/UpdateAddiDataRequest';
import { AddiDataRequestForm } from './AddiDataRequestForm';
import { AddiDocuSign } from './AddiDocuSign';
import * as yup from 'yup';

interface AddiDataRequestRouteParams {
	id: string
}

type AddiDataRequestDetailsProps = RouteComponentProps<AddiDataRequestRouteParams>;

export const AddiDataRequestDetails: FunctionComponent<AddiDataRequestDetailsProps> = ({
	match
}) => {
	const MAX_NOTES_LENGTH = 2000;

	const dispatch = useDispatch();

	const isAddiManagementLoading = useSelector(selectIsAddiManagementLoading);
	const isAddiDataRequestUpdating = useSelector(selectIsAddiDataRequestUpdating);
	const addiDataRequest = useSelector(selectAddiDataRequestDetails);

	useEffect(() => {
		dispatch(addiDataRequestDetailsSlice.actions.getAddiDataRequest({ id: match.params.id }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const validationSchema = yup.object().shape({
		notes: yup.string()
			.trim()
			.max(MAX_NOTES_LENGTH, `Max length is ${MAX_NOTES_LENGTH}`)
	});

	const getUpdateFieldsFromFormFields = (formFields: AddiDataRequestEditFormFields): UpdateAddiDataRequest => {
		return {
			id: match.params.id,
			data: {
				notes: formFields.notes
			}
		};
	};

	const form = useFormik<AddiDataRequestEditFormFields>({
		initialValues: {
			notes: ''
		},
		validationSchema,
		onSubmit: values => {
			dispatch(addiDataRequestDetailsSlice.actions.updateAddiDataRequest(getUpdateFieldsFromFormFields(values)));
		}
	});

	useEffect(() => {
		if (addiDataRequest !== null) {
			const initialValues = {
				notes: addiDataRequest.notes ?? ''
			};
			form.resetForm({
				values: initialValues,
				touched: Object.fromEntries(Object.entries(initialValues).map((m: [string, unknown]) => [m[0], true])),
				errors: {},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addiDataRequest]);

	const saveAddiDataRequest = useCallback(() => {
		if (form.isValid) {
			form.handleSubmit();
		}
	}, [form]);

	return <Box>
		<Box py={3} px={5} width="100%">
			<Box mb={3} display="flex" justifyContent="space-between" alignItems="center">
				<Typography component='h1' variant='h4'>
					{'ADDI Data Request'}
				</Typography>
				<Box display={'flex'}>
					<LoaderButton
						isLoading={isAddiDataRequestUpdating}
						ButtonProps={{
							disabled: isAddiManagementLoading,
							size: 'large',
							color: 'primary',
							variant: 'contained',
							onClick: saveAddiDataRequest
						}}
					>
						{'Save Changes'}
					</LoaderButton>
				</Box>
			</Box>
			<Box display="flex" flexWrap="wrap">
				<Box display="flex" flexDirection="column">
					<AddiGeneralInfo />
				</Box>
				<Box display="flex" flexDirection="column">
					<AddiNotesSection form={form} />
					<AddiDocuSign />
					<AddiDataRequestForm />
				</Box>
			</Box>
		</Box>
	</Box>;
};
