import React, { useCallback, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

import { Routes } from 'app/Routes';
import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';
import { LoaderButton } from 'components/LoaderButton';
import { selectIsUserAuthorized, selectIsAccountLoading } from 'auth/selectors';
import { portalAuthManager } from 'auth/portalAuthManager';

import { SkipMenu } from './SkipMenu';
import { HeaderNavigation } from './HeaderNavigation';
import { UserMenu } from './UserMenu';

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		textTransform: 'uppercase',
		'& img': {
			display: 'block',
			height: '3.5rem',
			width: '3.5rem'
		}
	},
	delimiter: {
		opacity: 0.3
	},
	headerToolBar: {
		height: theme.spacing(HEADER_HEIGHT_SPACING)
	},
	appBarRoot: {
		backgroundColor: theme.palette.primary.main
	}
}));

interface HeaderProps {
	classes?: ClassNameMap
}

export const Header: FunctionComponent<HeaderProps> = ({
	classes: classesProp = {}
}) => {
	const classes = useStyles({ classes: classesProp });

	const isUserAuthorized = useSelector(selectIsUserAuthorized);
	const isAccountLoading = useSelector(selectIsAccountLoading);

	const signInHandler = useCallback(() => portalAuthManager.signIn(), []);

	return (
		<AppBar position="relative" classes={{ root: classes.appBarRoot }}>
			<SkipMenu />
			<Toolbar className={ classes.headerToolBar }>
				<Box my={1.5} width="100%" display="flex" flexDirection="flex-row" justifyContent="space-between" alignItems="center">
					<Link
						to={ Routes.Home }
						component={RouterLink}
						className={classes.logo}
						color="inherit"
						underline="none">
						<Box display="flex" flexDirection="flex-row" alignItems="center">
							<picture>
								<source type="image/webp" srcSet="/logo_light.webp"/>
								<img src="/logo_light.png" alt="Answer ALS Logo"/>
							</picture>
							<Box px={1} />
							<Typography variant="h5" component="span" style={{ fontWeight: 'bold' }}>{'Neuromine'}</Typography>
							<Box mx={1}><Typography variant="h5" component="span" style={{ fontWeight: 'normal' }}>{'|'}</Typography></Box>
							<Typography variant="h5" component="span" style={{ fontWeight: 'normal' }}>{'Data Portal'}</Typography>
						</Box>
					</Link>
					<Box display="flex" alignItems="center">
						<HeaderNavigation />
						<Box mx={2} className={classes.delimiter}>
							<Typography variant="h5" component="span" style={{ fontWeight: 'normal' }}>
								{'|'}
							</Typography>
						</Box>
						{ isUserAuthorized ?
							<UserMenu />
							:
							<LoaderButton
								isLoading={isAccountLoading}
								ButtonProps={{
									variant: 'contained',
									onClick: signInHandler
								}}
							>
								{ 'Sign In' }
							</LoaderButton>
						}
					</Box>
				</Box>
			</Toolbar>
		</AppBar>
	);
};
