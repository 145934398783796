import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

import { FieldsConfigResponse } from '../types';

export const getDefaultFieldsConfig = async (): Promise<AxiosResponse<FieldsConfigResponse>> => {
	const method: Method = 'GET';
	const requestOptions = {
		method,
		headers: {
			'Content-Type': 'application/json',
			'accept': 'text/plain',
		}
	};

	return networkManager.fetch<FieldsConfigResponse>(Urls.GetDefaultFieldsConfig, requestOptions);
};
