import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from '../../../network';

export const getAuthenticationCode = async (refreshToken: string): Promise<AxiosResponse> => {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: JSON.stringify(refreshToken)
	};
	return networkManager.fetch(Urls.GetAuthenticationCode, requestOptions);
};
