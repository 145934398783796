import React, { useRef, useCallback, FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	Box,
	Button,
	ClickAwayListener,
	Grow,
	makeStyles,
	MenuItem,
	MenuList,
	Paper,
	Popper
} from '@material-ui/core';

import {
	selectIsLoadingProceedTabs,
	selectProceedTabs,
	selectIsSavingSearchParameters,
	selectDownloadMetadataIsLoading
} from '../selectors';

import { proceedSlice } from '../slices';

import { usePopupState, bindHover, bindMenu } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';

const useStyles = makeStyles(() => ({
	proceedButtonRoot: {
		minWidth: '250px',
		minHeight: '32px'
	}
}));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ProceedButtonWithHover: FunctionComponent = () => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const proceedGroupButtonRef = useRef<HTMLElement | null>(null);

	const [isProceedMenuOpen, setIsProceedMenuOpen] = React.useState(false);

	const proceedMenuItems = useSelector(selectProceedTabs);
	const isLoadingTabs = useSelector(selectIsLoadingProceedTabs);
	const isSavingSearchParameters = useSelector(selectIsSavingSearchParameters);
	const isLoadingDownloadMetadata = useSelector(selectDownloadMetadataIsLoading);

	const isDisabled = isLoadingTabs || isSavingSearchParameters || !proceedMenuItems.length || isLoadingDownloadMetadata;

	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'demoMenu',
	});

	const toggleProceedMenu = useCallback(() => {
		setIsProceedMenuOpen(isOpen => !isOpen);
	}, []);

	const closeProceedMenu = useCallback((closeEvent: React.MouseEvent<Document, MouseEvent>) => {
		if (proceedGroupButtonRef.current && proceedGroupButtonRef.current.contains(closeEvent.target as HTMLElement)) {
			return;
		}
		setIsProceedMenuOpen(false);
	}, []);

	const setProceedGroupButtonRef = useCallback((element: HTMLElement | null) => {
		proceedGroupButtonRef.current = element;
	}, []);

	const handleListKeyDown = useCallback((keyboardEvent: React.KeyboardEvent) => {
		if (keyboardEvent.key === 'Tab') {
			keyboardEvent.preventDefault();
			setIsProceedMenuOpen(false);
		}
	}, []);

	const getClickProceedMenuItemHandler = useCallback((index: number) => () => {
		dispatch(proceedSlice.actions.setActiveTabIndex(index));
		dispatch(proceedSlice.actions.saveSearchParametersRequest());
		setIsProceedMenuOpen(false);
		popupState.close();
	}, [dispatch, popupState]);

	return (
		<Box pl={2}>
			<Button
				{...bindHover(popupState)}
				color='primary'
				size='large'
				aria-controls={isProceedMenuOpen ? 'split-button-menu' : undefined}
				aria-expanded={isProceedMenuOpen ? 'true' : undefined}
				aria-label='select proceed tab'
				aria-haspopup='menu'
				onClick={toggleProceedMenu}
				ref={setProceedGroupButtonRef}
				disabled={isDisabled}
				variant='contained'
				className={classes.proceedButtonRoot}
			>
				{'Next'}
			</Button>
			{!isProceedMenuOpen &&
				<HoverMenu
					{...bindMenu(popupState)}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
					transformOrigin={{ vertical: 78, horizontal: 'left' }}
				>
					<Box style={{ width: `${proceedGroupButtonRef.current ? proceedGroupButtonRef.current.offsetWidth.toString() : '0'}px` }}>
						{proceedMenuItems.map((item, index) =>
							<MenuItem
								key={item}
								onClick={getClickProceedMenuItemHandler(index)}
							>
								{item}
							</MenuItem>
						)}
					</Box>
				</HoverMenu>
			}
			<Popper open={isProceedMenuOpen} anchorEl={proceedGroupButtonRef.current} placement='bottom-start' role={undefined} transition disablePortal>
				{({ TransitionProps }) =>
					<Grow
						{...TransitionProps}
						style={{
							width: `${proceedGroupButtonRef.current ? proceedGroupButtonRef.current.offsetWidth.toString() : '0'}px`
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={closeProceedMenu}>
								<MenuList id='split-button-menu' autoFocusItem={isProceedMenuOpen} onKeyDown={handleListKeyDown}>
									{proceedMenuItems.map((item, index) =>
										<MenuItem
											key={item}
											onClick={getClickProceedMenuItemHandler(index)}
										>
											{item}
										</MenuItem>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				}
			</Popper>
		</Box>
	);
};
