export enum ColumnTypes {
	Static,
	Flex
}
export enum StaticColumnAlign {
	Start,
	End
}

interface ColumnBase<T> {
	name: T
	displayName: string
	sortable: boolean
	description?: string
	isVisible: boolean
}
interface StaticColumn<T> extends ColumnBase<T> {
	type: ColumnTypes.Static
	align?: StaticColumnAlign
}
interface FlexColumn<T> extends ColumnBase<T> {
	type: ColumnTypes.Flex
}

export type Column<T = string> = StaticColumn<T> | FlexColumn<T>;
