import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACTIONS_PREFIX } from '../constants';

export interface TotpAuthenticationCodeState {
	code: string
	codeLifeTimeInSec: number
	codeGenerateTimestamp: number
	codeRemainingTimeInSec: number
	isCodeLoading: boolean
	isCliInitLoading: boolean
	isCliUserAuthorized: boolean
}

const initialState: TotpAuthenticationCodeState = {
	code: '',
	codeLifeTimeInSec: 0,
	codeGenerateTimestamp: 0,
	codeRemainingTimeInSec: 0,
	isCodeLoading: false,
	isCliInitLoading: true,
	isCliUserAuthorized: false
};

export const totpAuthenticationCodeSlice = createSlice({
	name: `${ACTIONS_PREFIX}.code`,
	initialState,
	reducers: {
		startCodeUpdater: () => undefined,
		stopCodeUpdater: () => undefined,

		initStart: (state: TotpAuthenticationCodeState) => {
			state.isCliInitLoading = true;
		},
		initComplete: (state: TotpAuthenticationCodeState) => {
			state.isCliInitLoading = false;
		},

		setCodeRemainingTime: (state: TotpAuthenticationCodeState, { payload }: PayloadAction<number>) => {
			state.codeRemainingTimeInSec = payload;
		},

		generateCodeRequest: (state: TotpAuthenticationCodeState) => {
			state.isCodeLoading = true;
			state.code = '';
			state.codeLifeTimeInSec = 0;
			state.codeRemainingTimeInSec = 0;
		},
		generateCodeSuccess: (state: TotpAuthenticationCodeState, { payload: {
			code,
			codeLifeTimeInSec,
			codeGenerateTimestamp
		} }: PayloadAction<{
			code: string
			codeLifeTimeInSec: number
			codeGenerateTimestamp: number
		}>) => {
			state.isCodeLoading = false;
			state.code = code;
			state.codeLifeTimeInSec = codeLifeTimeInSec;
			state.codeRemainingTimeInSec = codeLifeTimeInSec;
			state.codeGenerateTimestamp = codeGenerateTimestamp;
			state.isCliUserAuthorized = true;
		},
		generateCodeFail: (state: TotpAuthenticationCodeState) => {
			state.isCodeLoading = false;
			state.code = '';
			state.codeLifeTimeInSec = 0;
			state.codeRemainingTimeInSec = 0;
			state.codeGenerateTimestamp = 0;
			state.isCliUserAuthorized = false;
		}
	}
});
