/* eslint-disable @typescript-eslint/no-explicit-any */
import { call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { handleErrorSaga } from 'features/errorHandling/sagas';
import { AxiosResponse } from 'axios';
import { SagaEndState } from 'types';
import { indexManagementSlice } from '../slices';
import { getCurrentIndex, setCurrentIndex } from '../api';
import { IndexItem } from '../types';


export function* setCurrentIndexSaga(params: unknown): SagaIterator {
	try {
		const responseSet = (yield call(setCurrentIndex as any, params)) as AxiosResponse;
		if (responseSet) {
			yield put(indexManagementSlice.actions.getCurrentIndexStart());
			const response = (yield call(getCurrentIndex)) as AxiosResponse<IndexItem>;
			yield put(indexManagementSlice.actions.getCurrentIndexEnd({ endState: SagaEndState.Success, data: response.data }));
		}
	} catch (e) {
		yield call(handleErrorSaga, e);
	}
}
