import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { AxiosResponse, Method } from 'axios';

import { networkManager, Urls } from 'network';

import { GetUsersListResponse, GetUsersListRequest } from '../types';

export function* getUsersList(params: GetUsersListRequest): SagaIterator<AxiosResponse<GetUsersListResponse>> {
	const method: Method = 'POST';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params
	};

	return (yield call(networkManager.fetch, Urls.GetUsersList, requestOptions)) as AxiosResponse<GetUsersListResponse>;
}
