import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { SagaEndState } from 'types';
import { handleErrorSaga } from 'features/errorHandling/sagas';

import { usersManagementSlice } from '../slices';
import { getPossibleUserRoles } from '../api';
import { GetPossibleUserRolesResponse } from '../types';
import { selectIsPossibleUserRolesInitialized, selectPossibleUserRoles } from '../selectors';

export function* getPossibleUserRolesConfigSaga(): SagaIterator<ReturnType<typeof selectPossibleUserRoles>> {
	const isPossibleUserRolesInitialized = (yield select(selectIsPossibleUserRolesInitialized)) as ReturnType<typeof selectIsPossibleUserRolesInitialized>;
	let possibleUserRoles = (yield select(selectPossibleUserRoles)) as ReturnType<typeof selectPossibleUserRoles>;

	try {
		yield put(usersManagementSlice.actions.getPossibleUserRolesConfigStart());

		if (!isPossibleUserRolesInitialized) {
			const possibleUserRolesResponse = (yield call(getPossibleUserRoles)) as AxiosResponse<GetPossibleUserRolesResponse>;
			possibleUserRoles = possibleUserRolesResponse.data;
		}

		yield put(usersManagementSlice.actions.getPossibleUserRolesConfigEnd({
			endState: SagaEndState.Success,
			data: possibleUserRoles
		}));
	} catch (e) {
		yield put(usersManagementSlice.actions.getPossibleUserRolesConfigEnd({ endState: SagaEndState.Fail }));
		yield call(handleErrorSaga, e);
	}

	return possibleUserRoles;
}
