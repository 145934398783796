import React, { FunctionComponent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import Box from '@material-ui/core/Box';
import { createStyles, LinearProgress, Theme } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { common, grey } from '@material-ui/core/colors';

import { HEADER_HEIGHT_SPACING } from 'styles/constants.ui';

import { getGenesHighchartsOptions } from '../utils';
import {
	selectIsHeatmapDataInitialized,
	selectIsHeatmapDataLoading,
	selectParticipants,
	selectGenes, selectHeatmapData
} from '../selectors';
import { omicL4VisualizationSlice } from '../slices';

import { SidePanel } from './SidePanel';
import { BottomAppBar } from './BottomAppBar';

interface OmicL4MatchParams {
	releaseName: string
	searchId: string
}

type OmicL4Props = RouteComponentProps<OmicL4MatchParams>;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			// eslint-disable-next-line @typescript-eslint/no-magic-numbers
			padding: theme.spacing(3)
		},
		wrapper: {
			padding: 0,
			width: '100%',
			height: `calc(100% - ${theme.spacing(HEADER_HEIGHT_SPACING)}px)`,
			backgroundColor: common.white
		},
		chartWrapper: {
			'& > div': {
				overflow: 'auto !important',
				height: '100%'
			},
			'& .highcharts-contextmenu': {
				left: '-102px',
				right: 'auto !important',
				whiteSpace: 'nowrap !important'
			}
		}
	})
);

export const OmicL4: FunctionComponent<OmicL4Props> = ({
	match
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();

	const isHeatmapDataInitialized = useSelector(selectIsHeatmapDataInitialized);
	const isHeatmapDataLoading = useSelector(selectIsHeatmapDataLoading);
	const storeParticipants = useSelector(selectParticipants);
	const storeGenes = useSelector(selectGenes);
	const heatmapData = useSelector(selectHeatmapData);

	useEffect(() => {
		dispatch(omicL4VisualizationSlice.actions.initHeatmapDataRequest({
			searchId: match.params.searchId,
			releaseName: match.params.releaseName
		}));
	}, [dispatch, match.params.searchId, match.params.releaseName]);

	const isPageLoading = isHeatmapDataLoading;

	const options = getGenesHighchartsOptions({
		xAxisCategories: storeParticipants,
		yAxisCategories: storeGenes,
		data: heatmapData
	});

	const isHeatmapFilled = storeParticipants.length > 0 && storeGenes.length > 0;

	return (
		<>
			<Box visibility={isPageLoading ? 'visible' : 'hidden'} position='absolute' top={theme.spacing(HEADER_HEIGHT_SPACING)} width="100%" zIndex={zIndex.appBar}>
				<LinearProgress />
			</Box>
			<div className={ classes.wrapper }>
				<Box display='flex' flexDirection='row' height='100%'>
					<SidePanel />
					<Box p={3} width='100%' overflow='auto'>
						<Typography component='h1' variant='h4'>
							{ 'Heatmap' }
						</Typography>
						<Box pb={2} />
						<Box height={'calc(100% - 120px)'} className={ classes.chartWrapper }>
							{isHeatmapDataInitialized && isHeatmapFilled &&
								<HighchartsReact
									highcharts={Highcharts}
									options={options}
								/>
							}
							{isHeatmapDataInitialized && !isHeatmapFilled &&
								<Box pt={14} color={grey['600']}>
									<Typography
										component="p"
										variant="h3"
										align="center"
										style={{ whiteSpace: 'pre-wrap' }}
									>
										{ 'All fields should be filled' }
									</Typography>
								</Box>
							}
						</Box>
					</Box>
				</Box>
				<BottomAppBar />
			</div>
		</>
	);
};
