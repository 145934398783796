import { ApiApplicationType, ApiUrl } from '../types';

export const getUrlString = (apiUrl: ApiUrl): string => {
	switch (apiUrl.applicationType) {
		case ApiApplicationType.Search:
			return `${process.env.REACT_APP_SEARCH_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.ProceedSearch:
			return `${process.env.REACT_APP_PROCEED_SEARCH_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.Billing:
			return `${process.env.REACT_APP_BILLING_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.UsersManagement:
			return `${process.env.REACT_APP_USERS_MANAGEMENT_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.Wordpress:
			return `${process.env.REACT_APP_WP_REST_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.ExtInt:
			return `${process.env.REACT_APP_EXT_INT_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.Doi:
			return `${process.env.REACT_APP_DOI_MANAGEMENT_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.IndexManagement:
			return `${process.env.REACT_APP_INDEX_MANAGEMENT_API_HOST || ''}${apiUrl.path}`;
		case ApiApplicationType.Statistics:
			return `${process.env.REACT_APP_STATISTICS_API_HOST || ''}${apiUrl.path}`;
		default:
			return '';
	}
};
