export const ACTIONS_PREFIX = 'account';

export const TENANT = process.env.REACT_APP_AUTH_TENANT as string;
export const CLIENT_ID = process.env.REACT_APP_AUTH_CLIENT_ID as string;
export const REGISTERED_REDIRECT_PATH = process.env.REACT_APP_AUTH_REGISTERED_REDIRECT_PATH as string;
export const POLICY_SIGN_IN_SIGN_UP = process.env.REACT_APP_AUTH_POLICY_SIGN_IN_SIGN_UP as string;
export const POLICY_RESET_PASSWORD = process.env.REACT_APP_AUTH_POLICY_RESET_PASSWORD as string;

export enum B2CErrors {
	FORGOT_PASSWORD = 'AADB2C90118',
	CANCEL = 'AADB2C90091'
}

export enum UserRoles {
	Admin = 'Role: Admin',
	Approver = 'Role: Approver',
	Researcher = 'Role: Researcher',
	Requested = 'Role: Requested',
	DataSubmitter = 'Role: DataSubmitter'
}

export enum DataRequestStatus {
	Approved = 'Approved',
	Rejected = 'Rejected',
	Submitted = 'Submitted'
}

export const ADMIN_ROLES = [
	UserRoles.Admin,
	UserRoles.Approver
];
