/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SagaEndPayloadAction, SagaEndState } from 'types';
import { ACTIONS_PREFIX } from '../constants';
import { CreateManagementConfig, CreateManagementConfigResponse, CreateManagementInformation, GetIndexListResponse, IndexData } from '../types';

interface CurrentIndexItem {
	name?: string
}

interface IndexManagementState {
	indexList: IndexData[]
	isGetIndexListLoading: boolean
	indexListTotalCount: number
	isRequestLoading: boolean
	isRequestSent: boolean
	isCreateManagementConfigLoading: boolean
	createManagementConfig: CreateManagementConfig | null
	currentIndex: CurrentIndexItem | null
	isExportingReleasedFilesList: boolean
	isExportingParticipantsIntersecList: boolean
	isExportingUploadedFilesList: boolean
}

const initialState: IndexManagementState = {
	indexList: [],
	isGetIndexListLoading: false,
	indexListTotalCount: 0,
	isRequestLoading: false,
	isRequestSent: false,
	isCreateManagementConfigLoading: false,
	createManagementConfig: null,
	currentIndex: null,
	isExportingReleasedFilesList: false,
	isExportingParticipantsIntersecList: false,
	isExportingUploadedFilesList: false
};

export const indexManagementSlice = createSlice({
	name: ACTIONS_PREFIX,
	initialState,
	reducers: {
		getIndexList: () => undefined,
		getIndexListStart: (state: IndexManagementState) => {
			state.isGetIndexListLoading = true;
		},
		getIndexListEnd: (state: IndexManagementState, { payload }: SagaEndPayloadAction<GetIndexListResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.indexList = payload.data.indexList;
				state.indexListTotalCount = payload.data.totalCount;
				state.isRequestSent = false;
				state.createManagementConfig = null;
			}
			state.isGetIndexListLoading = false;
		},
		sendFormDataRequest: (state: IndexManagementState, _: PayloadAction<CreateManagementInformation>) => {
			state.isRequestLoading = true;
		},
		sendFormDataSuccess: (state: IndexManagementState) => {
			state.isRequestLoading = false;
			state.isRequestSent = true;
		},
		sendFormDataFailure: (state: IndexManagementState) => {
			state.isRequestLoading = false;
		},
		getCreateManagementConfig: () => undefined,
		getCreateManagementConfigStart: (state: IndexManagementState) => {
			state.isCreateManagementConfigLoading = true;
		},
		getCreateManagementConfigEnd: (state: IndexManagementState, { payload }: SagaEndPayloadAction<CreateManagementConfigResponse>) => {
			if (payload.endState === SagaEndState.Success) {
				state.createManagementConfig = payload.data;
			}
			state.isCreateManagementConfigLoading = false;
		},
		setCurrentIndex: (state: IndexManagementState, { payload }) => {
			state.currentIndex = null;
		},
		deleteCurrentIndex: (state: IndexManagementState, { payload }) => undefined,
		deleteCurrentIndexStart: (state: IndexManagementState) => {
			state.currentIndex = null;
		},
		deleteCurrentIndexEnd: (state: IndexManagementState) => undefined,
		getCurrentIndex: () => undefined,
		getCurrentIndexStart: (state: IndexManagementState) => {
			state.currentIndex = null;
		},
		getCurrentIndexEnd: (state: IndexManagementState, { payload }: SagaEndPayloadAction<CurrentIndexItem>) => {
			if (payload.endState === SagaEndState.Success) {
				state.currentIndex = payload.data;
			}
		},
		exportReleasedFilesList: () => undefined,
		exportReleasedFilesListStart: (state: IndexManagementState) => {
			state.isExportingReleasedFilesList = true;
		},
		exportReleasedFilesListEnd: (state: IndexManagementState) => {
			state.isExportingReleasedFilesList = false;
		},
		exportParticipantsIntersecList: () => undefined,
		exportParticipantsIntersecListStart: (state: IndexManagementState) => {
			state.isExportingParticipantsIntersecList = true;
		},
		exportParticipantsIntersecListEnd: (state: IndexManagementState) => {
			state.isExportingParticipantsIntersecList = false;
		},
		exportUploadedFilesList: () => undefined,
		exportUploadedFilesListStart: (state: IndexManagementState) => {
			state.isExportingUploadedFilesList = true;
		},
		exportUploadedFilesListEnd: (state: IndexManagementState) => {
			state.isExportingUploadedFilesList = false;
		},
	},
});
