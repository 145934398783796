import React, { FunctionComponent, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import {
	CustomTable,
	CustomTableColumnsPanel,
	Column,
	CustomTableTextCell,
	CustomTableNoRows
} from 'components/CustomTable';
import { mapCellsDataToNodes } from 'components/CustomTable/utils';
import { TooltipWrapper } from 'components';

import {
	selectFacetsTotalCount,
	selectSearchDetailsTableColumns,
	selectSearchDetailsOrder,
	selectSearchDetailsOrderBy,
	selectSearchDetailsPageNumber,
	selectSearchDetailsRows,
	selectSearchDetailsRowsPerPage,
	selectSearchFieldsConfig,
	selectSearchDetailsIndexKeyColumnName
} from '../selectors';
import { searchDetailsSlice } from '../slices';
import { SEARCH_TABLE_ROWS_PER_PAGE_OPTIONS } from '../constants';
import { mapFieldsConfigToColumns } from '../utils';

const MIN_LETTERS_FOR_BODY_CELL_VALUE = 18;

export const SearchDetailsTable: FunctionComponent = () => {
	const dispatch = useDispatch();

	const rows = useSelector(selectSearchDetailsRows);
	const rowsPerPage = useSelector(selectSearchDetailsRowsPerPage);
	const pageNumber = useSelector(selectSearchDetailsPageNumber);
	const facetsTotalCount = useSelector(selectFacetsTotalCount);
	const tableColumns = useSelector(selectSearchDetailsTableColumns);
	const fieldsConfig = useSelector(selectSearchFieldsConfig);
	const indexKeyColumnName = useSelector(selectSearchDetailsIndexKeyColumnName);
	const order = useSelector(selectSearchDetailsOrder);
	const orderBy = useSelector(selectSearchDetailsOrderBy);

	const defaultColumns = useMemo<Column[]>(() => mapFieldsConfigToColumns(fieldsConfig, indexKeyColumnName),
		[fieldsConfig, indexKeyColumnName]);

	const tableRows = useMemo(() =>
		mapCellsDataToNodes(rows, tableColumns, (row, column) => {
			const cellValue = String(row[column.name] ?? '');
			return (
				<TooltipWrapper title={ cellValue } disabled={ cellValue.length < MIN_LETTERS_FOR_BODY_CELL_VALUE }>
					<CustomTableTextCell value={ cellValue } />
				</TooltipWrapper>
			);
		})
	, [tableColumns, rows]);

	const handleChangePage = useCallback((handleChangePageEvent: unknown, newPage: number) => {
		dispatch(searchDetailsSlice.actions.setPageNumber({ tablePageNumber: newPage }));
	}, [dispatch]);
	const handleChangeRowsPerPage = useCallback((handleChangeRowsEvent: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(searchDetailsSlice.actions.selectRowsPerPage({ tableRowsPerPage: parseInt(handleChangeRowsEvent.target.value, 10) }));
	}, [dispatch]);

	const onColumnSort = useCallback((column: Column) => {
		dispatch(searchDetailsSlice.actions.requestSorting({ fieldName: column.name }));
	}, [dispatch]);
	const onChangeColumns = useCallback((columns: Column[]) => {
		dispatch(searchDetailsSlice.actions.setTableColumns(columns));
	}, [dispatch]);

	return (
		<>
			<Box display="flex" justifyContent="flex-end" pb={0.5}>
				<CustomTableColumnsPanel
					id="search-details-table-columns-panel"
					columns={ tableColumns }
					defaultColumns={ defaultColumns }
					onChangeColumns={ onChangeColumns }
				/>
			</Box>
			<CustomTable
				columns={ tableColumns }
				rows={ tableRows }
				sort={{ order, orderBy }}
				onChangeColumns={ onChangeColumns }
				onColumnSort={ onColumnSort }
				noRowsElement={ <CustomTableNoRows text={ 'No entries found' } /> }
				paginationProps={{
					component: 'div',
					rowsPerPageOptions: SEARCH_TABLE_ROWS_PER_PAGE_OPTIONS,
					count: facetsTotalCount,
					rowsPerPage,
					page: pageNumber,
					onPageChange: handleChangePage,
					// onChangePage: handleChangePage,
					onChangeRowsPerPage: handleChangeRowsPerPage
				}}
				tableProps={{
					id: 'search-details-table',
					'aria-label': 'Table with faceted metadata search results. All data for every participant that matches selection presented in one line.'
				}}
				tableContainerProps={{
					style: {
						maxHeight: 'calc(100vh - 315px)'
					}
				}}
			/>
		</>
	);
};
