import { AuthError, AuthLibraryErrors } from '../AuthError';
import { BrowserCache, StorageType } from '../BrowserCache';
import { getCodes, getRandomString } from '../Crypto';
import { base64UrlDecode, base64UrlEncode } from '../Utils';

import {
	AccountInfo,
	AcquireTokenOptions,
	CacheKeys,
	ClientSettings,
	ErrorResponse,
	LoginSettings,
	LoginRedirectState,
	RedirectState,
	LogoutSettings,
	RedirectPromiseStates,
	RedirectCache,
	GetAuthorizationCodeAttemptCache,
	RedirectToLoginCallbackUriWithErrorCache,
	RedirectToLoginCallbackUriWithAuthorizationCodeCache,
	LogoutAttemptCache,
	TokenInfo,
	TokenResponse,
	ParsedTokenResponse,
	IdTokenInfo
} from './types';

export class PublicClientApplication {
	private readonly settings: ClientSettings;
	private readonly redirectCache: BrowserCache;
	private readonly infoCache: BrowserCache;
	private redirectPromiseState: RedirectPromiseStates;
	private refreshTokenRequest: Promise<TokenInfo> | null;

	constructor(settings: ClientSettings) {
		this.settings = settings;

		this.settings.loginRegisteredRedirectUri = window.encodeURI(this.settings.loginRegisteredRedirectUri);
		this.settings.name = settings.name ?? '';

		const storageName = PublicClientApplication.generateStorageName(settings);
		this.redirectCache = new BrowserCache(storageName, StorageType.sessionStorage);
		this.infoCache = new BrowserCache(storageName, StorageType.localStorage);

		this.redirectPromiseState = RedirectPromiseStates.PENDING;
		this.refreshTokenRequest = null;
	}

	async loginRedirect(loginSettings: LoginSettings = {}) {
		const policy = loginSettings.policy ?? this.settings.defaultPolicy;
		const callbackUri = loginSettings.callbackUri ?? window.location.href;

		const { codeVerifier, codeChallenge } = await getCodes();

		const state: LoginRedirectState = {
			salt: getRandomString(32)
		};
		const encodedState = base64UrlEncode(JSON.stringify(state));

		const cache: GetAuthorizationCodeAttemptCache = {
			step: RedirectState.GET_AUTHORIZATION_CODE_ATTEMPT,
			codeVerifier,
			state: encodedState,
			policy,
			callbackUri
		};

		this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);

		const redirectUri =
			`https://${this.settings.tenant}.b2clogin.com/${this.settings.tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/authorize?` +
			`client_id=${this.settings.clientId}&` +
			`response_type=code&` +
			`redirect_uri=${this.settings.loginRegisteredRedirectUri}&` +
			`response_mode=fragment&` +
			`scope=${this.settings.codeScopes.join(' ')}&` +
			`code_challenge=${codeChallenge}&` +
			`code_challenge_method=S256&` +
			`state=${encodedState}&` +
			`prompt=login`;

		window.location.assign(redirectUri);
	}

	async logoutRedirect(logoutSettings: LogoutSettings = {}) {
		const callbackUri = logoutSettings.callbackUri ?? window.location.href;

		let info: TokenInfo;
		try {
			info = this.infoCache.getItem(CacheKeys.TOKEN_INFO) as TokenInfo;
		} catch (e) {
			throw new AuthError(AuthLibraryErrors.TOKEN_INFO_NOT_FOUND, 'There is no token info in cache');
		}

		const policy = logoutSettings.policy ?? info.policy;

		this.clearInfoCache();

		const cache: LogoutAttemptCache = {
			step: RedirectState.LOGOUT_ATTEMPT,
			callbackUri
		};

		this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);

		const redirectUri =
			`https://${this.settings.tenant}.b2clogin.com/${this.settings.tenant}.onmicrosoft.com/${policy}/oauth2/v2.0/logout?` +
			`post_logout_redirect_uri=${this.settings.loginRegisteredRedirectUri}`;

		window.location.assign(redirectUri);
	}

	async handleRedirectPromise(): Promise<TokenResponse | null> {
		if (this.redirectPromiseState !== RedirectPromiseStates.PENDING) {
			throw new AuthError(AuthLibraryErrors.REDIRECT_PROMISE_DUPLICATE, `"handleRedirectPromise" should be called only once on page load`);
		}

		this.redirectPromiseState = RedirectPromiseStates.IN_PROGRESS;

		try {
			let temporaryCache: RedirectCache;

			try {
				temporaryCache = this.redirectCache.getItem(CacheKeys.REDIRECT_INFO) as RedirectCache;
			} catch (e) {
				await this.updateInfoCache();
				return null;
			}

			this.clearTemporaryCache();

			const redirectHandlers = {
				[RedirectState.GET_AUTHORIZATION_CODE_ATTEMPT]: this.onGetAuthorizationCodeResponseReceived,
				[RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR]: this.onLoginCallbackUriWithErrorLoaded,
				[RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_AUTHORIZATION_CODE]: this.onLoginCallbackUriWithAuthorizationCodeLoaded,

				[RedirectState.LOGOUT_ATTEMPT]: this.onLogoutResponseReceived
			};

			return await redirectHandlers[temporaryCache.step].call(this, temporaryCache);
		} finally {
			this.redirectPromiseState = RedirectPromiseStates.COMPLETED;
		}
	}

	getAccountInfo(): AccountInfo | null {
		if (this.redirectPromiseState !== RedirectPromiseStates.COMPLETED) {
			throw new AuthError(AuthLibraryErrors.REDIRECT_PROMISE_INCOMPLETE, `"getAccountInfo" should be called after "handleRedirectPromise" completion`);
		}

		let info: AccountInfo;

		try {
			info = this.infoCache.getItem(CacheKeys.ACCOUNT_INFO) as AccountInfo;
		} catch (e) {
			return null;
		}

		return info;
	}

	async getTokenInfo(options: AcquireTokenOptions = {}): Promise<TokenInfo> {
		if (this.refreshTokenRequest !== null) {
			return this.refreshTokenRequest;
		}

		options.forceRefresh = (this.redirectPromiseState !== RedirectPromiseStates.COMPLETED ? true : options.forceRefresh) ?? false;
		options.refreshThresholdBeforeExpirationInSec = Math.max(0, options.refreshThresholdBeforeExpirationInSec ?? 60);

		let info: TokenInfo;

		try {
			info = this.infoCache.getItem(CacheKeys.TOKEN_INFO) as TokenInfo;
		} catch (e) {
			throw new AuthError(AuthLibraryErrors.TOKEN_INFO_NOT_FOUND, 'There is no token info in cache');
		}

		const currentTimeInSec = Math.ceil(Date.now() / 1000);

		if (!options.forceRefresh && info.expiresOn - currentTimeInSec > options.refreshThresholdBeforeExpirationInSec) {
			return info;
		} else {
			this.refreshTokenRequest = (async () => {
				let response: TokenResponse | ErrorResponse;

				try {
					response = await this.sendRequest<TokenResponse | ErrorResponse>(`https://${this.settings.tenant}.b2clogin.com/${this.settings.tenant}.onmicrosoft.com/${info.policy}/oauth2/v2.0/token`, {
						grant_type: 'refresh_token',
						client_id: this.settings.clientId,
						scope: this.settings.tokenScopes.join(' '),
						refresh_token: info.refreshToken
					});
				} catch (e) {
					throw new AuthError(AuthLibraryErrors.INVALID_REFRESH_TOKEN_REQUEST, e.message);
				}

				if ('error' in response) {
					this.clearInfoCache();
					throw new AuthError(AuthError.ParseB2CErrorCode(response.error_description), response.error_description);
				}

				const { accountInfo, tokenInfo } = PublicClientApplication.parseTokenResponse(response);

				this.infoCache.setItem(CacheKeys.TOKEN_INFO, tokenInfo);
				this.infoCache.setItem(CacheKeys.ACCOUNT_INFO, accountInfo);

				return tokenInfo;
			})()
				.finally(() => {
					this.refreshTokenRequest = null;
				});

			return this.refreshTokenRequest;
		}
	}

	async updateInfoCache(): Promise<void> {
		try {
			await this.getTokenInfo({ forceRefresh: true });
		} catch (e) {}
	}

	clearInfoCache(): void {
		this.infoCache.removeItem(CacheKeys.TOKEN_INFO);
		this.infoCache.removeItem(CacheKeys.ACCOUNT_INFO);
	}

	private async onGetAuthorizationCodeResponseReceived(temporaryCache: RedirectCache): Promise<TokenResponse | null> {
		const temporaryGetCodeCache = temporaryCache as GetAuthorizationCodeAttemptCache;

		const url = new URL(window.location.href);

		const { state = '', code = '', error, error_description = '' } = url.hash
			.replace(/^#/, '')
			.split('&')
			.map(keyValue => keyValue.split('='))
			.reduce((acc: Record<string, string>, [key, value]) => {
				acc[key] = value;
				return acc;
			}, {});

		if (state === temporaryGetCodeCache.state) {
			if (error) {
				const cache: RedirectToLoginCallbackUriWithErrorCache = {
					step: RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR,
					errorCode: AuthError.ParseB2CErrorCode(error_description),
					errorMessage: error_description
				};

				this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);
			} else {
				try {
					const cache: RedirectToLoginCallbackUriWithAuthorizationCodeCache = {
						step: RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_AUTHORIZATION_CODE,
						code,
						codeVerifier: temporaryGetCodeCache.codeVerifier,
						policy: temporaryGetCodeCache.policy
					};

					this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);
				} catch (e) {
					const cache: RedirectToLoginCallbackUriWithErrorCache = {
						step: RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR,
						errorCode: AuthLibraryErrors.INVALID_STATE_FORMAT,
						errorMessage: 'Login state parse error'
					};

					this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);
				}
			}
		} else {
			const cache: RedirectToLoginCallbackUriWithErrorCache = {
				step: RedirectState.REDIRECT_TO_LOGIN_CALLBACK_URI_WITH_ERROR,
				errorCode: AuthLibraryErrors.INVALID_STATE,
				errorMessage: 'Login state is invalid'
			};

			this.redirectCache.setItem(CacheKeys.REDIRECT_INFO, cache);
		}

		url.hash = '';
		window.location.replace(temporaryGetCodeCache.callbackUri ?? url.toString());
		return null;
	}

	private async onLoginCallbackUriWithErrorLoaded(temporaryCache: RedirectCache): Promise<TokenResponse | null> {
		const temporaryGetCodeErrorCache = temporaryCache as RedirectToLoginCallbackUriWithErrorCache;

		throw new AuthError(temporaryGetCodeErrorCache.errorCode, window.decodeURIComponent(temporaryGetCodeErrorCache.errorMessage).replace(/\+/g, ' '));
	}

	private async onLoginCallbackUriWithAuthorizationCodeLoaded(temporaryCache: RedirectCache): Promise<TokenResponse | null> {
		const temporaryGetTokenCache = temporaryCache as RedirectToLoginCallbackUriWithAuthorizationCodeCache;
		let response: TokenResponse | ErrorResponse;

		try {
			response = await this.sendRequest<TokenResponse | ErrorResponse>(`https://${this.settings.tenant}.b2clogin.com/${this.settings.tenant}.onmicrosoft.com/${temporaryGetTokenCache.policy}/oauth2/v2.0/token`, {
				grant_type: 'authorization_code',
				client_id: this.settings.clientId,
				code: temporaryGetTokenCache.code,
				scope: this.settings.tokenScopes.join(' '),
				code_verifier: temporaryGetTokenCache.codeVerifier
			});
		} catch (e) {
			throw new AuthError(AuthLibraryErrors.INVALID_GET_TOKEN_REQUEST, e.message);
		}

		if ('error' in response) {
			throw new AuthError(AuthError.ParseB2CErrorCode(response.error_description), response.error_description);
		}

		const { accountInfo, tokenInfo } = PublicClientApplication.parseTokenResponse(response);

		this.infoCache.setItem(CacheKeys.TOKEN_INFO, tokenInfo);
		this.infoCache.setItem(CacheKeys.ACCOUNT_INFO, accountInfo);

		return response;
	}

	private async onLogoutResponseReceived(temporaryCache: RedirectCache): Promise<TokenResponse | null> {
		const temporaryLogoutCache = temporaryCache as LogoutAttemptCache;

		window.location.replace(temporaryLogoutCache.callbackUri);
		return null;
	}

	private clearTemporaryCache(): void {
		this.redirectCache.removeItem(CacheKeys.REDIRECT_INFO);
	}

	private async sendRequest<T>(url: string, options: Record<string, string> = {}): Promise<T> {
		const body = new URLSearchParams();

		Object.entries(options)
			.forEach(([key, value]) => body.append(key, String(value)));

		return await fetch(
			url,
			{
				method: 'POST',
				body
			}).then(response => response.json());
	}

	private static parseTokenResponse(response: TokenResponse): ParsedTokenResponse {
		let idTokenInfo: IdTokenInfo;
		let accountInfo: AccountInfo;
		let tokenInfo: TokenInfo;

		try {
			idTokenInfo = JSON.parse(base64UrlDecode(response.id_token.split('.')[1]));
		} catch (e) {
			throw new AuthError(AuthLibraryErrors.INVALID_ID_TOKEN_FORMAT, 'id_token parse error');
		}

		accountInfo = {
			...idTokenInfo,
			name: idTokenInfo.name,
			emails: idTokenInfo.emails
		};

		tokenInfo = {
			accessToken: response.access_token,
			expiresOn: response.expires_on,
			refreshToken: response.refresh_token,
			policy: idTokenInfo.tfp
		};

		return {
			accountInfo,
			tokenInfo
		};
	}

	private static generateStorageName(settings: ClientSettings): string {
		return JSON.stringify(settings);
	}
}
