/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { AxiosResponse, Method } from 'axios';
import { networkManager, Urls } from 'network';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';


export function* deleteCurrentIndex(params: unknown): SagaIterator<AxiosResponse> {
	const method: Method = 'DELETE';
	const requestOptions = {
		method,
		headers: { 'Content-Type': 'application/json' },
		data: params
	};
	const parameters = {
		...Urls.SendCreateManagementRequest,
		path: `${Urls.SendCreateManagementRequest.path}/${requestOptions.data}`
	};

	return (yield call(networkManager.fetch, parameters, requestOptions)) as AxiosResponse<unknown>;
}
